import { useState } from "react";
import {
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import DeleteIcon from "@material-ui/icons/Delete";

const FormActionCell = (props) => {
  const [openDeleteWarning, setOpenDeleteWarning] = useState(false);

  const handleOpenDelete = () => {
    setOpenDeleteWarning(true);
  };

  const handleCloseDelete = () => {
    setOpenDeleteWarning(false);
  };

  const handleDeleteAndClose = () => {
    props.handleDelete(props.value);
    handleCloseDelete();
  };

  
  return (
      <div className="action_cell">
      <Tooltip title="View form">
          <IconButton onClick={() => props.openModal(props.value)}>
            <VisibilityOutlinedIcon />
          </IconButton>
        </Tooltip> 
        <Tooltip title="Delete form">
          <IconButton onClick={handleOpenDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Dialog open={openDeleteWarning} onClose={handleCloseDelete}>
          <DialogTitle>{"Delete Form"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you wish to delete this item? This action cannot be
              undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDelete} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleDeleteAndClose}
              color="secondary"
              variant="contained"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
};

export default FormActionCell;
