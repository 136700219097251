import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import PageForm from "../../../components/forms/page";
import { useSelector } from "react-redux";
import UPDATE_PAGE from "../../../graphql/UPDATE_PAGE";
import GET_PAGES from "../../../graphql/GET_PAGES";
import GET_LAYOUTS from "../../../graphql/GET_LAYOUTS";
import { useMutation, useQuery } from "@apollo/client";
import Loader from "../../../components/layout/Loader";
import ErrorToast from "../../../components/layout/ErrorToast";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import * as Routes from "../../../routes";
import { RootState } from "../../../store/store";
import slugify from "slugify";
import FormLayout from "../../../components/layout/FormLayout";

const CreatePage = () => {
  let history = useHistory();
  const params = useParams<SiteParams>();
  const state = useSelector((state: RootState) => state);
  const [page, setPage] = useState({
    physical_file_path: "",
    slug: "",
    content: "",
    searchable: "true",
    layout: "",
  });
  const [layouts, setLayouts] = useState([]);
  const [metadata, setMetadata] = useState({
    name: "",
    type: "",
    meta_title: "",
    meta_desc: "",
  });

  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(GET_PAGES, {
    variables: {
      id: params.id,
    },
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  useEffect(() => {
    if (queryData) {
      const result = queryData.admin_pages.results[0];
      setPage({
        physical_file_path: result.physical_file_path,
        slug: result.slug,
        content: result.content,
        searchable: result.searchable,
        layout: result.layout,
      });
      setMetadata({ ...metadata, ...result.metadata });
    }
  }, [queryData]);


  const {
    loading: loadingLayout,
    error: layoutError,
    data: layoutData,
  } = useQuery(GET_LAYOUTS, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  useEffect(() => {
    if (layoutData) {
      setLayouts(layoutData.admin_liquid_layouts.results);
    }
  }, [layoutData]);

  const [
    updatePage,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(UPDATE_PAGE, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const newPage = data?.admin_page_create;
      const existingPages: any = cache.readQuery({ query: GET_PAGES });

      if (newPage && existingPages) {
        cache.writeQuery({
          query: GET_PAGES,
          data: {
            admin_pages: {
              results: [...existingPages?.admin_pages.results, newPage],
            },
          },
        });
      }
    },
    onCompleted: (data) => {
      if (data) {
        history.push(Routes.PAGES_LINK(params.site));
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "slug") {
      const slug = event.target.value
        .split("/")
        .map((slugPart: string) => slugify(slugPart).toLowerCase())
        .join("/");

      setPage({
        ...page,
        slug: slug
      });
    } else {
      setPage({
        ...page,
        [event.target.name]: event.target.value as string,
      });
    }
  };

  const handleMetadataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMetadata({
      ...metadata,
      [event.target.name]: event.target.value as string,
    });
  };

  const handleTypeChange = (event: any, newValue: string) => {
    setMetadata({
      ...metadata,
      type: newValue,
    });
  };

  const handleLayoutChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setPage({
      ...page,
      layout: event.target.value as string,
    });
  };

  const handleBodyChange = (content: string) => {
    setPage({
      ...page,
      content: content,
    });
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    updatePage({
      variables: {
        id: params.id,
        slug: page.slug,
        content: page.content,
        searchable: page.searchable === "false " ? false : true,
        layout: page.layout,
        physical_file_path: page.physical_file_path,
        metadata: metadata,
      },
    });
  };

  return (
    <FormLayout loading={mutationLoading} formHeading="Create Page" backLink={Routes.PAGES_LINK(params.site)} size="lg">
            {mutationError ? (
        <ErrorToast open={true} errorMessage={mutationError.message} />
      ) : null}
     
      <PageForm
          handleChange={handleChange}
          handleMetadataChange={handleMetadataChange}
          handleTypeChange={handleTypeChange}
          handleLayoutChange={handleLayoutChange}
          handleSubmit={handleSubmit}
          handleBodyChange={handleBodyChange}
          isFetching={false}
          page={page}
          layouts={layouts}
          metadata={metadata}
          isSubmitting={mutationLoading}
        />
    </FormLayout>
   
  );
};

interface SiteParams {
  site: string;
  id: string;
}

export default CreatePage;
