import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import LayoutForm from '../../../components/forms/layout';
import { useSelector } from 'react-redux'
import UPDATE_LAYOUT from '../../../graphql/UPDATE_LAYOUT';
import GET_LAYOUTS from '../../../graphql/GET_LAYOUTS';
import { useQuery, useMutation } from '@apollo/client';
import ErrorToast from '../../../components/layout/ErrorToast';
import * as Routes from "../../../routes";
import { RootState } from '../../../store/store';
import FormLayout from '../../../components/layout/FormLayout';


const UpdateLayout = () => {
    let history = useHistory();
    const params = useParams<LayoutParams>()
    const state = useSelector((state: RootState) => state)
    const [layout, setLayout] = useState({
        path: "", 
        body: "", 
        physical_file_path: "", 
    })
    const [metadata, setMetadata] = useState({
        name: "",
        type: ""
    })

    const { loading: queryLoading, error: queryError, data: queryData } = useQuery(GET_LAYOUTS, {
      variables : {
        id: params.id
      },
      context: {
        headers: {
          'Authorization': `Bearer ${state.current_user.user?.token}`,
          'instance': state.current_site.site?.slug
        }
      }
    });

    useEffect(() => {
        if(queryData){
            const result = queryData.admin_liquid_layouts.results[0];
            setLayout({
                physical_file_path: result.physical_file_path, 
                path: result.path,
                body: result.body 
            })
            setMetadata({ ...metadata, ...result.metadata })
        }
    }, [queryData])


    const [updateLayout, { data: mutationData, loading: mutationLoading, error: mutationError }] = useMutation(UPDATE_LAYOUT, {
        context: {
            headers: {
                'Authorization': `Bearer ${state.current_user.user?.token}`,
                'instance': state.current_site.site?.slug
            }
        },
        onCompleted: (data) => {
            if(data){
                history.push(Routes.LAYOUTS_LINK(params.site));
            }
        },
        onError: (error) => {
            console.log(error)
        }
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLayout({
            ...layout,
            [event.target.name]: event.target.value as string,
        })
    }
    
    const handleBodyChange = (content: string) => {
        setLayout({
            ...layout,
            body: content,
        })
    } 

    const handleMetadataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.name === "name"){
            setMetadata({
                ...metadata,
                name: event.target.value as string
            })
        }
        else if(event.target.name === "type"){
            setMetadata({
                ...metadata,
                type: event.target.value as string
            })
        }
    }

    const handleSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();
        updateLayout({
            variables: {
                id: params.id,
                physical_file_path: layout.physical_file_path, 
                path: layout.path, 
                body: layout.body, 
                metadata: metadata, 
            }
        })
    }
   
    return(
        <FormLayout loading={mutationLoading} formHeading="Edit Site Layout" backLink={Routes.LAYOUTS_LINK(params.site)} size="lg">
            { mutationError ? <ErrorToast open={true} errorMessage={mutationError.message} />: null }
            <LayoutForm
                handleChange={handleChange}
                handleBodyChange={handleBodyChange}
                handleMetadataChange={handleMetadataChange}
                handleSubmit={handleSubmit}
                isFetching={queryLoading}
                layout={layout}
                metadata={metadata}
                isSubmitting={mutationLoading}
            />
        </FormLayout>

    );
}

interface LayoutParams {
  site: string
  id: string
}

export default UpdateLayout;