import { gql } from '@apollo/client';

const UPDATE_LAYOUT = gql`
    mutation update_layout(
        $id: ID!, 
        $path: String!, 
        $physical_file_path: String!, 
        $metadata: HashObject!, 
        $body: String!
    ){
    admin_liquid_layout_update(id: $id, liquid_layout: {path: $path, body: $body, metadata: $metadata, physical_file_path: $physical_file_path}) {
        id
        updated_at
        created_at
        path
        metadata
        body
        physical_file_path
    }
}

`

export default UPDATE_LAYOUT;