import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import * as Routes from "../../routes";

const PagesActionCell = (props) => {
  const [openDeleteWarning, setOpenDeleteWarning] = useState(false);

  const handleOpenDelete = () => {
    setOpenDeleteWarning(true);
  };

  const handleCloseDelete = () => {
    setOpenDeleteWarning(false);
  };

  const handleDeleteAndClose = () => {
    props.handleDelete(props.value);
    handleCloseDelete();
  };

  return (
    <div className="action_cell">
      {props.slug && props.collectionURL ? (
        <Tooltip title="View collection item on live site" placement="right">
          <IconButton
            component={"a"}
            target="_blank"
            href={`${props.baseURL}${props.collectionURL}/${props.slug}`}
          >
            <VisibilityOutlinedIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      <Tooltip title="Edit collection item" placement="right">
        <IconButton
          component={RouterLink}
          to={Routes.ENTITY_EDIT_LINK(
            props.site,
            props.collection,
            props.value
          )}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete collection item" placement="right">
        <IconButton onClick={handleOpenDelete}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={openDeleteWarning} onClose={handleCloseDelete}>
        <DialogTitle>{"Delete Item"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you wish to delete this item? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteAndClose}
            color="secondary"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PagesActionCell;
