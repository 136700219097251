import { gql } from "@apollo/client";

const GET_SIDEMENUS = gql`
  query sidemenu_get(
    $per_page: Int = 3000
    $page: Int = 1
  ) {
    models(
      filter: {
        name: { exact: "side_menu" }
      }
      per_page: $per_page
      page: $page
    ) {
      total_entries
      total_pages
      results {
        id
        created_at
        updated_at
        properties
      }
    }
  }
`;

export default GET_SIDEMENUS;
