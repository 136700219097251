import { useState, useMemo, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useQuery, useMutation } from "@apollo/client";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import GET_SINGLE_CONTENT_TYPE_WITH_ENTITIES from "../../../graphql/GET_SINGLE_CONTENT_TYPE_WITH_ENTITIES";
import CREATE_RECORD from "../../../graphql/CREATE_RECORD";
import UPDATE_RECORD from "../../../graphql/UPDATE_RECORD";

import * as Routes from "../../../routes";
import { RootState } from "../../../store/store";
import Loader from "../../../components/layout/Loader";
import TextAreaInput from "../../../components/entities/TextAreaInput";
import TextInput from "../../../components/entities/TextInput";
import RichTextAreaInput from "../../../components/entities/RichTextAreaInput";
import NumberInput from "../../../components/entities/NumberInput";
import BooleanInput from "../../../components/entities/BooleanInput";
import ImageInput from "../../../components/entities/ImageInput";
import ImageGroupInput from "../../../components/entities/ImageGroupInput";
import DateTimeInput from "../../../components/entities/DateTimeInput";
import ArrayInput from "../../../components/entities/ArrayInput";
import ColourInput from "../../../components/entities/ColourInput";
import DateInput from "../../../components/entities/DateInput";
import TimeInput from "../../../components/entities/TimeInput";
import SingleRelationInput from "../../../components/entities/SingleRelationInput";
import ManyRelationInput from "../../../components/entities/ManyRelationsInput";
import ErrorToast from "../../../components/layout/ErrorToast";
import FormLayout from "../../../components/layout/FormLayout";

const UpdateComponentItem = () => {
  const [properties, setProperties] = useState<PropertyValue[]>([]);
  const params = useParams<CollectionParams>();
  const state = useSelector((state: RootState) => state);
  let history = useHistory();

  const [
    createRecord,
    {
      data: createMutationData,
      loading: createMutationLoading,
      error: createMutationError,
    },
  ] = useMutation(CREATE_RECORD, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const newRecord = data?.record_create;
      const existingRecords: any = cache.readQuery({
        query: GET_SINGLE_CONTENT_TYPE_WITH_ENTITIES,
        variables: {
          name: params.slug,
        },
      });
      if (newRecord && existingRecords) {
        cache.writeQuery({
          query: GET_SINGLE_CONTENT_TYPE_WITH_ENTITIES,
          variables: {
            name: params.slug,
          },
          data: {
            records: {
              results: [...existingRecords?.records.results, newRecord],
            },
          },
        });
      }
    },
    onCompleted: (data) => {
      if (data) {
        history.push(Routes.COMPONENT_LINK(params.site));
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [
    updateRecord,
    {
      data: editMutationData,
      loading: editMutationLoading,
      error: editMutationError,
    },
  ] = useMutation(UPDATE_RECORD, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const updatedRecord = data?.record_update;
      const existingRecords: any = cache.readQuery({
        query: GET_SINGLE_CONTENT_TYPE_WITH_ENTITIES,
        variables: {
          name: params.slug,
        },
      });

      // map new result
      const updatedRecords = existingRecords.records.results.map(
        (item: any) => {
          if (item.id === updatedRecord.id) {
            return updatedRecord;
          }
          return item;
        }
      );

      if (updatedRecord && existingRecords) {
        cache.writeQuery({
          query: GET_SINGLE_CONTENT_TYPE_WITH_ENTITIES,
          variables: {
            name: params.slug,
          },
          data: {
            records: {
              results: [...updatedRecords],
            },
          },
        });
      }
    },
    onCompleted: (data) => {
      if (data) {
        history.push(Routes.COMPONENT_LINK(params.site));
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { loading, error, data } = useQuery(
    GET_SINGLE_CONTENT_TYPE_WITH_ENTITIES,
    {
      variables: {
        name: params.slug,
      },
      context: {
        headers: {
          Authorization: `Bearer ${state.current_user.user?.token}`,
          instance: state.current_site.site?.slug,
        },
      },
    }
  );

  const collectionType: any = useMemo(() => {
    return data ? data.admin_tables.results[0] : null;
  }, [data]);

  const collectionItem: any = useMemo(() => {
    return data ? data.records.results[0] : null;
  }, [data]);

  useEffect(() => {
    if (collectionType) {
      const _props = [...collectionType.properties];

      let sorted = _props.sort(
        (a: CollectionProperty, b: CollectionProperty) => {
          return b.metadata.display_priority - a.metadata.display_priority;
        }
      );

      const _properties = sorted.map(
        (_prop: CollectionProperty): PropertyValue => {
          let value: PropertyValue = {
            name: _prop.name,
            input_type: _prop.metadata.type,
            display_name: _prop.metadata.display_name,
          };

          if (_prop.belongs_to) {
            value = {
              ...value,
              belongs_to: _prop.belongs_to,
            };
          }

          if (collectionItem) {
            return mapPropertyWithCurrentValue(
              value,
              _prop.metadata.type,
              collectionItem
            );
          }

          return mapPropertyValueType(value, _prop.metadata.type);
        }
      );

      setProperties(_properties);
    }
  }, [collectionType, collectionItem]);

  const mapInputComponent = (type: string, index: number) => {
    switch (type) {
      case "short_text":
        return (
          <TextInput
            index={index}
            key={index}
            handleChange={handleTextInputChange}
            propertyValue={properties[index]}
          />
        );
      case "long_text":
        return (
          <TextAreaInput
            index={index}
            key={index}
            handleChange={handleTextInputChange}
            propertyValue={properties[index]}
          />
        );
      case "rich_text":
        return (
          <RichTextAreaInput
            index={index}
            key={index}
            handleChange={handleTextInputChange}
            propertyValue={properties[index]}
          />
        );
      case "int":
        return (
          <NumberInput
            index={index}
            key={index}
            handleChange={handleIntInputChange}
            propertyValue={properties[index]}
          />
        );
      case "float":
        return (
          <NumberInput
            index={index}
            key={index}
            handleChange={handleFloatInputChange}
            propertyValue={properties[index]}
          />
        );
      case "boolean":
        return (
          <BooleanInput
            index={index}
            key={index}
            handleChange={handleBooleanInputChange}
            propertyValue={properties[index]}
          />
        );
      case "datetime":
        return (
          <DateTimeInput
            index={index}
            key={index}
            handleChange={handleTextInputChange}
            propertyValue={properties[index]}
          />
        );
      case "date":
        return (
          <DateInput
            index={index}
            key={index}
            handleChange={handleTextInputChange}
            propertyValue={properties[index]}
          />
        );
      case "time":
        return (
          <TimeInput
            index={index}
            key={index}
            handleChange={handleTextInputChange}
            propertyValue={properties[index]}
          />
        );
      case "array":
        return (
          <ArrayInput
            index={index}
            key={index}
            handleEditArray={handleArraySetInputChange}
            handleChange={handleArrayAddInputChange}
            handleRemove={handleArrayRemoveInputChange}
            propertyValue={properties[index]}
          />
        );
      case "image":
        return (
          <ImageInput
            index={index}
            key={index}
            handleChange={handleTextInputChange}
            propertyValue={properties[index]}
            collectionType={collectionType.name}
          />
        );
      case "image_group":
        return (
          <ImageGroupInput
            index={index}
            key={index}
            handleChange={handleArrayAddInputChange}
            handleRemove={handleArrayRemoveInputChange}
            propertyValue={properties[index]}
            collectionType={collectionType.name}
          />
        );
      case "colour":
        return (
          <ColourInput
            index={index}
            key={index}
            handleChange={handleTextInputChange}
            propertyValue={properties[index]}
          />
        );
      case "one_to_many":
        return (
          <ManyRelationInput
            index={index}
            key={index}
            handleChange={handleArraySetInputChange}
            propertyValue={properties[index]}
            collectionType={collectionType.name}
          />
        );
      case "one_to_one":
        return (
          <SingleRelationInput
            index={index}
            key={index}
            handleChange={handleIntInputChange}
            propertyValue={properties[index]}
            collectionType={collectionType.name}
          />
        );
      case "many_to_many":
        return (
          <TextAreaInput
            index={index}
            key={index}
            handleChange={handleTextInputChange}
            propertyValue={properties[index]}
          />
        );
      case "many_to_one":
        return (
          <SingleRelationInput
            index={index}
            key={index}
            handleChange={handleIntInputChange}
            propertyValue={properties[index]}
            collectionType={collectionType.name}
          />
        );
      default:
        return null;
    }
  };

  const mapPropertyWithCurrentValue = (
    value: PropertyValue,
    type: string,
    collectionItem: any
  ): PropertyValue => {
    const currentValue = collectionItem.properties[value.name];
    return mapPropertyValueType(value, type, currentValue);
  };

  const mapPropertyValueType = (
    value: PropertyValue,
    type: string,
    currentValue?: any
  ): PropertyValue => {
    switch (type) {
      case "short_text":
        return {
          ...value,
          value: currentValue ? currentValue : "",
        };
      case "long_text":
        return {
          ...value,
          value: currentValue ? currentValue : "",
        };
      case "rich_text":
        return {
          ...value,
          value: currentValue ? currentValue : "",
        };
      case "int":
        return {
          ...value,
          value_int: currentValue ? currentValue : 0,
        };
      case "float":
        return {
          ...value,
          value_float: currentValue ? currentValue : 0,
        };
      case "boolean":
        return {
          ...value,
          value_boolean: currentValue ? currentValue : false,
        };
      case "datetime":
        return {
          ...value,
          value: currentValue ? currentValue : "",
        };
      case "date":
        return {
          ...value,
          value: currentValue ? currentValue : "",
        };
      case "time":
        return {
          ...value,
          value: currentValue ? currentValue : "",
        };
      case "array":
        return {
          ...value,
          value_array: currentValue ? currentValue : [],
        };
      case "image":
        return {
          ...value,
          value: currentValue ? currentValue : "",
        };
      case "image_group":
        return {
          ...value,
          value_array: currentValue ? currentValue : [],
        };
      case "colour":
        return {
          ...value,
          value: currentValue ? currentValue : "",
        };
      case "one_to_many":
        return {
          ...value,
          value_array: currentValue ? currentValue : [],
        };
      case "one_to_one":
        return {
          ...value,
          value_int: currentValue ? currentValue : 0,
        };
      case "many_to_many":
        return {
          ...value,
          value_array: currentValue ? currentValue : [],
        };
      case "many_to_one":
        return {
          ...value,
          value_int: currentValue ? currentValue : 0,
        };
      default:
        return {
          ...value,
        };
    }
  };

  const handleTextInputChange = (value: string, index: number) => {
    let newArr = [...properties];
    newArr[index] = {
      ...newArr[index],
      value: value,
    };

    setProperties(newArr);
  };

  const handleIntInputChange = (value: number, index: number) => {
    let newArr = [...properties];

    newArr[index] = {
      ...newArr[index],
      value_int: value,
    };

    setProperties(newArr);
  };

  const handleFloatInputChange = (value: number, index: number) => {
    let newArr = [...properties];

    newArr[index] = {
      ...newArr[index],
      value_float: value,
    };

    setProperties(newArr);
  };

  const handleBooleanInputChange = (value: boolean, index: number) => {
    let newArr = [...properties];

    newArr[index] = {
      ...newArr[index],
      value_boolean: value,
    };

    setProperties(newArr);
  };

  const handleArraySetInputChange = (value: string[], index: number) => {
    let newArr = [...properties];

    newArr[index] = {
      ...newArr[index],
      value_array: [...value],
    };

    setProperties(newArr);
  };

  const handleArrayAddInputChange = (value: string, index: number) => {
    let newArr = [...properties];

    newArr[index] = {
      ...newArr[index],
      value_array: [...newArr[index].value_array!, value],
    };

    setProperties(newArr);
  };

  const handleArrayRemoveInputChange = (index: number, removeIndex: number) => {
    let newArr = [...properties];

    const newValueArray = newArr[index].value_array!.filter(
      (arrItem: string, index) => {
        if (index !== removeIndex) {
          return arrItem;
        }
      }
    );

    newArr[index] = {
      ...newArr[index],
      value_array: newValueArray as string[],
    };

    setProperties(newArr);
  };

  const handleSubmit = () => {
    const mappedProperties = properties.map(
      (_prop: PropertyAttribute): PropertyAttribute => {
        delete _prop["display_name"];
        delete _prop["input_type"];
        delete _prop["belongs_to"];

        return _prop;
      }
    );

    if (collectionItem) {
      updateRecord({
        variables: {
          id: collectionItem.id,
          properties: mappedProperties,
        },
      });
    } else {
      createRecord({
        variables: {
          table: params.slug,
          properties: mappedProperties,
        },
      });
    }
  };

  return (
    <FormLayout
      loading={loading}
      formHeading={`Edit ${
        collectionType ? collectionType.metadata.display_name : ""
      }`}
      backLink={Routes.COMPONENT_LINK(params.site)}
      size="lg"
    >
      {collectionType ? (
        <>
          <Grid container spacing={5}>
            {properties.map((_property, i: number) =>
              mapInputComponent(_property.input_type, i)
            )}
          </Grid>
          <Button
            className="form_button"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Submit
          </Button>
          {createMutationLoading || editMutationLoading ? (
            <Loader open={true} />
          ) : null}
          {createMutationError ? (
            <ErrorToast
              open={true}
              errorMessage={createMutationError.message}
            />
          ) : null}
        </>
      ) : null}
    </FormLayout>
  );
};

interface CollectionParams {
  site: string;
  slug: string;
}

export interface CollectionProperty {
  name: string;
  belongs_to?: string;
  attribute_type: string | null;
  metadata: PropertyMetadata;
}

export interface PropertyMetadata {
  display_name: string;
  type: string;
  private: boolean;
  display_priority: number;
  required: boolean;
}

export interface PropertyValue {
  name: string;
  value?: string;
  value_int?: number;
  value_float?: number;
  value_boolean?: boolean;
  value_array?: string[];
  input_type: string;
  display_name: string;
  belongs_to?: string;
}

export interface PropertyAttribute {
  name: string;
  value?: string;
  value_int?: number;
  value_float?: number;
  value_boolean?: boolean;
  value_array?: string[];
  input_type?: string;
  display_name?: string;
  belongs_to?: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({}));

export default UpdateComponentItem;
