import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import CityForm from "../../../../../components/forms/city";
import { useSelector } from "react-redux";
import CREATE_CITY from "../../../../../graphql/areas/CREATE_CITY";
import UPDATE_CITY from "../../../../../graphql/areas/UPDATE_CITY";
import GET_CITIES from "../../../../../graphql/areas/GET_CITIES";
import { useMutation, useQuery } from "@apollo/client";
import ErrorToast from "../../../../../components/layout/ErrorToast";
import * as Routes from "../../../../../routes";
import { RootState } from "../../../../../store/store";
import FormLayout from "../../../../../components/layout/FormLayout";
import { handleChange, handleBlur } from "../../../../../utils/slugsCheck";

const CreateCityPage = () => {
  let history = useHistory();
  const params = useParams<SiteParams>();
  const state = useSelector((state: RootState) => state);
  const [city, setCity] = useState({
    name: "",
    slug: "",
    state: "",
  });

  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(GET_CITIES, {
    variables: {
      id: params.id,
    },
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  useEffect(() => {
    if (queryData && params.id) {
      const result = queryData.models.results[0];
      setCity({
        ...city,
        name: result.properties.name,
        slug: result.properties.slug,
        state: result.properties.state,
      });
    }
  }, [queryData]);

  const [
    createCity,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(CREATE_CITY, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const newInfo = data?.model_create;
      const existingInfos: any = cache.readQuery({ query: GET_CITIES });

      if (newInfo && existingInfos) {
        cache.writeQuery({
          query: GET_CITIES,
          data: {
            models: {
              results: [...existingInfos?.models.results, newInfo],
            },
          },
        });
      }
    },
    onCompleted: (data) => {
      if (data) {
        history.push(Routes.CITY_LINK(params.site));
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [
    updateCity,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useMutation(UPDATE_CITY, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const newInfo = data?.model_update;
      const existingInfos: any = cache.readQuery({ query: GET_CITIES });

      const updatedCities = existingInfos.models.results.map((item: any) => {
        if (item.id === newInfo.id) {
          return newInfo;
        }
        return item;
      });

      if (newInfo && existingInfos) {
        cache.writeQuery({
          query: GET_CITIES,
          data: {
            models: {
              results: updatedCities,
            },
          },
        });
      }
    },
    onCompleted: (data) => {
      if (data) {
        history.push(Routes.CITY_LINK(params.site));
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (params.id) {
      updateCity({
        variables: {
          id: params.id,
          properties: [
            { name: "slug", value: city.slug },
            { name: "state", value: city.state },
            { name: "name", value: city.name },
          ],
        },
      });
    } else {
      createCity({
        variables: {
          properties: [
            { name: "slug", value: city.slug },
            { name: "state", value: city.state },
            { name: "name", value: city.name },
          ],
        },
      });
    }
  };

  return (
    <FormLayout
      formHeading={params.id ? "Update City" : "Create City"}
      backLink={Routes.CITY_LINK(params.site)}
      size="lg"
      loading={mutationLoading}
    >
      {mutationError ? (
        <ErrorToast open={true} errorMessage={mutationError.message} />
      ) : null}
      <CityForm
        handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChange(e, setCity, city)
        }
        handleSubmit={handleSubmit}
        handleBlur={(e: React.FocusEvent<HTMLInputElement>) =>
          handleBlur(e, setCity, city)
        }
        isFetching={false}
        city={city}
        setCity={setCity}
        isSubmitting={mutationLoading}
      />
    </FormLayout>
  );
};

interface SiteParams {
  site: string;
  id?: string;
}

export default CreateCityPage;
