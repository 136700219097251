import React from "react";
import { useParams, NavLink, Link as RouterLink } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  Divider,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import PersonIcon from "@material-ui/icons/Person";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import * as Routes from "../../../routes";

const CRMSubMenu = (props) => {
  const params = useParams();

  return (
    <List disablePadding="true">
      <ListItem button onClick={props.handleBackClick}>
        <ListItemIcon>
          <ArrowBackIosIcon />
        </ListItemIcon>
        <ListItemText primary="Back" />
      </ListItem>
      <Divider />
      <ListSubheader>Customer Relationship Manager</ListSubheader>
      <ListItem
        button
        component={NavLink}
        to={Routes.SITE_USERS_LINK(params.site)}
      >
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </ListItem>
      <ListItem
        button
        component={NavLink}
        to={Routes.WEBFORMS_LINK(params.site)}
      >
        <ListItemIcon>
          <ContactMailIcon />
        </ListItemIcon>
        <ListItemText primary="Webforms" />
      </ListItem>
    </List>
  );
};

export default CRMSubMenu;
