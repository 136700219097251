import { useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { Typography, Container, Grid } from "@material-ui/core";

import { RootState } from "../../store/store";
import DashboardCard from "../../components/dashboard/dashboardCard";
import { fetchFavourites } from "../../store/currentSite/actions";

const Dashboard = () => {
  const classes = useStyles();
  const [favourites, setFavourites] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const state = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFavourites());
  }, [dispatch, state.current_user]);

  useEffect(() => {
    if (state.current_site.site?.favourites) {
      setFavourites(state.current_site.site?.favourites);
    }
  });


  return (
    <Container>
      <Typography
        component="h1"
        variant="h3"
        style={{ marginBottom: 30 }}
        className="heading"
      >
        Dashboard
      </Typography>
      <Grid container spacing={4}>
        {favourites.map((favourite: any) => {
          return (
            <Grid item xs={12} sm={6} md={4}>
              <DashboardCard slug={favourite.slug} type={favourite.type} />
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  loader: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  logo: {
    height: "40px",
    marginRight: theme.spacing(1),
  },
}));

export default Dashboard;
