import { gql } from '@apollo/client';

const GET_INFO_PAGES = gql`
    query get_info_article($id: ID $slug: String $info_collection_id: Int $info_category_id: Int $per_page: Int = 3000 $page: Int = 1) {
  models(
    filter: { 
      name: { exact: "modules/infopages/info_article" }, 
      id: { value: $id },
      properties: [
        { name: "slug" value: $slug },
        { name: "info_collection_page_id" value_int: $info_collection_id },
        { name: "info_category_id" value_int: $info_category_id }
      ],
      deleted_at: { exists: false } 
    }, 
    per_page: $per_page, 
    page: $page,
    sort: { properties: { name: "info_collection_page_id" order: ASC }} 
  ) {
    total_entries
    total_pages
    results {
      id
      created_at
      updated_at
      properties
      collection: related_model(model_schema_name: "modules/infopages/info_collection_page" join_on_property: "info_collection_page_id") { 
        id
        properties 
      }
    }
  }
}
  `

export default GET_INFO_PAGES;