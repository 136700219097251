
import {Button} from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

function BackDirectoryCard(props: FolderCardProps){    
    return (        
    <Button onClick={props.handleBack} startIcon={<ArrowBackIosIcon />} style={{marginRight:'20px'}}>
      Back
    </Button>
    );
}

interface FolderCardProps {
    handleBack: any
}

export default BackDirectoryCard;