import React, { ChangeEvent, useState, useEffect } from "react";
import { PropertyValue } from "../../pages/site/entities/create";
import { TextField, Button, Grid, InputAdornment } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import MenuInput from "./MenuInput";

const ArrayInput = (props: ArrayInputProps) => {
  const [inputValue, setInputValue] = useState("");
  const [isValidJSON, setIsValidJSON] = useState(true);
  const [newItem, setNewItem] = useState({
    name: "",
    title: "",
    link: "",
  });
  const [jsonValidity, setJsonValidity] = useState<boolean[]>([]);

  let isBreadcrumbs = props.propertyValue.name === "breadcrumbs";
  let isMenu =
    props.propertyValue.name === "menu" ||
    props.propertyValue.name === "menu_items" ||
    props.propertyValue.name === "side_menu";

  useEffect(() => {
    setJsonValidity(
      props.propertyValue.value_array!.map((itemString) =>
        validateJSON(itemString)
      )
    );
  }, [props.propertyValue.value_array]);

  const validateJSON = (str: string) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  const addArrayElement = () => {
    if (isValidJSON) {
      props.handleChange(inputValue, props.index);
      setInputValue("");
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      addArrayElement();
    }
  };

  const handleNewItemChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    setIsValidJSON(validateJSON(value));
  };

  function handleNewItem(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void {
    setNewItem({
      ...newItem,
      [event.target.name]: event.target.value,
    });
  }

  const handleAddNewItem = () => {
    const newItemString = JSON.stringify(newItem);
    if (validateJSON(newItemString)) {
      props.handleChange(newItemString, props.index);
      setNewItem({
        name: "",
        title: "",
        link: "",
      });
      setIsValidJSON(true);
    }
  };

  const handleBreadcrumbChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let items = props.propertyValue.value_array;

    if (items === undefined) return;
    let editedItems = items.map((itemString: string, indexToChange) => {
      if (indexToChange === index) {
        let item = JSON.parse(itemString);
        return JSON.stringify({
          ...item,
          [event.target.name]: event.target.value,
        });
      }
      return itemString;
    });

    props.handleEditArray(editedItems, props.index);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let items = props.propertyValue.value_array;

    if (items === undefined) return;
    let editedItems = items.map((itemString: string, indexToChange) => {
      if (indexToChange === index) {
        const value = event.target.value;
        setJsonValidity((prevValidity) => {
          const newValidity = [...prevValidity];
          newValidity[index] = validateJSON(value);
          return newValidity;
        });
        return value;
      }
      return itemString;
    });

    props.handleEditArray(editedItems, props.index);
  };

  const handleMove = (toIndex: number, fromIndex: number) => {
    let items = [...props.propertyValue.value_array!];

    if (items === undefined) return;
    const startIndex = fromIndex < 0 ? items.length + fromIndex : fromIndex;

    if (startIndex >= 0 && startIndex < items.length) {
      const endIndex = toIndex < 0 ? items.length + toIndex : toIndex;

      const [item] = items.splice(fromIndex, 1);
      items.splice(endIndex, 0, item);
    }

    props.handleEditArray(items, props.index);
  };

  const handleItemDelete = (itemIndex: number) => {
    let items = props.propertyValue.value_array;
    if (items === undefined) return;

    let remainingItems = items
      .slice(0, itemIndex)
      .concat(items.slice(itemIndex + 1));

    props.handleEditArray(remainingItems, props.index);
    setJsonValidity((prevValidity) => {
      const newValidity = [...prevValidity];
      newValidity.splice(itemIndex, 1);
      return newValidity;
    });
  };

  return (
    <Grid item xs={12} lg={12}>
      <p className="entity__inputHeading">{props.propertyValue.display_name}</p>
      {isBreadcrumbs ? (
        <>
          {props.propertyValue.value_array!.map((itemString: string, index) => {
            let item = JSON.parse(itemString);
            return (
              <div className="input_wrapper_flex marginTop" key={index}>
                <TextField
                  variant="outlined"
                  label="Name"
                  id="name"
                  name="name"
                  fullWidth
                  value={item.name}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleBreadcrumbChange(event, index)
                  }
                />
                <TextField
                  variant="outlined"
                  label="Title"
                  id="title"
                  fullWidth
                  name="title"
                  value={item.title}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleBreadcrumbChange(event, index)
                  }
                />
                <TextField
                  variant="outlined"
                  label="Link"
                  id="link"
                  fullWidth
                  name="link"
                  value={item.link}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleBreadcrumbChange(event, index)
                  }
                />
                <Button
                  onClick={() => handleItemDelete(index)}
                  variant="outlined"
                  color="primary"
                >
                  <DeleteIcon />
                </Button>
              </div>
            );
          })}
          <div className="input_wrapper_flex marginTop">
            <TextField
              variant="outlined"
              label="Name"
              id="name"
              fullWidth
              name="name"
              value={newItem.name}
              onChange={handleNewItem}
            />
            <TextField
              variant="outlined"
              label="Title"
              id="title"
              fullWidth
              name="title"
              value={newItem.title}
              onChange={handleNewItem}
            />
            <TextField
              variant="outlined"
              label="Link"
              id="link"
              fullWidth
              name="link"
              value={newItem.link}
              onChange={handleNewItem}
            />
            <Button
              onClick={handleAddNewItem}
              variant="outlined"
              color="primary"
            >
              <AddCircleIcon />
            </Button>
          </div>
        </>
      ) : isMenu ? (
        <MenuInput
          index={props.index}
          propertyValue={props.propertyValue}
          handleEditArray={props.handleEditArray}
          handleChange={props.handleChange}
        />
      ) : (
        <>
          <div
            style={{ display: "flex", marginBottom: 6, alignItems: "center" }}
          >
            <TextField
              multiline
              variant="outlined"
              value={inputValue}
              onChange={handleNewItemChange}
              onKeyDown={handleKeyDown}
              label="JSON"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {validateJSON(inputValue) ? (
                      <CheckCircleIcon color="primary" />
                    ) : (
                      <ErrorIcon color="secondary" />
                    )}
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
            <Button
              className="marginLeft"
              startIcon={<AddCircleOutlineIcon />}
              color="primary"
              onClick={addArrayElement}
            >
              Add
            </Button>
          </div>

          {props.propertyValue.value_array!.map((path: string, index) => (
            <div
              key={index}
              style={{
                borderRadius: 5,
                marginTop: 26,
                border: "1px solid #ccc",
                padding: 10,
                marginBottom: 15,
              }}
            >
              <TextField
                variant="outlined"
                multiline
                rows={4}
                value={path}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(event, index)
                }
                label="JSON String"
                fullWidth
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  {index !== 0 && (
                    <Button
                      style={{ margin: 5 }}
                      className="marginLeft"
                      startIcon={<KeyboardArrowUp />}
                      color="primary"
                      onClick={() => handleMove(index - 1, index)}
                    >
                      Move up
                    </Button>
                  )}
                  {index !== props.propertyValue.value_array!.length - 1 && (
                    <Button
                      style={{ margin: 5 }}
                      className="marginLeft"
                      startIcon={<KeyboardArrowDown />}
                      color="primary"
                      onClick={() => handleMove(index + 1, index)}
                    >
                      Move down
                    </Button>
                  )}
                  <Button
                    style={{ margin: 5 }}
                    className="marginLeft"
                    startIcon={<DeleteIcon />}
                    color="secondary"
                    onClick={() => handleItemDelete(index)}
                  >
                    delete
                  </Button>
                </div>
                <Button
                  startIcon={
                    jsonValidity[index] ? <CheckCircleIcon /> : <ErrorIcon />
                  }
                  color={jsonValidity[index] ? "primary" : "secondary"}
                >
                  {jsonValidity[index] ? "Valid" : "Invalid"}
                </Button>
              </div>
            </div>
          ))}
        </>
      )}
    </Grid>
  );
};

interface ArrayInputProps {
  index: number;
  propertyValue: PropertyValue;
  handleEditArray: (value: string[], index: number) => void;
  handleChange: (value: string, index: number) => void;
  handleRemove: (index: number, removeIndex: number) => void;
}

export default ArrayInput;
