import React, { useMemo } from 'react'; 
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useQuery, useMutation } from "@apollo/client";
import GET_PARTIALS from "../../../graphql/GET_PARTIALS"
import DELETE_PARTIAL from "../../../graphql/DELETE_PARTIAL"
import * as Routes from "../../../routes";
import DataTable from '../../../components/dataTable';
import ActionCell from '../../../components/dataTable/partialActionCell';
import { RootState } from '../../../store/store';
import TableLayout from '../../../components/layout/TableLayout';
import EmptyContentMessage from '../../../components/layout/EmptyContentMessage';

function Partials() {
  const params = useParams<SiteParams>()
  const state = useSelector((state: RootState) => state)


  const { loading, error, data } = useQuery(GET_PARTIALS, {
      context: {
        headers: {
          'Authorization': `Bearer ${state.current_user.user?.token}`,
          'instance': state.current_site.site?.slug
        }
      }
  });



  const partials: any = useMemo(() => {
        return data ? data.admin_liquid_partials.results : []
  }, [data])

  const [deletePartial, { data: mutationData, loading: mutationLoading, error: mutationError }] = useMutation(DELETE_PARTIAL, {  
      context: {
            headers: {
                'Authorization': `Bearer ${state.current_user.user?.token}`,
                'instance': state.current_site.site?.slug
            }
        },
        update: (cache, { data } ) => {
            const partialFromResponse = data?.admin_liquid_partial_delete
            const existingPartials: any = cache.readQuery({query: GET_PARTIALS })

            if (partialFromResponse && existingPartials) {
                const updatedPartials = existingPartials.admin_liquid_partials.results.filter((partial: any) => {
                  if(partial.id !== partialFromResponse.id){
                    return partial
                  }
                });

                cache.writeQuery({
                    query: GET_PARTIALS,
                    data: {
                        admin_liquid_partials: {
                            results: [
                              ...updatedPartials
                            ]
                        }
                    }
                });
            }
        },
        onError: (error) => {
            console.log(error)
        }
    });

  const handleDelete = (id: string) => {
    deletePartial({
      variables: {
        id: id
      }
    })
  }

  const columns = useMemo(() => [
    {
        Header: 'Name',
        accessor: 'metadata.name',
    },
    {
        Header: 'Type',
        accessor: 'metadata.type',
    },
    {
        Header: 'path',
        accessor: 'path',
    },
    {
        Header: 'Created At',
        accessor: 'created_at',
        disableFilters: true,
        Cell: (props: any) => {
          let date = new Date(props.value);
          return <>{date.toLocaleString("en-GB")}</>;
        },
    },
    {
        Header: 'Updated At',
        accessor: 'updated_at',
        disableFilters: true,
        Cell: (props: any) => {
          let date = new Date(props.value);
          return <>{date.toLocaleString("en-GB")}</>;
        },
    },
    {
        Header: 'Actions',
        accessor: 'id',
        disableSortBy: true,
        disableFilters: true,
        Cell: (props: any) => {
            return (
                <ActionCell site={params.site} id={props.value} handleDelete={handleDelete} /> 
            )
        }
    }
  ], [])

  return (
    <TableLayout loading={loading} buttonLink={Routes.PARTIALS_CREATE_LINK(params.site)} tableHeading="Partials" buttonText="Create Partial">
{partials.length === 0 ?  (
          <EmptyContentMessage
            message={"This instance does not have any partials"}
          />
        )
     : (
        <DataTable data={partials} columns={columns} />
      )}
    </TableLayout>
    

  );
}

interface SiteParams {
  site: string
}


export default Partials;