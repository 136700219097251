import { gql } from '@apollo/client';

const UPDATE_RECORD = gql`
    mutation update_record(
        $id: ID!, 
        $properties: [PropertyInputType]!
    ){
    record_update(id: $id, record: {properties: $properties}) {
        id
        created_at
        updated_at
        properties
    }
}
`

export default UPDATE_RECORD;