import { gql } from '@apollo/client';

const GET_USERS = gql`
    query get_users {
        users(page: 1, per_page: 2000) {
            results {
                id
                first_name
                last_name
                email
                created_at
                updated_at
                properties
            }
        }
    }
  `

export default GET_USERS;