import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery, useMutation } from "@apollo/client";
import GET_CONSTANTS from "../../../graphql/GET_CONSTANTS";
import UNSET_CONSTANT from "../../../graphql/UNSET_CONSTANT";
import Loader from "../../../components/layout/Loader";
import ErrorToast from "../../../components/layout/ErrorToast";
import * as Routes from "../../../routes";
import DataTable from "../../../components/dataTable";
import ActionCell from "../../../components/dataTable/constantsActionCell";
import { RootState } from "../../../store/store";
import TableLayout from "../../../components/layout/TableLayout";
import EmptyContentMessage from "../../../components/layout/EmptyContentMessage";

function Constants() {
  const params = useParams<SiteParams>();
  const state = useSelector((state: RootState) => state);

  const { loading, error, data } = useQuery(GET_CONSTANTS, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  const partials: any = useMemo(() => {
    return data ? data.constants.results : [];
  }, [data]);

  const [
    unsetConstant,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(UNSET_CONSTANT, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const constantFromResponse = data?.constant_unset;
      const existingConstants: any = cache.readQuery({ query: GET_CONSTANTS });

      if (constantFromResponse && existingConstants) {
        const updatedConstants = existingConstants.constants.results.filter(
          (constant: any) => {
            if (constant.id !== constantFromResponse.id) {
              return constant;
            }
          }
        );

        cache.writeQuery({
          query: GET_CONSTANTS,
          data: {
            constants: {
              results: [...updatedConstants],
            },
          },
        });
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleDelete = (id: string) => {
    console.log(id);
    unsetConstant({
      variables: {
        name: id,
      },
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Created At",
        accessor: "created_at",
        disableFilters: true,
        Cell: (props: any) => {
          let date = new Date(props.value);
          return <>{date.toLocaleString("en-GB")}</>;
        },
      },
      {
        Header: "Updated At",
        accessor: "updated_at",
        disableFilters: true,
        Cell: (props: any) => {
          let date = new Date(props.value);
          return <>{date.toLocaleString("en-GB")}</>;
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <ActionCell
              id={props.row.values.name}
              site={params.site}
              handleDelete={handleDelete}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <TableLayout
      loading={loading}
      tableHeading="Constants"
      buttonLink={Routes.CONSTANT_CREATE_LINK(params.site)}
      buttonText="Create New Constant"
    >
      {mutationError ? (
        <ErrorToast open={true} errorMessage={mutationError.message} />
      ) : null}
      {mutationLoading ? <Loader open={true} /> : null}
      {partials.length === 0 ? (
        <EmptyContentMessage
          message={"This instance does not have any constants"}
        />
      ) : (
        <DataTable data={partials} columns={columns} />
      )}
    </TableLayout>
  );
}

interface SiteParams {
  site: string;
}

export default Constants;
