import { useMemo} from "react";
import { useParams } from "react-router-dom";
import { Container } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import GET_WEBFORMS from "../../../graphql/GET_WEBFORMS";
import DataTable from "../../../components/dataTable";
import ActionCell from "../../../components/dataTable/webformsActionCell";
import { RootState } from "../../../store/store";
import EmptyContentMessage from "../../../components/layout/EmptyContentMessage";
import Loader from "../../../components/layout/Loader";
function Webforms() {
  const params = useParams<SiteParams>();
  const state = useSelector((state: RootState) => state);
  const { loading, error, data } = useQuery(GET_WEBFORMS, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  const webforms: any = useMemo(() => {
    return data ? data.models.results : [];
  }, [data]);

  const columns = useMemo(
    () => [
      {
        Header: "Form Schema",
        accessor: "model_schema_name",
      },
      {
        Header: "email",
        accessor: "properties.email",
      },
      {
        Header: "Form Type",
        accessor: "properties.form_type",
      },
      {
        Header: "Created At",
        accessor: "created_at",
        disableFilters: true,
        Cell: (props: any) => {
          let date = new Date(props.value);
          return <>{date.toLocaleString("en-GB")}</>;
        },
      },
      {
        Header: "Updated At",
        accessor: "updated_at",
        disableFilters: true,
        Cell: (props: any) => {
          let date = new Date(props.value);
          return <>{date.toLocaleString("en-GB")}</>;
        },
      },
      {
        Header: "",
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props: any) => {
          return <ActionCell site={params.site} id={props.value} />;
        },
      },
    ],
    []
  );

  return (
    <Container>
      {webforms.length === 0 ? (
        loading ? (
          <Loader open={true} />
        ) : (
          <EmptyContentMessage
            message={"This instance does not have any webforms"}
          />
        )
      ) : (
        <DataTable data={webforms} columns={columns} />
      )}
    </Container>
  );
}

interface SiteParams {
  site: string;
}

export default Webforms;
