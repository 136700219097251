import { Link as RouterLink } from "react-router-dom";
import { useState } from "react";
import {
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import * as Routes from "../../routes";
import FavouriteButton from "../layout/FavouriteButton";

const PagesActionCell = (props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");

  const handleOpenDelete = () => {
    setDialogContent(
      "Are you sure you wish to delete this item? This action cannot be undone."
    );
    setOpenDialog(true);
  };

  const handleCloseDelete = () => {
    setOpenDialog(false);
  };

  const handleDeleteAndClose = () => {
    setDialogContent(
      `The component content has been deleted. The schema will remain.
       This popup will close in a few seconds.`
    );
    setOpenDialog(true);
    setTimeout(() => {
      handleCloseDelete();
      props.handleDelete(props.value);
    }, 4000);
  };
  return (
    <div className="action_cell">
      <Tooltip title="Edit Component">
        <IconButton
          component={RouterLink}
          to={Routes.COMPONENT_EDIT_ITEM_LINK(props.site, props.value)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <FavouriteButton type="component" slug={props.slug} />
      <Tooltip title="Delete component item" placement="right">
        <IconButton onClick={handleOpenDelete}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={openDialog} onClose={handleCloseDelete}>
        <DialogTitle>{"Delete Item"}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogContent}</DialogContentText>
        </DialogContent>
        {dialogContent.startsWith("Are you sure") && (
          <DialogActions>
            <Button onClick={handleCloseDelete} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleDeleteAndClose}
              color="secondary"
              variant="contained"
            >
              Delete
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

export default PagesActionCell;
