import React, { useMemo, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button, Container } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import DataTable from "../../components/dataTable";
import ActionCell from "../../components/dataTable/usersActionsCell";
import {
  fetchUserListIfNeeded,
  invalidateUserList,
} from "../../store/userList/actions";
import { RootState } from "../../store/store";
import * as Routes from "../../routes";
import TableLayout from "../../components/layout/TableLayout";
import EmptyContentMessage from "../../components/layout/EmptyContentMessage";

function Users() {
  const state = useSelector((state: RootState) => state);
  const currentUser = state.current_user.user;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserListIfNeeded());
  }, [dispatch, currentUser]);

  const handleDelete = (id: number) => {
    console.log(id);
  };

  const handleFetchUsers = () => {
    dispatch(invalidateUserList());
    dispatch(fetchUserListIfNeeded());
  };

  const data = useMemo(() => {
    return state.user_list.users;
  }, [state.user_list.users]);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Role",
        filterType: "select",
        accessor: "role",
      },
      {
        Header: "",
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <ActionCell userID={props.value} handleDelete={handleDelete} />
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <Container className="container">
        <Button
          component={RouterLink}
          startIcon={<KeyboardBackspaceIcon />}
          to={Routes.INSTANCE_PORTAL}
        >
          Back
        </Button>
        <TableLayout
          loading={state.user_list.fetching}
          buttonLink={Routes.USERS_CREATE}
          tableHeading="Users"
          buttonText="Create New Users"
        >
          {state.user_list.users.length === 0 ? (
            <EmptyContentMessage
              message={"This instance does not have any users"}
            />
          ) : (
            <DataTable data={data} columns={columns} />
          )}
        </TableLayout>
      </Container>
    </>
  );
}

export default Users;
