import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import ConstantForm from '../../../components/forms/constant';
import { useSelector } from 'react-redux'
import SET_CONSTANT from '../../../graphql/SET_CONSTANT';
import GET_CONSTANT from '../../../graphql/GET_CONSTANTS';
import { useMutation, useQuery } from '@apollo/client';
import Loader from '../../../components/layout/Loader';
import ErrorToast from '../../../components/layout/ErrorToast';
import * as Routes from "../../../routes";
import { RootState } from '../../../store/store';
import FormLayout from '../../../components/layout/FormLayout';

const UpdateConstant = () => {
    let history = useHistory();
    const params = useParams<UpdateParams>()
    const state = useSelector((state: RootState) => state)
    const [constantType, setConstantType] = useState("String");
    const [constantName, setConstantName] = useState("");
    const [constantStringValue, setConstantStringValue] = useState("");
    const [constantObjectValue, setConstantObjectValue] = useState([
        {name: "", value: ""}
    ]);

    const { loading: queryLoading, error: queryError, data: queryData } = useQuery(GET_CONSTANT, {
      variables : {
        name: params.id
      },
      context: {
        headers: {
          'Authorization': `Bearer ${state.current_user.user?.token}`,
          'instance': state.current_site.site?.slug
        }
      }
    });

    const [setConstant, { data: mutationData, loading: mutationLoading, error: mutationError }] = useMutation(SET_CONSTANT, {
        context: {
            headers: {
                'Authorization': `Bearer ${state.current_user.user?.token}`,
                'instance': state.current_site.site?.slug
            }
        },
        onCompleted: data => {
            if(data){
                history.push(Routes.CONSTANTS_LINK(params.site));
            }
        }
    }); 

    useEffect(() => {
        if(queryData){
            const constant = queryData.constants.results[0];
            setConstantName(constant.name)
            if(isJson(constant.value)){
                setConstantType("Object");
                const obj = JSON.parse(constant.value);
                const objEntries = Object.entries(obj).map(field =>{
                    return { name: field[0], value: typeof field[1] === "object" ? JSON.stringify(field[1]) : field[1] as string };
                });
                
                setConstantObjectValue(objEntries)
            } 
            else {
                setConstantType("String")
                setConstantStringValue(constant.value)
            }
        }
    }, [queryData])

    const isJson = (str: string) => {
        try {
            JSON.parse(str);
        } catch(e) {
            return false;
        }     
        return true;
    }

    const handleSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();
        let constValue;
        if(constantType === "String"){
            constValue = constantStringValue;
        } 
        else {
            let valueObject: any = {}
            for(let valuePair of constantObjectValue){
                if(valuePair.name && valuePair.value){
                    valueObject[valuePair.name] = valuePair.value;
                }
            }
            constValue = JSON.stringify(valueObject); 
        }
        setConstant({
            variables: {
                name: constantName, 
                value: constValue
            }
        })
    }

    const changeObjectField = (event: React.ChangeEvent<HTMLInputElement>, key: number) => {
        const newFields = constantObjectValue.map((valuePair, index) => {
            if (index !== key) {
              return valuePair
            }
            return {
              ...valuePair,
              [event.target.name]: event.target.value
            }
          })
        setConstantObjectValue(newFields);
    }

    const addField = () => {
        setConstantObjectValue([
            ...constantObjectValue,
            {name: "", value: ""}
        ])
    }

    if(queryLoading){
      return(<Loader open={true} />)
    }
    const removeField = () => {
        const filteredObjectValues = constantObjectValue.filter((property, i, { length }) => {
            if(length - 1  !==  i){
                return property
            }
        })
    
        setConstantObjectValue(filteredObjectValues)
    }
    return(
        <FormLayout loading={mutationLoading} formHeading="Edit Site Constant" backLink={Routes.CONSTANTS_LINK(params.site)} size="md">
            { queryError ? <ErrorToast open={true} errorMessage={queryError.message} />: null }
            { mutationError ? <ErrorToast open={true} errorMessage={mutationError.message} />: null }
            <ConstantForm
                constantType={constantType}
                setConstantType={setConstantType}
                constantName={constantName}
                setConstantName={setConstantName}
                constantStringValue={constantStringValue}
                setConstantStringValue={setConstantStringValue}
                constantObjectValue={constantObjectValue}
                addField={addField}
                changeObjectField={changeObjectField}
                handleSubmit={handleSubmit}
                removeField={removeField}
            />
        </FormLayout>
    );
}

interface UpdateParams {
  site: string
  id: string
}

export default UpdateConstant;