import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $createHeadingNode,
  $createQuoteNode,
  HeadingTagType,
} from "@lexical/rich-text";
import { $setBlocksType, $isAtNodeEnd } from "@lexical/selection";
import { $findMatchingParent, mergeRegister } from "@lexical/utils";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import {
  $createParagraphNode,
  $getRoot,
  $getSelection,
  $insertNodes,
  $isElementNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  ElementNode,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  LexicalEditor,
  LexicalNode,
  RangeSelection,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  TextNode,
  UNDO_COMMAND,
} from "lexical";
import { useCallback, useEffect, useRef, useState } from "react";
import { INSERT_IMAGE_COMMAND } from "./ImagePlugin";

import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from "@lexical/list";
import ViewSourceModal from "./ViewSourceModal";
import HyperlinkModal from "./HyperlinkModal";
import ImageSelectModal from "../../assets/ImageSelectModal";

const LowPriority = 1;

function Divider() {
  return <div className="divider" />;
}

export default function ToolbarPlugin({
  toggleLegacyEditor,
}: {
  toggleLegacyEditor: any;
}) {
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [viewSourceModalOpen, setViewSourceModalOpen] = useState(false);
  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const [imagePath, setImagePath] = useState("");
  const [altText, setAltText] = useState("");
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isBlockquote, setIsBlockquote] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [isImageCaption, setIsImageCaption] = useState(false);
  const [linkInfo, setLinkInfo] = useState({
    url: "",
    displayText: "",
    title: "",
    linkType: "external",
  });
  const [blockType, setBlockType] = useState("paragraph");
  const [selectedText, setSelectedText] = useState("");

  function $isEditorIsNestedEditor(editor: LexicalEditor): boolean {
    return editor._parentEditor !== null;
  }

  function getSelectedNode(selection: RangeSelection): TextNode | ElementNode {
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    if (anchorNode === focusNode) {
      return anchorNode;
    }
    const isBackward = selection.isBackward();
    if (isBackward) {
      return $isAtNodeEnd(focus) ? anchorNode : focusNode;
    } else {
      return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
    }
  }

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      if (
        activeEditor !== editor &&
        $isEditorIsNestedEditor(activeEditor as unknown as LexicalEditor)
      ) {
        const rootElement = activeEditor.getRootElement();
        setIsImageCaption(
          !!rootElement?.parentElement?.classList.contains(
            "image-caption-container"
          )
        );
      } else {
        setIsImageCaption(false);
      }

      const anchorNode = selection.anchor.getNode();
      let element: ElementNode | TextNode =
        anchorNode.getKey() === "root"
          ? (anchorNode as ElementNode)
          : ($findMatchingParent(anchorNode, (e): e is LexicalNode => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            }) as ElementNode | null) || anchorNode;

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();

      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));

      // Update links
      const node = getSelectedNode(selection);
      const parent = $findMatchingParent(node, $isLinkNode);
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
        setLinkInfo({
          url: parent?.getURL() || "",
          displayText: parent?.getTextContent() || "",
          title: parent?.getTitle() || "",
          linkType: parent?.getRel() === "noopener" ? "external" : "internal",
        });
      } else {
        setIsLink(false);
        setLinkInfo({
          url: "",
          displayText: "",
          title: "",
          linkType: "external",
        });
      }

      // Update block type
      const blockType = element.getType();
      setBlockType(blockType);
      setIsBlockquote(blockType === "quote");

      // Handle buttons
      let matchingParent;
      if ($isLinkNode(parent)) {
        // If node is a link, we need to fetch the parent paragraph node to set format
        matchingParent = $findMatchingParent(
          node,
          (parentNode) => $isElementNode(parentNode) && !parentNode.isInline()
        );
      }

      // Update selected text
      const textContent = selection.getTextContent();
      setSelectedText(textContent);
    }
  }, [activeEditor, editor]);

  const handleHeadingChange = (event: { target: { value: string } }) => {
    const tag = event.target.value as HeadingTagType | "paragraph";
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () =>
          tag === "paragraph" ? $createParagraphNode() : $createHeadingNode(tag)
        );
      }
    });
  };

  const insertLink = (
    url: string,
    displayText: string,
    title: string,
    linkType: string
  ) => {
    setIsLink(linkInfo.url !== "");
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        editor.dispatchCommand(TOGGLE_LINK_COMMAND, {
          url,
          title,
          rel: linkType === "external" ? "noopener" : undefined,
        });
      }
    });
  };

  const removeLink = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
      }
    });
  };

  const handleBlockquoteChange = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () =>
          isBlockquote ? $createParagraphNode() : $createQuoteNode()
        );
      }
    });
  };

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, _newEditor) => {
          $updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor, $updateToolbar]);

  useEffect(() => {
    if (imagePath) {
      editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
        src: imagePath,
        altText: altText,
      });
    }
  }, [imagePath, editor, altText]);

  const formatList = (listType: string) => {
    if (listType === "number" && blockType !== "number") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
      setBlockType("number");
    } else if (listType === "bullet" && blockType !== "bullet") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
      setBlockType("bullet");
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      setBlockType("paragraph");
    }
  };

  return (
    <div className="toolbar" ref={toolbarRef}>
      {imageModalOpen ? (
        <ImageSelectModal
          open={imageModalOpen}
          handleClose={() => setImageModalOpen(false)}
          altText={altText}
          setAltText={setAltText}
          setImagePath={setImagePath}
        />
      ) : null}
      {viewSourceModalOpen ? (
        <ViewSourceModal
          open={viewSourceModalOpen}
          handleClose={() => setViewSourceModalOpen(false)}
          content={editor.getEditorState().read(() => {
            const html = $generateHtmlFromNodes(editor, null);
            return html;
          })}
          handleUpdateContent={(content: string) => {
            editor.update(() => {
              const parser = new DOMParser();
              const dom = parser.parseFromString(content, "text/html");
              const nodes = $generateNodesFromDOM(editor, dom);

              $getRoot().select();
              $getRoot().clear();
              $insertNodes(nodes);

              setViewSourceModalOpen(false);
            });
          }}
        />
      ) : null}
      {linkModalOpen ? (
        <HyperlinkModal
          open={linkModalOpen}
          handleClose={() => setLinkModalOpen(false)}
          isLink={isLink}
          selectedText={selectedText}
          handleUpdateLink={(
            url: string,
            displayText: string,
            title: string,
            linkType: string
          ) => {
            if (url) {
              insertLink(url, displayText, title, linkType);
            } else {
              removeLink();
            }
          }}
          link={linkInfo}
        />
      ) : null}
      <button
        disabled={!canUndo}
        onClick={() => {
          editor.dispatchCommand(UNDO_COMMAND, undefined);
        }}
        className="toolbar-item spaced"
        title="Undo"
        aria-label="Undo"
      >
        <i className="format undo" />
      </button>
      <button
        disabled={!canRedo}
        onClick={() => {
          editor.dispatchCommand(REDO_COMMAND, undefined);
        }}
        className="toolbar-item"
        title="Redo"
        aria-label="Redo"
      >
        <i className="format redo" />
      </button>
      <Divider />
      <select onChange={handleHeadingChange} className="heading-dropdown">
        <option value="paragraph">Paragraph</option>
        <option value="h1">Heading 1</option>
        <option value="h2">Heading 2</option>
        <option value="h3">Heading 3</option>
        <option value="h4">Heading 4</option>
        <option value="h5">Heading 5</option>
      </select>
      <Divider />
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
        }}
        className={"toolbar-item spaced " + (isBold ? "active" : "")}
        title="Bold"
        aria-label="Format Bold"
      >
        <i className="format bold" />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
        }}
        className={"toolbar-item spaced " + (isItalic ? "active" : "")}
        title="Italic"
        aria-label="Format Italics"
      >
        <i className="format italic" />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
        }}
        className={"toolbar-item spaced " + (isUnderline ? "active" : "")}
        title="Underline"
        aria-label="Format Underline"
      >
        <i className="format underline" />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
        }}
        className={"toolbar-item spaced " + (isStrikethrough ? "active" : "")}
        title="Strikethrough"
        aria-label="Format Strikethrough"
      >
        <i className="format strikethrough" />
      </button>
      <button
        onClick={handleBlockquoteChange}
        className={"toolbar-item spaced " + (isBlockquote ? "active" : "")}
        title="Blockquote"
        aria-label="Blockquote"
      >
        <i className="format quote" />
      </button>
      <Divider />
      <button
        className={"toolbar-item spaced"}
        onClick={() => formatList("bullet")}
      >
        <i className="format bullet-list" />
      </button>
      <button
        className={"toolbar-item spaced"}
        onClick={() => formatList("number")}
      >
        <i className="format number-list" />
      </button>
      <Divider />
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
        }}
        className="toolbar-item spaced"
        title="Left Align"
        aria-label="Left Align"
      >
        <i className="format left-align" />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
        }}
        className="toolbar-item spaced"
        title="Center Align"
        aria-label="Center Align"
      >
        <i className="format center-align" />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
        }}
        className="toolbar-item spaced"
        title="Right Align"
        aria-label="Right Align"
      >
        <i className="format right-align" />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
        }}
        className="toolbar-item"
        title="Justify Align"
        aria-label="Justify Align"
      >
        <i className="format justify-align" />
      </button>
      <Divider />
      <button
        onClick={() => {
          setLinkModalOpen(true);
        }}
        className={"toolbar-item spaced " + (isLink ? "active" : "")}
        title="Insert Link"
        aria-label="Insert Link"
      >
        <i className="format add-link" />
      </button>
      <button
        onClick={() => {
          setImageModalOpen(true);
        }}
        className="toolbar-item"
        title="Add Image"
        aria-label="Add Image"
      >
        <i className="format add-image" />
      </button>
      <button
        onClick={() => {
          setViewSourceModalOpen(true);
        }}
        className="toolbar-item"
        title="View Source"
        aria-label="View Source"
      >
        <i className="format code" />
      </button>
      <button
        onClick={() => {
          toggleLegacyEditor(true);
        }}
        className="toolbar-item"
        title="Legacy Editor"
        aria-label="Legacy Editor"
      >
        <i className="format warning" />
      </button>
    </div>
  );
}
