import { SET_USER, LOGOUT, User } from "./types";

export function setAuthenticatedUser(user: User) {
  return { type: SET_USER, payload: user };
}

export function logoutUser() {
  // delete cookie named JWT_DEV, redirect to login page
  document.cookie = "JWT_DEV=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  window.location.href = process.env.REACT_APP_LOGIN_URL as string;

  return { type: LOGOUT };
}
