
import { gql } from '@apollo/client';

const UPDATE_CITY = gql`
mutation city_update($id:ID! $properties: [CustomAttributeInputType]!) {
  model_update(
    id: $id
    model: {model_schema_name: "modules/areas/city", properties: $properties}
  ) {
    id
    created_at
    updated_at
    properties
  }
}
`

export default UPDATE_CITY;