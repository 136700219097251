

  import { gql } from '@apollo/client';

const UPDATE_INFO = gql`
mutation MyMutation($id:ID! $properties: [CustomAttributeInputType]!) {
  model_update(
    id: $id
    model: {model_schema_name: "modules/infopages/info_article", properties: $properties}
  ) {
    id
    created_at
    updated_at
    properties
  }
}
`

export default UPDATE_INFO;