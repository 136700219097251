import React, { useState } from 'react';
import { PropertyValue } from '../../pages/site/entities/create';
import { Button, IconButton, Grid } from '@material-ui/core';
import ImageSelectModal from '../assets/ImageSelectModal';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';

const ImageInput = (props: ImageInputProps) => {
    const [modalOpen, setModalOpen] = useState(false)
    
    const setImage = (imagePath: string) => {
        props.handleChange(imagePath, props.index)
    }

    const unsetImage = () => {
        props.handleChange("", props.index)
    }

    const handleClose = () => {
        setModalOpen(false)
    }

    const handleOpen = () => {
        setModalOpen(true)
    }

    return(
        <Grid item xs={12} lg={6}>
        <p className="entity__inputHeading">{props.propertyValue.display_name}</p>
        {
               props.propertyValue.value ? (
                   <div style={{width:'33%'}} className="entity__imageWrap">
                        <img  className="entity__image" src={props.propertyValue.value} />
                        <IconButton className="entity__removeButton" onClick={unsetImage}>
                            <CancelIcon />
                        </IconButton>
                    </div>
                ) : (
                    <Button startIcon={<AddCircleOutlineIcon />} color="primary" onClick={handleOpen}>Add</Button>
                )
            }
            <ImageSelectModal open={modalOpen} handleClose={handleClose} setImagePath={setImage} assetPath={props.collectionType} propertyValue={props.propertyValue} />
        </Grid>
    );
}

interface ImageInputProps {
    index: number
    propertyValue: PropertyValue
    handleChange: (value: string, index: number) => void
    collectionType: string
}

export default ImageInput;