export interface Site {
    id: number
    slug: string
    name: string
    url: string
    image: string | null
    build: boolean
    favourites?: Favourite[]
}

export interface SiteErrors {
    errorType: string
}

export interface Favourite {
    type: string
    slug: string
    id: number
}

export interface CurrentSiteState {
    site: Site | null
    fetching: boolean
    success: boolean
    invalidate: boolean
    errors: SiteErrors[]
}


export const FETCH_SITE = "FETCH_CURRENT_SITE";
export const SET_SITE = "SET_CURRENT_SITE_STATE";
export const UNSET_SITE = "UNSET_CURRENT_SITE_STATE";
export const SET_ERRORS = "SET_CURRENT_SITE_ERRORS";
export const SET_FAVOURITES = "SET_CURRENT_SITE_FAVOURITES";
export const DELETE_FAVOURITE = "DELETE_CURRENT_SITE_FAVOURITES";
export const UPDATE_FAVOURITES = "UPDATE_CURRENT_SITE_FAVOURITES";
interface setCurrentSite {
  type: typeof SET_SITE
  payload: Site
}

interface setFavourites {
    type: typeof SET_FAVOURITES
    payload: Favourite[]
  }

  interface deleteFavourites {
    type: typeof DELETE_FAVOURITE
    payload: Favourite
  }

  interface updateFavourites {
    type: typeof UPDATE_FAVOURITES
    payload: Favourite
  }
interface unsetCurrentSite {
    type: typeof UNSET_SITE
}

interface fetchCurrentSite {
    type: typeof FETCH_SITE
}

interface setCurrentSiteErrors {
    type: typeof SET_ERRORS
    payload: SiteErrors[]
}

export type CurrentSiteActions = setCurrentSite | unsetCurrentSite | fetchCurrentSite | setCurrentSiteErrors | setFavourites | updateFavourites | deleteFavourites