import React, { Fragment } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  IconButton,
  Container,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

import { makeStyles } from "@material-ui/core/styles";
import { useParams, Link as RouterLink } from "react-router-dom";
import * as Routes from "../../routes";

const ConstantForm = (props: ConstantsFormProps) => {
  const classes = useStyles();
  const params = useParams<any>();
  return (
    <div className="form">
      <TextField
        label="Constant Name"
        variant="outlined"
        type="text"
        name="name"
        onChange={(event) => {
          props.setConstantName(event.target.value);
        }}
        value={props.constantName}
        required
      />
      <FormControl variant="outlined" required>
        <InputLabel>Constant Type</InputLabel>
        <Select
          name="constantType"
          onChange={(event) => {
            props.setConstantType(event.target.value);
          }}
          value={props.constantType}
          required
          labelWidth={118}
        >
          <MenuItem value="String">String</MenuItem>
          <MenuItem value="Object">Object</MenuItem>
        </Select>
      </FormControl>
      {props.constantType === "String" ? (
        <TextField
          label="Constant Value"
          variant="outlined"
          type="text"
          name="value"
          onChange={(event) => {
            props.setConstantStringValue(event.target.value);
          }}
          value={props.constantStringValue}
          required
        />
      ) : (
        <Fragment>
          {props.constantObjectValue.map((valuePair: any, i: number) => {
            return (
              <div key={i}>
                <TextField
                  className="marginRight"
                  label="Name"
                  variant="outlined"
                  type="text"
                  name="name"
                  onChange={(event) => props.changeObjectField(event, i)}
                  value={valuePair.name}
                  required
                />
                <TextField
                  label="Value"
                  variant="outlined"
                  type="text"
                  name="value"
                  onChange={(event) => props.changeObjectField(event, i)}
                  value={valuePair.value}
                  required
                />
              </div>
            );
          })}
          <div>
            <IconButton size="small" onClick={props.addField}>
              <AddCircleOutlineIcon />
            </IconButton>
            <IconButton size="small" onClick={props.removeField}>
              <RemoveCircleOutlineIcon />
            </IconButton>
          </div>
        </Fragment>
      )}
      <Button
        className="form_button"
        variant="contained"
        color="primary"
        size="large"
        onClick={props.handleSubmit}
      >
        Add Constant
      </Button>
    </div>
  );
};

interface ConstantsFormProps {
  constantType: string;
  setConstantType: any;
  constantName: string;
  setConstantName: any;
  constantStringValue: string;
  setConstantStringValue: any;
  constantObjectValue: any;
  addField: any;
  changeObjectField: any;
  handleSubmit: any;
  removeField: any;
}

const useStyles = makeStyles((theme) => ({}));

export default ConstantForm;
