import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Container } from "@material-ui/core";
import {
  updateUser,
  unsetUserUpdateSuccessFlag,
} from "../../store/userList/actions";
import { fetchOrganisationListIfNeeded } from "../../store/organisationList/actions";
import * as Routes from "../../routes";
import { RootState } from "../../store/store";
import cleanObject from "../../utils/cleanObject";
import UserForm from "../../components/forms/user";
import FormLayout from "../../components/layout/FormLayout";
function Create() {
  const dispatch = useDispatch();
  const params = useParams<UserParams>();
  let history = useHistory();
  const state = useSelector((state: RootState) => state);

  const [user, setUser] = useState({
    name: "",
    passwordStored: false,
    password: "",
    email: "",
    role: "",
    organisationID: "",
    enabled: true,
  });
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (state.user_list.createSuccess) {
      dispatch(unsetUserUpdateSuccessFlag());
      history.push(Routes.USERS);
    }
  }, [state.user_list.createSuccess]);

  useEffect(() => {
    dispatch(fetchOrganisationListIfNeeded());
  }, [state.organisation_list.organisations]);

  useEffect(() => {
    setIsFetching(true);
    fetch(`${process.env.REACT_APP_BASE_URL}/v1/user/${params.id}`, {
      method: "get",
      headers: {
        Authorization: "Bearer " + state.current_user.user?.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        delete data.id;
        setUser({
          ...user,
          name: data.name,
          email: data.email,
          passwordStored: data.password,
          role: data.role,
        });
        setIsFetching(false);
      });
  }, [state.current_user]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    let valid = validateForm();

    if (valid) {
      let reqBody: any = {};
      reqBody["user"] = cleanObject(user);
      dispatch(updateUser(Number(params.id), reqBody));
    }
  };

  const validateForm = () => {
    return true;
  };

  return (
    <Container className="container">
      <FormLayout
        loading={null}
        formHeading="Update User"
        backLink={Routes.INSTANCE_PORTAL}
        size="md"
      >
        <UserForm
          user={user}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          organisationList={state.organisation_list.organisations}
          isFetching={false}
          isSubmitting={state.user_list.creating}
          currentUserRole={state.current_user.user?.role}
        />
      </FormLayout>
    </Container>
  );
}

interface UserParams {
  id: string;
}

export default Create;
