import { gql } from '@apollo/client';

const GET_CITIES = gql`
  query city_get(
    $id: ID
    $slug: String
    $per_page: Int = 3000
    $page: Int = 1
  ) {
    models(
      filter: {
        name: { exact: "modules/areas/city" }
        id: { value: $id }
        properties: [{ name: "slug", value: $slug }]
        deleted_at: { exists: false }
      }
      per_page: $per_page
      page: $page
    ) {
      total_entries
      total_pages
      results {
        id
        created_at
        updated_at
        properties
        content: related_models(model_schema_name: "modules/areas/city_content" join_on_property: "id" foreign_property: "city_id"){
          id
          properties
        }
      }
    }
  }
`;

export default GET_CITIES;

