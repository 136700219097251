import React, { useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useQuery, useMutation } from "@apollo/client";
import GET_COLLECTION_WITH_ENTITIES from "../../../graphql/GET_SINGLE_CONTENT_TYPE_WITH_ENTITIES";
import DELETE_RECORD from "../../../graphql/DELETE_RECORD";
import * as Routes from "../../../routes";
import DataTable from "../../../components/dataTable";
import ActionCell from "../../../components/dataTable/collectionItemActionCell";
import { RootState } from "../../../store/store";
import Loader from "../../../components/layout/Loader";
import ErrorToast from "../../../components/layout/ErrorToast";
import TableLayout from "../../../components/layout/TableLayout";
import EmptyContentMessage from "../../../components/layout/EmptyContentMessage";

function SingleContentTypeListView() {
  const params = useParams<SlugParams>();
  const state = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const { loading, error, data } = useQuery(GET_COLLECTION_WITH_ENTITIES, {
    variables: { name: params.slug },
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  const collectionType: any = useMemo(() => {
    return data ? data.admin_tables.results[0] : null;
  }, [data]);

  const [
    deleteItem,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(DELETE_RECORD, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const deletedItem = data?.record_delete;
      const currentItems: any = cache.readQuery({
        query: GET_COLLECTION_WITH_ENTITIES,
        variables: {
          name: params.slug,
        },
      });

      if (deletedItem && currentItems) {
        const updatedItems = currentItems.records.results.filter(
          (item: any) => {
            if (item.id !== deletedItem.id) {
              return item;
            }
          }
        );

        cache.writeQuery({
          query: GET_COLLECTION_WITH_ENTITIES,
          variables: {
            name: params.slug,
          },
          data: {
            records: {
              results: [...updatedItems],
            },
          },
        });
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const entities: any = useMemo(() => {
    return data ? data.records.results : [];
  }, [data]);

  const columns: any = useMemo(() => {
    if (collectionType) {
      const properties = collectionType.properties.filter((property: any) => {
        if (property.metadata.hasOwnProperty("table_display")) {
          if (property.metadata.table_display === true) {
            return property;
          } else {
            return null;
          }
        }
        if (property.metadata.display_priority > 0) {
          return property;
        }
      });

      let columns = properties.map((property: any) => {
        return {
          Header: `${property.metadata.display_name}`,
          accessor: `properties.${property.name}`,
          displayPriority: Number(property.metadata.display_priority),
          Cell: (props: any) => {
            if (typeof props.value === "string") {
              return <>{truncate(props.value)}</>;
            }
            return <>{props.value}</>;
          },
        };
      });

      const dateColumns = [
        {
          Header: "Created At",
          accessor: "created_at",
          displayPriority: 1,
          disableFilters: true,
          Cell: (props: any) => {
            let date = new Date(props.value);
            return <>{date.toLocaleString("en-GB")}</>;
          },
        },
        {
          Header: "Updated At",
          accessor: "updated_at",
          displayPriority: 2,
          disableFilters: true,
          Cell: (props: any) => {
            let date = new Date(props.value);
            return <>{date.toLocaleString("en-GB")}</>;
          },
        },
      ];

      columns = columns.concat(dateColumns);

      columns = columns.sort((a: any, b: any) => {
        return b.displayPriority - a.displayPriority;
      });

      const actionColumn = {
        Header: "",
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <ActionCell
              site={params.site}
              collection={params.slug}
              value={props.value}
              handleDelete={handleDelete}
              baseURL={state.current_site.site?.url}
              collectionURL={
                collectionType?.metadata.collection_url
                  ? collectionType.metadata.collection_url
                  : null
              }
              slug={props.row.values["properties.slug"]}
            />
          );
        },
      };
      columns.push(actionColumn);

      return columns;
    }
    return [];
  }, [collectionType]);

  const handleDelete = (id: string) => {
    deleteItem({
      variables: {
        id: id,
      },
    });
  };

  function truncate(str: string) {
    return str.length > 64 ? str.substring(0, 64) + "..." : str;
  }

  return (
    <TableLayout
      loading={loading}
      description={
        data ? data.admin_tables.results[0].metadata.description : null
      }
      favouriteSlug={params.slug!}
      favouriteType="collection"
      buttonLink={Routes.ENTITY_CREATE_LINK(params.site, params.slug)}
      tableHeading={
        collectionType?.metadata.display_name
          ? collectionType.metadata.display_name
          : "Collection"
      }
      buttonText="New Collection Item"
      secondaryButton={{
        buttonLink: Routes.COLLECTION_EDIT_LINK(params.site, params.slug),
        buttonText: "Edit Collection Fields",
      }}
    >
      {loading ? (
        <Loader open={true} />
      ) : collectionType ? (
        <>
          {entities.length === 0 ? (
            <EmptyContentMessage
              message={"This collection does not have any items"}
            />
          ) : (
            <>
              <DataTable data={entities} columns={columns} />
              {mutationLoading ? <Loader open={true} /> : null}
              {mutationError ? (
                <ErrorToast open={true} errorMessage={mutationError.message} />
              ) : null}
            </>
          )}
        </>
      ) : (
        <div>Can't find collection Type</div>
      )}
    </TableLayout>
  );
}

interface SlugParams {
  site: string;
  slug: string;
}

export default SingleContentTypeListView;
