import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import {
    FETCHING,
    FETCHED,
    CREATED,
    CREATING,
    UPDATING,
    UPDATED,
    DELETING,
    DELETED,
    RESET_CREATE_SUCCESS,
    RESET_UPDATE_SUCCESS,
    RESET_DELETE_SUCCESS,
    INVALIDATE,
} from "./types"
import { RootState } from '../store';

export function invalidateUserList() {
    return { type: INVALIDATE }
}

export function unsetUserCreateSuccessFlag(){
    return { type: RESET_CREATE_SUCCESS }
}
export function unsetUserUpdateSuccessFlag(){
    return { type: RESET_UPDATE_SUCCESS }
}
export function unsetUserDeleteSuccessFlag(){
    return { type: RESET_DELETE_SUCCESS }
}

export function fetchUserListIfNeeded() {
    return function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any){
        const state: RootState = getState()
        if(shouldFetchUserList(state.user_list)){
            return dispatch(fetchUserList())
        }
    }
}

function shouldFetchUserList(users: any){
    if(!Array.isArray(users.users) || users.users.length === 0){
        return true;
    }
    if(users.fetching){
        return false;
    }
    return users.didInvalidate;
}

function fetchUserList() {
    return function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) {
        const state: RootState = getState();

        dispatch({ type: FETCHING  })
        return fetch(`${process.env.REACT_APP_BASE_URL}/v1/user`, 
          {
            method: "get",
            headers: {
                'Authorization': `Bearer ${state.current_user.user?.token}`
            }
          }
        )
        .then(response => {
            return response.json();   
        })
        .then(json => {
            dispatch({ type: FETCHED, payload: json });
        });
    };
};


export function createUser(user: any){
    return function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) {
        const state: RootState = getState();        
        
        dispatch({ type: CREATING })
        return fetch(`${process.env.REACT_APP_BASE_URL}/v1/user/`, {
            method: "post",
            body: JSON.stringify(user),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${state.current_user.user?.token}`
            }
        })
        .then(response => {
            if(response.status === 200){
                return response.json();
            } else {
                throw new Error('Network Error');
            }       
        })
        .then(json => {
            if(!json.errors){
                dispatch({ type: CREATED, payload: json })
            } else {
                for(let error of json.errors){
                    throw new Error(error.message);
                }
            }  
        })
        .catch((error) => {
            console.log(error)
        })
    }
}


export function updateUser(id: number, user: any){
    return function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) {
        const state: RootState = getState();        
        
        dispatch({ type: UPDATING })
        return fetch(`${process.env.REACT_APP_BASE_URL}/v1/user/${id}`, {
            method: "put",
            body: JSON.stringify(user),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${state.current_user.user?.token}`
            }
        })
        .then(response => {
            if(response.status === 200){
                return response.json();
            } else {
                throw new Error('Network Error');
            }       
        })
        .then(json => {
            if(!json.errors){
                dispatch({ type: UPDATED, payload: json })
            } else {
                for(let error of json.errors){
                    throw new Error(error.message);
                }
            }  
        })
        .catch((error) => {
            console.log(error)
        })
    }
}

export function deleteUser(id: number){
    return function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) {
        const state: RootState = getState();        
        
        dispatch({ type: DELETING })
        return fetch(`${process.env.REACT_APP_BASE_URL}/v1/user/${id}`, {
            method: "delete",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${state.current_user.user?.token}`
            }
        })
        .then(response => {
            if(response.status === 200){
                return response.json();
            } else {
                throw new Error('Network Error');
            }       
        })
        .then(json => {
            if(!json.errors){
                dispatch({ type: DELETED, payload: json })
            } else {
                for(let error of json.errors){
                    throw new Error(error.message);
                }
            }  
        })
        .catch((error) => {
            console.log(error)
        })
    }
}