import React, { useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useQuery, useMutation } from "@apollo/client";
import GET_CITIES from "../../../../../graphql/areas/GET_CITIES";
import DELETE_CITY from "../../../../../graphql/DELETE_RECORD";
import * as Routes from "../../../../../routes";
import DataTable from "../../../../../components/dataTable";
import DataTableSkeleton from "../../../../../components/dataTable/skeleton";
import ActionCell from "../../../../../components/dataTable/areas/cityActionCell";
import { RootState } from "../../../../../store/store";
import TableLayout from "../../../../../components/layout/TableLayout";
import EmptyContentMessage from "../../../../../components/layout/EmptyContentMessage";

function City() {
  const params = useParams<SiteParams>();
  const state = useSelector((state: RootState) => state);

  const { loading, error, data } = useQuery(GET_CITIES, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  const pages: any = useMemo(() => {
    return data ? data.models.results : [];
  }, [data]);

  const [
    deletePage,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(DELETE_CITY, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const pageFromResponse = data?.model_delete;
      const existingPages: any = cache.readQuery({ query: GET_CITIES });

      if (pageFromResponse && existingPages) {
        const updatedPages = existingPages.models.results.filter(
          (page: any) => {
            if (page.id !== pageFromResponse.id) {
              return page;
            }
          }
        );

        cache.writeQuery({
          query: GET_CITIES,
          data: {
            models: {
              results: [...updatedPages],
            },
          },
        });
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleDelete = (id: string) => {
    deletePage({
      variables: {
        id: id,
      },
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "properties.name",
      },
      {
        Header: "Slug",
        accessor: "properties.slug",
      },
      {
        Header: "Created At",
        accessor: "created_at",
        disableFilters: true,
        Cell: (props: any) => {
          let date = new Date(props.value);
          return <>{date.toLocaleString("en-GB")}</>;
        },
      },
      {
        Header: "Updated At",
        accessor: "updated_at",
        disableFilters: true,
        Cell: (props: any) => {
          let date = new Date(props.value);
          return <>{date.toLocaleString("en-GB")}</>;
        },
      },
      {
        Header: "Unique Content No.",
        accessor: "content",
        disableFilters: true,
        Cell: (props: any) => {
          return <>{props.value.length}</>;
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <ActionCell
              site={params.site}
              value={props.value}
              siteURL={state.current_site.site?.url}
              slug={props.row.values.slug}
              handleDelete={handleDelete}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <TableLayout
      loading={loading}
      buttonLink={Routes.CITY_CREATE_LINK(params.site)}
      tableHeading="Service Areas"
      buttonText="Add New Service Area"
    >
      {pages.length === 0 ? (
        <EmptyContentMessage
          message={"This instance does not have any service areas"}
        />
      ) : (
        <DataTable data={pages} columns={columns} />
      )}
    </TableLayout>
  );
}

interface SiteParams {
  site: string;
}

export default City;
