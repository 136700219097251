import { combineReducers } from 'redux';

import CurrentUserReducer from './currentUser/reducer';
import CurrentSiteReducer from './currentSite/reducer';
import UserListReducer from './userList/reducer';
import SiteListReducer from './siteList/reducer';
import SiteModeReducer from './siteMode/reducer';
import organisationListReducer from './organisationList/reducer';

const rootReducer = combineReducers({
    current_user: CurrentUserReducer,
    current_site: CurrentSiteReducer,
    user_list: UserListReducer,
    site_list: SiteListReducer,
    site_mode: SiteModeReducer,
    organisation_list: organisationListReducer,
})

export default rootReducer;