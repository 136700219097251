import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import RegionForm from "../../../../../components/forms/region";
import { useSelector } from "react-redux";
import CREATE_REGION from "../../../../../graphql/areas/CREATE_REGION";
import UPDATE_REGION from "../../../../../graphql/areas/UPDATE_REGION";
import GET_CITIES from "../../../../../graphql/areas/GET_CITIES";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import ErrorToast from "../../../../../components/layout/ErrorToast";
import * as Routes from "../../../../../routes";
import { RootState } from "../../../../../store/store";
import slugify from "slugify";
import FormLayout from "../../../../../components/layout/FormLayout";
import GET_SUBURBS from "../../../../../graphql/areas/GET_SUBURBS_SIMPLE";
import GET_REGIONS from "../../../../../graphql/areas/GET_REGIONS";
import { handleChange, handleBlur } from "../../../../../utils/slugsCheck";

const CreateSuburbPage = () => {
  let history = useHistory();
  const params = useParams<SiteParams>();
  const state = useSelector((state: RootState) => state);
  const [region, setRegion] = useState({
    name: "",
    slug: "",
    suburb_ids: [] as any,
    city_id: "",
  });
  const [cities, setCities] = useState<any[]>([]);
  const [suburbs, setSuburbs] = useState<any[]>([]);

  // get cities for city id select
  const {
    loading: cityLoading,
    error: cityError,
    data: cityData,
  } = useQuery(GET_CITIES, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  useEffect(() => {
    if (cityData) {
      const result = cityData.models.results;
      setCities(result);
    }
  }, [cityData]);

  // get suburbs for service multiselect
  const [
    getSuburbQuery,
    { loading: suburbLoading, error: suburbError, data: suburbData },
  ] = useLazyQuery(GET_SUBURBS, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (region.city_id) {
      getSuburbQuery({
        variables: {
          city_id: Number(region.city_id),
        },
      });
    }
  }, [region.city_id]);

  useEffect(() => {
    if (suburbData) {
      const result = suburbData.models.results;
      setSuburbs(result);
    }
  }, [suburbData]);

  //get suburbs for edit page
  const {
    loading: regionLoading,
    error: regionError,
    data: regionData,
  } = useQuery(GET_REGIONS, {
    variables: {
      id: params.id,
    },
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  useEffect(() => {
    if (regionData && params.id) {
      const result = regionData.models.results[0];
      setRegion({
        ...region,
        name: result.properties.name,
        slug: result.properties.slug,
        suburb_ids: result.properties.suburb_ids,
        city_id: result.properties.city_id,
      });
    }
  }, [regionData]);

  const [
    createRegion,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(CREATE_REGION, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const newItem = data?.model_create;
      const existingItems: any = cache.readQuery({ query: GET_REGIONS });

      if (newItem && existingItems) {
        cache.writeQuery({
          query: GET_REGIONS,
          data: {
            models: {
              results: [...existingItems?.models.results, newItem],
            },
          },
        });
      }
    },
    onCompleted: (data) => {
      if (data) {
        history.push(Routes.REGION_LINK(params.site));
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [
    updateRegion,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useMutation(UPDATE_REGION, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const newInfo = data?.model_update;
      const existingInfos: any = cache.readQuery({ query: GET_REGIONS });

      const updatedCities = existingInfos.models.results.map((item: any) => {
        if (item.id === newInfo.id) {
          return newInfo;
        }
        return item;
      });

      if (newInfo && existingInfos) {
        cache.writeQuery({
          query: GET_REGIONS,
          data: {
            models: {
              results: updatedCities,
            },
          },
        });
      }
    },
    onCompleted: (data) => {
      if (data) {
        history.push(Routes.REGION_LINK(params.site));
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRegion({
      ...region,
      city_id: event.target.value as string,
    });
  };

  const handleMultiChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }

    setRegion({
      ...region,
      suburb_ids: value,
    });
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (params.id) {
      updateRegion({
        variables: {
          id: params.id,
          properties: [
            { name: "slug", value: region.slug },
            { name: "name", value: region.name },
            { name: "city_id", value_int: Number(region.city_id) },
            { name: "suburb_ids", value_array: region.suburb_ids },
          ],
        },
      });
    } else {
      createRegion({
        variables: {
          properties: [
            { name: "slug", value: region.slug },
            { name: "name", value: region.name },
            { name: "city_id", value_int: Number(region.city_id) },
            { name: "suburb_ids", value_array: region.suburb_ids },
          ],
        },
      });
    }
  };

  return (
    <FormLayout
      formHeading={params.id ? "Update Region" : "Create Region"}
      backLink={Routes.REGION_LINK(params.site)}
      size="lg"
      loading={mutationLoading}
    >
      {mutationError ? (
        <ErrorToast open={true} errorMessage={mutationError.message} />
      ) : null}
      <RegionForm
        handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChange(e, setRegion, region)
        }
        handleBlur={(e: React.FocusEvent<HTMLInputElement>) =>
          handleBlur(e, setRegion, region)
        }
        handleSubmit={handleSubmit}
        handleMultiChange={handleMultiChange}
        handleSelectChange={handleSelectChange}
        isFetching={false}
        region={region}
        cities={cities}
        suburbs={suburbs}
        setRegion={setRegion}
        isSubmitting={mutationLoading}
      />
    </FormLayout>
  );
};

interface SiteParams {
  site: string;
  id?: string;
}

export default CreateSuburbPage;
