//Where is this?

import React from "react";
import { useParams, NavLink, Link as RouterLink } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  Divider,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import RedoIcon from "@material-ui/icons/Redo";
import DnsRoundedIcon from "@material-ui/icons/DnsRounded";

import * as Routes from "../../../routes";

const LegacySiteMangerSubMenu = (props: any) => {
  const params = useParams<SiteParams>();

  return (
    <List disablePadding={true}>
      <ListItem button onClick={props.handleBackClick}>
        <ListItemIcon>
          <ArrowBackIosIcon />
        </ListItemIcon>
        <ListItemText primary="Back" />
      </ListItem>
      <Divider />
      <ListSubheader>Site Manager</ListSubheader>
      <ListItem
        button
        component={NavLink}
        to={Routes.CONSTANTS_LINK(params.site)}
      >
        <ListItemIcon>
          <DnsRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Constants" />
      </ListItem>
      <ListItem
        button
        component={NavLink}
        to={Routes.REDIRECTS_LINK(params.site)}
      >
        <ListItemIcon>
          <RedoIcon />
        </ListItemIcon>
        <ListItemText primary="Redirects" />
      </ListItem>
    </List>
  );
};

interface SiteParams {
  site: string;
}

export default LegacySiteMangerSubMenu;
