import React, { useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery, useMutation } from "@apollo/client";
import GET_REDIRECTS from "../../../graphql/GET_REDIRECTS";
import DELETE_PAGE from "../../../graphql/DELETE_PAGE";
import * as Routes from "../../../routes";
import DataTable from "../../../components/dataTable";
import ActionCell from "../../../components/dataTable/redirectsActionCell";
import { RootState } from "../../../store/store";
import TableLayout from "../../../components/layout/TableLayout";
import EmptyContentMessage from "../../../components/layout/EmptyContentMessage";

function Redirects() {
  const params = useParams<SiteParams>();
  const state = useSelector((state: RootState) => state);

  interface SiteParams {
    site: string;
  }

  const { loading, error, data } = useQuery(GET_REDIRECTS, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  const redirects: any = useMemo(() => {
    return data ? data.admin_pages.results : [];
  }, [data]);

  const [
    deletePage,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(DELETE_PAGE, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const redirectFromResponse = data?.admin_page_delete;
      const existingRedirects: any = cache.readQuery({ query: GET_REDIRECTS });

      if (existingRedirects && redirectFromResponse) {
        const updatedRedirects = existingRedirects.admin_pages.results.filter(
          (page: any) => {
            if (page.id !== redirectFromResponse.id) {
              return page;
            }
          }
        );

        cache.writeQuery({
          query: GET_REDIRECTS,
          data: {
            admin_pages: {
              results: [...updatedRedirects],
            },
          },
        });
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleDelete = (id: string) => {
    deletePage({
      variables: {
        id: id,
      },
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Redirect From",
        accessor: "slug",
      },
      {
        Header: "Redirect to",
        accessor: "redirect_to",
      },
      {
        Header: "Redirect Code",
        accessor: "redirect_code",
      },
      {
        Header: "Created At",
        accessor: "created_at",
        disableFilters: true,
        Cell: (props: any) => {
          let date = new Date(props.value);
          return <>{date.toLocaleString("en-GB")}</>;
        },
      },
      {
        Header: "Updated At",
        accessor: "updated_at",
        disableFilters: true,
        Cell: (props: any) => {
          let date = new Date(props.value);
          return <>{date.toLocaleString("en-GB")}</>;
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <ActionCell
              site={params.site}
              id={props.value}
              handleDelete={handleDelete}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <TableLayout
      loading={loading}
      buttonLink={Routes.REDIRECT_CREATE_LINK(params.site)}
      tableHeading="Redirects"
      buttonText="Create Redirect"
    >
      {redirects.length === 0 ? (
        <EmptyContentMessage message="This instance does not have any redirect." />
      ) : (
        <DataTable data={redirects} columns={columns} />
      )}
    </TableLayout>
  );
}

export default Redirects;
