import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Divider,
  InputBase,
  Paper,
} from "@material-ui/core";
import imageCompression from "browser-image-compression";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import slugify from "slugify";
import { useMutation } from "@apollo/client";
import CREATE_ASSET from "../../graphql/CREATE_ASSET";
import GET_ASSETS from "../../graphql/GET_ASSETS";
import GetAppIcon from "@material-ui/icons/GetApp";
import Loader from "../layout/Loader";

function ImageUploader(props: UploaderProps) {
  const [imageUpload, setImageUpload] = useState<any | null>(null);
  const [compressedImageURL, setCompressedImageURL] = useState<string>("");
  const [assetPath, setAssetPath] = useState<string>("");
  const [assetName, setAssetName] = useState<string>("");
  const [assetExtension, setAssetExtension] = useState<string>("");
  const [compressing, setCompressing] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const classes = useStyles();
  const state = useSelector((state: RootState) => state);

  useEffect(() => {
    if (props.assetPath) {
      setAssetPath(props.assetPath);
    }
  }, []);

  const [
    addAsset,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(CREATE_ASSET, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const newAsset = data?.admin_assets_create.assets[0];
      const existingAssets: any = cache.readQuery({ query: GET_ASSETS });

      if (newAsset && existingAssets) {
        cache.writeQuery({
          query: GET_ASSETS,
          data: {
            admin_assets: {
              results: [...existingAssets?.admin_assets.results, newAsset],
            },
          },
        });
      }
    },
    onCompleted: (data) => {
      setIsFetching(false);
      const imagePath = data?.admin_assets_create.assets[0].url;
      if (props.setImagePath) {
        props.setImagePath(imagePath as string);
      }
      props.handleClose();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleFileChange = async (event: any) => {
    setCompressing(true);
    const imageFile = event.target.files[0];
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      setCompressing(false);
      setImageUpload(compressedFile);

      const split = imageFile.name.split(".");
      const extension = split.pop();
      let fileName = split.join(".");
      fileName = slugify(fileName, {
        lower: true,
      });
      setAssetName(fileName);
      setAssetExtension(extension);

      setCompressedImageURL(URL.createObjectURL(compressedFile));
    } catch (error) {
      console.log(error);
    }
  };

  const handleAssetPath = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAssetPath(event.target.value as string);
  };

  const handleAssetName = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAssetName(event.target.value as string);
  };

  const uploadFile = async () => {
    setIsFetching(true);
    const formData = new FormData();
    formData.append("file", imageUpload);

    const path = assetPath
      ? `images/${assetPath}/${assetName}.${assetExtension}`
      : `images/${assetName}.${assetExtension}`;

    const fileUploadResponse = await fetch(
      `${process.env.REACT_APP_BASE_URL}/asset`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${state.current_user.user?.token}`,
          instance: state.current_site.site?.slug!,
          "asset-path": path,
        },
        body: formData,
      }
    );
    const resJson = await fileUploadResponse.json();

    addAsset({
      variables: {
        name: resJson.name,
        physical_file_path: `assets/${resJson.name}`,
        url: resJson.url,
      },
    });
  };

  const handleDrag = () => {};

  return (
    <>
      {imageUpload ? (
        compressedImageURL ? (
          <>
            {isFetching ? <Loader open={true} /> : null}
            <img className={classes.image} src={compressedImageURL} />
            <div className={classes.urlWrapper}>
              <Paper component="form" className={classes.root}>
                <Typography className={classes.url}>assets/images/</Typography>
                <Divider className={classes.divider} orientation="vertical" />
                <InputBase
                  className={classes.input}
                  placeholder="image path"
                  name="path"
                  value={assetPath}
                  onChange={handleAssetPath}
                />
                <Typography className={classes.url}>/</Typography>
                <InputBase
                  className={classes.input}
                  placeholder="name"
                  name="path"
                  value={assetName}
                  onChange={handleAssetName}
                />
                <span style={{ padding: "0.5rem" }}>.{assetExtension}</span>
              </Paper>
              <Button
                style={{ height: 56 }}
                variant="contained"
                color="primary"
                onClick={uploadFile}
              >
                Upload Image
              </Button>
            </div>
          </>
        ) : (
          <p>Compressing!</p>
        )
      ) : (
        <form>
          <Typography variant="h6" className={classes.header} component="h2">
            Select Image
          </Typography>

          <div className={classes.dropWrapper} onDragEnter={handleDrag}>
            <input
              className={classes.drop}
              type="file"
              name="file"
              onChange={handleFileChange}
            />
            <div className={classes.dropText}>
              <GetAppIcon className={classes.drag__icon} />
              <span>Choose an image or drag it here.</span>
            </div>
          </div>
        </form>
      )}
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      width: "100%",
      maxHeight: "60vh",
      overflow: "hidden",
      objectFit: "contain",
    },
    drop: {
      width: "100%",
      height: "100%",
      opacity: "0%",
      position: "relative",
      zIndex: 99,
      cursor: "pointer",
    },
    dropText: {
      position: "absolute",
      top: "40%",
      left: "40%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    dropWrapper: {
      position: "relative",
      border: "1px dashed lightgrey",
      borderRadius: "5px",
      width: "100%",
      height: "64vh",
    },
    drag__icon: {
      fontSize: "50px",
      color: "lightgrey",
      marginBottom: theme.spacing(2),
    },
    header: {
      marginBottom: theme.spacing(3),
    },
    root: {
      padding: 5,
      display: "flex",
      alignItems: "center",
      height: 56,
      width: 500,
      marginRight: theme.spacing(1),
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    url: {
      padding: 5,
    },
    urlWrapper: {
      display: "flex",
      justifyContent: "center",
      placeItems: "center",
      alignItems: "center",
    },
  })
);

interface UploaderProps {
  handleClose: () => void;
  setImagePath?: (imagePath: string) => void;
  assetPath?: string;
}

export default ImageUploader;
