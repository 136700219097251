import { useState, useMemo } from 'react';
import FolderView from './FolderView';


const DirectoryView = (props: DirectoryViewProps) => {
    const root = useMemo(() => {
        
        const root: directory = {
            type: "DIRECTORY",
            name: "Assets",
            isRoot: true,
            path: "",
            children: []
        }

        for(const asset of props.assets ){
            const split = asset.name.split("/")
            const fileName = split.pop()
            const joinedPath = split.join("/")
            const currentPath: string[] = []
            let currentDirectory = root
            

            while(split.length > 0 ){
                const folder = split.shift();
                
                currentPath.push(folder);
                const path = currentPath.join("/")
                
                const found = currentDirectory.children.find((child: any) =>{ 
                    if(child.type === "DIRECTORY" && child.path === path){
                        return child
                    }
                })

                if(found){
                    currentDirectory = found
                    continue;
                }

                currentDirectory.children.push({
                    type: "DIRECTORY",
                    name: folder,
                    path: path,
                    isRoot: false,
                    children: []
                })

                const created = currentDirectory.children.find((child: any) =>{ 
                    if(child.type === "DIRECTORY" && child.path === path){
                        return child
                    }
                })
                currentDirectory = created
            }
            
            currentDirectory.children.push({
                type: "FILE",
                name: fileName,
                path: joinedPath,
                asset: asset
            })

        }
        return root
    }, [props.assets])

    const [ currentDirectory, setCurrentDirectory ] = useState<any>(root)
    const [ parentDirectories, setParentDirectories ] = useState<any[]>([])

    const handleDirectorySelection = (directoryPath: string) => {      
        const found = currentDirectory.children.find((child: any) =>{ 
            if(child.type === "DIRECTORY" && child.path === directoryPath){
                return child
            }
        })
        if(found){
            setParentDirectories(parentDirectories.concat(currentDirectory));
            setCurrentDirectory(found);
        }
    }

    const handleDirectoryBack = () => {    
        const finalIndex = parentDirectories.length - 1
        const parentDirectory = parentDirectories[finalIndex]
        setParentDirectories(parentDirectories.slice(0, finalIndex));
        setCurrentDirectory(parentDirectory);
    }

    return(
        <FolderView 
            currentDirectory={currentDirectory} 
            parentDirectories={parentDirectories} 
            handleDirectorySelection={handleDirectorySelection} 
            handleBack={handleDirectoryBack} 
            setSelectedImage={props.setSelectedImage}
            selectedImageUrl={props.selectedImage}
            assets={props.assets}
        />
    );
}

interface DirectoryViewProps {
    assets: any[]
    setSelectedImage?: (url: string) =>  void
    selectedImage?: string
}

interface directory {
    type: string
    name: string
    path: string
    isRoot: boolean
    children: any[]
    selectedImageUrl?: string
}



export default DirectoryView;