import { gql } from '@apollo/client';

const CREATE_COLLECTION = gql`
  mutation create_collection(
    $metadata: HashObject!, 
    $physical_file_path: String!, 
    $properties: [NestedPropertyInputType]
  ){
    admin_table_create(
      table: {
        physical_file_path: $physical_file_path, 
        metadata: $metadata, 
        properties: $properties
      }
    )
  {
    id
    created_at
    updated_at
    name
    metadata
    parameterized_name
    physical_file_path
    properties {
      name
      attribute_type
      belongs_to
      metadata
    }
  }
}`

export default CREATE_COLLECTION;