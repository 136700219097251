import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Divider,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import GET_WEBFORMS from "../../graphql/GET_WEBFORMS";

import { RootState } from "../../store/store";
import Loader from "../../components/layout/Loader";
/* to={Routes.WEBFORMS_VIEW_LINK(props.site, props.value)} */

function ImageSelectModal(props: UploadModalProps) {
  const classes = useStyles();
  const state = useSelector((state: RootState) => state);

  const { loading, error, data } = useQuery(GET_WEBFORMS, {
    variables: { id: props.id },
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  const webform: any = useMemo(() => {
    return data ? data.models.results[0] : null;
  }, [data]);

  if (props.open && loading) {
    return <Loader open={true} /> 
  }
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-view-modal"
    >
      <div className={classes.paper}>
        <IconButton className={classes.closeIcon} onClick={props.handleClose}>
          <CloseIcon />
        </IconButton>
        {webform ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Created At</TableCell>
                <TableCell>
                  {new Date(webform.created_at).toLocaleString("en-GB")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Updated At</TableCell>
                <TableCell>
                  {new Date(webform.updated_at).toLocaleString("en-GB")}
                </TableCell>
              </TableRow>
              {Object.entries(webform.properties).map((entry: any) => {
                return (
                  <TableRow>
                    <TableCell>{entry[0]}</TableCell>
                    <TableCell>
                      {typeof entry[1] === "object"
                        ? JSON.stringify(entry[1])
                        : entry[1]}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <p>Webform not found</p>
        )}
      </div>
    </Modal>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeIcon: {
      position: "absolute",
      right: "8px",
      top: "8px",
    },
    paper: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      position: "absolute",
      backgroundColor: theme.palette.background.paper,
      borderRadius: 4,
      padding: "32px 16px 16px 16px",
    },
  })
);

interface UploadModalProps {
  open: boolean;
  handleClose: any;
  id: string;
}

export default ImageSelectModal;
