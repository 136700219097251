import { Organisation } from "../organisationList/types";

export interface User {
    _id: number
    email: string
    name: string
    image: string
    role: Role
    token: string
    organisationID: string | null
    organisation: Organisation | null
}

export type Role = "ADMIN" | "USER";

export interface UserErrors {
    errorType: string
}

export interface CurrentUserState {
    user: User | null
    fetching: boolean
    success: boolean
    errors: UserErrors[]
    isLoggingOut: boolean
    logoutSuccess:  boolean
    logOutErrors: UserErrors[]
}

export const GET_USER  = 'GET_CURRENT_USER';
export const SET_USER = 'SET_USER';
export const LOGOUT = 'LOGOUT';

interface setAuthenticatedUser {
  type: typeof SET_USER
  payload: User
}

interface logoutUser {
  type: typeof LOGOUT
}

export type CurrentUserActions = setAuthenticatedUser | logoutUser