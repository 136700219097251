import {
    CurrentSiteState,
    CurrentSiteActions,
    FETCH_SITE,
    SET_SITE,
    SET_ERRORS,
    UNSET_SITE,
    SET_FAVOURITES,
    UPDATE_FAVOURITES,
    DELETE_FAVOURITE
} from "./types"

const initialState: CurrentSiteState = {
    site: null,
    fetching: false,
    success: false,
    invalidate: false,
    errors: [],
}

function currentSiteReducer(state = initialState, action: CurrentSiteActions): CurrentSiteState {
    switch (action.type) {
        case FETCH_SITE:{
            return {
                ...state,
                fetching: true,
                site: null
            }
        }
        case SET_SITE:{
            return {
                ...state,
                fetching: false,
                success: true,
                site: action.payload
            }
        }
        case UNSET_SITE:{
            return {
                ...state,
                site: null
            }
        }
        case SET_ERRORS:{
            return {
                ...state,
                fetching: false,
                errors: action.payload
            }
        }
        case SET_FAVOURITES:{

            return {
                ...state,
                site: {...state.site!, favourites: action.payload}
            }
        }
        case DELETE_FAVOURITE:{
            const filteredFavourites = state.site?.favourites!.filter(item => {
                if(item.id !== action.payload.id){
                    return item
                }
            })
            return {
                ...state,
                site: {...state.site!, favourites: filteredFavourites}
            }
        }
        case UPDATE_FAVOURITES:{
            return {
                ...state,
                site: {...state.site!, favourites: state.site?.favourites?.concat(action.payload)}
            }
        }
        default:
            return state
    }
};

export default currentSiteReducer;