import { gql } from '@apollo/client';

const UNSET_CONSTANT = gql`
    mutation unset_constant($name: String!) {
        constant_unset(name: $name) {
            id: name
            name
            value
        }
    }
`

export default UNSET_CONSTANT;