import React, { useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../../components/layout/Loader";
import ErrorToast from "../../../components/layout/ErrorToast";

import { useSelector, useDispatch } from "react-redux";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import GET_SINGLES from "../../../graphql/GET_SINGLE_CONTENT_TYPES";
import * as Routes from "../../../routes";
import DataTable from "../../../components/dataTable";

import ActionCell from "../../../components/dataTable/singlesActionCell";
import { RootState } from "../../../store/store";

import TableLayout from "../../../components/layout/TableLayout";
import EmptyContentMessage from "../../../components/layout/EmptyContentMessage";

import DELETE_SINGLE from "../../../graphql/DELETE_SINGLE";
import SINGLE_PAGE_ID_GET from "../../../graphql/GET_SINGLE_PAGE_ID";

function SingleTypes() {
  const params = useParams<SiteParams>();
  const state = useSelector((state: RootState) => state);
  const client = useApolloClient();

  interface SiteParams {
    site: string;
  }

  const { loading, error, data } = useQuery(GET_SINGLES, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  const singles: any = useMemo(() => {
    return data ? data.admin_tables.results : [];
  }, [data]);

  const [deleteSingle, { loading: mutationLoading, error: mutationError }] =
    useMutation(DELETE_SINGLE, {
      context: {
        headers: {
          Authorization: `Bearer ${state.current_user.user?.token}`,
          instance: state.current_site.site?.slug,
        },
      },
      update: (cache, { data }) => {
        const deletedItem = data?.record_delete;
        const currentItems: any = cache.readQuery({
          query: GET_SINGLES,
          variables: {
            name: params.site,
          },
        });

        if (deletedItem && currentItems) {
          const updatedItems = currentItems.admin_tables.results.filter(
            (item: any) => item.id !== deletedItem.id
          );

          cache.writeQuery({
            query: GET_SINGLES,
            variables: {
              name: params.site,
            },
            data: {
              admin_tables: {
                results: [...updatedItems],
              },
            },
          });
        }
      },
      onError: (error) => {
        console.log(error);
      },
    });

  const handleDelete = useCallback(
    async (value: string) => {
      try {
        const { data: queryData } = await client.query({
          query: SINGLE_PAGE_ID_GET,
          variables: { table: value },
          context: {
            headers: {
              Authorization: `Bearer ${state.current_user.user?.token}`,
              instance: state.current_site.site?.slug,
            },
          },
        });

        const singleId = queryData?.id?.results[0]?.id;

        if (!singleId) {
          console.log("No single item found to delete.");
          return;
        }

        await deleteSingle({
          variables: {
            id: singleId,
            table: value,
          },
        });
        // reload the page to update the singles records
        window.location.reload();
      } catch (error) {
        console.error("Error fetching ID or deleting item:", error);
      }
    },
    [
      client,
      deleteSingle,
      state.current_user.user?.token,
      state.current_site.site?.slug,
    ]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Schema",
        accessor: "metadata.display_name",
      },
      {
        Header: "Unique ID",
        accessor: "name",
      },
      {
        Header: "Created At",
        accessor: "created_at",
        disableFilters: true,
        Cell: (props: any) => {
          let date = new Date(props.value);
          return <>{date.toLocaleString("en-GB")}</>;
        },
      },
      {
        Header: "Updated At",
        accessor: "updated_at",
        disableFilters: true,
        Cell: (props: any) => {
          let date = new Date(props.value);
          return <>{date.toLocaleString("en-GB")}</>;
        },
      },
      {
        Header: "",
        accessor: "parameterized_name",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <ActionCell
              site={params.site}
              value={props.value}
              slug={props.value}
              handleDelete={handleDelete}
            />
          );
        },
      },
    ],
    [handleDelete, params.site]
  );

  return (
    <TableLayout
      loading={loading}
      buttonLink={Routes.SINGLE_CREATE_LINK(params.site)}
      tableHeading="All Singles"
      buttonText="Create Single"
    >
      {loading ? (
        <Loader open={true} />
      ) : singles.length === 0 ? (
        <EmptyContentMessage message="This instance does not have any singles" />
      ) : (
        <>
          <DataTable data={singles} columns={columns} />
          {mutationLoading ? <Loader open={true} /> : null}
          {mutationError ? (
            <ErrorToast open={true} errorMessage={mutationError.message} />
          ) : null}
        </>
      )}
    </TableLayout>
  );
}

export default SingleTypes;
