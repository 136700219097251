import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { ListItem, } from "@material-ui/core";
import * as Routes from "../../../routes";

function FormListItem(props:any) {
  const params = useParams<SiteParams>();  
  
  return (
    <ListItem button component={NavLink} className='nested' exact to={Routes.FORMS_VIEW_LINK(params.site, props.collection.name)}>
        {props.collection.metadata.display_name}
    </ListItem>
  );
}

interface SiteParams {
  site: string;
}
export default FormListItem;