import React, { useMemo, useState } from "react";
import {
  Container,
  Typography,
  Button,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import GET_ASSETS from "../../../graphql/GET_ASSETS";
import { RootState } from "../../../store/store";
import Loader from "../../../components/layout/Loader";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import UploadModal from "../../../components/assets/ImageUploadModal";
import DirectoryView from "../../../components/assets/DirectoryView";
import FlatView from "../../../components/assets/FlatView";
import EmptyContentMessage from "../../../components/layout/EmptyContentMessage";

const Assets = () => {
  const state = useSelector((state: RootState) => state);
  const classes = useStyles();
  const [openModal, setOpenUploadModal] = useState(false);
  const [imageView, setImageView] = useState("DIRECTORY");

  const openUploadModal = () => {
    setOpenUploadModal(true);
  };

  const closeUploadModal = () => {
    setOpenUploadModal(false);
  };

  const switchViews = (event: React.ChangeEvent<{ value: unknown }>) => {
    setImageView(event.target.value as string);
  };

  const { loading, error, data } = useQuery(GET_ASSETS, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  const assets: any = useMemo(() => {
    return data ? data.admin_assets.results : [];
  }, [data]);

  return (
    <Container>
      <div className={classes.action_bar}>
        <Typography component="h1" variant="h3">
          Asset Manager
        </Typography>
        <div className="action_bar_buttons">
          <Select
            className={classes.view__select}
            value={imageView}
            onChange={switchViews}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem value={"DIRECTORY"}>Directory</MenuItem>
            <MenuItem value={"FLAT"}>All</MenuItem>
          </Select>
          <Button
            startIcon={<AddCircleIcon />}
            variant="contained"
            color="primary"
            onClick={openUploadModal}
          >
            add asset
          </Button>
        </div>
      </div>
      {assets.length === 0 ? (
        loading ? (
          <Loader open={true} />
        ) : (
          <EmptyContentMessage
            message={"This instance does not have any assets"}
          />
        )
      ) : imageView === "DIRECTORY" ? (
        <DirectoryView assets={assets} />
      ) : (
        <FlatView assets={assets} />
      )}

      <UploadModal open={openModal} handleClose={closeUploadModal} />
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  action_bar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: theme.spacing(1, 0, 2, 0),
  },
  view__select: {
    margin: "0px 40px",
    padding: theme.spacing(0, 1),
    width: 125,
  },
}));

export default Assets;
