import { useMemo } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import GET_COLLECTION_CONTENT_TYPES from "../../../graphql/GET_COLLECTION_CONTENT_TYPES";
import { RootState } from "../../../store/store";
import {
  ListItem,
  CircularProgress,
  Button,
} from "@material-ui/core";
import * as Routes from "../../../routes";
import CollectionListItem from "./CollectionListItem";

function CollectionsList(props: any) {
  const state = useSelector((state: RootState) => state);
  const params = useParams<SiteParams>();
  const { loading, error, data } = useQuery(GET_COLLECTION_CONTENT_TYPES, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  const collections: any = useMemo(() => {
    return data ? data.admin_tables.results : [];
  }, [data]);

  return (
    <>
      <ListItem
        component={NavLink}
        exact
        to={Routes.COLLECTION_LINK(params.site)}
        button
        className="nested"
      >
        All Collections
      </ListItem>
      {loading ? (
        <CircularProgress size={30} className="loading_marginLeft" />
      ) : collections.length > 0 ? (
        collections.map((collection: any) => (
          <CollectionListItem key={collection.name} collection={collection} />
        ))
      ) : null}
      <ListItem className="nested">
        <Button
          component={NavLink}
          to={Routes.COLLECTION_CREATE_LINK(params.site)}
          variant="outlined"
          color="primary"
          size="small"
        >
          Create Collection
        </Button>
      </ListItem>
    </>
  );
}

interface SiteParams {
  site: string;
}
export default CollectionsList;
