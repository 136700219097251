import React from 'react'; 
import { Select, MenuItem } from '@material-ui/core';

const SelectColumnFilter = ({column: { 
    filterValue, 
    setFilter, 
    preFilteredRows, 
    id 
}}) => {
    
    const options = React.useMemo(() => {
      const options = new Set()
      preFilteredRows.forEach(row => {
        if(row.values[id]){
          options.add(row.values[id])
        }
      })
      const uniqueOptions = []
      options.forEach(value => uniqueOptions.push(value))
      return uniqueOptions
    }, [id, preFilteredRows])
  
    return (
      <Select
        value={filterValue || ""}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
      >
        <MenuItem value="">All</MenuItem>
        {options.map((option, i) => (
          <MenuItem  key={i} value={option}>
            {String(option)}
          </MenuItem>
        ))}
      </Select>
    )
}
  
export default SelectColumnFilter;