import { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import GET_COLLECTION_WITH_ENTITIES from "../../graphql/GET_SINGLE_CONTENT_TYPE_WITH_ENTITIES";
import { useQuery, useMutation } from "@apollo/client";
import { RootState } from "../../store/store";
const useStyles = makeStyles({
  root: {
    minWidth: 275,
    padding: 10,
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },

  title: {
    fontSize: 14,
    marginBottom: 12,
  },
});

export default function DashboardCard(props: DashboardCardProps) {
  const [collection, setCollection] = useState({
    name: "",
    description: "",
    itemCount: 0,
  });
  const state = useSelector((state: RootState) => state);
  const classes = useStyles();
  const { loading, error, data } = useQuery(GET_COLLECTION_WITH_ENTITIES, {
    variables: { name: props.slug },
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });
  const collectionType: any = useMemo(() => {
    if (data) {
      setCollection({
        ...collection,
        name: data.admin_tables.results[0].metadata.display_name,
        description: data.admin_tables.results[0].metadata.description,
        itemCount: data.records.results.length,
      });
    }
    return data ? data.admin_tables.results[0] : null;
  }, [data]);

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography variant="h4" component="h2">
          {collection.name}
        </Typography>
        <Typography className={classes.title} color="textSecondary">
          {props.type === "single" ? "Single" : `${collection.itemCount} Items`}
        </Typography>
        <Typography variant="body2" component="p">
          {collection.description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          component={RouterLink}
          to={
            props.type === "single"
              ? `/site/${
                  state.current_site.site?.slug
                    ? state.current_site.site?.slug
                    : ""
                }/${props.type}/${props.slug}/item`
              : `/site/${
                  state.current_site.site?.slug
                    ? state.current_site.site?.slug
                    : ""
                }/${props.type}/${props.slug}`
          }
          color="primary"
          variant="outlined"
          size="small"
        >
          Go to {collection.name}
        </Button>
      </CardActions>
    </Card>
  );
}

interface DashboardCardProps {
  slug: string;
  type: string;
}
