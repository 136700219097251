import { gql } from '@apollo/client';

const CREATE_ASSET = gql`
    mutation create_asset(
        $name: String!, 
        $physical_file_path: String!, 
        $url: String!,
    ){
      admin_assets_create(assets: {
        name: $name, 
        physical_file_path: $physical_file_path, 
        url: $url
      }){
        assets {
          id
          created_at
          updated_at
          name
          metadata
          url
          physical_file_path
        }
      }
    }
`

export default CREATE_ASSET;