import React from "react";
import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

function ErrorToast(props: ErrorToastProps) {
  const [open, setOpen] = React.useState(props.open);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const position: SnackbarOrigin = { vertical: "top", horizontal: "center" };

  return (
    <Snackbar
      anchorOrigin={position}
      open={open}
      onClose={handleClose}
      key={position.vertical + position.horizontal}
    >
      <Alert
        severity={props.toastType ? props.toastType : "error"}
        onClose={handleClose}
      >
        {props.errorMessage}
      </Alert>
    </Snackbar>
  );
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface ErrorToastProps {
  open: boolean;
  toastType?: "error" | "info" | "success" | "warning";
  errorMessage: string;
}

export default ErrorToast;
