import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import InfoForm from "../../../../components/forms/info";
import { useSelector } from "react-redux";
import CREATE_INFO from "../../../../graphql/UPDATE_INFO";
import GET_INFO from "../../../../graphql/GET_INFO";
import GET_INFO_COLLECTION from "../../../../graphql/GET_INFO_COLLECTION";
import GET_INFO_CATEGORY from "../../../../graphql/GET_INFO_CATEGORY";
import { useMutation, useQuery } from "@apollo/client";
import ErrorToast from "../../../../components/layout/ErrorToast";
import * as Routes from "../../../../routes";
import { RootState } from "../../../../store/store";
import slugify from "slugify";
import FormLayout from "../../../../components/layout/FormLayout";
import { handleChange, handleBlur } from "../../../../utils/slugsCheck";

const CreateInfoPage = () => {
  let history = useHistory();
  const params = useParams<SiteParams>();
  const state = useSelector((state: RootState) => state);
  const [info, setInfo] = useState({
    name: "",
    slug: "",
    info_collection_page_id: 0,
    info_category_id: 0,
    content: "",
    meta_title: "",
    meta_desc: "",
  });
  const [collections, setCollections] = useState([]);
  const [categories, setCategories] = useState([]);
  const {
    loading: loadingCollection,
    error: collectionError,
    data: collectionData,
  } = useQuery(GET_INFO_COLLECTION, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });
  const {
    loading: loadingCategory,
    error: categoryError,
    data: categoryData,
  } = useQuery(GET_INFO_CATEGORY, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(GET_INFO, {
    variables: {
      id: params.id,
    },
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  useEffect(() => {
    if (queryData) {
      const result = queryData.models.results[0];
      setInfo({
        ...info,
        name: result.properties.name,
        slug: result.properties.slug,
        content: result.properties.content,
        meta_desc: result.properties.meta_desc,
        meta_title: result.properties.meta_title,
        info_category_id: result.properties.info_category_id,
        info_collection_page_id: result.properties.info_collection_page_id,
      });
    }
  }, [queryData]);

  useEffect(() => {
    if (collectionData) {
      setCollections(collectionData.models.results);
    }
  }, [collectionData]);

  useEffect(() => {
    if (info.info_collection_page_id) {
      const cats = categoryData.models.results.filter((category: any) => {
        if (
          info.info_collection_page_id ===
          category.properties.info_collection_page_id
        ) {
          return category;
        }
        return null;
      });
      setCategories(cats);
    }
  }, [info.info_collection_page_id]);

  const [
    createInfo,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(CREATE_INFO, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const newInfo = data?.model_create;
      const existingInfos: any = cache.readQuery({ query: GET_INFO });

      if (newInfo && existingInfos) {
        cache.writeQuery({
          query: GET_INFO,
          data: {
            models: {
              results: [...existingInfos?.models.results, newInfo],
            },
          },
        });
      }
    },
    onCompleted: (data) => {
      if (data) {
        history.push(Routes.INFO_LINK(params.site));
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleBodyChange = (content: string) => {
    setInfo({
      ...info,
      content: content,
    });
  };

  const handleCollectionChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setInfo({
      ...info,
      info_collection_page_id: Number(event.target.value),
    });
  };
  const handleCategoryChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setInfo({
      ...info,
      info_category_id: Number(event.target.value),
    });
  };
  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    createInfo({
      variables: {
        id: params.id,
        properties: [
          { name: "slug", value: info.slug },
          { name: "content", value: info.content },
          { name: "name", value: info.name },
          {
            name: "info_collection_page_id",
            value: String(info.info_collection_page_id),
          },
          { name: "info_category_id", value: String(info.info_category_id) },
          { name: "meta_title", value: info.meta_title },
          { name: "meta_desc", value: info.meta_desc },
        ],
      },
    });
  };

  return (
    <FormLayout
      loading={mutationLoading}
      formHeading="Create Information Page"
      backLink={Routes.INFO_LINK(params.site)}
      size="lg"
    >
      {mutationError ? (
        <ErrorToast open={true} errorMessage={mutationError.message} />
      ) : null}
      <InfoForm
        handleCollectionChange={handleCollectionChange}
        handleCategoryChange={handleCategoryChange}
        handleChange={handleChange}
        handleBlur={handleBlur}
        handleSubmit={handleSubmit}
        handleBodyChange={handleBodyChange}
        isFetching={false}
        info={info}
        setInfo={setInfo}
        collections={collections}
        categories={categories}
        isSubmitting={mutationLoading}
      />
    </FormLayout>
  );
};

interface SiteParams {
  site: string;
  id: string;
}

export default CreateInfoPage;
