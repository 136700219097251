import { TextField, Grid } from '@material-ui/core';
import React from 'react';
import { PropertyValue } from '../../pages/site/entities/create';

const DateTimeInput = (props: DateTimeInputProps) => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const offset = new Date().getTimezoneOffset();
        const utcOffset = ((offset / 60) * -1) * 100
        const date = `${event.target.value}+${utcOffset}`
        props.handleChange(date, props.index);
    }
    
    return(
        <Grid item xs={12} lg={6}>
            <p className="entity__inputHeading">{props.propertyValue.display_name}</p>
            <TextField
                type="datetime-local"
                onChange={handleChange}
                variant="outlined"
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Grid>
    );
}

interface DateTimeInputProps {
    index: number
    propertyValue: PropertyValue
    handleChange: (value: string, index: number) => void
}


export default DateTimeInput;