import React, { useState } from "react";
import {
  CollectionMetadata,
  CollectionProperty,
  PropertyMetadata,
} from "../../pages/site/collection/create";
import {
  Button,
  Typography,
  IconButton,
  TextField,
  Collapse,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import { useParams, Link as RouterLink } from "react-router-dom";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CollectionsFieldModal from "./CollectionsFieldModal";
import { makeStyles } from "@material-ui/core/styles";
import * as Routes from "../../routes";
import Edit from "@material-ui/icons/Edit";

const CollectionsFormView = (props: CollectionFormViewProps) => {
  const [formStep, setFormStep] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [fieldsModelProps, setFieldModelProps] = useState<detailModalProps>({
    open: false,
    index: null,
  });
  const [expanded, setExpanded] = React.useState(false);
  const classes = useStyles();
  const params = useParams<SiteParams>();

  const moveNextStep = () => {
    if (props.filePath) {
      setErrorMessage("");
      setFormStep(1);
    } else {
      setErrorMessage("Collection needs a name");
    }
  };

  const movePreviousStep = () => {
    setFormStep(0);
  };

  const toggleExpandClick = () => {
    setExpanded(!expanded);
  };

  const closeFieldModal = () => {
    setFieldModelProps({
      open: false,
      index: null,
    });
  };

  const openFieldModal = (index: number) => {
    setFieldModelProps({
      open: true,
      index: index,
    });
  };

  const handleCancelFieldModal = () => {
    setFieldModelProps({
      open: false,
      index: null,
    });
  };

  const handleEditFieldOpen = (index: number) => {
    setFieldModelProps({
      open: true,
      index: index,
    });
  };

  const handleAddNewClick = () => {
    openFieldModal(props.collectionProperties.length);
  };

  const disabled = props.collectionProperties.length;

  return (
    <>
      {formStep === 0 ? (
        <div className="form">
          <TextField
            label="Display Name"
            variant="outlined"
            name="display_name"
            value={props.collectionMetadata.display_name}
            onChange={props.handleDisplayNameChange}
            helperText={errorMessage}
            error={!!errorMessage}
          />
          <TextField
            variant="outlined"
            type="text"
            name="description"
            value={props.collectionMetadata.description}
            onChange={props.handleMetadataChange}
            multiline
            rows={6}
            label="Description"
          />
          {props.collectionMetadata.hasOwnProperty("collection_url") ? (
            <Tooltip
              title="The root path to the collection i.e 'blogs'"
              placement="right"
            >
              <TextField
                variant="outlined"
                type="text"
                name="collection_url"
                value={props.collectionMetadata.collection_url}
                onChange={props.handleMetadataChange}
                multiline
                rows={1}
                label="Collection Slug"
              />
            </Tooltip>
          ) : null}
          <div className={classes.advanced__header}>
            <Typography component="h2" variant="h6" className="marginRight">
              Advanced Settings
            </Typography>
            <IconButton
              className={expanded ? classes.expandOpen : classes.expand}
              onClick={toggleExpandClick}
            >
              <ExpandMoreIcon />
            </IconButton>
          </div>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <div className={classes.advanced__form}>
              <div className={classes.advanced__checkboxWrap}>
                <FormControlLabel
                  control={
                    <Checkbox
                      className="marginRight"
                      color="primary"
                      name="public_read"
                      checked={props.collectionMetadata.public_read}
                      value={props.collectionMetadata.public_read}
                      onChange={props.handleMetadataChange}
                    />
                  }
                  label="Public Read"
                />
                <Tooltip
                  title="All collections items will be visible to the public"
                  placement="right"
                >
                  <HelpOutlineIcon className="tooltip__icon" />
                </Tooltip>
              </div>

              <div className={classes.advanced__checkboxWrap}>
                <FormControlLabel
                  control={
                    <Checkbox
                      className="marginRight"
                      color="primary"
                      name="public_write"
                      checked={props.collectionMetadata.public_write}
                      onChange={props.handleMetadataChange}
                    />
                  }
                  label="Public Write"
                />
                <Tooltip
                  title="Public will be able to create collection items. Useful for forms."
                  placement="right"
                >
                  <HelpOutlineIcon className="tooltip__icon" />
                </Tooltip>
              </div>

              <div className={classes.advanced__checkboxWrap}>
                <FormControlLabel
                  control={
                    <Checkbox
                      className="marginRight"
                      color="primary"
                      name="public_delete"
                      checked={props.collectionMetadata.public_delete}
                      onChange={props.handleMetadataChange}
                    />
                  }
                  label="Public Delete"
                />
                <Tooltip
                  title="Public will be able to delete collection items. Use with caution."
                  placement="right"
                >
                  <HelpOutlineIcon className="tooltip__icon" />
                </Tooltip>
              </div>
              <Tooltip
                title="The directory path for your collection."
                placement="right"
              >
                <TextField
                  className={classes.advanced__filepath}
                  variant="outlined"
                  type="text"
                  name="physical_file_path"
                  label="Table Schema File Path"
                  value={props.filePath}
                />
              </Tooltip>
            </div>
          </Collapse>
          <div className={classes.collectionform_button}>
            <Button
              variant="text"
              component={RouterLink}
              to={Routes.COLLECTION_LINK(params.site)}
              className="marginRight"
            >
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={moveNextStep}>
              Next
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div className={classes.action_bar}>
            <div className={classes.collection__details}>
              <div className={classes.collection__displayname}>
                <Typography component="h1" variant="h5">
                  {props.collectionMetadata.display_name}
                </Typography>
                <Tooltip title="Edit your collection details" placement="right">
                  <IconButton
                    className={classes.collection__editicon}
                    onClick={movePreviousStep}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
              </div>
              <Typography variant="subtitle1">
                {truncate(props.collectionMetadata.description)}
              </Typography>
            </div>
            <Button
              className={classes.collection__createButton}
              startIcon={<AddCircleIcon />}
              variant="outlined"
              color="primary"
              onClick={handleAddNewClick}
            >
              Add New Collection Item
            </Button>
          </div>

          <div className={classes.collection__table}>
            {props.collectionProperties.map((collectionProperty, i) => (
              <div className={classes.table__row}>
                <div className={classes.collection__name__wrap}>
                  <Typography className={classes.collection__name}>
                    {collectionProperty.metadata?.display_name}
                  </Typography>
                  <caption className={classes.collection__name}>
                    ({collectionProperty.metadata?.type.replace("_", " ")})
                  </caption>
                </div>
                <IconButton>
                  <DeleteIcon
                    onClick={() => props.removeCollectionProperty(i)}
                  />
                </IconButton>
              </div>
            ))}
            <CollectionsFieldModal
              open={fieldsModelProps.open}
              index={fieldsModelProps.index}
              displayName={props.collectionMetadata.display_name}
              handleSave={closeFieldModal}
              handleCancel={handleCancelFieldModal}
              addCollectionProperty={props.addCollectionProperty}
              collectionProperties={props.collectionProperties}
              editCollectionProperty={props.editCollectionProperty}
            />
          </div>
          <div>
            <Button
              onClick={props.handleSubmit}
              variant="contained"
              color="primary"
              disabled={props.collectionProperties.length > 0 ? false : true}
            >
              Create Collection
            </Button>
          </div>
        </>
      )}
    </>
  );
};

interface CollectionFormViewProps {
  collectionMetadata: CollectionMetadata;
  collectionProperties: CollectionProperty[];
  handleMetadataChange: any;
  setCollectionProperties: any;
  handleDisplayNameChange: any;
  filePath: string;
  addCollectionProperty: (
    collectionProperty: CollectionProperty,
    propertyMetadata: PropertyMetadata
  ) => void;
  removeCollectionProperty: (index: number) => void;
  editCollectionProperty: (
    collectionProperty: CollectionProperty,
    propertyMetadata: PropertyMetadata,
    index: number
  ) => void;
  handleSubmit: () => void;
}

interface detailModalProps {
  open: boolean;
  index: number | null;
}

function truncate(str: string) {
  return str.length > 64 ? str.substring(0, 64) + "..." : str;
}

const useStyles = makeStyles((theme) => ({
  /*Create Collection */
  collectionform_button: {
    marginTop: theme.spacing(2),
    marginLeft: "auto",
  },

  /*Advanced Section*/
  advanced__form: {
    display: "flex",
    flexDirection: "column",
    padding: "5px 0",
  },
  advanced__filepath: {
    marginTop: theme.spacing(2),
  },
  advanced__header: {
    display: "flex",
    alignItems: "center",
    marginBottom: "-20px",
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  advanced__checkboxWrap: {
    display: "flex",
    alignItems: "center",
  },

  /*Create Collection Items Form Section*/
  action_bar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  collection__displayname: {
    display: "flex",
    alignItems: "center",
  },
  collection__details: {
    marginRight: theme.spacing(3),
    maxWidth: 400,
  },
  collection__editicon: {
    marginLeft: theme.spacing(1),
  },
  collection__table: {
    boxShadow: "none",
    margin: theme.spacing(3, 0),
  },
  table__row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3px 0px",
    borderBottom: "solid 1px #F0F0F0",
  },
  collection__name__wrap: {
    display: "flex",
    alignItems: "center",
  },
  collection__name: {
    marginRight: theme.spacing(2),
    textTransform: "capitalize",
  },
  collection__createButton: {
    marginRight: theme.spacing(2),
  },
}));

export interface SiteParams {
  site: string;
}

export default CollectionsFormView;
