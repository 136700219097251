import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import * as Routes from "../../routes";

const LayoutActionCell = (props) => {
  const [openDeleteWarning, setOpenDeleteWarning] = useState(false);

  const handleOpenDelete = () => {
    setOpenDeleteWarning(true);
  };

  const handleCloseDelete = () => {
    setOpenDeleteWarning(false);
  };

  const handleDeleteAndClose = () => {
    props.handleDelete(props.id);
    handleCloseDelete();
  };

  return (
    <div className="action_cell">
      <Tooltip title="Delete Layout">
        <IconButton onClick={handleOpenDelete}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Edit Layout">
        <IconButton
          component={RouterLink}
          to={Routes.LAYOUTS_EDIT_LINK(props.site, props.id)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={openDeleteWarning} onClose={handleCloseDelete}>
        <DialogTitle>{"Delete Layout"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you wish to delete this Layout? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteAndClose}
            variant="contained"
            color="secondary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LayoutActionCell;
