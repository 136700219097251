import React, { useState } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Tooltip,
  Switch,
  Input,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import CachedIcon from "@material-ui/icons/Cached";

function InstanceForm(props: InstanceFormProps) {
  const classes = useStyles();
  const [isGitRepositoryValid, setIsGitRepositoryValid] = useState(true);

  const onHandleRefreshClick = () => {
    if (props.refreshScreenshot) {
      props.refreshScreenshot();
    }
  };

  const validateGitRepository = (value: string) => {
    const isValid =
      value.startsWith("https://github.com/") && !value.endsWith("/");
    setIsGitRepositoryValid(isValid);
    return isValid;
  };

  const handleGitRepositoryChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;

    validateGitRepository(value);

    props.handleChange({
      target: {
        name,
        value,
      },
    });
  };

  return (
    <div className="form">
      {props.isFetching ? (
        <Skeleton variant="rect" width={"100%"} height={56} />
      ) : (
        <div className={classes.instance__info}>
          <Tooltip
            title="The current environment for your instance"
            placement="left"
          >
            <TextField
              value={props.instance.isStaging ? "STAGING" : "PRODUCTION"}
              onChange={props.handleChangeStaging}
              select
              label="Environment"
              variant="outlined"
              style={{ width: "25%" }}
              required
            >
              <MenuItem value={"STAGING"}>Staging</MenuItem>
              <MenuItem value={"PRODUCTION"}>Production</MenuItem>
            </TextField>
          </Tooltip>

          <Tooltip
            title="The name that will be displayed in your instance portal"
            placement="left"
          >
            <TextField
              error={props.errors.inputName === "name"}
              helperText={
                props.errors.inputName === "name"
                  ? props.errors.errorMessage
                  : ""
              }
              variant="outlined"
              type="text"
              name="name"
              onChange={props.handleChange}
              value={props.instance.name}
              label="Display Name"
              fullWidth
              required
            />
          </Tooltip>
        </div>
      )}

      {props.isFetching ? (
        <Skeleton variant="rect" width={"100%"} height={56} />
      ) : (
        <Tooltip title="The slug that is used for SmartyrDXP" placement="left">
          <TextField
            error={props.errors.inputName === "slug"}
            helperText={
              props.errors.inputName === "slug" ? props.errors.errorMessage : ""
            }
            variant="outlined"
            type="text"
            name="slug"
            onChange={props.handleChange}
            value={props.instance.slug}
            label="SmartyrDXP Slug"
            fullWidth
            required
          />
        </Tooltip>
      )}
      {props.isFetching ? (
        <Skeleton variant="rect" width={"100%"} height={56} />
      ) : (
        <Tooltip
          title="The URL of your website, this must end in a ' / '"
          placement="left"
        >
          <TextField
            error={props.errors.inputName === "url"}
            helperText={
              props.errors.inputName === "url" ? props.errors.errorMessage : ""
            }
            variant="outlined"
            type="text"
            name="url"
            onChange={props.handleChange}
            value={props.instance.url}
            label="Site URL"
            fullWidth
            required
          />
        </Tooltip>
      )}

      {props.isFetching ? (
        <Skeleton variant="rect" width={"100%"} height={56} />
      ) : (
        <Tooltip
          title="The PlatformOS token used to authenticate your instance registration"
          placement="left"
        >
          <TextField
            error={props.errors.inputName === "token"}
            helperText={
              props.errors.inputName === "token"
                ? props.errors.errorMessage
                : ""
            }
            variant="outlined"
            type="password"
            name="token"
            onChange={props.handleChange}
            value={props.instance.token}
            label={
              props.instance.tokenStored
                ? "Token (token is currently stored)"
                : "Token"
            }
            fullWidth
            required
          />
        </Tooltip>
      )}

      {props.isFetching ? (
        <Skeleton variant="rect" width={"100%"} height={56} />
      ) : (
        <Tooltip
          title="The URL for your website's GitHub repository"
          placement="left"
        >
          <TextField
            error={!isGitRepositoryValid}
            helperText={
              !isGitRepositoryValid
                ? "The URL must start with 'https://github.com/' and not end with a '/'"
                : ""
            }
            variant="outlined"
            type="text"
            name="gitRepository"
            required
            onChange={handleGitRepositoryChange}
            value={props.instance.gitRepository}
            label="Git Repository"
            fullWidth
          />
        </Tooltip>
      )}

      {props.instanceScreenShot && props.isFetching ? (
        <Skeleton variant="rect" width={"100%"} height={350} />
      ) : props.instanceScreenShot ? (
        <div>
          <img
            src={props.instanceScreenShot}
            width="100%"
            height="350"
            style={{ objectFit: "cover", objectPosition: "top" }}
          />
          {props.isRefreshing ? (
            <CircularProgress />
          ) : (
            <IconButton onClick={onHandleRefreshClick}>
              <CachedIcon />
            </IconButton>
          )}
        </div>
      ) : props.instanceScreenShot === "" ? (
        <div>
          <Skeleton variant="rect" width={"100%"} height={350} />
          {props.isRefreshing ? (
            <CircularProgress />
          ) : (
            <IconButton onClick={onHandleRefreshClick}>
              <CachedIcon />
            </IconButton>
          )}
        </div>
      ) : null}

      {props.organisationList && props.currentUserRole === "ADMIN" ? (
        <Tooltip
          title="Select the Organisation the website belongs to"
          placement="left"
        >
          <FormControl error={props.errors.inputName === "organisation"}>
            <InputLabel>Organisation</InputLabel>
            <Select
              value={props.instance.organisationID}
              onChange={props.handleChange}
              name="organisationID"
              fullWidth
              input={<Input />}
            >
              {props.organisationList.map((organisation: any) => (
                <MenuItem key={organisation.id} value={organisation.id}>
                  {organisation.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {props.errors.inputName === "organisation"
                ? props.errors.errorMessage
                : ""}
            </FormHelperText>
          </FormControl>
        </Tooltip>
      ) : null}
      <Tooltip
        title="Generally, this should be left off, build button is only necessary if site is a static generated site."
        placement="left"
      >
        <FormControl>
          <InputLabel>Build</InputLabel>
          <Switch
            checked={props.instance.build}
            onChange={props.toggleBuild}
            name="darkmode"
            color="primary"
          />
        </FormControl>
      </Tooltip>
      <div>
        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <Button
            className="form_button"
            size="large"
            variant="contained"
            color="primary"
            onClick={props.handleSubmit}
            disabled={props.isSubmitting || !isGitRepositoryValid}
          >
            {props.isSubmitting ? <CircularProgress /> : "Submit"}
          </Button>
        )}
      </div>
    </div>
  );
}

interface InstanceFormProps {
  instance: any;
  handleSubmit: any;
  handleChange: any;
  handleChangeStaging: any;
  toggleBuild: any;
  isFetching: boolean;
  isSubmitting: boolean;
  organisationList: any;
  instanceScreenShot?: string | null;
  refreshScreenshot?: () => void;
  isRefreshing?: boolean;
  currentUserRole: any;
  errors: any;
}

const useStyles = makeStyles((theme) => ({
  instance__info: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(2),
  },
}));

export default InstanceForm;
