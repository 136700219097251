import React from 'react';
import { TextField, Button, Switch, FormControlLabel, CircularProgress, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';


function OrganisationForm(props: OrganisationFormProps){
    const classes = useStyles();
    
    return (
      <form className="form">
        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <TextField
            variant="outlined"
            type="text"
            name="name"
            onChange={props.handleChange}
            value={props.organisation.name}
            label="Name"
            fullWidth
          />
        )}

        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <TextField
            variant="outlined"
            type="text"
            name="slug"
            onChange={props.handleChange}
            value={props.organisation.slug}
            label="Name"
            fullWidth
          />
        )}

        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <Tooltip title="" placement="right">
            <FormControlLabel
              control={
                <Switch
                  checked={props.organisation.hasCRM}
                  onChange={props.handleToggleChange}
                  name="hasCRM"
                  color="primary"
                />
              }
              label="Connect to CRM"
            />
          </Tooltip>
        )}

        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <Tooltip title="Show Dashboard" placement="right">
            <FormControlLabel
              control={
                <Switch
                  checked={props.organisation.hasDashboard}
                  onChange={props.handleToggleChange}
                  name="hasDashboard"
                  color="primary"
                />
              }
              label="Show Dashboard"
            />
          </Tooltip>
        )}
        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <Button
            className="form_button"
            variant="contained"
            size="large"
            color="primary"
            onClick={props.handleSubmit}
            disabled={props.isSubmitting}
          >
            {props.isSubmitting ? <CircularProgress /> : "Submit"}
          </Button>
        )}
      </form>
    );
}

interface OrganisationFormProps {
    isFetching: boolean
    isSubmitting: boolean
    handleChange: any
    handleToggleChange: any
    handleSubmit: any
    organisation: any
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignSelf: 'center',
        maxWidth: 450,
        width: '100%',
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '5px 0',
    },
    margin: {
        marginBottom: '14px'
    }
}));

export default OrganisationForm;