import { gql } from '@apollo/client';

const CREATE_GRAPHQL_QUERY_AND_PAGE = gql`
    mutation createAPIRoute($body: String!, $query_file_path: String!, $page_file_path: String!, $slug: String!, $content: String!) {
        admin_graphql_create(graphql: {body: $body, physical_file_path: $query_file_path}) {
            id
            metadata
            physical_file_path
            body
        }
        admin_page_create(page: {physical_file_path: $page_file_path, layout: "", searchable: false, slug: $slug, content: $content}) {
            id
            slug
            layout
            request_method
            physical_file_path
            content
        }
    }
`

export default CREATE_GRAPHQL_QUERY_AND_PAGE;