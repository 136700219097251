import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, Link } from "react-router-dom";
import { Container } from "@material-ui/core";
import { fetchOrganisationListIfNeeded } from "../../store/organisationList/actions";
import {
  updateInstance,
  unsetSiteUpdateSuccessFlag,
} from "../../store/siteList/actions";
import { RootState } from "../../store/store";
import * as Routes from "../../routes";
import cleanObject from "../../utils/cleanObject";
import InstanceForm from "../../components/forms/instance";
import FormLayout from "../../components/layout/FormLayout";
function Update() {
  const params = useParams<InstanceParams>();
  let history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);

  const [instanceID, setInstanceID] = useState(0);
  const [instance, setInstance] = useState({
    name: "",
    url: "",
    slug: "",
    email: "",
    tokenStored: false,
    token: "",
    gitRepository: "",
    organisationID: "",
    isStaging: false,
    build: false,
  });

  const [screenshot, setScreenshot] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [isRefeshing, setIsRefeshing] = useState(false);
  const [errors, setErrors] = useState({
    inputName: "",
    errorMessage: "",
  });
  useEffect(() => {
    setIsFetching(true);
    fetch(`${process.env.REACT_APP_BASE_URL}/v1/instance/${params.slug}`, {
      method: "get",
      headers: {
        Authorization: "Bearer " + state.current_user.user?.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setInstanceID(data.id.toString());
        delete data.id;
        setInstance({
          ...instance,
          name: data.name,
          url: data.url,
          slug: data.slug,
          email: data.email,
          tokenStored: data.token,
          gitRepository: data.gitRepository,
          organisationID: data.organisationID,
          isStaging: data.isStaging,
          build: data.build,
        });
        setScreenshot(data.image ? data.image : "");
        setIsFetching(false);
      });
  }, [state.current_user]);

  useEffect(() => {
    if (state.site_list.updateSuccess) {
      dispatch(unsetSiteUpdateSuccessFlag());
      history.push(Routes.INSTANCE_PORTAL);
    }
  }, [state.site_list.updateSuccess]);

  useEffect(() => {
    dispatch(fetchOrganisationListIfNeeded());
  }, [state.current_user]);

  useEffect(() => {
    if (state.current_user.user?.role === "USER") {
      setInstance({
        ...instance,
        organisationID: state.current_user.user?.organisationID
          ? state.current_user.user?.organisationID
          : "",
      });
    }
  }, [state.current_user]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInstance({
      ...instance,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeStaging = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInstance({
      ...instance,
      isStaging: event.target.value === "STAGING",
    });
  };

  const toggleBuild = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInstance({
      ...instance,
      build: event.target.checked,
    });
  };
  const handleImageRefresh = () => {
    setIsRefeshing(true);
    fetch(`${process.env.REACT_APP_BASE_URL}/v1/instance/${instanceID}/image`, {
      method: "put",
      headers: {
        Authorization: "Bearer " + state.current_user.user?.token,
      },
    }).then((res) => {
      setIsRefeshing(false);
    });
  };
  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    let valid = validateForm();

    if (valid) {
      let reqBody: any = {};
      reqBody["instance"] = cleanObject(instance);
      reqBody["instance"]["isStaging"] = instance.isStaging;

      console.log(reqBody);
      dispatch(updateInstance(instanceID, reqBody));
    }
  };

  const validateForm = () => {
    return true;
  };

  return (
    <>
      <Container className="container">
        <FormLayout
          formHeading="Update Website Details"
          backLink={Routes.INSTANCE_PORTAL}
          size="md"
          loading={null}
        >
          <InstanceForm
            instance={instance}
            handleChange={handleChange}
            handleChangeStaging={handleChangeStaging}
            handleSubmit={handleSubmit}
            isFetching={isFetching}
            isSubmitting={state.site_list.updating}
            organisationList={state.organisation_list.organisations}
            currentUserRole={state.current_user.user?.role}
            toggleBuild={toggleBuild}
            instanceScreenShot={screenshot}
            errors={errors}
            refreshScreenshot={handleImageRefresh}
          />
        </FormLayout>
      </Container>
    </>
  );
}

interface InstanceParams {
  slug: string;
}

export default Update;
