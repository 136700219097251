import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Modal } from "@material-ui/core";
import ImageUploader from "./ImageUploader";

function ImageUploadModal(props: UploadModalProps) {
  const classes = useStyles();

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="image-upload-modal"
    >
      <div className={classes.paper}>
        <ImageUploader handleClose={props.handleClose} />
      </div>
    </Modal>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      position: "absolute",
      minWidth: 1000,
      backgroundColor: theme.palette.background.paper,
      borderRadius: 8,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(3),
    },
  })
);

interface UploadModalProps {
  open: boolean;
  handleClose: any;
}

export default ImageUploadModal;
