import { gql } from "@apollo/client";

const GET_SUBURBS = gql`
  query suburb_get(
    $city_id: Int
    $per_page: Int = 4000
    $page: Int = 1
  ) {
    models(
      filter: {
        name: { exact: "modules/areas/suburb" }
        properties: [{ name: "city_id", value_int: $city_id }]
        deleted_at: { exists: false }
      }
      per_page: $per_page
      page: $page
    ) {
      total_entries
      total_pages
      results {
        id
        created_at
        updated_at
        properties
      }
    }
  }
`;

export default GET_SUBURBS;
