import { gql } from '@apollo/client';

const GET_SUBURBS = gql`
  query suburb_get(
    $id: ID
    $city_id: Int
    $per_page: Int = 4000
    $page: Int = 1
  ) {
    models(
      filter: {
        name: { exact: "modules/areas/suburb" }
        id: { value: $id }
        properties: [{ name: "city_id", value_int: $city_id }]
        deleted_at: { exists: false }
      }
      per_page: $per_page
      page: $page
    ) {
      total_entries
      total_pages
      results {
        id
        created_at
        updated_at
        properties
        content: related_models(
          model_schema_name: "modules/areas/suburb_content"
          join_on_property: "id"
          foreign_property: "suburb"
        ) {
          id
          properties
        }
        services: related_models(
          model_schema_name: "modules/services/service"
          join_on_property: "service_ids"
          foreign_property: "id"
        ) {
          id
          properties
        }
        city: related_model(
          model_schema_name: "modules/areas/city"
          join_on_property: "city_id"
          foreign_property: "id"
        ) {
          id
          properties
        }
      }
    }
  }
`;

export default GET_SUBURBS;

