import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container } from "@material-ui/core";
import {
  createUser,
  unsetUserCreateSuccessFlag,
} from "../../store/userList/actions";
import { fetchOrganisationListIfNeeded } from "../../store/organisationList/actions";
import * as Routes from "../../routes";
import { RootState } from "../../store/store";
import cleanObject from "../../utils/cleanObject";
import UserForm from "../../components/forms/user";
import FormLayout from "../../components/layout/FormLayout";
function Create() {
  const dispatch = useDispatch();
  let history = useHistory();
  const state = useSelector((state: RootState) => state);

  const [user, setUser] = useState({
    name: "",
    password: "",
    email: "",
    role: "",
    organisationID: "",
  });

  useEffect(() => {
    if (state.user_list.createSuccess) {
      dispatch(unsetUserCreateSuccessFlag());
      history.push(Routes.USERS);
    }
  }, [state.user_list.createSuccess]);

  useEffect(() => {
    dispatch(fetchOrganisationListIfNeeded());
  }, [state.current_user]);

  useEffect(() => {
    if (state.current_user.user?.role === "USER") {
      setUser({
        ...user,
        role: "USER",
        organisationID: state.current_user.user?.organisationID
          ? state.current_user.user?.organisationID
          : "",
      });
    }
  }, [state.current_user]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    let valid = validateForm();

    if (valid) {
      let reqBody: any = {};
      reqBody["user"] = cleanObject(user);
      dispatch(createUser(reqBody));
    }
  };

  const validateForm = () => {
    return true;
  };

  return (
    <Container className="container">
      <FormLayout
        loading={null}
        formHeading="Create User"
        backLink={Routes.INSTANCE_PORTAL}
        size="md"
      >
        <UserForm
          user={user}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          organisationList={state.organisation_list.organisations}
          currentUserRole={state.current_user.user?.role}
          isFetching={false}
          isSubmitting={state.user_list.creating}
        />
      </FormLayout>
    </Container>
  );
}

export default Create;
