import React from 'react';
import { TextField, Grid } from '@material-ui/core';
import { PropertyValue } from '../../pages/site/entities/create';

const NumberInput = (props: NumberInputProps ) => {

    return(
        <Grid item xs={12} lg={6} className="entity__inputWrap">
            <p className="entity__inputHeading">{props.propertyValue.display_name}</p>
            <TextField 
            fullWidth
                type="number"
                variant="outlined"
                InputProps={{ 
                    inputProps: { step: 1 },
                }}
                value={props.propertyValue.value}
                onChange={(event) => props.handleChange(Number((event.target.value as string)), props.index)}
                placeholder="Whole Number"
            />
        </Grid>
    );
}

interface NumberInputProps {
    index: number
    propertyValue: PropertyValue
    handleChange: (value: number, index: number) => void
}

export default NumberInput;