import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import {
    FETCHING,
    FETCHED,
    INVALIDATE,
    CREATED,
    CREATING,
    UPDATED,
    UPDATING,
    DELETING,
    DELETED,
    RESET_CREATE_SUCCESS,
    RESET_DELETE_SUCCESS,
    RESET_UPDATE_SUCCESS,
    OrganisationListState
} from "./types"
import { RootState } from '../store';


export function invalidateOrganisationList() {
    return { type: INVALIDATE }
}

export function unsetOrganisationCreateSuccessFlag(){
    return { type: RESET_CREATE_SUCCESS }
}
export function unsetOrganisationUpdateSuccessFlag(){
    return { type: RESET_UPDATE_SUCCESS }
}
export function unsetOrganisationDeleteSuccessFlag(){
    return { type: RESET_DELETE_SUCCESS }
}

export function fetchOrganisationListIfNeeded() {
    return function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any){
        const state: RootState = getState()
        if(shouldFetchOrganisationList(state.organisation_list)){
            return dispatch(fetchOrganisationList())
        }
    }
}

function shouldFetchOrganisationList(state: OrganisationListState){
    return true
}

function fetchOrganisationList() {
    return function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) {
        const state: RootState = getState();

        dispatch({ type: FETCHING  })
        return fetch(`${process.env.REACT_APP_BASE_URL}/v1/organisation`, 
          {
            method: "get",
            headers: {
                'Authorization': `Bearer ${state.current_user.user?.token}`
            }
          }
        )
        .then(response => {
            return response.json();   
        })
        .then(json => {
            dispatch({ type: FETCHED, payload: json });
        });
    };
};


export function createOrganisation(organisation: any){
    return function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) {
        const state: RootState = getState();        
        
        dispatch({ type: CREATING })
        return fetch(`${process.env.REACT_APP_BASE_URL}/v1/organisation`, {
            method: "post",
            body: JSON.stringify(organisation),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${state.current_user.user?.token}`
            }
        })
        .then(response => {
            if(response.status === 200){
                return response.json();
            } else {
                throw new Error('Network Error');
            }       
        })
        .then(json => {
            if(!json.errors){
                dispatch({ type: CREATED, payload: json })
            } else {
                for(let error of json.errors){
                    throw new Error(error.message);
                }
            }  
        })
        .catch((error) => {
            console.log(error)
        })
    }
}


export function updateOrganisation(id: number, organisation: any){
    return function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) {
        const state: RootState = getState();        
        
        dispatch({ type: UPDATING })
        return fetch(`${process.env.REACT_APP_BASE_URL}/v1/organisation/${id}`, {
            method: "put",
            body: JSON.stringify(organisation),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${state.current_user.user?.token}`
            }
        })
        .then(response => {
            if(response.status === 200){
                return response.json();
            } else {
                throw new Error('Network Error');
            }       
        })
        .then(json => {
            if(!json.errors){
                dispatch({ type: UPDATED, payload: json })
            } else {
                for(let error of json.errors){
                    throw new Error(error.message);
                }
            }  
        })
        .catch((error) => {
            console.log(error)
        })
    }
}

export function deleteOrganisation(id: number){
    return function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) {
        const state: RootState = getState();        
        
        dispatch({ type: DELETING })
        return fetch(`${process.env.REACT_APP_BASE_URL}/v1/organisation/${id}`, {
            method: "delete",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${state.current_user.user?.token}`
            }
        })
        .then(response => {
            if(response.status === 200){
                return response.json();
            } else {
                throw new Error('Network Error');
            }       
        })
        .then(json => {
            if(!json.errors){
                dispatch({ type: DELETED, payload: json })
            } else {
                for(let error of json.errors){
                    throw new Error(error.message);
                }
            }  
        })
        .catch((error) => {
            console.log(error)
        })
    }
}