import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const dataTableSkeleton = ({ rows, columns }) => {

  const cellsPerRow = Array(columns).fill()
  const bodyRows =  Array(rows).fill()
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {cellsPerRow.map((cell,  index) => (
              <TableCell key={index}>
                <Skeleton variant="rect" height={60} width={180} />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {bodyRows.map((row, rowIndex) => (
            <TableRow key={rowIndex} >
              {cellsPerRow.map((cell, cellIndex) => (
                <TableCell key={`${rowIndex}-${cellIndex}`}>
                  <Skeleton variant="rect" height={24} width={180} />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer> 
  );
};

export default dataTableSkeleton;