import React, { useState, useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/store";
import { makeStyles } from "@material-ui/core/styles";
import {

  Menu,
  MenuItem,
  ListItemIcon,
  IconButton,
  Typography,
  Link as MUILink
} from "@material-ui/core";

import SettingsIcon from "@material-ui/icons/Settings";
import BusinessIcon from "@material-ui/icons/Business";
import GroupIcon from "@material-ui/icons/Group";
import TimelineIcon from "@material-ui/icons/Timeline";
import * as Routes from "../../routes";

function Settings() {
  const [userAnchor, setUserAnchor] = useState<null | HTMLElement>(null);
  const [settingsAnchor, setSettingsAnchor] = useState<null | HTMLElement>(
    null
  );
  const classes = useStyles();
  const openSettings = Boolean(settingsAnchor);
  const state = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const handleSettingsOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSettingsAnchor(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setSettingsAnchor(null);
  };

  return (
    <div className={classes.appbarButtons}>
      <IconButton
        aria-label="share"

        onClick={handleSettingsOpen}
        className={classes.settingsIcon}
      >
        <SettingsIcon  />
      </IconButton>

      <Menu
        open={openSettings}
        anchorEl={settingsAnchor}
        onClose={handleSettingsClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem component={RouterLink} to={Routes.USERS}>
          <ListItemIcon className={classes.iconPaddingLeft}>
            <GroupIcon />
          </ListItemIcon>
          <Typography>Users</Typography>
        </MenuItem>

        {state.current_user.user?.role === "ADMIN" ? (
          <>
            <MenuItem component={RouterLink} to={Routes.ORGANISATIONS}>
              <ListItemIcon className={classes.iconPaddingLeft}>
                <BusinessIcon />
              </ListItemIcon>
              <Typography>Organisations</Typography>
            </MenuItem>
            <MenuItem component={'a'} href={process.env.REACT_APP_BI_URL }>
              <ListItemIcon className={classes.iconPaddingLeft}>
                <TimelineIcon />
              </ListItemIcon>
              <Typography>BI Dashboards</Typography>
            </MenuItem>
          </>
        ) : null}
      </Menu>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  appbarButtons: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    cursor: "pointer",
    marginRight: theme.spacing(1),
  },

  iconPaddingLeft: {
    paddingLeft: "12px",
  },

  settingsIcon: {
    "&.hover": {
      color: theme.palette.primary,
    },
  },
}));

interface BarLink {
  title: string;
  path: string;
}

export default Settings;
