import React, { useState } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Collapse,
  Typography,
  IconButton,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from "@material-ui/lab/Skeleton";
import RichTextAreaInput from "../editor/RichTextEditor";

function InfoForm(props: InfoFormProps) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  return (
    <div className="form">
      {props.isFetching ? (
        <Skeleton variant="rect" width={"100%"} height={56} />
      ) : (
        <TextField
          variant="outlined"
          type="text"
          name="name"
          onChange={(e) => props.handleChange(e, props.setInfo, props.info)}
          value={props.info.name}
          label="Name"
          fullWidth
        />
      )}

      {props.isFetching ? (
        <Skeleton variant="rect" width={"100%"} height={56} />
      ) : (
        <TextField
          variant="outlined"
          type="text"
          name="slug"
          onChange={(e) => props.handleChange(e, props.setInfo, props.info)}
          onBlur={(e) => props.handleBlur(e, props.setInfo, props.info)}
          value={props.info.slug}
          label="Slug"
          fullWidth
        />
      )}
      {props.isFetching ? (
        <Skeleton variant="rect" width={"100%"} height={56} />
      ) : (
        <TextField
          variant="outlined"
          type="text"
          name="meta_title"
          onChange={(e) => props.handleChange(e, props.setInfo, props.info)}
          value={props.info.meta_title}
          label="Meta Title"
          fullWidth
        />
      )}
      {props.isFetching ? (
        <Skeleton variant="rect" width={"100%"} height={56} />
      ) : (
        <TextField
          variant="outlined"
          type="text"
          name="meta_desc"
          onChange={(e) => props.handleChange(e, props.setInfo, props.info)}
          value={props.info.meta_desc}
          label="Meta Description"
          fullWidth
        />
      )}
      {props.isFetching ? (
        <Skeleton variant="rect" width={"100%"} height={56} />
      ) : (
        <FormControl>
          <InputLabel>Service</InputLabel>
          <Select
            name="collection"
            onChange={props.handleCollectionChange}
            value={props.info.info_collection_page_id}
            label="collection"
            placeholder="collection"
            fullWidth
          >
            {props.collections.map((collection: any) => {
              return (
                <MenuItem value={collection.id}>
                  {collection.properties.slug}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
      {props.isFetching ? (
        <Skeleton variant="rect" width={"100%"} height={56} />
      ) : (
        <FormControl>
          <InputLabel>Category</InputLabel>
          <Select
            name="category"
            onChange={props.handleCategoryChange}
            value={props.info.info_category_id}
            label="Category"
            placeholder="Category"
            fullWidth
          >
            {props.categories.map((category: any) => {
              return (
                <MenuItem value={category.id}>
                  {category.properties.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}

      {props.isFetching ? (
        <Skeleton variant="rect" width={"100%"} height={56} />
      ) : (
        <RichTextAreaInput
          content={props.info.content}
          handleChange={props.handleBodyChange}
        />
      )}

      <div className="form_button">
        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={props.handleSubmit}
            disabled={props.isSubmitting}
          >
            {props.isSubmitting ? <CircularProgress /> : "Submit"}
          </Button>
        )}
      </div>
    </div>
  );
}

interface InfoFormProps {
  info: any;
  setInfo: React.Dispatch<React.SetStateAction<any>>;
  handleSubmit: any;
  handleChange: any;
  handleBlur: any;
  handleBodyChange: any;
  handleCategoryChange: any;
  handleCollectionChange: any;
  collections: any[];
  categories: any[];
  isFetching: boolean;
  isSubmitting: boolean;
}

const useStyles = makeStyles((theme) => ({}));

export default InfoForm;
