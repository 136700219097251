import { Link as RouterLink } from "react-router-dom";
import { IconButton, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import * as Routes from "../../routes";

const FormActionCell = (props) => {
  return (
    <div className="action_cell">
      <Tooltip title="View forms">
        <IconButton
          component={RouterLink}
          to={Routes.FORMS_VIEW_LINK(props.site, props.value)}
        >
          <VisibilityOutlinedIcon />
        </IconButton>
      </Tooltip>
      {/*             <Tooltip title="Edit forms">
            <IconButton component={RouterLink} to={Routes.FORMS_EDIT_LINK(props.site, props.value)}>
                <EditIcon />
            </IconButton>
            </Tooltip> */}
    </div>
  );
};

export default FormActionCell;
