import { gql } from '@apollo/client';

const CREATE_REDIRECT = gql`
    mutation delete_page($id: ID!) {
        admin_page_delete(id: $id) {
            id
            slug
            physical_file_path
        }
    }
`

export default CREATE_REDIRECT;