export default function cleanObject(object: any){
    const cleanObject: any = {}
    
    for(const field in object){
        if(object[field]){
            cleanObject[field] = object[field]
        }
    }
    
    return cleanObject
}