import { gql } from "@apollo/client";

const GET_REGION_CONTENT = gql`
  query city_get(
    $id: ID
    $region_id: Int
    $per_page: Int = 3000
    $page: Int = 1
  ) {
    models(
      filter: {
        name: { exact: "modules/areas/region_content" }
        id: { value: $id }
        properties: [{ name: "region_id", value_int: $region_id }]
        deleted_at: { exists: false }
      }
      per_page: $per_page
      page: $page
    ) {
      total_entries
      total_pages
      results {
        id
        created_at
        updated_at
        properties
      }
    }
  }
`;

export default GET_REGION_CONTENT;
