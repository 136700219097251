import React, { useMemo, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button, Container } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import DataTable from "../../components/dataTable";
import DataTableSkeleton from "../../components/dataTable/skeleton";
import ActionCell from "../../components/dataTable/organisationsActionCell";
import {
  fetchOrganisationListIfNeeded,
  invalidateOrganisationList,
} from "../../store/organisationList/actions";
import { RootState } from "../../store/store";
import * as Routes from "../../routes";
import TableLayout from "../../components/layout/TableLayout";
import EmptyContentMessage from "../../components/layout/EmptyContentMessage";
function Organisations() {
  const state = useSelector((state: RootState) => state);
  const currentUser = state.current_user.user;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOrganisationListIfNeeded());
  }, [dispatch, currentUser]);

  const handleDelete = (id: number) => {
    console.log(id);
  };

  const handleFetchOrganisations = () => {
    dispatch(invalidateOrganisationList());
    dispatch(fetchOrganisationListIfNeeded());
  };

  const data = useMemo(() => {
    return state.organisation_list.organisations;
  }, [state.organisation_list.organisations]);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Sites Number",
        accessor: "instances",
        Cell: (props: any) => {
          return <>{props.value.length}</>;
        },
      },
      {
        Header: "Users Number",
        accessor: "users",
        Cell: (props: any) => {
          return <>{props.value.length}</>;
        },
      },
      {
        Header: "",
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <ActionCell
              organisationID={props.value}
              handleDelete={handleDelete}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <Container className="container">
        <Button
          component={RouterLink}
          startIcon={<KeyboardBackspaceIcon />}
          to={Routes.INSTANCE_PORTAL}
        >
          Back
        </Button>
        <TableLayout
          loading={null}
          buttonLink={Routes.ORGANISATION_CREATE}
          tableHeading="Organisations"
          buttonText="New Organisation"
        >
          {state.organisation_list.organisations.length === 0 ? (
            state.organisation_list.fetching ? (
              <DataTableSkeleton rows={15} columns={8} />
            ) : (
              <EmptyContentMessage message={"No organisations found"} />
            )
          ) : (
            <DataTable data={data} columns={columns} />
          )}
        </TableLayout>
      </Container>
    </>
  );
}

export default Organisations;
