import React from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  IconButton,
  FormControlLabel,
  Checkbox,
  Collapse,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import RichTextAreaInput from "../editor/RichTextEditor";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

function CityForm(props: CityFormProps) {
  const [expanded, setExpanded] = React.useState(false);
  const [mainServiceSelector, setMainServiceSelector] = React.useState(
    props.mainServices?.length > 0
  );
  const [menuSelector, setMenuSelector] = React.useState(false);
  const classes = useStyles();

  const toggleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <div className={classes.advanced__header}>
        <Typography component="h2" variant="h6" className="marginRight">
          Advanced Settings
        </Typography>
        <IconButton
          className={expanded ? classes.expandOpen : classes.expand}
          onClick={toggleExpandClick}
        >
          <ExpandMoreIcon />
        </IconButton>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div className={classes.advanced__checkboxWrap}>
          <FormControlLabel
            control={
              <Checkbox
                className="marginRight"
                color="primary"
                name="mainServiceSelector"
                checked={mainServiceSelector}
                onChange={() => setMainServiceSelector(!mainServiceSelector)}
              />
            }
            label="Service Selector based on Main Services Collection"
          />
          <Tooltip
            title="Set Service Selector to Main Services collection for selected service, else it is based on the table modules/services/service."
            placement="right"
          >
            <HelpOutlineIcon className="tooltip__icon" />
          </Tooltip>
        </div>
        <div className={classes.advanced__checkboxWrap}>
          <FormControlLabel
            control={
              <Checkbox
                className="marginRight"
                color="primary"
                name="menuSelector"
                checked={menuSelector}
                onChange={() => setMenuSelector(!menuSelector)}
              />
            }
            label="Side Menu uses Menus Collection"
          />
          <Tooltip
            title="Set Side Menu to Menus collection to select a menu, else it is based off Side Menus Collection"
            placement="right"
          >
            <HelpOutlineIcon className="tooltip__icon" />
          </Tooltip>
        </div>
      </Collapse>
      <div className="form">
        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <FormControl variant="outlined">
            <InputLabel>Service Selector</InputLabel>
            <Select
              name="slug"
              onChange={props.handleServiceChange}
              label="Service Selector"
              placeholder="Service Selector"
              fullWidth
            >
              {mainServiceSelector
                ? props.mainServices?.map((service: any) => {
                    return (
                      <MenuItem value={service.slug} key={service.id}>
                        {service.title}
                      </MenuItem>
                    );
                  })
                : props.services?.map((service: any) => {
                    return (
                      <MenuItem value={service.properties.slug} key={service.id}>
                        {service.properties.name}
                      </MenuItem>
                    );
                  })}
              
            </Select>
            <FormHelperText>
              <span>Choose a service to create the slug. Then copy for the Breadcrumbs below. If no services appear try switching in the Advanced Settings</span>
            </FormHelperText>
           <TextField
            variant="outlined"
            type="text"
            name="name"
            disabled
            value={props.content.slug}
            label="Slug"
            fullWidth
          />
          </FormControl>
        )}

        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <TextField
            variant="outlined"
            type="text"
            name="name"
            onChange={props.handleChange}
            value={props.content.name}
            label="Title"
            fullWidth
          />
        )}

        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <TextField
            variant="outlined"
            type="text"
            name="subtitle"
            onChange={props.handleChange}
            value={props.content.subtitle}
            label="Subtitle"
            fullWidth
          />
        )}

        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <TextField
            variant="outlined"
            type="text"
            name="meta_title"
            onChange={props.handleChange}
            value={props.content.meta_title}
            label="Meta Title"
            fullWidth
          />
        )}
        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <TextField
            variant="outlined"
            type="text"
            name="meta_desc"
            onChange={props.handleChange}
            value={props.content.meta_desc}
            label="Meta Description"
            fullWidth
          />
        )}

        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <>
            <Typography variant="subtitle2">Breadcrumbs</Typography>

            {props.content.breadcrumbs.map((link: any, index: number) => {
              return (
                <div className="input_wrapper_flex">
                  <TextField
                    variant="outlined"
                    label="Name"
                    id="name"
                    defaultValue="name"
                    name="name"
                    fullWidth
                    value={props.content.breadcrumbs[index].name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      props.editBreadcrumb(event, index)
                    }
                  />
                  <TextField
                    variant="outlined"
                    label="Title"
                    id="title"
                    fullWidth
                    defaultValue="title"
                    name="title"
                    value={props.content.breadcrumbs[index].title}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      props.editBreadcrumb(event, index)
                    }
                  />
                  <TextField
                    variant="outlined"
                    label="Link"
                    id="link"
                    fullWidth
                    defaultValue="link"
                    name="link"
                    value={props.content.breadcrumbs[index].link}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      props.editBreadcrumb(event, index)
                    }
                  />
                  <Button
                    onClick={() => props.deleteBreadcrumb(index)}
                    variant="outlined"
                    color="primary"
                  >
                    <DeleteIcon />
                  </Button>
                </div>
              );
            })}
            <div className="input_wrapper_flex">
              <TextField
                variant="outlined"
                label="Name"
                id="name"
                defaultValue="name"
                fullWidth
                name="name"
                value={props.newBreadcrumb.name}
                onChange={props.handleNewBreadcrumb}
              />
              <TextField
                variant="outlined"
                label="Title"
                id="title"
                defaultValue="Title"
                fullWidth
                name="title"
                value={props.newBreadcrumb.title}
                onChange={props.handleNewBreadcrumb}
              />
              <TextField
                variant="outlined"
                label="Link"
                id="link"
                defaultValue="link"
                fullWidth
                name="link"
                value={props.newBreadcrumb.link}
                onChange={props.handleNewBreadcrumb}
              />
              <Button
                onClick={props.handleAddNewBreadcrumb}
                variant="outlined"
                color="primary"
              >
                <AddCircleIcon />
              </Button>
            </div>
          </>
        )}

        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <FormControl variant="outlined">
            <InputLabel>Side Menu</InputLabel>
            <Select
              name="layout"
              value={props.content.side_menu}
              onChange={props.handleSideMenuChange}
              label="Layout"
              placeholder="Layout"
              fullWidth
            >
              {menuSelector
                ? props.menus?.map((menu: any) => {
                    return (
                      <MenuItem value={menu.id} key={menu.id}>
                        {menu.properties.title}
                      </MenuItem>
                    );
                  })
                : props.sideMenus?.map((sideMenu: any) => {
                return (
                  <MenuItem value={sideMenu.id} key={sideMenu.id}>
                    {sideMenu.properties.title}
                  </MenuItem>
                );
              })}
            </Select>
             <FormHelperText>
              Make sure you have the correct menu set in Advanced Settings
             </FormHelperText>
          </FormControl>
        )}

        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <RichTextAreaInput
            content={props.content.description}
            handleChange={props.handleDescriptionChange}
          />
        )}

        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <TextField
            variant="outlined"
            type="text"
            name="form_heading"
            onChange={props.handleChange}
            value={props.content.form_heading}
            label="Form Heading"
            fullWidth
          />
        )}

        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <RichTextAreaInput
            content={props.content.content}
            handleChange={props.handleBodyChange}
          />
        )}

        <div className="form_button">
          {props.isFetching ? (
            <Skeleton variant="rect" width={"100%"} height={56} />
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={props.handleSubmit}
              disabled={props.isSubmitting}
            >
              {props.isSubmitting ? <CircularProgress /> : "Submit"}
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

interface CityFormProps {
  content: any;
  handleSubmit: any;
  handleChange: any;
  handleBodyChange: any;
  handleDescriptionChange: any;
  handleSideMenuChange: any;
  handleServiceChange: any;
  editBreadcrumb: any;
  deleteBreadcrumb: any;
  handleNewBreadcrumb: any;
  newBreadcrumb: any;
  handleAddNewBreadcrumb: any;
  selectedService: any;
  services: any[];
  mainServices: any[];
  sideMenus: any[];
  menus: any[];
  isFetching: boolean;
  isSubmitting: boolean;
}

const useStyles = makeStyles((theme) => ({
  /*Create Collection */
  collectionform_button: {
    marginTop: theme.spacing(2),
    marginLeft: "auto",
  },

  /*Advanced Section*/
  advanced__form: {
    display: "flex",
    flexDirection: "column",
    padding: "5px 0",
  },
  advanced__filepath: {
    marginTop: theme.spacing(2),
  },
  advanced__header: {
    display: "flex",
    alignItems: "center",
    marginBottom: "-20px",
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  advanced__checkboxWrap: {
    display: "flex",
    alignItems: "center",
  },

  /*Create Collection Items Form Section*/
  action_bar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  collection__displayname: {
    display: "flex",
    alignItems: "center",
  },
  collection__details: {
    marginRight: theme.spacing(3),
    maxWidth: 400,
  },
  collection__editicon: {
    marginLeft: theme.spacing(1),
  },
  collection__table: {
    boxShadow: "none",
    margin: theme.spacing(3, 0),
  },
  table__row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3px 0px",
    borderBottom: "solid 1px #F0F0F0",
  },
  collection__name__wrap: {
    display: "flex",
    alignItems: "center",
  },
  collection__name: {
    marginRight: theme.spacing(2),
    textTransform: "capitalize",
  },
  collection__createButton: {
    marginRight: theme.spacing(2),
  },
}));

export default CityForm;
