import { gql } from '@apollo/client';

const CREATE_RECORD = gql`
mutation create_record(
  $table: String!, 
  $properties: [PropertyInputType]!
){
  record_create(record: {table: $table, properties: $properties}) {
    id
    created_at
    updated_at
    properties
  }
}
`

export default CREATE_RECORD;