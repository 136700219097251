import { gql } from "@apollo/client";

const GET_SINGLE_CONTENT_TYPE_WITH_ENTITIES = gql`
  query get_single_content_types($name: String!, $id: ID) {
    admin_tables(filter: { name: { exact: $name } }) {
      results {
        id
        created_at
        updated_at
        name
        metadata
        parameterized_name
        physical_file_path
        properties {
          name
          attribute_type
          belongs_to
          metadata
        }
      }
    }
    records(
      per_page: 2000
      filter: { table: { exact: $name }, id: { value: $id } }
      sort: { created_at: { order: DESC } }
    ) {
      results {
        id
        created_at
        updated_at
        properties
      }
    }
  }
`;

export default GET_SINGLE_CONTENT_TYPE_WITH_ENTITIES;
