import React, { useState, useEffect } from "react";
import {
  Container,
} from "@material-ui/core";
import FormLayout from "../components/layout/FormLayout";
import { RootState } from "../store/store";
import { useSelector } from "react-redux";
import * as Routes from "../routes";
const Account = () => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    role: "",
    organisationName: "",
  });
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const state = useSelector((state: RootState) => state);
  useEffect(() => {
    setIsFetching(true);
    fetch(`${process.env.REACT_APP_BASE_URL}/v1/user/profile`, {
      method: "get",
      headers: {
        Authorization: "Bearer " + state.current_user.user?.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setUser({
          ...user,
          name: data.name,
          email: data.email,
          role: data.role,
          organisationName: data.organisation.name,
        });
        setIsFetching(false);
      });
  }, [state.current_user]);



  return (
    <>

      <Container className="container">
        <FormLayout
          formHeading="Account"
          backLink={Routes.INSTANCE_PORTAL}
          size="md"
          loading={null}
        >
            <p style={{textTransform: "capitalize"}}>Company: {user.organisationName}</p>
            <p>{user.name}</p>
            <p style={{textTransform: "capitalize"}}><span>Role: </span>{user.role.toLowerCase()}</p>
            <p>{user.email}</p>
         
        </FormLayout>
      </Container>
    </>
  );
};

interface UserFormProps {
  isFetching: boolean;
  isSubmitting: boolean;
  handleChange: any;
  handleSubmit: any;
  user: any;
  organisationList: any;
  currentUserRole: any;
}

export default Account;
