import { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Breadcrumbs, Typography } from '@material-ui/core';

import ImageCard from './ImageCard';
import FolderCard from './FolderCard';
import BackDirectoryCard from './BackDirectoryCard';


const FolderView = (props: FolderViewProps) => {
    const classes = useStyles();


    const children = useMemo(()=> {
        const sortedChildren = props.currentDirectory.children.sort((a: any, b: any) => {
            if(a.type < b.type) { return -1; }
            if(a.type > b.type) { return 1; }
            return 0;
        })
        return sortedChildren
    }, [props.currentDirectory, props.assets])
    
    
    return(
        <>
        { props.parentDirectories.length > 0 ? (
            <div className={classes.navigationControls}>
                <BackDirectoryCard handleBack={props.handleBack} />
                <Breadcrumbs>
                    { props.parentDirectories.length > 0 ? (
                            props.parentDirectories.map((directory: any) => {
                            return(<Typography color="textPrimary">{directory.name}</Typography>)
                        })
                        ) : null 
                    }
                    <Typography color="textPrimary">{props.currentDirectory.name}</Typography>
                </Breadcrumbs>
            </div>
            ) : null 
        }
       
        <Grid container spacing={2}>
            {children.map((child: any) => {
                return(
                    <Grid item xs={12} sm={6} md={4} lg={3} key={`${child.path}/${child.name}` }>
                        { child.type === "DIRECTORY" ? <FolderCard directory={child} handleDirectorySelection={props.handleDirectorySelection} /> :  null }
                        { child.type === "FILE" ? <ImageCard imageUrl={child.asset.url} imageFilePath={child.asset.physical_file_path } imageName={child.asset.name} setSelectedImage={props.setSelectedImage} selectedImageUrl={props.selectedImageUrl} /> :  null }
                    </Grid>
                )
            })}
        </Grid>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
  navigationControls: {
    display:'flex', 
    alignItems:'center',
    padding: '20px 0px'
  },
}));

interface FolderViewProps {
    currentDirectory: any,
    parentDirectories: any[],
    handleDirectorySelection: any,
    handleBack: any,
    assets: any[],
    setSelectedImage?: (url: string) =>  void,
    selectedImageUrl?: string
}


export default FolderView;