import React, { useMemo } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { Container, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useQuery, useMutation } from "@apollo/client";
import DELETE_ITEM from "../../../../../../graphql/DELETE_RECORD";
import * as Routes from "../../../../../../routes";
import DataTable from "../../../../../../components/dataTable";
import ActionCell from "../../../../../../components/dataTable/areas/suburbContentActionCell";
import { RootState } from "../../../../../../store/store";
import TableLayout from "../../../../../../components/layout/TableLayout";
import GET_SUBURB_CONTENT from "../../../../../../graphql/areas/GET_SUBURB_CONTENT";
import EmptyContentMessage from "../../../../../../components/layout/EmptyContentMessage";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

function City() {
  const params = useParams<SiteParams>();
  const state = useSelector((state: RootState) => state);

  const { loading, error, data } = useQuery(GET_SUBURB_CONTENT, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    variables: {
      suburb_id: Number(params.suburbID),
    },
  });

  const suburbs: any = useMemo(() => {
    return data ? data.models.results : [];
  }, [data]);

  const [
    deleteItem,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(DELETE_ITEM, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const deletedItem = data?.model_delete;
      const existingItems: any = cache.readQuery({
        query: GET_SUBURB_CONTENT,
      });

      if (deletedItem && existingItems) {
        const updatedItems = existingItems.models.results.filter(
          (item: any) => {
            if (item.id !== deletedItem.id) {
              return item;
            }
          }
        );

        cache.writeQuery({
          query: GET_SUBURB_CONTENT,
          data: {
            models: {
              results: [...updatedItems],
            },
          },
        });
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleDelete = (id: string) => {
    deleteItem({
      variables: {
        id: id,
      },
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "properties.name",
      },
      {
        Header: "Slug",
        accessor: "properties.slug",
      },
      {
        Header: "Created At",
        accessor: "created_at",
        disableFilters: true,
        Cell: (props: any) => {
          let date = new Date(props.value);
          return <>{date.toLocaleString("en-GB")}</>;
        },
      },
      {
        Header: "Updated At",
        accessor: "updated_at",
        disableFilters: true,
        Cell: (props: any) => {
          let date = new Date(props.value);
          return <>{date.toLocaleString("en-GB")}</>;
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <ActionCell
              site={params.site}
              value={props.value}
              suburb={params.suburbID}
              siteURL={state.current_site.site?.url}
              slug={props.row.values.slug}
              handleDelete={handleDelete}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <Container className="removePadding">
      <Button
        className="marginBottom"
        component={RouterLink}
        startIcon={<KeyboardBackspaceIcon />}
        to={Routes.SUBURB_LINK(params.site)}
      >
        Back
      </Button>
      <TableLayout
        loading={loading}
        buttonLink={Routes.SUBURB_CONTENT_CREATE_LINK(
          params.site,
          params.suburbID
        )}
        tableHeading="Suburb Content"
        buttonText="Add New Suburb Content"
      >
        {suburbs.length === 0 ? (
          <EmptyContentMessage message="This suburb has no unique content" />
        ) : (
          <DataTable data={suburbs} columns={columns} />
        )}
      </TableLayout>
    </Container>
  );
}

interface SiteParams {
  site: string;
  suburbID: string;
}

export default City;
