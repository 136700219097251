import React from "react";
import { Container, Paper, Typography, Button } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Link as RouterLink } from "react-router-dom";
import Loader from "../../components/layout/Loader";
export default function FormLayout(props: FormLayoutProps) {
  return (
    <>
      {props.loading ? (
        <Loader open={true} />
      ) : (
        <Container maxWidth="lg" className="removePadding">
          <Button
            component={RouterLink}
            startIcon={<KeyboardBackspaceIcon />}
            to={props.backLink}
          >
            Back
          </Button>
          <Container maxWidth={props.size} className="removePadding">
            <Paper className="form_wrap">
              <Typography component="h1" variant="h3" className="form_heading">
                {props.formHeading}
              </Typography>
              {props.children}
            </Paper>
          </Container>
        </Container>
      )}
    </>
  );
}

interface FormLayoutProps {
  formHeading: string;
  backLink: string;
  loading: any;
  size: any;
  children: React.ReactNode;
}
