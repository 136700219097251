import React, { useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

function ImageCardMenu(props: ImageCardMenuProps) {
  const [openDeleteWarning, setOpenDeleteWarning] = useState(false);
  const classes = useStyles();
  const open = Boolean(props.anchor);

  const handleClickOpenDelete = () => {
    setOpenDeleteWarning(true);
  };

  const handleCloseDelete = () => {
    setOpenDeleteWarning(false);
  };

  const handleDeleteAndClose = () => {
    props.handleDelete(props.imagePath);
    handleCloseDelete();
  };

  return (
    <Fragment>
      <Menu
        className={classes.settings}
        open={open}
        anchorEl={props.anchor}
        onClose={props.handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem onClick={handleClickOpenDelete}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <Typography>Delete Image</Typography>
        </MenuItem>
      </Menu>
      <Dialog open={openDeleteWarning} onClose={handleCloseDelete}>
        <DialogTitle>{"Delete Instance"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you wish to delete this image? This cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteAndClose} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  settings: {
    padding: "0.5rem",
  },
}));

interface ImageCardMenuProps {
  anchor: any;
  handleClose: any;
  handleDelete: any;
  imagePath: string;
}

export default ImageCardMenu;
