import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Typography,
  Container,
  Button,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import RefreshIcon from "@material-ui/icons/Refresh";
import { RootState } from "../../store/store";

const useStyles = makeStyles((theme) => ({
  logs: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    alignItems: "center",
    padding: theme.spacing(4, 2, 2, 2),
    backgroundColor: theme.palette.background.default,
  },
  log: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    borderRadius: "16px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
  },
  refreshButton: {
    marginBottom: theme.spacing(2),
  },
  controlBar: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const formatDuration = (duration: number) => {
  const seconds = Math.floor((duration / 1000) % 60);
  const minutes = Math.floor((duration / (1000 * 60)) % 60);
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  return `${hours}hrs, ${minutes}mins, ${seconds}secs`;
};

const timeAgo = (date: Date) => {
  const now = new Date();
  const diff = now.getTime() - date.getTime();

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) return `${days} days ago`;
  if (hours > 0) return `${hours} hours ago`;
  if (minutes > 0) return `${minutes} minutes ago`;
  return `${seconds} seconds ago`;
};

export default function Logs() {
  const state = useSelector((state: RootState) => state);
  const classes = useStyles();
  const [logs, setLogs] = useState<any[]>([]);

  const fetchLogs = async () => {
    await fetch(`${process.env.REACT_APP_BASE_URL}/v1/logs`, {
      method: "get",
      headers: {
        Authorization: "Bearer " + state.current_user.user!.token,
        instance: state.current_site.site!.slug,
      },
    })
      .then((response) => response.json())
      .then((data) => setLogs(data));
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  const getStatusIcon = (status: string) => {
    switch (status) {
      case "SUCCESS":
        return <CheckCircleIcon style={{ color: "green" }} />;
      case "FAILED":
        return <ErrorIcon style={{ color: "red" }} />;
      case "STARTED":
        return <AccessTimeIcon style={{ color: "orange" }} />;
      default:
        return <ErrorIcon style={{ color: "gray" }} />;
    }
  };

  const lastSuccessfulBuild = logs.find(
    (log) =>
      log.buildStatus === "SUCCESS" &&
      log.buildUser &&
      log.buildUser.name !== "SYSTEM"
  );

  return (
    <Container className={classes.logs}>
      <div className={classes.controlBar}>
        <div>
          <Typography variant="h6" component="h2">
            Last Successful Build:{" "}
            {lastSuccessfulBuild
              ? timeAgo(new Date(lastSuccessfulBuild.updatedAt))
              : "Unknown"}
          </Typography>
          <Typography variant="h6" component="h2">
            Last Successful Build Duration:{" "}
            {lastSuccessfulBuild
              ? formatDuration(lastSuccessfulBuild.duration)
              : "Unknown"}
          </Typography>
        </div>
        <Button
          variant="contained"
          color="primary"
          startIcon={<RefreshIcon />}
          onClick={fetchLogs}
          className={classes.refreshButton}
        >
          Refresh Logs
        </Button>
      </div>
      {logs.length === 0 ? (
        <Typography variant="h6" component="h2">
          No logs found
        </Typography>
      ) : (
        logs.map((log: any) => (
          <Card variant="outlined" className={classes.log} key={log.id}>
            <CardContent
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "24px",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                {getStatusIcon(log.buildStatus)}
                <Typography variant="h6" component="div">
                  BUILD {log.buildStatus}
                </Typography>
              </div>

              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <Typography variant="body2" color="textSecondary">
                  Triggered By: {log.buildUser ? log.buildUser.name : "SYSTEM"}{" "}
                  ({log.buildUser ? log.buildUser.email : "GitHub Action"})
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Last Update: {new Date(log.createdAt).toLocaleString()}
                </Typography>
                {log.duration > 0 && log.buildUser && (
                  <Typography variant="body2" color="textSecondary">
                    Duration: {formatDuration(log.duration)}
                  </Typography>
                )}
              </div>
            </CardContent>
          </Card>
        ))
      )}
    </Container>
  );
}
