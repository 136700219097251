import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core'
import FolderSharpIcon from '@material-ui/icons/FolderSharp';
import CardActionArea from '@material-ui/core/CardActionArea';

function FolderCard(props: FolderCardProps){
    const classes = useStyles();
    
    return (
      <>
        <CardActionArea className={classes.folderCard} onClick={() =>  props.handleDirectorySelection(props.directory.path)}>
        <FolderSharpIcon color='inherit' className={classes.folder}></FolderSharpIcon>
        <Typography component="h2" className={classes.heading}>
                {truncate(props.directory.name)}
        </Typography>
        </CardActionArea>
      </>
    );
}

const useStyles = makeStyles((theme) => ({
  folderCard:{
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  heading:{
    textTransform: 'capitalize', 
    marginTop: '-20px',
    padding: '0px 18px 18px 18px'
  },
  folder:{
    fontSize: 200,
    color: theme.palette.primary.light
  }
}));

interface FolderCardProps {
    directory: any
    handleDirectorySelection: any
}

function truncate(str:string) {
  return str.length > 12 ? str.substring(0, 12) + "..." : str;
}

export default FolderCard;

