import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import RedirectForm from "../../../components/forms/redirect";
import { useSelector } from "react-redux";
import UPDATE_REDIRECT from "../../../graphql/UPDATE_REDIRECT";
import GET_REDIRECTS from "../../../graphql/GET_REDIRECTS";
import { useQuery, useMutation } from "@apollo/client";
import ErrorToast from "../../../components/layout/ErrorToast";
import * as Routes from "../../../routes";
import { RootState } from "../../../store/store";
import FormLayout from "../../../components/layout/FormLayout";

const UpdateRedirect = () => {
  let history = useHistory();
  const params = useParams<RedirectParams>();
  const state = useSelector((state: RootState) => state);
  const [redirect, setRedirect] = useState({
    physical_file_path: "",
    slug: "",
    redirect_to: "",
    redirect_code: "MOVED_PERMANENTLY",
  });

  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(GET_REDIRECTS, {
    variables: {
      name: params.id,
    },
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  const [
    updateRedirect,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(UPDATE_REDIRECT, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    onCompleted: (data) => {
      if (data) {
        history.push(Routes.REDIRECTS_LINK(params.site));
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    if (queryData) {
      const result = queryData.admin_pages.results[0];
      setRedirect({
        physical_file_path: result.physical_file_path,
        slug: result.slug,
        redirect_to: result.redirect_to,
        redirect_code:
          result.redirect_code === 302
            ? "MOVED_TEMPORARILY"
            : "MOVED_PERMANENTLY",
      });
    }
  }, [queryData]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "slug") {
      setRedirect({
        ...redirect,
        slug: event.target.value,
      });
    }
    if (event.target.name === "redirect_to") {
      setRedirect({
        ...redirect,
        redirect_to: event.target.value,
      });
    }
    if (event.target.name === "physical_file_path") {
      setRedirect({
        ...redirect,
        physical_file_path: event.target.value,
      });
    }
  };

  const handleCodeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRedirect({
      ...redirect,
      redirect_code: event.target.value as string,
    });
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    updateRedirect({
      variables: {
        id: params.id,
        physical_file_path: redirect.physical_file_path,
        slug: redirect.slug,
        redirect_to: redirect.redirect_to,
        redirect_code: redirect.redirect_code,
      },
    });
  };

  return (
    <FormLayout
      loading={mutationLoading}
      formHeading="Update Redirect"
      backLink={Routes.REDIRECTS_LINK(params.site)}
      size="md"
    >
      {mutationError ? (
        <ErrorToast open={true} errorMessage={mutationError.message} />
      ) : null}
      <RedirectForm
        handleChange={handleChange}
        handleCodeChange={handleCodeChange}
        handleSubmit={handleSubmit}
        isFetching={queryLoading}
        redirect={redirect}
        isSubmitting={mutationLoading}
      />
    </FormLayout>
  );
};

interface RedirectParams {
  site: string;
  id: string;
}

export default UpdateRedirect;
