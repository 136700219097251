import React from "react";
import { useParams, NavLink, Link as RouterLink } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  Divider,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import DescriptionIcon from "@material-ui/icons/Description";
import ListAltIcon from "@material-ui/icons/ListAlt";
import RedoIcon from "@material-ui/icons/Redo";
import DnsRoundedIcon from "@material-ui/icons/DnsRounded";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import * as Routes from "../../../routes";

const LegacySiteMangerSubMenu = (props: any) => {
  const params = useParams<SiteParams>();

  return (
    <List disablePadding={true}>
      <ListItem button onClick={props.handleBackClick}>
        <ListItemIcon>
          <ArrowBackIosIcon />
        </ListItemIcon>
        <ListItemText primary="Back" />
      </ListItem>
      <Divider />
      <ListSubheader>Legacy Site Manager</ListSubheader>
      <ListItem
        button
        component={NavLink}
        className="navLink"
        to={Routes.PAGES_LINK(params.site)}
      >
        <ListItemIcon>
          <DescriptionIcon />
        </ListItemIcon>
        <ListItemText primary="Pages" />
      </ListItem>

      <ListItem
        className="navLink"
        button
        component={NavLink}
        to={Routes.LAYOUTS_LINK(params.site)}
      >
        <ListItemIcon>
          <ViewColumnIcon />
        </ListItemIcon>

        <ListItemText primary="Layouts" />
      </ListItem>
      <ListItem
        className="navLink"
        button
        component={NavLink}
        to={Routes.PARTIALS_LINK(params.site)}
      >
        <ListItemIcon>
          <ListAltIcon />
        </ListItemIcon>

        <ListItemText primary="Partials" />
      </ListItem>
      <ListItem
        className="navLink"
        button
        component={NavLink}
        to={Routes.CONSTANTS_LINK(params.site)}
      >
        <ListItemIcon>
          <DnsRoundedIcon />
        </ListItemIcon>

        <ListItemText primary="Constants" />
      </ListItem>
      <ListItem
        className="navLink"
        button
        component={NavLink}
        to={Routes.REDIRECTS_LINK(params.site)}
      >
        <ListItemIcon>
          <RedoIcon />
        </ListItemIcon>

        <ListItemText primary="Redirects" />
      </ListItem>
    </List>
  );
};

interface SiteParams {
  site: string;
}

export default LegacySiteMangerSubMenu;
