import React, { useMemo } from "react";
import { Select, MenuItem, Input, Chip, Grid } from "@material-ui/core";
import { PropertyValue } from "../../pages/site/entities/create";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { RootState } from "../../store/store";
import GET_COLLECTION_WITH_ENTITIES from "../../graphql/GET_SINGLE_CONTENT_TYPE_WITH_ENTITIES";

const ManyRelationInput = (props: ManyRelationInputProps) => {
  const state = useSelector((state: RootState) => state);

  const { loading, error, data } = useQuery(GET_COLLECTION_WITH_ENTITIES, {
    variables: { name: props.propertyValue.belongs_to! },
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  const collectionItems: any = useMemo(() => {
    if (data) {
      const table = data.admin_tables.results[0];
      const handle = table.properties.reduce((acc: any, current: any) => {
        if (current.metadata.display_priority > acc.metadata.display_priority) {
          return current;
        }
        return acc;
      });

      const items = data.records.results.map((item: any) => {
        let displayName = item.properties[handle.name];

        return {
          displayName: displayName,
          value: item.id,
        };
      });

      return items;
    }
    return [];
  }, [data]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    props.handleChange(event.target.value as string[], props.index);
  };

  return (
    <Grid item xs={12} lg={6}>
      <p className="entity__inputHeading">{props.propertyValue.display_name}</p>
      <Select
        variant="outlined"
        fullWidth
        multiple
        value={props.propertyValue.value_array}
        onChange={handleChange}
        input={<Input />}
        renderValue={(selected: any) => {
          // get collection item display name and map it to the selected value
          return selected.map((value: any) => {
            const item = collectionItems.find(
              (item: any) => item.value === value
            );
            return item ? (
              <Chip
                key={item.value}
                label={item.displayName}
                style={{ marginRight: 10 }}
              />
            ) : null;
          });
        }}
      >
        {collectionItems.map((item: any) => (
          <MenuItem value={item.value} key={item.value}>
            {item.displayName}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};

interface ManyRelationInputProps {
  index: number;
  propertyValue: PropertyValue;
  handleChange: (value: string[], index: number) => void;
  collectionType: string;
}

export default ManyRelationInput;
