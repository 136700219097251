const generateGetAPIPageBody = (name: string, fields: any[]): string => {
    let hasSlug = false
    for(const field of fields){
        if(field.name === "slug"){
            hasSlug = true
        }
    }


    if(hasSlug){
        const pageBody = `\
        {%- liquid
            if context.params.slug3
                assign template = "/rsc/${name}/{+third}"
                assign extract = context.location.pathname | extract_url_params: template
                graphql g = "${name}_get" slug: extract.third
                if g.records.results.first
                    assign response = g.records.results.first
                else 
                    assign response = '{"error": "no resouce found"}'
                    response_status 400
                endif
            else
                graphql g = "${name}_get"
                if g.records.results
                    assign response = g.records.results
                else 
                    assign response = '{"error": "query error"}'
                    response_status 400
                endif
            endif
        -%}
        {{- response -}}`

        return pageBody;
    }


    const pageBody = `\
{%- liquid
    if context.params.slug3
        assign response = '{"error": "no resouce found"}'
        response_status 400
    else
        graphql g = "${name}_get"
        if g.records.results
            assign response = g.records.results
        else 
            assign response = '{"error": "query error"}'
            response_status 400
        endif
    endif
-%}
{{- response -}}`

    return pageBody;
}

export default generateGetAPIPageBody;