function Styles(theme: any) {
  return {
    main: {
      "@global": {
        /* Form */
        ".form": {
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
          marginTop: theme.spacing(4),
        },
        ".form_wrap": {
          padding: theme.spacing(6, 4, 4, 4),
          width: "100%",
          marginTop: theme.spacing(1),
          margin: "0 auto",
          boxShadow: "none",
          borderColor: theme.palette.grey[300],
          border: "1px solid",
          backgroundColor: "white",
          borderRadius: "12px",
          overflow: "hidden",
          [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(4, 2, 2, 2),
          },
        },
        ".form_heading": {
          marginBottom: theme.spacing(2),
        },
        ".form_button": {
          marginTop: theme.spacing(2),
          width: "fit-content",
        },

        ".form_select > select": {
          height: "400px",
        },
        ".input_wrapper_flex": {
          display: "flex",
          gap: theme.spacing(1),
        },

        /* Action Bar */

        ".action_bar": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: theme.spacing(4, 3, 2, 3),
        },
        ".action_bar_right": {
          display: "flex",
          gap: theme.spacing(1),
        },
        ".action_bar_left": {
          display: "flex",
          alignItems: "center",
        },
        ".action_bar_left_buttons": {
          display: "flex",
          alignItems: "center",
          marginLeft: theme.spacing(3),
          gap: theme.spacing(1),
        },

        //   Action Cell
        ".action_cell": {
          display: "flex",
          gap: "1px",
          justifyContent: "flex-end",
        },

        /*Tool Tip */

        ".tooltip__icon": {
          fontSize: "17px",
          color: theme.palette.grey[700],
        },
        ".backButton": {
          display: "inline-flex",
          alignItems: "center",
        },

        /* Advanced Section */
        ".expand": {
          transform: "rotate(0deg)",
          transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
          }),
        },
        ".expandOpen": {
          transform: "rotate(180deg)",
        },
        ".advanced__headingWrap": {
          display: "flex",
          alignItems: "center",
          marginTop: theme.spacing(1),
        },
        ".advanced__wrap": {
          margin: theme.spacing(1, 0),
        },

        ".collections_grid": {
          marginTop: theme.spacing(2),
        },

        /*Create Entity */
        ".entity__inputHeading": {
          textTransform: "capitalize",
          fontWeight: 500,
          fontSize: 14,
          margin: "0",
          marginBottom: 4,
        },
        ".entity__image": {
          width: "100%",
          height: "300px",
          objectFit: "cover",
        },
        ".entity__imageWrap": {
          position: "relative",
        },
        ".entity__flexWrap": {
          display: "flex",
          alignItems: "center",
          marginBottom: theme.spacing(2),
        },
        ".entity__removeButton": {
          position: "absolute",
          right: theme.spacing(2),
          top: theme.spacing(2),
          color: "white",
          backgroundColor: theme.palette.grey[500],
          padding: 0,
          borderRadius: "100%",
          cursor: "pointer",
          "&.hover": {
            backgroundColor: theme.palette.primary.main,
            color: "black",
          },
        },
        ".container": {
          paddingTop: theme.spacing(4),
          [theme.breakpoints.down("sm")]: {
            paddingTop: theme.spacing(4),
          },
        },

        /*General */
        ".marginRight": {
          marginRight: theme.spacing(1),
        },
        ".marginLeft": {
          marginLeft: theme.spacing(1),
        },
        ".marginBottom": {
          marginBottom: theme.spacing(1),
        },
        ".marginTop": {
          marginTop: theme.spacing(1),
        },
        ".mobile-hide": {
          [theme.breakpoints.down("sm")]: {
            display: "none!important",
          },
        },
        ".desktop-hide": {
          [theme.breakpoints.up("sm")]: {
            display: "none!important",
          },
        },
        ".removePadding": {
          padding: "0",
        },

        //Instance Card
        ".link": {
          flexGrow: 1,
          color: theme.palette.text.primary,
          textDecoration: 'none',
          paddingLeft: "1rem",
        },
        ".media": {
          objectPosition: "top"
        },
        ".actions": {
          display: 'flex',
          justifyContent: 'space-between',
          borderTop: "solid 1px",
          borderColor: theme.palette.grey[300]
        },
        "a": {
          textDecoration: "none !important"
        },
        //Scrollbar
        "*::-webkit-scrollbar": {
          width: "0.6em",
          height: "0.6em",
        },
        "*::-webkit-scrollbar-track": {
          "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
          backgroundColor: "#efefef",
          borderTop: "1px solid",
          borderColor: "#e0e0e0",
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: "#ACB2B8",
          borderRadius: 20,
        },

      },
    },
  };
}

export default Styles;
