import { gql } from "@apollo/client";

const CREATE_COMPONENT_TABLE_WITH_RECORD = gql`
  mutation create_compoenent_with_record(
    $metadata: HashObject!
    $physical_file_path: String!
    $properties: [NestedPropertyInputType]
    $table_name: String
  ) {
    admin_table_create(
      table: {
        physical_file_path: $physical_file_path
        metadata: $metadata
        properties: $properties
      }
    ) {
      id
      created_at
      updated_at
      name
      metadata
      parameterized_name
      physical_file_path
      properties {
        name
        attribute_type
        belongs_to
        metadata
      }
    }
    record_create(record: { table: $table_name }) {
      id
      created_at
      updated_at
      properties
    }
  }
`;

export default CREATE_COMPONENT_TABLE_WITH_RECORD;
