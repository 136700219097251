import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { NavLink, useParams } from "react-router-dom";
import GET_FORM_CONTENT_TYPES from "../../../graphql/GET_FORM_CONTENT_TYPES";
import { RootState } from "../../../store/store";
import { ListItem, CircularProgress, Button } from "@material-ui/core";
import * as Routes from "../../../routes";
import FormListItem from "./FormListItem";

function FormsList(props: any) {
  const state = useSelector((state: RootState) => state);
  const params = useParams<SiteParams>();

  const { loading, error, data } = useQuery(GET_FORM_CONTENT_TYPES, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  const Forms: any = useMemo(() => {
    return data ? data.admin_tables.results : [];
  }, [data]);

  return (
    <>
      <ListItem
        component={NavLink}
        exact
        to={Routes.FORMS_LINK(params.site)}
        button
        className="nested"
      >
        All Forms
      </ListItem>
      {loading ? (
        <CircularProgress size={30} className="loading_marginLeft" />
      ) : Forms.length > 0 ? (
        Forms.map((form: any) => <FormListItem collection={form} />)
      ) : null}
      <ListItem className="nested">
        <Button
          component={NavLink}
          to={Routes.FORMS_CREATE_LINK(params.site)}
          variant="outlined"
          color="primary"
          size="small"
        >
          Create Form
        </Button>
      </ListItem>
    </>
  );
}

interface SiteParams {
  site: string;
}

export default FormsList;
