import React from "react";
import {
  TextField,
  Button,
  Typography,
  Collapse,
  IconButton,
  FormControl,
  InputLabel,
  InputAdornment,
  Select,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
function RedirectForm(props: InstanceFormProps) {
  const [expanded, setExpanded] = React.useState(false);
  const toggleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="form">
      {props.isFetching ? (
        <Skeleton variant="rect" width={900} height={56} />
      ) : (
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-amount">
            Redirect From
          </InputLabel>
          <OutlinedInput
            placeholder="Redirect From"
            name="slug"
            value={props.redirect.slug}
            onChange={props.handleChange}
            startAdornment={
              <InputAdornment position="start">
                {props.baseURL ? props.baseURL : ""}
              </InputAdornment>
            }
            labelWidth={100}
          />
        </FormControl>
      )}

      {props.isFetching ? (
        <Skeleton variant="rect" width={900} height={56} />
      ) : (
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-amount">
            Redirect To
          </InputLabel>
          <OutlinedInput
            placeholder="Redirect To"
            name="redirect_to"
            value={props.redirect.redirect_to}
            onChange={props.handleChange}
            startAdornment={
              <InputAdornment position="start">
          {props.baseURL ? props.baseURL : ""}
              </InputAdornment>
            }
            labelWidth={83}
          />
        </FormControl>
      )}

      <div className="advanced__headingWrap">
        <Typography className="marginRight" component="h2" variant="h6">
          Advanced Settings
        </Typography>
        <IconButton
          className={expanded ? "expandOpen" : "expand"}
          onClick={toggleExpandClick}
        >
          <ExpandMoreIcon />
        </IconButton>
      </div>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        className="advanced__wrap"
      >
        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <TextField
            className="form__input"
            variant="outlined"
            type="text"
            name="physical_file_path"
            onChange={props.handleChange}
            value={props.redirect.physical_file_path}
            label="Physical File Path"
          />
        )}
        <FormControl variant="outlined" className="form__input">
          <InputLabel>Redirect Code</InputLabel>
          <Select
            name="format"
            onChange={props.handleCodeChange}
            value={props.redirect.redirect_code}
            labelWidth={110}
          >
            <MenuItem value="MOVED_PERMANENTLY">
              301 - Moved Permanently
            </MenuItem>
            <MenuItem value="MOVED_TEMPORARILY">
              302 - Moved Temporarily
            </MenuItem>
          </Select>
        </FormControl>
      </Collapse>

      <Button
        className="form_button"
        variant="contained"
        color="primary"
        onClick={props.handleSubmit}
      >
        Create Redirect
      </Button>
    </div>
  );
}

interface InstanceFormProps {
  redirect: any;
  handleSubmit: any;
  handleChange: any;
  handleCodeChange: any;
  isFetching: boolean;
  isSubmitting: boolean;
  baseURL?: string | undefined;
}

export default RedirectForm;
