import { gql } from "@apollo/client";

const GET_COMPONENT_PAGE_ID = gql`
  query component_id_get($per_page: Int = 100, $page: Int = 1, $table: String) {
    id: records(
      per_page: $per_page
      page: $page
      filter: { table: { exact: $table } }
    ) {
      results {
        id
      }
    }
  }
`;

export default GET_COMPONENT_PAGE_ID;
