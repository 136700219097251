import { gql } from '@apollo/client';

const DELETE_PARTIAL = gql`
    mutation delete_partial($id: ID!) {
        admin_liquid_partial_delete(id: $id) {
            id
            path
            physical_file_path
        }
    }
`

export default DELETE_PARTIAL;