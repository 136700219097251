import React, { useState, Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Card,
  CardMedia,
  CardActions,
  IconButton,
  Typography,
  CardActionArea,
  Tooltip,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import InstanceSettingsMenu from "./InstanceSettingsMenu";
import * as Routes from "../../routes";
import { User } from "../../store/currentUser/types";

function InstanceCard(props: InstanceCardProps) {
  const [settingsAnchor, setSettingsAnchor] = useState<null | HTMLElement>(
    null
  );
  const visitSite = () => {
    window.open(props.instanceUrl, "_blank");
  };
  const handleSettingsOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSettingsAnchor(event.currentTarget);
  };
  const handleSettingsClose = () => {
    setSettingsAnchor(null);
  };

  return (
    <Fragment>
      <Card
        variant="outlined"
        style={{
          borderRadius: "16px",
          // slight shadow
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
        }}
      >
        <CardActionArea>
          <RouterLink to={Routes.SITE_LINK(props.instanceSlug)}>
            <CardMedia
              className="media"
              component="img"
              alt={props.instanceName}
              height="200"
              image={
                props.instanceImage
                  ? props.instanceImage
                  : "https://i.ytimg.com/vi/sd0grLQ4voU/maxresdefault.jpg"
              }
              title={props.instanceName}
            />
          </RouterLink>

          <CardActions className="actions">
            <RouterLink
              to={Routes.SITE_LINK(props.instanceSlug)}
              className="link"
            >
              <Typography variant="h6" component="h2">
                {truncate(
                  `${props.instanceEnv === "STAGING" ? "[STAGING]" : ""} ${
                    props.instanceName
                  } Website Manager`
                )}
              </Typography>
            </RouterLink>
            <Tooltip title="Visit Website" placement="right">
              <IconButton onClick={visitSite}>
                <VisibilityOutlinedIcon />
              </IconButton>
            </Tooltip>

            {props.currentUser?.role === "ADMIN" ? (
              <Tooltip title="Website Settings" placement="right">
                <IconButton onClick={handleSettingsOpen}>
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
            ) : null}
          </CardActions>
        </CardActionArea>
      </Card>

      <InstanceSettingsMenu
        anchor={settingsAnchor}
        handleClose={handleSettingsClose}
        instanceId={props.instanceID}
        instanceSlug={props.instanceSlug}
        handleDelete={props.handleDelete}
      />
    </Fragment>
  );
}

interface InstanceCardProps {
  instanceUrl: string;
  instanceID: string;
  instanceName: string;
  instanceSlug: string;
  instanceImage: string | null;
  instanceEnv: string;
  handleDelete: any;
  currentUser: User | null;
}

function truncate(str: string) {
  return str.length > 38 ? str.substring(0, 38) + "..." : str;
}

export default InstanceCard;
