import React, { useState, useEffect } from "react";
import slugify from "slugify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { Container } from "@material-ui/core";
import ErrorToast from "../../components/layout/ErrorToast";
import {
  createInstance,
  unsetSiteCreateSuccessFlag,
} from "../../store/siteList/actions";
import * as Routes from "../../routes";
import { RootState } from "../../store/store";
import cleanObject from "../../utils/cleanObject";
import { fetchOrganisationListIfNeeded } from "../../store/organisationList/actions";
import InstanceForm from "../../components/forms/instance";
import FormLayout from "../../components/layout/FormLayout";
function Create() {
  const dispatch = useDispatch();
  let history = useHistory();
  const state = useSelector((state: RootState) => state);

  const [instance, setInstance] = useState({
    name: "",
    url: "",
    slug: "",
    email: "",
    token: "",
    gitRepository: "",
    organisationID: "",
    isStaging: false,
    build: false,
  });

  const [errors, setErrors] = useState({
    inputName: "",
    errorMessage: "",
  });

  useEffect(() => {
    if (state.site_list.createSuccess) {
      dispatch(unsetSiteCreateSuccessFlag());
      history.push(Routes.INSTANCE_PORTAL);
    }
  }, [state.site_list.createSuccess]);

  useEffect(() => {
    dispatch(fetchOrganisationListIfNeeded());
  }, [state.current_user]);

  useEffect(() => {
    if (state.current_user.user?.role === "USER") {
      setInstance({
        ...instance,
        organisationID: state.current_user.user?.organisationID
          ? state.current_user.user?.organisationID
          : "",
      });
    }
  }, [state.current_user]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "name") {
      setInstance({
        ...instance,
        name: event.target.value,
        slug: slugify(event.target.value, { lower: true }),
      });
    } else {
      setInstance({
        ...instance,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleChangeStaging = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInstance({
      ...instance,
      isStaging: event.target.value === "STAGING",
    });
  };

  const toggleBuild = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInstance({
      ...instance,
      build: event.target.checked,
    });
  };
  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setErrors({ inputName: "", errorMessage: "" });
    let valid = validateForm();
    if (valid) {
      let reqBody: any = {};
      reqBody["instance"] = cleanObject(instance);
      reqBody["instance"]["isStaging"] = instance.isStaging;

      dispatch(createInstance(reqBody));
    }
  };
  const validateForm = () => {
    if (!instance.name || instance.name.length === 0) {
      setErrors({
        inputName: "name",
        errorMessage: "Display Name is Required",
      });
      return false;
    }
    if (!instance.slug || instance.slug.length === 0) {
      setErrors({ inputName: "slug", errorMessage: "Slug is Required" });
      return false;
    }
    if (
      !instance.url ||
      instance.url.length === 0 ||
      instance.url.charAt(instance.url.length - 1) != "/"
    ) {
      setErrors({
        inputName: "url",
        errorMessage:
          "Must provide a valid Website URL which ends in a forward slash",
      });
      return false;
    }
    if (!instance.token || instance.token.length === 0) {
      setErrors({ inputName: "token", errorMessage: "Token is Required" });
      return false;
    }
    if (!instance.organisationID || instance.organisationID.length === 0) {
      setErrors({
        inputName: "organisation",
        errorMessage: "Organisation is Required",
      });
      return false;
    }

    return true;
  };
  return (
    <>
      <Container className="container">
        <FormLayout
          loading={null}
          formHeading="Add New Website"
          backLink={Routes.INSTANCE_PORTAL}
          size="md"
        >
          <InstanceForm
            instance={instance}
            handleChangeStaging={handleChangeStaging}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isFetching={false}
            isSubmitting={state.site_list.creating}
            organisationList={state.organisation_list.organisations}
            currentUserRole={state.current_user.user?.role}
            toggleBuild={toggleBuild}
            errors={errors}
          />
        </FormLayout>
      </Container>
      {state.site_list.createError.length > 0 ? (
        <ErrorToast open={true} errorMessage="" />
      ) : null}
    </>
  );
}

export default Create;
