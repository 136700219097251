import React, { useState } from "react";
import TopLevelSubMenu from "./topLevelSubMenu";
import LegacySiteManagerSubMenu from "./LegacySiteManagerSubMenu";
import SiteManagerSubMenu from "./SiteManagerSubMenu";
import CRMSubMenu from "./crmSubmenu";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import AreasSubMenu from "./AreasSubMenu";

const SiteManagerMenu = (props) => {
  const state = useSelector((state) => state);
  const [menuStack, setMenuStack] = useState([
    <TopLevelSubMenu handleClick={(key) => pushMenuToStack(key)} />,
    <TopLevelSubMenu handleClick={(key) => pushMenuToStack(key)} />,
  ]);

  const classes = useStyles();

  const pushMenuToStack = (menusKey) => {
    setMenuStack((currentStack) => [...currentStack, menus[menusKey]]);
  };

  const popMenuFromStack = () => {
    let stackCopy = [...menuStack];
    stackCopy.pop();
    setMenuStack(stackCopy);
  };

  const menus = {
    areasSubMenu: (
      <AreasSubMenu
        handleClick={pushMenuToStack}
        handleBackClick={popMenuFromStack}
      />
    ),
    legacySiteManager: (
      <LegacySiteManagerSubMenu
        handleClick={pushMenuToStack}
        handleBackClick={popMenuFromStack}
      />
    ),
    siteManager: (
      <SiteManagerSubMenu
        handleClick={pushMenuToStack}
        handleBackClick={popMenuFromStack}
      />
    ),
    crm: (
      <CRMSubMenu
        handleClick={pushMenuToStack}
        handleBackClick={popMenuFromStack}
      />
    ),
  };

  return (
    <>
      <div className={classes.drawer_top}>{menuStack[menuStack.length - 1]}</div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  drawer_wrap: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  drawer_top: {},
  drawer_bottom: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  logo_wrap: {
    height: "40px",
  },
}));

export default SiteManagerMenu;
