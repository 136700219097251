import { gql } from '@apollo/client';

const CREATE_LAYOUT = gql`
    mutation create_layout(
        $path: String!, 
        $body: String!, 
        $physical_file_path: String!, 
        $metadata: HashObject!
    ){
    admin_liquid_layout_create(liquid_layout: {path: $path, physical_file_path: $physical_file_path, body: $body, metadata: $metadata}) {
        id
        created_at
        updated_at
        path
        metadata
        body
        physical_file_path
  }
}

`

export default CREATE_LAYOUT;