import { gql } from '@apollo/client';

const CREATE_REDIRECT = gql`
    mutation create_redirect(
        $physical_file_path: String!, 
        $redirect_code: PageRedifectCode, 
        $redirect_to: String!, 
        $slug: String!
    ){
    admin_page_create(
        page: {
            physical_file_path: $physical_file_path, 
            slug: $slug, 
            redirect_to: $redirect_to, 
            redirect_code: $redirect_code, 
            layout: "", 
            metadata: { type: "redirect" }
        }
    ){
        id
        created_at
        updated_at
        slug
        redirect_to
        redirect_code
        layout
        physical_file_path
        metadata
    }
}
`

export default CREATE_REDIRECT;