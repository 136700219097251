import React, { useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import GET_WEBFORMS from "../../../graphql/GET_WEBFORMS";
import * as Routes from "../../../routes";
import { RootState } from "../../../store/store";

import FormLayout from "../../../components/layout/FormLayout";

function WebformView() {
  const params = useParams<WebformViewParams>();
  const state = useSelector((state: RootState) => state);
  const classes = useStyles();

  const { loading, error, data } = useQuery(GET_WEBFORMS, {
    variables: { id: params.id },
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  const webform: any = useMemo(() => {
    return data ? data.models.results[0] : null;
  }, [data]);

  return (
    <FormLayout
      loading={loading}
      formHeading="Webform"
      backLink={Routes.WEBFORMS_LINK(params.site)}
      size="md"
    >
      {webform ? (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">Created At</TableCell>
              <TableCell align="center">{webform.created_at}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">Updated At</TableCell>
              <TableCell align="center">{webform.updated_at}</TableCell>
            </TableRow>
            {Object.entries(webform.properties).map((entry: any) => {
              return (
                <TableRow>
                  <TableCell align="center">{entry[0]}</TableCell>
                  <TableCell align="center">
                    {typeof entry[1] === "object"
                      ? JSON.stringify(entry[1])
                      : entry[1]}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <p>Webform not found</p>
      )}
    </FormLayout>
  );
}

interface WebformViewParams {
  site: string;
  id: string;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    margin: 20,
  },
});

export default WebformView;
