import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import PartialForm from "../../../components/forms/partial";
import { useSelector } from "react-redux";
import UPDATE_PARTIAL from "../../../graphql/UPDATE_PARTIAL";
import GET_PARTIALS from "../../../graphql/GET_PARTIALS";
import { useQuery, useMutation } from "@apollo/client";
import ErrorToast from "../../../components/layout/ErrorToast";
import * as Routes from "../../../routes";
import { RootState } from "../../../store/store";
import FormLayout from "../../../components/layout/FormLayout";

const CreatePartial = () => {
  let history = useHistory();
  const params = useParams<PartialParams>();
  const state = useSelector((state: RootState) => state);
  const [partial, setPartial] = useState({
    path: "",
    body: "",
    physical_file_path: "",
  });
  const [metadata, setMetadata] = useState({
    name: "",
    type: "",
  });

  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(GET_PARTIALS, {
    variables: {
      id: params.id,
    },
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  useEffect(() => {
    if (queryData) {
      const result = queryData.admin_liquid_partials.results[0];
      setPartial({
        physical_file_path: result.physical_file_path,
        path: result.path,
        body: result.body,
      });
      setMetadata({ ...metadata, ...result.metadata });
    }
  }, [queryData]);

  const [
    updatePartial,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(UPDATE_PARTIAL, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    onCompleted: (data) => {
      if (data) {
        history.push(Routes.PARTIALS_LINK(params.site));
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPartial({
      ...partial,
      [event.target.name]: event.target.value as string,
    });
  };
  const handleBodyChange = (content: string) => {
    setPartial({
      ...partial,
      body: content,
    });
  };

  const handleMetadataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "name") {
      setMetadata({
        ...metadata,
        name: event.target.value as string,
      });
    }
  };

  const handleSelectChange = (event: any, newValue: string) => {
    setMetadata({
      ...metadata,
      type: newValue,
    });
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    updatePartial({
      variables: {
        id: params.id,
        physical_file_path: partial.physical_file_path,
        path: partial.path,
        body: partial.body,
        metadata: metadata,
      },
    });
  };

  return (
    <FormLayout
      loading={mutationLoading}
      formHeading="Edit Partial"
      backLink={Routes.PARTIALS_LINK(params.site)}
      size="lg"
    >
      {mutationError ? (
        <ErrorToast open={true} errorMessage={mutationError.message} />
      ) : null}

      <PartialForm
        handleChange={handleChange}
        handleMetadataChange={handleMetadataChange}
        handleSelectChange={handleSelectChange}
        handleBodyChange={handleBodyChange}
        handleSubmit={handleSubmit}
        isFetching={queryLoading}
        partial={partial}
        metadata={metadata}
        isSubmitting={mutationLoading}
      />
    </FormLayout>
  );
};

interface PartialParams {
  site: string;
  id: string;
}

export default CreatePartial;
