import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import ErrorToast from "../ErrorToast";

const BuildButton = () => {
  const state = useSelector((state: RootState) => state);
  const [fetching, setFetching] = useState(false);
  const [show, setShow] = useState(false);
  const [result, setResult] = useState(false);
  const [message, setMessage] = useState("");
  const [logs, setLogs] = useState<any[]>([]);
  const [mostRecentStarted, setMostRecentStarted] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const fetchLogs = async () => {
    setFetching(true);
    await fetch(`${process.env.REACT_APP_BASE_URL}/v1/logs`, {
      method: "get",
      headers: {
        Authorization: "Bearer " + state.current_user.user!.token,
        instance: state.current_site.site!.slug,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setLogs(data);
        if (data.length > 0) {
          setMostRecentStarted(data[0].buildStatus === "STARTED");
        }
        setFetching(false);
      });
  };

  useEffect(() => {
    fetchLogs();
  }, [state.current_site]);

  const handleClick = () => {
    if (mostRecentStarted) {
      setConfirmOpen(true);
    } else {
      handleConfirm();
    }
  };

  const handleConfirm = async () => {
    setConfirmOpen(false);
    setFetching(true);
    const build = await fetch(`${process.env.REACT_APP_BASE_URL}/v1/build`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${state.current_user.user!.token}`,
        instance: state.current_site.site!.slug,
      },
    });

    setMessage(
      build.ok
        ? "Building Website. It may take some time before changes show up on the site"
        : "There seems to be a problem with the build, please contact us for assistance"
    );

    setResult(build.ok);
    setShow(true);
    setFetching(false);
    fetchLogs(); // Refresh logs after initiating build
  };

  const handleClose = () => {
    setConfirmOpen(false);
  };

  return (
    <>
      {state.current_site.site?.build ? (
        <>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={handleClick}
            disabled={fetching}
          >
            {mostRecentStarted ? "Restart Build" : "Start Build"}
          </Button>
          <Dialog open={confirmOpen} onClose={handleClose}>
            <DialogTitle>Confirm Build</DialogTitle>
            <DialogContent>
              <DialogContentText>
                There is currently a build in progress. Are you sure you want to
                start a new build?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleConfirm} color="primary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          {show ? (
            <ErrorToast
              errorMessage={message}
              open={true}
              toastType={result ? "success" : "error"}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default BuildButton;
