import React, { useState, useMemo } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Modal,
  Typography,
  Button,
  TextField,
  FormLabel,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Collapse,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import {
  CollectionProperty,
  PropertyMetadata,
} from "../../pages/site/collection/create";
import slugify from "slugify";
import { useQuery } from "@apollo/client";
import GET_TABLES from "../../graphql/GET_PUBLIC_READ_TABLES";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { FormControl } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ShortTextIcon from "@material-ui/icons/ShortText";
import SubjectIcon from "@material-ui/icons/Subject";
import ArtTrackIcon from "@material-ui/icons/ArtTrack";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ListIcon from "@material-ui/icons/List";
import ImageIcon from "@material-ui/icons/Image";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import PaletteIcon from "@material-ui/icons/Palette";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import EventNoteIcon from "@material-ui/icons/EventNote";
import ExposurePlus1Icon from "@material-ui/icons/ExposurePlus1";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import { ReactComponent as ManyToManyIcon } from "../../assets/icons/ManyToManyIcon.svg";
import { ReactComponent as ManyToOneIcon } from "../../assets/icons/ManyToOneIcon.svg";
import { ReactComponent as OneToManyIcon } from "../../assets/icons/OneToManyIcon.svg";
import { ReactComponent as OneToOneIcon } from "../../assets/icons/OneToOneIcon.svg";

const CollectionsFieldModal = (props: FieldModalProps) => {
  const classes = useStyles();

  const state = useSelector((state: RootState) => state);
  const [propertyNameErrorMessage, setPropertyNameErrorMessage] = useState("");
  const [attributeTypeErrorMessage, setAttributeTypeErrorMessage] =
    useState("");
  const [relationshipErrorMessage, setRelationshipErrorMessage] = useState("");
  const [relationshipTypeErrorMessage, setRelationshipTypeErrorMessage] =
    useState("");

  const [metadata, setMetadata] = useState<PropertyMetadata>({
    display_name: "",
    type: "",
    private: false,
    display_priority: 5,
    required: false,
    table_display: false,
  });
  const [property, setProperty] = useState<CollectionProperty>({
    name: "",
    attribute_type: null,
    metadata: null,
  });
  const [relationship, setRelationship] = useState<Relationship>({
    type: "",
    belongs_to: "",
  });
  const [expanded, setExpanded] = React.useState(false);
  const toggleExpandClick = () => {
    setExpanded(!expanded);
  };
  const [dialogOpen, setDialogOpen] = useState(false);
  const [radioChecked, setRadioCheck] = useState(false);

  const { loading, error, data } = useQuery(GET_TABLES, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  const tables: any = useMemo(() => {
    return data ? data.admin_tables.results : [];
  }, [data]);

  const handleDisplayNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const name: string = event.target.value;
    setMetadata({
      ...metadata,
      display_name: name,
    });

    const slugified = slugify(name, {
      replacement: "_",
      lower: true,
      trim: true,
    });
    setProperty({
      ...property,
      name: slugified,
    });
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const type: string = event.target.value;
    if (type !== "relationship") {
      setMetadata({
        ...metadata,
        display_priority: attributeDisplayMap[type],
        type: type,
      });

      setProperty({
        ...property,
        attribute_type: attributeMap[type],
      });
    }
  };

  const handleSave = (): boolean => {
    if (!property.name) {
      setPropertyNameErrorMessage("Propery Needs a Name");
      return false;
    }

    if (!property.attribute_type) {
      setAttributeTypeErrorMessage("Property needs a type");
      return false;
    }

    let match = false;

    for (const _property of props.collectionProperties) {
      if (property.name === _property.name) {
        match = true;
      }
    }

    if (match) {
      setPropertyNameErrorMessage("a field with this name already exists");
      return false;
    }

    setPropertyNameErrorMessage("");
    setAttributeTypeErrorMessage("");
    props.addCollectionProperty(property, metadata);
    return true;
  };

  const handleClose = () => {
    resetProperty();

    props.handleCancel();
  };

  const resetProperty = () => {
    setRadioCheck(false);
    setMetadata({
      display_name: "",
      type: "",
      private: false,
      display_priority: 5,
      required: false,
      table_display: false,
    });

    setProperty({
      name: "",
      attribute_type: null,
      metadata: null,
    });

    setRelationship({
      type: "",
      belongs_to: "",
    });
  };

  const saveAndClose = () => {
    const saved = handleSave();
    if (saved) {
      handleClose();
    }
  };

  const saveAndContinue = () => {
    setRadioCheck(true);
    const saved = handleSave();
    if (saved) {
      resetProperty();
    }
  };

  const handleRelationDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleRelationshipTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const type: string = event.target.value;

    setRelationship({
      ...relationship,
      type: type,
    });
    if (type === "short_text") {
      setMetadata({
        ...metadata,
        table_display: true,
      });
    }
  };

  const handleRelationshipBelongsChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const relatedTable = event.target.value as string;

    setRelationship({
      ...relationship,
      belongs_to: relatedTable,
    });
  };

  const handleRelationshipConfirm = (): boolean => {
    if (!relationship.belongs_to) {
      setRelationshipErrorMessage(
        "Please Select Collection the relationship is with"
      );
      return false;
    }

    if (!relationship.type) {
      setRelationshipTypeErrorMessage("Please Select the type of relationship");
      return false;
    }

    setMetadata({
      ...metadata,
      display_priority: attributeDisplayMap[relationship.type],
      type: relationship.type,
    });

    setProperty({
      ...property,
      belongs_to: relationship.belongs_to,
      attribute_type: attributeMap[relationship.type],
    });

    handleDialogClose();
    return true;
  };

  const handleAdvanceFeatureChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMetadata({
      ...metadata,
      [event.target.name!]: event.target.checked,
    });
  };

  const handleDisplayPriorityChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMetadata({
      ...metadata,
      display_priority: Number(event.target.value),
    });
  };

  return (
    <Modal
      open={props.open}
      onClose={props.handleSave}
      aria-labelledby="image-upload-modal"
    >
      <div className={classes.collections}>
        <Typography variant="h5" component="h2">
          Add Field
        </Typography>
        <TextField
          className={classes.collections__input}
          label="Field Name"
          variant="outlined"
          fullWidth
          name="display_name"
          value={metadata.display_name}
          onChange={handleDisplayNameChange}
          helperText={propertyNameErrorMessage}
          error={!!propertyNameErrorMessage}
        />

        <FormLabel className={classes.collections__title} component="legend">
          Select a Type
        </FormLabel>
        <Grid container spacing={3} className={classes.radio__wrap}>
          <Grid item xs={3}>
            <Tooltip
              title="Best used for short-form text content"
              placement="bottom"
              enterDelay={500}
              enterNextDelay={500}
            >
              <label
                className={
                  metadata.type === "short_text"
                    ? classes.radio__active
                    : classes.radio
                }
              >
                <input
                  className={classes.radio__circle}
                  type="radio"
                  name="type"
                  value="short_text"
                  checked={radioChecked}
                  onChange={handleTypeChange}
                />
                <ShortTextIcon className={classes.radio__icon} />
                <span>Short Text</span>
              </label>
            </Tooltip>
          </Grid>

          <Grid item xs={3}>
            <Tooltip
              title="Best used for short-form text content"
              placement="bottom"
              enterDelay={500}
              enterNextDelay={500}
            >
              <label
                className={
                  metadata.type === "long_text"
                    ? classes.radio__active
                    : classes.radio
                }
              >
                <SubjectIcon className={classes.radio__icon} />
                <span>Long Text</span>
                <input
                  className={classes.radio__circle}
                  type="radio"
                  name="type"
                  value="long_text"
                  onChange={handleTypeChange}
                  checked={radioChecked}
                />
              </label>
            </Tooltip>
          </Grid>

          <Grid item xs={3}>
            <Tooltip
              title="Use rich text for long-form content where you might need multiple paragraphs, headings, or inline media such as videos, images or links."
              placement="bottom"
              enterDelay={500}
              enterNextDelay={500}
            >
              <label
                className={
                  metadata.type === "rich_text"
                    ? classes.radio__active
                    : classes.radio
                }
              >
                <ArtTrackIcon className={classes.radio__icon} />
                <input
                  className={classes.radio__circle}
                  type="radio"
                  name="type"
                  value="rich_text"
                  checked={radioChecked}
                  onChange={handleTypeChange}
                />
                <span>Rich Text</span>
              </label>
            </Tooltip>
          </Grid>

          <Grid item xs={3}>
            <Tooltip
              title="This field is used when the input is meant to be a numeric value, either an integer or decimal number."
              placement="bottom"
              enterDelay={500}
              enterNextDelay={500}
            >
              <label
                className={
                  metadata.type === "int"
                    ? classes.radio__active
                    : classes.radio
                }
              >
                <ExposurePlus1Icon className={classes.radio__icon} />
                <input
                  className={classes.radio__circle}
                  type="radio"
                  name="type"
                  value="int"
                  checked={radioChecked}
                  onChange={handleTypeChange}
                />
                <span>Whole Number</span>
              </label>
            </Tooltip>
          </Grid>

          <Grid item xs={3}>
            <Tooltip
              title="Use this field when the input is meant to be a numeric value, either an integer or decimal number."
              placement="bottom"
              enterDelay={500}
              enterNextDelay={500}
            >
              <label
                className={
                  metadata.type === "float"
                    ? classes.radio__active
                    : classes.radio
                }
              >
                <ExposurePlus1Icon className={classes.radio__icon} />
                <input
                  className={classes.radio__circle}
                  type="radio"
                  name="type"
                  value="float"
                  checked={radioChecked}
                  onChange={handleTypeChange}
                />
                <span>Decimal </span>
              </label>
            </Tooltip>
          </Grid>

          <Grid item xs={3}>
            <Tooltip
              title="This field allows you to create both a date and time field."
              placement="bottom"
              enterDelay={500}
              enterNextDelay={500}
            >
              <label
                className={
                  metadata.type === "datetime"
                    ? classes.radio__active
                    : classes.radio
                }
              >
                <EventNoteIcon className={classes.radio__icon} />
                <input
                  className={classes.radio__circle}
                  type="radio"
                  name="type"
                  value="datetime"
                  checked={radioChecked}
                  onChange={handleTypeChange}
                />
                <span>Date and Time</span>
              </label>
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Tooltip
              title="This field allows you to create both a date and time field."
              placement="bottom"
              enterDelay={500}
              enterNextDelay={500}
            >
              <label
                className={
                  metadata.type === "date"
                    ? classes.radio__active
                    : classes.radio
                }
              >
                <CalendarTodayIcon className={classes.radio__icon} />
                <input
                  className={classes.radio__circle}
                  type="radio"
                  name="type"
                  value="date"
                  checked={radioChecked}
                  onChange={handleTypeChange}
                />
                <span>Date</span>
              </label>
            </Tooltip>
          </Grid>

          <Grid item xs={3}>
            <Tooltip
              title="This field allows you to create both a date and time field."
              placement="bottom"
              enterDelay={500}
              enterNextDelay={500}
            >
              <label
                className={
                  metadata.type === "time"
                    ? classes.radio__active
                    : classes.radio
                }
              >
                <AccessTimeIcon className={classes.radio__icon} />
                <input
                  className={classes.radio__circle}
                  type="radio"
                  name="type"
                  value="time"
                  checked={radioChecked}
                  onChange={handleTypeChange}
                />
                <span>Time</span>
              </label>
            </Tooltip>
          </Grid>

          <Grid item xs={3}>
            <Tooltip
              title="Use the list field when you want to create a list of predefined values."
              placement="bottom"
              enterDelay={500}
              enterNextDelay={500}
            >
              <label
                className={
                  metadata.type === "array"
                    ? classes.radio__active
                    : classes.radio
                }
              >
                <ListIcon className={classes.radio__icon} />
                <input
                  className={classes.radio__circle}
                  type="radio"
                  name="type"
                  value="array"
                  checked={radioChecked}
                  onChange={handleTypeChange}
                />
                <span>List</span>
              </label>
            </Tooltip>
          </Grid>

          <Grid item xs={3}>
            <Tooltip
              title="Use the boolean field to store simple true/false, yes/no values."
              placement="bottom"
              enterDelay={500}
              enterNextDelay={500}
            >
              <label
                className={
                  metadata.type === "boolean"
                    ? classes.radio__active
                    : classes.radio
                }
              >
                <SwapHorizIcon className={classes.radio__icon} />
                <input
                  className={classes.radio__circle}
                  type="radio"
                  name="type"
                  value="boolean"
                  checked={radioChecked}
                  onChange={handleTypeChange}
                />
                <span>Boolean</span>
              </label>
            </Tooltip>
          </Grid>

          <Grid item xs={3}>
            <Tooltip
              title="Upload images and use them as a dynamic element for your individual collection item."
              placement="bottom"
              enterDelay={500}
              enterNextDelay={500}
            >
              <label
                className={
                  metadata.type === "image"
                    ? classes.radio__active
                    : classes.radio
                }
              >
                <ImageIcon className={classes.radio__icon} />
                <input
                  className={classes.radio__circle}
                  type="radio"
                  name="type"
                  value="image"
                  checked={radioChecked}
                  onChange={handleTypeChange}
                />
                <span>Image</span>
              </label>
            </Tooltip>
          </Grid>

          <Grid item xs={3}>
            <Tooltip
              title="Add multiple images to a single field in your Collection."
              placement="bottom"
              enterDelay={500}
              enterNextDelay={500}
            >
              <label
                className={
                  metadata.type === "image_group"
                    ? classes.radio__active
                    : classes.radio
                }
              >
                <PhotoLibraryIcon className={classes.radio__icon} />
                <input
                  className={classes.radio__circle}
                  type="radio"
                  name="type"
                  value="image_group"
                  checked={radioChecked}
                  onChange={handleTypeChange}
                />
                <span>Image Gallery</span>
              </label>
            </Tooltip>
          </Grid>

          <Grid item xs={3}>
            <Tooltip
              title="Pick a color that can be used to style different elements."
              placement="bottom"
              enterDelay={500}
              enterNextDelay={500}
            >
              <label
                className={
                  metadata.type === "colour"
                    ? classes.radio__active
                    : classes.radio
                }
              >
                <PaletteIcon className={classes.radio__icon} />
                <input
                  className={classes.radio__circle}
                  type="radio"
                  name="type"
                  value="colour"
                  checked={radioChecked}
                  onChange={handleTypeChange}
                />
                <span>Colour</span>
              </label>
            </Tooltip>
          </Grid>

          <Grid item xs={3}>
            <Tooltip
              title="This field allows you to link to other collections within your website, allowing you to build pages that pull data from different collections."
              placement="bottom"
              enterDelay={500}
              enterNextDelay={500}
            >
              <label
                className={
                  metadata.type === "relationship"
                    ? classes.radio__active
                    : classes.radio
                }
              >
                <AccountTreeIcon className={classes.radio__icon} />
                <input
                  className={classes.radio__circle}
                  type="radio"
                  name="type"
                  value="relationship"
                  onChange={handleRelationDialogOpen}
                />
                <span>Relationship</span>
              </label>
            </Tooltip>
          </Grid>
        </Grid>
        {attributeTypeErrorMessage ? (
          <InputLabel error={true}>{attributeTypeErrorMessage}</InputLabel>
        ) : null}

        <div>
          <div className={classes.advanced__header}>
            <Typography
              component="h2"
              variant="h6"
              className={classes.marginRight}
            >
              Advanced Settings
            </Typography>
            <IconButton
              className={expanded ? classes.expandOpen : classes.expand}
              onClick={toggleExpandClick}
            >
              <ExpandMoreIcon />
            </IconButton>
          </div>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <div className={classes.advanced__input}>
              <Tooltip
                title="Set the order for how you wish the collections field to appear."
                placement="right"
              >
                <TextField
                  label="Display Priority"
                  type="number"
                  variant="outlined"
                  value={metadata.display_priority}
                  onChange={handleDisplayPriorityChange}
                />
              </Tooltip>
            </div>

            <div className={classes.advanced__checkboxWrap}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.advanced__checkbox}
                    color="primary"
                    name="required"
                    checked={metadata.required}
                    value={metadata.required}
                    onChange={handleAdvanceFeatureChange}
                  />
                }
                label="Required"
              />
              <Tooltip
                title="A required field cannot be left blank"
                placement="right"
              >
                <HelpOutlineIcon className="tooltip__icon" />
              </Tooltip>
            </div>

            <div className={classes.advanced__checkboxWrap}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.advanced__checkbox}
                    color="primary"
                    name="private"
                    checked={metadata.private}
                    value={metadata.private}
                    onChange={handleAdvanceFeatureChange}
                  />
                }
                label="Private"
              />
              <Tooltip
                title="This collection field will not be visible to the public."
                placement="right"
              >
                <HelpOutlineIcon className="tooltip__icon" />
              </Tooltip>
            </div>
            <div className={classes.advanced__checkboxWrap}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.advanced__checkbox}
                    color="primary"
                    name="table_display"
                    checked={metadata.table_display}
                    value={metadata.table_display}
                    onChange={handleAdvanceFeatureChange}
                  />
                }
                label="Show Table"
              />
              <Tooltip
                title="This collection field will not be visible on the table view."
                placement="right"
              >
                <HelpOutlineIcon className="tooltip__icon" />
              </Tooltip>
            </div>
          </Collapse>
        </div>

        <Dialog
          className={classes.modal}
          open={dialogOpen}
          onClose={handleDialogClose}
        >
          <div className={classes.modal__wrap}>
            <DialogTitle>Choose Relationship Type</DialogTitle>
            <DialogContent className={classes.modal__wrap}>
              <div className={classes.radio__wrap}>
                <div>{props.displayName}</div>
                <Grid container spacing={3} className={classes.radio__grid}>
                  <Grid item xs={6}>
                    <label
                      className={
                        relationship.type === "one_to_one"
                          ? classes.radio__active
                          : classes.radio
                      }
                    >
                      <OneToOneIcon className={classes.relationship__icon} />
                      <input
                        className={classes.radio__circle}
                        type="radio"
                        name="relationship"
                        value="one_to_one"
                        onChange={handleRelationshipTypeChange}
                      />
                      <span>One To One</span>
                    </label>
                  </Grid>
                  <Grid item xs={6}>
                    <label
                      className={
                        relationship.type === "one_to_many"
                          ? classes.radio__active
                          : classes.radio
                      }
                    >
                      <OneToManyIcon className={classes.relationship__icon} />
                      <input
                        className={classes.radio__circle}
                        type="radio"
                        name="relationship"
                        value="one_to_many"
                        onChange={handleRelationshipTypeChange}
                      />
                      <span>One To Many</span>
                    </label>
                  </Grid>
                  <Grid item xs={6}>
                    <label
                      className={
                        relationship.type === "many_to_many"
                          ? classes.radio__active
                          : classes.radio
                      }
                    >
                      <ManyToManyIcon className={classes.relationship__icon} />
                      <input
                        className={classes.radio__circle}
                        type="radio"
                        name="relationship"
                        value="many_to_many"
                        onChange={handleRelationshipTypeChange}
                      />
                      <span>Many To Many</span>
                    </label>
                  </Grid>
                  <Grid item xs={6}>
                    <label
                      className={
                        relationship.type === "many_to_one"
                          ? classes.radio__active
                          : classes.radio
                      }
                    >
                      <ManyToOneIcon className={classes.relationship__icon} />
                      <input
                        className={classes.radio__circle}
                        type="radio"
                        name="relationship"
                        value="many_to_one"
                        onChange={handleRelationshipTypeChange}
                      />
                      <span>Many To One</span>
                    </label>
                  </Grid>
                </Grid>
                {relationshipTypeErrorMessage ? (
                  <InputLabel error={true}>
                    {relationshipTypeErrorMessage}
                  </InputLabel>
                ) : null}
                <div>
                  <FormControl className={classes.relationship__select}>
                    <InputLabel htmlFor="relationship-select">
                      Select relationship target
                    </InputLabel>
                    <Select
                      labelId="relationship-select"
                      id="relationship-select"
                      value={relationship.belongs_to}
                      onChange={handleRelationshipBelongsChange}
                      error={!!relationshipErrorMessage}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {tables.map((table: any) => (
                        <MenuItem value={table.name}>
                          {table.metadata.display_name}
                        </MenuItem>
                      ))}
                    </Select>
                    {relationshipErrorMessage ? (
                      <InputLabel error={true}>
                        {relationshipErrorMessage}
                      </InputLabel>
                    ) : null}
                  </FormControl>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} variant="text">
                Cancel
              </Button>
              <Button
                onClick={handleRelationshipConfirm}
                variant="contained"
                color="primary"
              >
                Confirm
              </Button>
            </DialogActions>
          </div>
        </Dialog>
        <div className={classes.collections__buttons}>
          {/* <Button className={classes.marginRight} variant="contained" color="primary" onClick={saveAndClose}>Save</Button> */}
          <Button
            className={classes.marginRight}
            variant="contained"
            color="primary"
            onClick={saveAndContinue}
          >
            Add Field
          </Button>
          <Button variant="contained" onClick={handleClose}>
            done
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /*Collections Modal*/
    collections: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      position: "absolute",
      minWidth: 400,
      maxWidth: 800,
      backgroundColor: theme.palette.background.paper,
      borderRadius: 4,
      boxShadow: theme.shadows[5],
      padding: 40,
      width: "100%",
    },
    collections__title: {
      margin: theme.spacing(3, 0),
    },
    marginRight: {
      marginRight: "16px",
    },
    collections__input: {
      margin: "10px 0px",
    },
    collections__buttons: {
      display: "flex",
      justifyContent: "flex-end",
    },
    radio__grid: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      flexGrow: 1,
    },
    radio__wrap: {
      flexGrow: 1,
    },
    radio: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(1),
      position: "relative",
      border: "1px solid",
      borderRadius: 4,
      cursor: "pointer",
      borderColor: theme.palette.grey[400],
      transitionDuration: ".3s",
      "&:hover": {
        background: theme.palette.grey[100],
      },
    },
    radio__active: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(1),
      position: "relative",
      border: "1px solid",
      borderRadius: 4,
      cursor: "pointer",
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
      color: "white",
      transitionDuration: ".3s",
    },
    radio__icon: {
      marginRight: theme.spacing(3),
    },
    radio__circle: {
      opacity: "0%",
      position: "absolute",
    },

    /* Advanced Section */
    expand: {
      transform: "rotate(0deg)",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    advanced__header: {
      display: "flex",
      alignItems: "center",
      margin: theme.spacing(3, 0, 0, 0),
    },
    advanced__checkboxWrap: {
      display: "flex",
      alignItems: "center",
    },
    advanced__input: {
      margin: theme.spacing(2, 0),
    },
    advanced__checkbox: {
      marginRight: theme.spacing(1),
    },

    /*Relationship Modal*/
    modal: {
      width: "100%",
      flexGrow: 1,
    },
    modal__wrap: {
      padding: theme.spacing(3),
      flexGrow: 1,
    },
    relationship__select: {
      maxWidth: 250,
      width: "100%",
    },
    relationship__icon: {
      height: 25,
      width: 25,
      marginRight: theme.spacing(3),
      fill: "currentColor",
    },
  })
);

const attributeMap: {
  [key: string]: string;
} = {
  short_text: "string",
  long_text: "text",
  rich_text: "text",
  int: "integer",
  float: "float",
  boolean: "boolean",
  datetime: "datetime",
  date: "date",
  time: "time",
  array: "array",
  image: "string",
  image_group: "array",
  colour: "string",
  one_to_many: "array",
  one_to_one: "integer",
  many_to_many: "array",
  many_to_one: "integer",
};

const attributeDisplayMap: {
  [key: string]: number;
} = {
  short_text: 8,
  long_text: 3,
  rich_text: 0,
  int: 4,
  float: 4,
  boolean: 2,
  datetime: 5,
  date: 5,
  time: 5,
  array: 3,
  image: 0,
  image_group: 0,
  colour: 0,
  one_to_many: 0,
  one_to_one: 0,
  many_to_many: 0,
  many_to_one: 0,
};

interface Relationship {
  type: string;
  belongs_to: string;
}

interface FieldModalProps {
  open: boolean;
  handleSave: any;
  handleCancel: any;
  index: number | null;
  displayName: string;
  collectionProperties: CollectionProperty[];
  addCollectionProperty: (
    collectionProperty: CollectionProperty,
    propertyMetadata: PropertyMetadata
  ) => void;
  editCollectionProperty: (
    collectionProperty: CollectionProperty,
    propertyMetadata: PropertyMetadata,
    index: number
  ) => void;
}

export default CollectionsFieldModal;
