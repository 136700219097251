import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  FETCH_SITE,
  SET_SITE,
  UNSET_SITE,
  SET_ERRORS,
  SET_FAVOURITES,
  UPDATE_FAVOURITES,
  DELETE_FAVOURITE
} from "./types";

import { RootState } from "../store";

export function invalidateCurrentSiteInfo() {
  return { type: UNSET_SITE };
}

export function fetchSiteInfoIfNeeded(slug: string, client: any) {
  return function (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) {
    const state = getState();
    if (shouldFetchSiteInfo(slug, state, dispatch, client)) {
      return dispatch(fetchSiteInfo(slug));
    }
  };
}

function shouldFetchSiteInfo(
  slug: string,
  state: RootState,
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  client: any
) {
  if (!state.current_site.site) {
  
    return true;
  }
  if (state.current_site.site.slug !== slug) {
    
    unsetSite(dispatch, client);
    return true;
  }
  if (state.current_site.fetching) {
    return false;
  }
  return false;
}

function fetchSiteInfo(slug: string) {
  return function (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) {
    const state: RootState = getState();

    if (
      state.site_list.sites !== undefined &&
      state.site_list.sites.length > 0
    ) {
      for (const site of state.site_list.sites) {
        if (site.slug && site.slug === slug) {
          return dispatch({ type: SET_SITE, payload: site });
        }
      }
    }

    const url = `${process.env.REACT_APP_BASE_URL}/v1/instance/` + slug;
    dispatch({ type: FETCH_SITE });
    return fetch(url, {
      method: "get",
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Network Error");
        }
      })
      .then((json) => {
        if (!json.errors) {
          return dispatch({
            type: SET_SITE,
            payload: json,
          });
        } else {
          for (let error of json.errors) {
            throw new Error(error.message);
          }
        }
      })
      .catch((error) => {
        return dispatch({
          type: SET_ERRORS,
          payload: error.message,
        });
      });
  };
}

function unsetSite(dispatch: ThunkDispatch<{}, {}, AnyAction>, client: any) {
  client.clearStore();
  dispatch({ type: UNSET_SITE });
}

export function fetchFavourites() {
  return function (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) {
    const state: RootState = getState();

    /*         if(state.current_site.site?.favourites === undefined || state.current_site.site.favourites.length ){
                 
                    return dispatch({  type: SET_FAVOURITES, payload: state.current_site.site!.favourites });
                
            
        } */

    const url = `${process.env.REACT_APP_BASE_URL}/v1/favourite`;
    return fetch(url, {
      method: "get",
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site!.slug,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Network Error");
        }
      })
      .then((json) => {
        if (!json.errors) {
          return dispatch({
            type: SET_FAVOURITES,
            payload: json,
          });
        } else {
          for (let error of json.errors) {
            throw new Error(error.message);
          }
        }
      })
      .catch((error) => {
        return dispatch({
          type: SET_ERRORS,
          payload: error.message,
        });
      });
  };
}

export function createFavourite(slug: string, type: string) {
  return function (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) {
    const state: RootState = getState();
    const url = `${process.env.REACT_APP_BASE_URL}/v1/favourite`;
    const body = { favourite: { slug: slug, type: type } };
    return fetch(url, {
      method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${state.current_user.user?.token}`,
        'instance': state.current_site.site!.slug,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Network Error");
        }
      })
      .then((json) => {
        if (!json.errors) {
          return dispatch({
            type: UPDATE_FAVOURITES,
            payload: json,
          });
        } else {
          for (let error of json.errors) {
            throw new Error(error.message);
          }
        }
      })
      .catch((error) => {
        return dispatch({
          type: SET_ERRORS,
          payload: error.message,
        });
      });
  };
}


export function deleteFavourite(id : string) {
  return function (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) {
    const state: RootState = getState();
    const url = `${process.env.REACT_APP_BASE_URL}/v1/favourite/${id}`;
    return fetch(url, {
      method: "delete",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${state.current_user.user?.token}`,
        'instance': state.current_site.site!.slug,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Network Error");
        }
      })
      .then((json) => {
        if (!json.errors) {
          return dispatch({
            type: DELETE_FAVOURITE,
            payload: json,
          });
        } else {
          for (let error of json.errors) {
            throw new Error(error.message);
          }
        }
      })
      .catch((error) => {
        return dispatch({
          type: SET_ERRORS,
          payload: error.message,
        });
      });
  };
}
