import { Input, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  const count = preFilteredRows.length;

  return (
    <Input
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon fontSize="small" color="disabled" />
        </InputAdornment>
      }
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      //placeholder={`Search ${count} records...`}
    />
  );
};

export default DefaultColumnFilter;
