import { TOGGLE_DARK_MODE, SiteMode, SiteModeActions } from "./types";

const initialState: SiteMode = {
    isDarkMode: false,
}

function siteModeReducer(state = initialState, action: SiteModeActions): SiteMode {
    switch (action.type) {
        case TOGGLE_DARK_MODE: {
            return {
                isDarkMode: !state.isDarkMode
            }
        }
        default:
            return state
    }
};

export default siteModeReducer;