import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { Delete } from "@material-ui/icons";

function HyperlinkModal(props: HyperlinkModalProps) {
  const classes = useStyles();
  const [linkInfo, setLinkInfo] = useState({
    url: props.link?.url || "",
    displayText: props.link?.displayText || props.selectedText || "",
    title: props.link?.title || "",
    linkType: props.link?.linkType || "external",
  });
  const theme = useTheme();
  const state = useSelector((state: RootState) => state);

  useEffect(() => {
    const determineLinkType = () => {
      if (
        linkInfo.url?.includes(state.current_site.site?.url as string) ||
        !linkInfo.url?.includes("https://")
      ) {
        return "internal";
      } else {
        return "external";
      }
    };
    setLinkInfo((prev) => ({ ...prev, linkType: determineLinkType() }));
  }, [linkInfo.url, state.current_site.site?.url]);

  const handleSave = () => {
    props.handleUpdateLink(
      linkInfo.url,
      linkInfo.displayText,
      linkInfo.title,
      linkInfo.linkType
    );
    props.handleClose();
  };

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="view-source-modal"
    >
      <div className={classes.paper}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" component="h2">
            Hyperlink Editor
          </Typography>
        </div>
        <TextField
          value={linkInfo.url}
          onChange={(e) => setLinkInfo({ ...linkInfo, url: e.target.value })}
          label="URL"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          value={linkInfo.displayText}
          onChange={(e) =>
            setLinkInfo({ ...linkInfo, displayText: e.target.value })
          }
          label="Selected Text"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          value={linkInfo.title}
          onChange={(e) => setLinkInfo({ ...linkInfo, title: e.target.value })}
          label="Title/Tooltip"
          size="small"
          variant="outlined"
          fullWidth
        />
        <FormControl variant="outlined" style={{ marginTop: theme.spacing(2) }}>
          <InputLabel>Link Type</InputLabel>
          <Select
            disabled
            value={linkInfo.linkType}
            onChange={(e) =>
              setLinkInfo({
                ...linkInfo,
                linkType: e.target.value as "internal" | "external",
              })
            }
            label="Link Type"
            variant="outlined"
            fullWidth
          >
            <MenuItem value="internal">Internal</MenuItem>
            <MenuItem value="external">External</MenuItem>
          </Select>
        </FormControl>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: theme.spacing(2),
            marginTop: theme.spacing(2),
          }}
        >
          {props.isLink && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                props.handleUpdateLink("", "", "", "internal");
                props.handleClose();
              }}
            >
              <Delete />
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSave}
            disabled={
              !linkInfo.url ||
              !linkInfo.displayText ||
              !linkInfo.title ||
              !linkInfo.linkType
            }
          >
            {props.isLink ? "Update" : "Add"} Hyperlink
          </Button>
        </div>
      </div>
    </Modal>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      maxHeight: "70vh",
      top: "50%",
      left: "50%",
      padding: theme.spacing(2),
      transform: "translate(-50%, -50%)",
      position: "absolute",
      width: "33vw",
      gap: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[5],
      display: "flex",
      flexDirection: "column",
    },
    container: {
      overflow: "auto",
      maxHeight: "65vh",
      width: "100%",
      overflowX: "hidden",
      border: "1px solid #ccc",
      borderRadius: theme.shape.borderRadius,
    },
    button: {
      marginTop: theme.spacing(2),
    },
  })
);

interface HyperlinkModalProps {
  open: boolean;
  isLink: boolean;
  handleClose: any;
  handleUpdateLink: any;
  selectedText: string;
  link?: {
    url: string;
    displayText: string;
    title: string;
    linkType: string;
  };
}

export default HyperlinkModal;
