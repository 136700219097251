import { gql } from '@apollo/client';

const GET_PUBLIC_READ_TABLES = gql`
    query get_public_read_tables {
        admin_tables(
            filter: {
                metadata: {
                    attribute: {key: "public_read", value_boolean: true},
                }
            }
        ){
            results {
                id
                name
                metadata
                properties {
                    name
                    attribute_type
                    belongs_to
                    metadata
                }
            }
        }
    }
  `

export default GET_PUBLIC_READ_TABLES;