import { gql } from "@apollo/client";

const GET_MAIN_SERVICES = gql`
  query main_service_get {
    records(
      per_page: 10
      page: 1
      filter: { table: { exact: "main_services" } }
    ) {
      results {
        id
        slug: property(name: "slug")
        title: property(name: "title")
      }
    }
  }
`;

export default GET_MAIN_SERVICES;
