

  import { gql } from '@apollo/client';

const DELETE_INFO = gql`
mutation MyMutation($id: ID!) {
  model_delete(id: $id) {
    properties
    id
  }
}
`

export default DELETE_INFO;