import React, { useState, Fragment } from 'react'; 
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { 
    Dialog, 
    DialogTitle, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    Button, 
    Menu, 
    MenuItem, 
    ListItemIcon, 
    Typography 
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import * as Routes from "../../routes";


interface InstanceSettingsMenuProps {
    anchor: any;
    handleClose: any;
    instanceId: string;
    handleDelete: any;
    instanceSlug: string;
}


function InstanceSettingsMenu(props: InstanceSettingsMenuProps){
    const [openDeleteWarning, setOpenDeleteWarning] = useState(false);
    const classes = useStyles();
    const open = Boolean(props.anchor);

    const handleClickOpenDelete = () => {
      setOpenDeleteWarning(true);
    };

    const handleCloseDelete = () => {
      setOpenDeleteWarning(false);
    };

    const handleDeleteAndClose = () => {
        props.handleDelete(props.instanceId)
        handleCloseDelete()
    }
    
    return (
      <Fragment>
        <Menu
          className={classes.settings}
          open={open}
          anchorEl={props.anchor}
          onClose={props.handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem onClick={handleClickOpenDelete} >
            <ListItemIcon>
                <DeleteIcon />
            </ListItemIcon>
            <Typography>
                Delete Instance
            </Typography>
          </MenuItem>
          <MenuItem component={RouterLink} to={Routes.INSTANCE_EDIT_LINK(props.instanceSlug)}>
            <ListItemIcon>
                <EditIcon />
            </ListItemIcon>
            <Typography>
                Edit Instance
            </Typography>
          </MenuItem>
        </Menu>
        <Dialog open={openDeleteWarning} onClose={handleCloseDelete}>
            <DialogTitle>{"Delete Instance"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you wish to delete this instance? This action cannot be undone.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDelete} variant="text" color="primary">
                    Cancel
                </Button>
                <Button onClick={handleDeleteAndClose} variant="contained" color="secondary">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
      </Fragment>
    );
}

const useStyles = makeStyles((theme) => ({
  settings: {
      padding: "0.5rem",
  },
}));


export default InstanceSettingsMenu;