import React, { useState, useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/store";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Switch from "@material-ui/core/Switch";
import * as Routes from "../../routes";
import { logoutUser } from "../../store/currentUser/actions";

function Settings() {
  const [userAnchor, setUserAnchor] = useState<null | HTMLElement>(null);
  const [settingsAnchor, setSettingsAnchor] = useState<null | HTMLElement>(
    null
  );
  const classes = useStyles();
  const openUser = Boolean(userAnchor);
  const state = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const handleUserOpen = (event: React.MouseEvent<HTMLImageElement>) => {
    setUserAnchor(event.currentTarget);
  };

  const handleUserClose = () => {
    setUserAnchor(null);
  };

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  function stringToColor(string: any) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name: any) {
    return {
      style: {
        backgroundColor: stringToColor(name),
      },
      children: `${name[0]}`,
    };
  }
  const toggleDarkMode = useCallback(
    () => dispatch({ type: "TOGGLE_DARK_MODE" }),
    [dispatch]
  );

  return (
    <>
      <Avatar
        onClick={handleUserOpen}
        className={classes.avatar}
        {...stringAvatar(
          state.current_user.user ? state.current_user.user?.name : " "
        )}
      ></Avatar>
      <Menu open={openUser} anchorEl={userAnchor} onClose={handleUserClose}>
        <MenuItem component={RouterLink} to={Routes.ACCOUNT}>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <Typography>My Account</Typography>
        </MenuItem>
        {/*         <MenuItem>
          <Switch
            checked={state.site_mode.isDarkMode}
            onChange={toggleDarkMode}
            name="darkmode"
            color="primary"
          />
          <Typography>Dark Mode</Typography>
        </MenuItem> */}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <Typography>Logout</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  appbarButtons: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    cursor: "pointer",
    color: "white",
  },

  iconPaddingLeft: {
    paddingLeft: "12px",
  },

  settingsIcon: {
    "&.hover": {
      color: theme.palette.primary,
    },
  },
}));

interface BarLink {
  title: string;
  path: string;
}

export default Settings;
