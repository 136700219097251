import React from "react";
import { NavLink, useParams } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import InboxIcon from "@material-ui/icons/Inbox";
import WebIcon from "@material-ui/icons/Web";
import { makeStyles } from "@material-ui/core/styles";
import * as Routes from "../../../routes";
import CollectionsList from "./CollectionsList";
import FormsList from "./FormList";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ImageIcon from "@material-ui/icons/Image";
import ExtensionIcon from "@material-ui/icons/Extension";
import PostAddIcon from "@material-ui/icons/PostAdd";
import { useSelector } from "react-redux";

const TopLevelSubMenu = (props) => {
  const params = useParams();
  const classes = useStyles();
  const [pluginsOpen, setPluginsOpen] = React.useState(false);
  const [collectionsOpen, setCollectionsOpen] = React.useState(false);
  const [formsOpen, setFormsOpen] = React.useState(false);
  const state = useSelector((state) => state);
  return (
    <>
      <Typography variant="h6" className={classes.drawerTitle}>
        {state.current_site.site ? state.current_site.site.name : " "}
      </Typography>
      <List className="list__wrapper">
        <ListItem
          component={NavLink}
          exact
          to={Routes.SITE_LINK(params.site)}
          button
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>

        <ListItem onClick={() => setCollectionsOpen(!collectionsOpen)} button>
          <ListItemIcon>
            <PostAddIcon />
          </ListItemIcon>
          <ListItemText primary="Collections" />
          {collectionsOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse
          className={classes.collapsedListWrapper}
          in={collectionsOpen}
          timeout="auto"
          unmountOnExit
        >
          <CollectionsList />
        </Collapse>
        <Tooltip
          title="All the forms submitted by users such as job bookings and reviews"
          placement="right"
          enterDelay={2000}
        >
          <ListItem onClick={() => setFormsOpen(!formsOpen)} button>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Forms" />
            {formsOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        </Tooltip>
        <Collapse in={formsOpen} timeout="auto" unmountOnExit>
          <FormsList />
        </Collapse>

        <ListItem
          button
          className="navLink"
          component={NavLink}
          exact
          to={Routes.SINGLE_LINK(params.site)}
        >
          <ListItemIcon>
            <WebIcon />
          </ListItemIcon>
          <ListItemText primary="Singles" />
        </ListItem>

        <ListItem
          button
          className="navLink"
          component={NavLink}
          exact
          to={Routes.COMPONENT_LINK(params.site)}
        >
          <ListItemIcon>
            <WebIcon />
          </ListItemIcon>
          <ListItemText primary="Components" />
        </ListItem>

        <ListItem
          button
          component={NavLink}
          exact
          to={Routes.ASSETS_LINK(params.site)}
        >
          <ListItemIcon>
            <ImageIcon />
          </ListItemIcon>
          <ListItemText primary="Asset Manager" />
        </ListItem>

        <ListItem button onClick={() => setPluginsOpen(!pluginsOpen)}>
          <ListItemIcon>
            <ExtensionIcon />
          </ListItemIcon>
          <ListItemText primary="Plugins" />

          {pluginsOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={pluginsOpen} timeout="auto" unmountOnExit>
          <List disablePadding>
            <ListItem
              button
              className="nested"
              onClick={() => props.handleClick("areasSubMenu")}
            >
              Area Pages
            </ListItem>
            <ListItem
              button
              className="nested"
              component={NavLink}
              exact
              to={Routes.INFO_LINK(params.site)}
            >
              Information Pages
            </ListItem>
            <ListItem
              onClick={() => props.handleClick("crm")}
              button
              className="nested"
            >
              CRM
            </ListItem>

            {state.current_site.site?.build ? (
              <ListItem
                className="nested"
                onClick={() => props.handleClick("siteManager")}
                button
              >
                Site Manager
              </ListItem>
            ) : null}

            {!state.current_site.site?.build ? (
              <ListItem
                className="nested"
                onClick={() => props.handleClick("legacySiteManager")}
                button
              >
                Legacy Site Manager
              </ListItem>
            ) : null}
          </List>
        </Collapse>
      </List>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  loader: {
    width: "100%",
    height: "100%",
    display: "inline-block",
    alignItems: "left",
    justifyContent: "left",
  },
  drawerTitle: {
    padding: theme.spacing(2, 2, 0, 2),
    textTransform: "uppercase",
    letterSpacing: "0.05em",
    color: "gray",
    fontWeight: 400,
    fontSize: 14,
  },
  "@global": {
    ".nested": {
      paddingLeft: theme.spacing(4),
    },
    ".nested_icon": {
      marginRight: 10,
      marginBottom: 2,
      fontWeight: 500,
    },
    ".loading_marginLeft": {
      marginLeft: theme.spacing(4),
    },
    ".list__wrapper > .MuiListItem-root": {
      borderLeft: "6px solid",
      borderColor: theme.palette.info.light,
      "&.active": {
        backgroundColor: theme.palette.info.main,
        borderLeft: "6px solid",
        borderColor: theme.palette.primary.main,
      },
    },
  },
}));

export default TopLevelSubMenu;
