import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import RedirectForm from "../../../components/forms/redirect";
import { useSelector } from "react-redux";
import CREATE_REDIRECT from "../../../graphql/CREATE_REDIRECT";
import GET_REDIRECTS from "../../../graphql/GET_REDIRECTS";
import { useMutation, gql } from "@apollo/client";
import ErrorToast from "../../../components/layout/ErrorToast";
import * as Routes from "../../../routes";
import { RootState } from "../../../store/store";
import FormLayout from "../../../components/layout/FormLayout";

const CreateRedirect = () => {
  let history = useHistory();
  const params = useParams<SiteParams>();
  const state = useSelector((state: RootState) => state);
  const [redirect, setRedirect] = useState({
    physical_file_path: "",
    slug: "",
    redirect_to: "",
    redirect_code: "MOVED_PERMANENTLY",
  });

  interface SiteParams {
    site: string;
  }

  const [
    createRedirect,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(CREATE_REDIRECT, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const newRedirect = data?.admin_page_create;
      const existingRedirects: any = cache.readQuery({ query: GET_REDIRECTS });

      if (newRedirect && existingRedirects) {
        cache.writeQuery({
          query: GET_REDIRECTS,
          data: {
            admin_pages: {
              results: [...existingRedirects?.admin_pages.results, newRedirect],
            },
          },
        });
      }
    },
    onCompleted: (data) => {
      if (data) {
        history.push(Routes.REDIRECTS_LINK(params.site));
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "slug") {
      setRedirect({
        ...redirect,
        slug: event.target.value,
        physical_file_path: `views/pages/redirects/${event.target.value}.liquid`,
      });
    }
    if (event.target.name === "redirect_to") {
      setRedirect({
        ...redirect,
        redirect_to: event.target.value,
      });
    }
    if (event.target.name === "physical_file_path") {
      setRedirect({
        ...redirect,
        physical_file_path: event.target.value,
      });
    }
  };

  const handleCodeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRedirect({
      ...redirect,
      redirect_code: event.target.value as string,
    });
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    createRedirect({
      variables: {
        physical_file_path: redirect.physical_file_path,
        slug: redirect.slug,
        redirect_to: redirect.redirect_to,
        redirect_code: redirect.redirect_code,
      },
    });
  };

  return (
    <FormLayout
      loading={mutationLoading}
      formHeading="Create Redirect"
      backLink={Routes.REDIRECTS_LINK(params.site)}
      size="md"
    >
      {mutationError ? (
        <ErrorToast open={true} errorMessage={mutationError.message} />
      ) : null}
      <RedirectForm
        handleChange={handleChange}
        handleCodeChange={handleCodeChange}
        handleSubmit={handleSubmit}
        isFetching={false}
        redirect={redirect}
        isSubmitting={mutationLoading}
        baseURL={state.current_site.site?.url}
      />
    </FormLayout>
  );
};

export default CreateRedirect;
