import React, { useState } from 'react';
import { PropertyValue } from '../../pages/site/entities/create';
import { Color, ColorPicker, createColor } from 'material-ui-color';
import { Grid } from '@material-ui/core';

const ColourInput = (props: ColourInputProps) => {
    
    const handleSetColour = (newValue: Color) => {
        props.handleChange(`#${newValue.hex}`, props.index);
    };

    return(
        <Grid item xs={12} lg={6}>
        <p className="entity__inputHeading">{props.propertyValue.display_name}</p>
            <ColorPicker value={props.propertyValue.value} onChange={handleSetColour} disableAlpha/>
        </Grid>
    );
}

interface ColourInputProps {
    index: number
    propertyValue: PropertyValue
    handleChange: (value: string, index: number) => void
}


export default ColourInput;