import React from 'react';
import { TextField, Button, CircularProgress, Collapse, Typography, IconButton, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AceEditor from '../editor/AceEditor';


function PartialForm(props: PartialFormProps){
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
     const toggleExpandClick = () => {
      setExpanded(!expanded);
    };
    
    return (
<>
      <div className="form__columns">
          {props.isFetching ? (
            <Skeleton variant="rect" width={"100%"} height={56} />
          ) : (
            <TextField className="marginRight"
              variant="outlined"
              type="text"
              name="name"
              onChange={props.handleMetadataChange}
              value={props.metadata.name}
              label="Name"
              fullWidth
            />
          )}

 
          {props.isFetching ? (
            <Skeleton variant="rect" width={"100%"} height={56} />
          ) : (
             <Autocomplete 
              value={props.metadata.type}
              onChange={props.handleSelectChange}
              onInputChange={props.handleSelectChange}
              options={typeOptions}
              getOptionLabel={(option: string) => option}
              renderInput={(params: any) => <TextField {...params} label="Type" name="type" variant="outlined" />}
              fullWidth
            />
          )}
</div>
       
          {props.isFetching ? (
            <Skeleton variant="rect" width={"100%"} height={56} />
          ) : (
            <AceEditor 
              content={props.partial.body}
              handleChange={props.handleBodyChange}
            />
          )}

        <div className="advanced__headingWrap">
            <Typography component="h2" variant="h6" className="marginRight">
              Advanced Settings
            </Typography>
            <IconButton className={expanded ? 'expandOpen' : 'expand'} onClick={toggleExpandClick}>
              <ExpandMoreIcon />
            </IconButton>
        </div>
         <Collapse in={expanded} timeout="auto" unmountOnExit className="advanced__wrap">
           
            {props.isFetching ? (
                <Skeleton variant="rect" width={"100%"} height={56} />
            ) : (
                <TextField className="form__input"
                variant="outlined"
                type="text"
                name="path"
                onChange={props.handleChange}
                value={props.partial.path}
                label="Path"
                fullWidth
                />
            )}

       
            {props.isFetching ? (
                <Skeleton variant="rect" width={"100%"} height={56} />
            ) : (
                <TextField className="form__input"
                fullWidth
                variant="outlined"
                type="text"
                name="physical_file_path"
                onChange={props.handleChange}
                value={props.partial.physical_file_path}
                label="Physical File Path"
                />
            )}

        </Collapse>
        <div className={classes.formContainer}>
          {props.isFetching ? (
            <Skeleton variant="rect" width={"100%"} height={56} />
          ) : (
            <Button className="form_button" color="primary" variant="contained" onClick={props.handleSubmit} disabled={props.isSubmitting}>
              {props.isSubmitting ? <CircularProgress /> : 'Submit' }
            </Button>
          )}
        </div>
      </>
    );
}

const typeOptions = [
  "section",
  "layout",
  "template",
  "menu",
  "form",
  "content"
]


interface PartialFormProps {
  partial: any
  metadata: any
  handleSubmit: any
  handleChange: any
  handleMetadataChange: any
  handleBodyChange: any
  handleSelectChange: any
  isFetching: boolean
  isSubmitting: boolean
}

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'center',
    maxWidth: 450,
    width: '100%',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px 0',
  },
  advancedHeader: {
    display: "flex",
    '& h2': {
      flexGrow: 1,
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

export default PartialForm;