export const handleChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  setState: React.Dispatch<React.SetStateAction<any>>,
  state: any
) => {
  if (event.target.name === "slug") {
    let slug = event.target.value;

    // Convert to lowercase
    slug = slug.toLowerCase();
    // Replace spaces with hyphens
    slug = slug.replace(/\s+/g, "-");
    // Remove invalid characters (only keep numbers, letters, '/', and '-')
    slug = slug.replace(/[^a-z0-9/-]/g, "");
    // Ensure no consecutive slashes
    slug = slug.replace(/[/]+/g, "/");
    // Ensure no consecutive hyphens
    slug = slug.replace(/-+/g, "-");
    // Remove invalid sequences `/-` or `-/`
    slug = slug.replace(/\/-|-\/+/g, "/");
    // Remove leading hyphens or slashes
    slug = slug.replace(/^[-\/]+/, "");

    setState({
      ...state,
      slug: slug,
    });
  } else {
    setState({
      ...state,
      [event.target.name]: event.target.value as string,
    });
  }
};

export const handleBlur = (
  event: React.FocusEvent<HTMLInputElement>,
  setState: React.Dispatch<React.SetStateAction<any>>,
  state: any
) => {
  if (event.target.name === "slug") {
    let slug = event.target.value;

    // Remove trailing hyphen or slash
    slug = slug.replace(/[-\/]$/, "");

    setState({
      ...state,
      slug: slug,
    });
  }
};
