import { gql } from '@apollo/client';

const CREATE_REGION = gql`
  mutation region_create($properties: [CustomAttributeInputType]!) {
    model_create(
      model: {
        model_schema_name: "modules/areas/region"
        properties: $properties
      }
    ) {
      id
      created_at
      updated_at
      properties
    }
  }
`;

export default CREATE_REGION;

