import { gql } from '@apollo/client';

const GET_LAYOUTS = gql`
    query get_admin_layouts($id: ID){
        admin_liquid_layouts(
            page: 1, 
            per_page: 2000
            filter: { 
                id: { value: $id }
            }
        ){
            results {
                id
                created_at
                updated_at
                format
                metadata
                body
                path
                physical_file_path
            }
        }
    }
  `

export default GET_LAYOUTS;