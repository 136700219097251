import React, {useState} from 'react';
import AceEditor from "react-ace";
import ImageIcon from '@material-ui/icons/Image';
import { IconButton } from '@material-ui/core';
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import  Alert from '@material-ui/lab/Alert';
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-tomorrow";
import ImageSelectModal from "../assets/ImageSelectModal"
import { makeStyles } from "@material-ui/core/styles";

function Editor(props: EditorProps) {
        const [openModal, setOpenModal] = useState(false)
        const [openToast, setOpenToast] = useState(false)
    
        const handleModalClose = () => {
            setOpenModal(false)
        }
    
        const setImage = (imagePath: string) => {
            navigator.clipboard.writeText(imagePath)
            setOpenToast(true)
        }
    
        const handleToastClose = (event?: React.SyntheticEvent, reason?: string) => {
            if (reason === 'clickaway') {
                return;
            }
            setOpenToast(false);
        };
    
       const position: SnackbarOrigin = { vertical: 'top',  horizontal: 'center' }
       const classes = useStyles();
    
    return(
        <>
        <div>

   
          <IconButton onClick={() => setOpenModal(true)} >
                <ImageIcon />
            </IconButton>
            </div>
            { openModal ? 
                <ImageSelectModal open={openModal} handleClose={handleModalClose} setImagePath={setImage} /> 
                : 
                null
            }
            { openToast ? (
                    <Snackbar
                        anchorOrigin={position}
                        open={openToast}
                        onClose={handleToastClose}
                        key={position.vertical + position.horizontal}
                    >
                        <Alert severity="info" onClose={handleToastClose} >
                            Copied Image Url to clipboard
                        </Alert>
                    </Snackbar>
                )
                : 
                null
            }
                    <AceEditor
            mode="html"
            theme="tomorrow"
            onChange={props.handleChange}
            name="UNIQUE_ID_OF_DIV"
            value={props.content}
            editorProps={{ $blockScrolling: true }}
            style={{width:"100%"}}
        />
        </>

    )
}


interface EditorProps {
    content: string
    handleChange: any
}

const useStyles = makeStyles((theme) => ({
    image_button: {

      },

}));

export default Editor;