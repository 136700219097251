export const INSTANCE_PORTAL = "/";
export const INSTANCE_CREATE = "/register-instance";
export const INSTANCE_EDIT = "/edit-instance/:slug";
export const ACCOUNT = "/account";
export const USERS = "/users";
export const USERS_CREATE = "/users/create";
export const USERS_EDIT = "/users/edit/:id";
export const ORGANISATION_CREATE = "/organisation/create";
export const ORGANISATION_EDIT = "/organisation/edit/:id";
export const ORGANISATIONS = "/organisation/";
export const SITE = "/site/:site";
export const SITE_PAGES = "/site/:site/pages";
export const PAGE_CREATE = "/site/:site/pages/create";
export const PAGE_EDIT = "/site/:site/pages/edit/:id";
export const SITE_LOGS = "/site/:site/logs";
export const SITE_ASSETS = "/site/:site/assets";
export const SITE_LAYOUT = "/site/:site/layouts";
export const LAYOUT_CREATE = "/site/:site/layouts/create";
export const LAYOUT_EDIT = "/site/:site/layouts/edit/:id";
export const SITE_PARTIALS = "/site/:site/partials";
export const PARTIALS_CREATE = "/site/:site/partials/create";
export const PARTIALS_EDIT = "/site/:site/partials/edit/:id";
export const SITE_CONSTANTS = "/site/:site/constants";
export const CONSTANT_CREATE = "/site/:site/constants/create";
export const CONSTANT_EDIT = "/site/:site/constants/edit/:id";
export const SITE_REDIRECTS = "/site/:site/redirects";
export const REDIRECT_CREATE = "/site/:site/redirects/create";
export const REDIRECT_EDIT = "/site/:site/redirects/edit/:id";
export const SITE_USERS = "/site/:site/users";
export const SITE_WEBFORMS = "/site/:site/webforms";
export const SITE_WEBFORM_VIEW = "/site/:site/webforms/:id";
export const SITE_COLLECTIONS = "/site/:site/collection";
export const SITE_COLLECTION_VIEW = "/site/:site/collection/:slug";
export const COLLECTION_CREATE = "/site/:site/collection/create";
export const COLLECTION_EDIT = "/site/:site/collection/edit/:collection";
export const ENTITY_CREATE = "/site/:site/collection/:collection/entity/create";
export const ENTITY_EDIT = "/site/:site/collection/:collection/entity/edit/:id";
export const SITE_FORMS = "/site/:site/form";
export const SITE_FORM_VIEW = "/site/:site/form/:slug";
export const FORM_CREATE = "/site/:site/form/create";
export const FORM_EDIT = "/site/:site/form/edit/:slug";
export const SITE_SINGLES = "/site/:site/single";
export const SINGLE_CREATE = "/site/:site/single/create";
export const SINGLE_EDIT_FIELDS = "/site/:site/single/:slug/fields";
export const SINGLE_EDIT_ITEM = "/site/:site/single/:slug/item";
export const SITE_COMPONENTS = "/site/:site/components";
export const COMPONENT_CREATE = "/site/:site/components/create";
export const COMPONENT_EDIT_ITEM = "/site/:site/components/:slug/item";
export const INFO = "/site/:site/plugins/info";
export const INFO_CREATE = "/site/:site/plugins/info/create";
export const INFO_EDIT = "/site/:site/plugins/info/edit/:id";
export const CITY = "/site/:site/plugins/areas/city";
export const CITY_CREATE = "/site/:site/plugins/areas/city/create";
export const CITY_EDIT = "/site/:site/plugins/areas/city/edit/:id";
export const CITY_CONTENT = "/site/:site/plugins/areas/city/:cityID/content";
export const CITY_CONTENT_CREATE =
  "/site/:site/plugins/areas/city/:cityID/content/create";
export const CITY_CONTENT_EDIT =
  "/site/:site/plugins/areas/city/:cityID/content/edit/:id";
export const REGION = "/site/:site/plugins/areas/region";
export const REGION_CREATE = "/site/:site/plugins/areas/region/create";
export const REGION_EDIT = "/site/:site/plugins/areas/region/edit/:id";
export const REGION_CONTENT =
  "/site/:site/plugins/areas/region/:regionID/content";
export const REGION_CONTENT_CREATE =
  "/site/:site/plugins/areas/region/:regionID/content/create";
export const REGION_CONTENT_EDIT =
  "/site/:site/plugins/areas/region/:regionID/content/edit/:id";
export const SUBURB = "/site/:site/plugins/areas/suburb";
export const SUBURB_CREATE = "/site/:site/plugins/areas/suburb/create";
export const SUBURB_EDIT = "/site/:site/plugins/areas/suburb/edit/:id";
export const SUBURB_CONTENT =
  "/site/:site/plugins/areas/suburb/:suburbID/content";
export const SUBURB_CONTENT_CREATE =
  "/site/:site/plugins/areas/suburb/:suburbID/content/create";
export const SUBURB_CONTENT_EDIT =
  "/site/:site/plugins/areas/suburb/:suburbID/content/edit/:id";
export const SUBURB_DEFAULT =
  "/site/:site/plugins/areas/suburb/default/content";
export const SUBURB_DEFAULT_CONTENT_CREATE =
  "/site/:site/plugins/areas/suburb/default/content/create";
export const SUBURB_DEFAULT_CONTENT_EDIT =
  "/site/:site/plugins/areas/suburb/default/content/edit/:id";

export const INSTANCE_EDIT_LINK = (slug: string) => `/edit-instance/${slug}`;
export const USER_EDIT_LINK = (id: string) => `/users/edit/${id}`;
export const ORGANISATION_EDIT_LINK = (id: string) =>
  `/organisation/edit/${id}`;
export const SITE_LINK = (site: string) => `/site/${site}`;
export const PAGES_LINK = (site: string) => `/site/${site}/pages`;
export const PAGE_CREATE_LINK = (site: string) => `/site/${site}/pages/create`;
export const PAGE_EDIT_LINK = (site: string, id: string) =>
  `/site/${site}/pages/edit/${id}`;
export const ASSETS_LINK = (site: string) => `/site/${site}/assets`;
export const CONSTANTS_LINK = (site: string) => `/site/${site}/constants`;
export const CONSTANT_CREATE_LINK = (site: string) =>
  `/site/${site}/constants/create`;
export const CONSTANT_EDIT_LINK = (site: string, id: string) =>
  `/site/${site}/constants/edit/${id}`;
export const REDIRECTS_LINK = (site: string) => `/site/${site}/redirects`;
export const REDIRECT_CREATE_LINK = (site: string) =>
  `/site/${site}/redirects/create`;
export const REDIRECT_EDIT_LINK = (site: string, id: string) =>
  `/site/${site}/redirects/edit/${id}`;
export const LAYOUTS_LINK = (site: string) => `/site/${site}/layouts`;
export const LAYOUTS_CREATE_LINK = (site: string) =>
  `/site/${site}/layouts/create`;
export const LAYOUTS_EDIT_LINK = (site: string, id: string) =>
  `/site/${site}/layouts/edit/${id}`;
export const PARTIALS_LINK = (site: string) => `/site/${site}/partials`;
export const PARTIALS_CREATE_LINK = (site: string) =>
  `/site/${site}/partials/create`;
export const PARTIALS_EDIT_LINK = (site: string, id: string) =>
  `/site/${site}/partials/edit/${id}`;
export const SITE_USERS_LINK = (site: string) => `/site/${site}/users`;
export const SITE_LOGS_LINK = (site: string) => `/site/${site}/logs`;
export const WEBFORMS_LINK = (site: string) => `/site/${site}/webforms`;
export const WEBFORMS_VIEW_LINK = (site: string, id: string) =>
  `/site/${site}/webforms/${id}`;
export const COLLECTION_LINK = (site: string) => `/site/${site}/collection`;
export const COLLECTION_VIEW_LINK = (site: string, slug: string) =>
  `/site/${site}/collection/${slug}`;
export const COLLECTION_CREATE_LINK = (site: string) =>
  `/site/${site}/collection/create`;
export const COLLECTION_EDIT_LINK = (site: string, collection: string) =>
  `/site/${site}/collection/edit/${collection}`;
export const ENTITY_CREATE_LINK = (site: string, collection: string) =>
  `/site/${site}/collection/${collection}/entity/create`;
export const ENTITY_EDIT_LINK = (
  site: string,
  collection: string,
  id: string
) => `/site/${site}/collection/${collection}/entity/edit/${id}`;
export const FORMS_LINK = (site: string) => `/site/${site}/form`;
export const FORMS_CREATE_LINK = (site: string) => `/site/${site}/form/create`;
export const FORMS_EDIT_LINK = (site: string, slug: string) =>
  `/site/${site}/form/edit/${slug}`;
export const FORMS_VIEW_LINK = (site: string, slug: string) =>
  `/site/${site}/form/${slug}`;
export const SINGLE_LINK = (site: string) => `/site/${site}/single`;
export const SINGLE_CREATE_LINK = (site: string) =>
  `/site/${site}/single/create`;
export const SINGLE_EDIT_FIELDS_LINK = (site: string, slug: string) =>
  `/site/${site}/single/${slug}/fields`;
export const SINGLE_EDIT_ITEM_LINK = (site: string, slug: string) =>
  `/site/${site}/single/${slug}/item`;
export const COMPONENT_LINK = (site: string) => `/site/${site}/components`;
export const COMPONENT_CREATE_LINK = (site: string) =>
  `/site/${site}/components/create`;
export const COMPONENT_EDIT_ITEM_LINK = (site: string, slug: string) =>
  `/site/${site}/components/${slug}/item`;
export const INFO_LINK = (site: string) => `/site/${site}/plugins/info`;
export const INFO_CREATE_LINK = (site: string) =>
  `/site/${site}/plugins/info/create`;
export const INFO_EDIT_LINK = (site: string, id: string) =>
  `/site/${site}/plugins/info/edit/${id}`;
export const CITY_LINK = (site: string) => `/site/${site}/plugins/areas/city`;
export const CITY_CREATE_LINK = (site: string) =>
  `/site/${site}/plugins/areas/city/create`;
export const CITY_EDIT_LINK = (site: string, id: string) =>
  `/site/${site}/plugins/areas/city/edit/${id}`;
export const CITY_CONTENT_LINK = (site: string, cityID: string) =>
  `/site/${site}/plugins/areas/city/${cityID}/content`;
export const CITY_CONTENT_CREATE_LINK = (site: string, cityID: string) =>
  `/site/${site}/plugins/areas/city/${cityID}/content/create`;
export const CITY_CONTENT_EDIT_LINK = (
  site: string,
  cityID: string,
  id: string
) => `/site/${site}/plugins/areas/city/${cityID}/content/edit/${id}`;
export const REGION_LINK = (site: string) =>
  `/site/${site}/plugins/areas/region`;
export const REGION_CREATE_LINK = (site: string) =>
  `/site/${site}/plugins/areas/region/create`;
export const REGION_EDIT_LINK = (site: string, id: string) =>
  `/site/${site}/plugins/areas/region/edit/${id}`;
export const REGION_CONTENT_LINK = (site: string, regionID: string) =>
  `/site/${site}/plugins/areas/region/${regionID}/content`;
export const REGION_CONTENT_CREATE_LINK = (site: string, regionID: string) =>
  `/site/${site}/plugins/areas/region/${regionID}/content/create`;
export const REGION_CONTENT_EDIT_LINK = (
  site: string,
  regionID: string,
  id: string
) => `/site/${site}/plugins/areas/region/${regionID}/content/edit/${id}`;
export const SUBURB_LINK = (site: string) =>
  `/site/${site}/plugins/areas/suburb`;
export const SUBURB_CREATE_LINK = (site: string) =>
  `/site/${site}/plugins/areas/suburb/create`;
export const SUBURB_EDIT_LINK = (site: string, id: string) =>
  `/site/${site}/plugins/areas/suburb/edit/${id}`;
export const SUBURB_CONTENT_LINK = (site: string, suburbID: string) =>
  `/site/${site}/plugins/areas/suburb/${suburbID}/content`;
export const SUBURB_CONTENT_CREATE_LINK = (site: string, suburbID: string) =>
  `/site/${site}/plugins/areas/suburb/${suburbID}/content/create`;
export const SUBURB_CONTENT_EDIT_LINK = (
  site: string,
  suburbID: string,
  id: string
) => `/site/${site}/plugins/areas/suburb/${suburbID}/content/edit/${id}`;
export const SUBURB_DEFAULT_CONTENT_LINK = (site: string) =>
  `/site/${site}/plugins/areas/suburb/default/content`;
export const SUBURB_DEFAULT_CONTENT_CREATE_LINK = (site: string) =>
  `/site/${site}/plugins/areas/suburb/default/content/create`;
export const SUBURB_DEFAULT_CONTENT_EDIT_LINK = (site: string, id: string) =>
  `/site/${site}/plugins/areas/suburb/default/content/edit/${id}`;
