import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import PartialForm from "../../../components/forms/partial";
import { useSelector, useDispatch } from "react-redux";
import CREATE_PARTIAL from "../../../graphql/CREATE_PARTIAL";
import GET_PARTIALS from "../../../graphql/GET_PARTIALS";
import { useMutation } from "@apollo/client";
import Loader from "../../../components/layout/Loader";
import ErrorToast from "../../../components/layout/ErrorToast";
import * as Routes from "../../../routes";
import { RootState } from "../../../store/store";
import slugify from "slugify";
import FormLayout from "../../../components/layout/FormLayout";

const CreatePartial = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<SiteParams>();
  const state = useSelector((state: RootState) => state);
  const [partial, setPartial] = useState({
    path: "",
    body: "",
    physical_file_path: "",
  });
  const [metadata, setMetadata] = useState({
    name: "",
    type: "page",
  });

  const [
    createPartial,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(CREATE_PARTIAL, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const newPartial = data?.admin_liquid_partial_create;
      const existingPartials: any = cache.readQuery({ query: GET_PARTIALS });

      if (newPartial && existingPartials) {
        cache.writeQuery({
          query: GET_PARTIALS,
          data: {
            admin_liquid_partials: {
              results: [
                ...existingPartials?.admin_liquid_partials.results,
                newPartial,
              ],
            },
          },
        });
      }
    },
    onCompleted: (data) => {
      if (data) {
        history.push(Routes.PARTIALS_LINK(params.site));
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPartial({
      ...partial,
      [event.target.name]: event.target.value as string,
    });
  };

  const handleMetadataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const type = slugify(metadata.type).toLowerCase();
    const name = slugify(event.target.value).toLowerCase();
    if (event.target.name === "name") {
      setPartial({
        ...partial,
        path: `${type}/${name}`,
        physical_file_path: `views/partials/${type}/${name}.liquid`,
      });
      setMetadata({
        ...metadata,
        name: event.target.value as string,
      });
    }
  };

  const handleBodyChange = (content: string) => {
    setPartial({
      ...partial,
      body: content,
    });
  };

  const handleSelectChange = (event: any, newValue: string) => {
    const type = slugify(newValue).toLowerCase();
    const name = slugify(metadata.name).toLowerCase();
    setPartial({
      ...partial,
      path: `${type}/${name}`,
      physical_file_path: `views/partials/${type}/${name}.liquid`,
    });
    setMetadata({
      ...metadata,
      type: type,
    });
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    createPartial({
      variables: {
        physical_file_path: partial.physical_file_path,
        path: partial.path,
        body: partial.body,
        metadata: metadata,
      },
    });
  };

  return (
    <FormLayout
      loading={mutationLoading}
      formHeading="Create Partial"
      backLink={Routes.PARTIALS_LINK(params.site)}
      size="lg"
    >
      {mutationError ? (
        <ErrorToast open={true} errorMessage={mutationError.message} />
      ) : null}
      <PartialForm
        handleChange={handleChange}
        handleMetadataChange={handleMetadataChange}
        handleSelectChange={handleSelectChange}
        handleBodyChange={handleBodyChange}
        handleSubmit={handleSubmit}
        isFetching={false}
        partial={partial}
        metadata={metadata}
        isSubmitting={mutationLoading}
      />
    </FormLayout>
  );
};

interface SiteParams {
  site: string;
}

export default CreatePartial;
