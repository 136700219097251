import { Link as RouterLink } from "react-router-dom";
import { IconButton, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";

import * as Routes from "../../routes";

const PagesActionCell = (props) => {
  return (
    <div className="action_cell">
      <Tooltip title="View collection">
        <IconButton
          component={RouterLink}
          to={Routes.COLLECTION_VIEW_LINK(props.site, props.value)}
        >
          <VisibilityOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Edit collection">
        <IconButton
          component={RouterLink}
          to={Routes.COLLECTION_EDIT_LINK(props.site, props.value)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default PagesActionCell;
