import { gql } from '@apollo/client';

const GET_PAGES = gql`
    query get_admin_pages($id: ID){
        admin_pages(
            per_page: 1000, 
            page: 1, 
            filter: {
                id: { value: $id } 
                metadata: { 
                    attribute: { key: "type", value: "redirect"}, 
                    exclude: true
                }
            }
        ){
            results {
                id
                slug
                created_at
                updated_at
                metadata
                searchable
                static_cache_expire
                request_method
                content
                layout
                physical_file_path
            }
        }
    }
  `

export default GET_PAGES;