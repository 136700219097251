import React, { useRef, useState } from "react";
import { Grid, IconButton } from "@material-ui/core";
import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import { Editor } from "@tinymce/tinymce-react";
import ImageIcon from "@material-ui/icons/Image";
import Alert from "@material-ui/lab/Alert";
import ImageSelectModal from "../assets/ImageSelectModal";

const RichTextAreaInput = (props: RichTextAreaInputProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const editorRef = useRef(null);

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const setImage = (imagePath: string) => {
    navigator.clipboard.writeText(imagePath);
    setOpenToast(true);
  };

  const handleToastClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenToast(false);
  };

  const handleEditorChange = () => {
    if (editorRef.current) {
      // @ts-expect-error
      props.handleChange(editorRef.current.getContent(), props.index);
    }
  };

  const position: SnackbarOrigin = { vertical: "top", horizontal: "center" };

  return (
    <Grid item sm={12}>
      <IconButton onClick={() => setOpenModal(true)}>
        <ImageIcon />
      </IconButton>
      {openModal ? (
        <ImageSelectModal
          open={openModal}
          handleClose={handleModalClose}
          setImagePath={setImage}
        />
      ) : null}
      {openToast ? (
        <Snackbar
          anchorOrigin={position}
          open={openToast}
          onClose={handleToastClose}
          key={position.vertical + position.horizontal}
        >
          <Alert severity="info" onClose={handleToastClose}>
            Copied Image Url to clipboard
          </Alert>
        </Snackbar>
      ) : null}
      <Editor
        // @ts-expect-error
        onInit={(evt, editor) => (editorRef.current = editor)}
        value={props.content}
        apiKey="2dqjlzhfais6i4d8ltfd45j4viaj62fbalr7re4gd73lzci0"
        init={{
          height: 700,
          menubar: false,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "undo redo | formatselect | " +
            "bold italic |  bullist numlist | blockquote code | link image | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
        onEditorChange={handleEditorChange}
      />
    </Grid>
  );
};

interface RichTextAreaInputProps {
  content: string;
  handleChange: (value: string) => void;
}

export default RichTextAreaInput;
