import {
    FETCHED,
    FETCHING,
    CREATED,
    CREATING,
    UPDATING,
    UPDATED,
    DELETED,
    DELETING,
    INVALIDATE,
    RESET_CREATE_SUCCESS,
    RESET_DELETE_SUCCESS,
    RESET_UPDATE_SUCCESS,
    OrganisationListActions,
    OrganisationListState
} from "./types"

const initialState: OrganisationListState = {
    organisations: [],
    fetching: false,
    fetchSuccess: false,
    creating: false,
    createSuccess: false,
    updating: false,
    updateSuccess: false,
    deleting: false,
    deleteSuccess: false,
    didInvalidate: false,
    fetchErrors: []
}

function organisationListReducer(state = initialState, action: OrganisationListActions): OrganisationListState {
    switch (action.type) {
        case FETCHING:{
            return {
                ...state,
                fetching: true,
                didInvalidate: false
            }
        }
        case FETCHED:{
            return {
                ...state,
                fetching: false,
                fetchSuccess: true,
                organisations: action.payload
            }
        }
        case CREATING:{
            return {
                ...state,
                creating: true
            }
        }
        case CREATED:{
            return {
                ...state,
                creating: false,
                createSuccess: true,
                organisations: state.organisations.concat(action.payload)
            }
        }
        case RESET_CREATE_SUCCESS: {
            return {
                ...state,
                createSuccess: false
            }
        }
        case UPDATING:{
            return {
                ...state,
                updating: false
            }
        }
        case UPDATED:{
            const updatedOrganisations = state.organisations.map(item => (item.id === action.payload.id ? action.payload : item))
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                organisations: updatedOrganisations
            }
        }
        case RESET_UPDATE_SUCCESS: {
            return {
                ...state,
                updateSuccess: false
            }
        }
        case DELETING:{
            return {
                ...state,
                deleting: false,
            }
        }
        case DELETED:{
            const filteredSites = state.organisations.filter(item => {
                if(item.id !== action.payload.id){
                    return item
                }
            })
            return {
                ...state,
                deleting: false,
                deleteSuccess: true,
                organisations: filteredSites
            }
        }
        case RESET_DELETE_SUCCESS: {
            return {
                ...state,
                deleteSuccess: false
            }
        }
        case INVALIDATE:{
            return {
                ...state,
                didInvalidate: true,
                organisations: []
            }
        }
        default:
            return state
    }
};

export default organisationListReducer;