import { gql } from '@apollo/client';

const GET_CONSTANTS = gql`
    query get_contants($name: String){
        constants(page: 1, per_page: 2000, filter: {name: {value: $name}}) {
            results {
                id: name
                name
                value
                updated_at
                created_at
            }
        }
    }
`

export default GET_CONSTANTS;