
import React, { useState, Fragment, useMemo } from 'react'; 
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardMedia, CardActions, IconButton, Typography, CardActionArea } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ImageCardMenu from "./ImageCardMenu";


function ImageCard(props: ImageCardProps){
    const [settingsAnchor, setSettingsAnchor] = useState<null | HTMLElement>(null);;
    
    const classes = useStyles();

    const handleSettingsOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setSettingsAnchor(event.currentTarget);
    };

    const handleSettingsClose = () => {
      setSettingsAnchor(null);
    };

    const deleteImage = (filePath: string) => {
        console.log(filePath)
    }

    const name = useMemo(() => {
      return props.imageName.split('/').pop()
    }, [props.imageName])
    
    const cardClasses = (): string => {
      if(props.selectedImageUrl && props.selectedImageUrl === props.imageUrl){
        return classes.card + " " + classes.active
      }
      return classes.card
    } 
 
    return (
      <Fragment>
        <CardActionArea >
        <Card variant="outlined" className={cardClasses()} onClick={() => props.setSelectedImage && props.setSelectedImage(props.imageUrl)}>
          <CardMedia className={classes.media} image={props.imageUrl} title={props.imageName} />
          <CardActions className={classes.actions}>
              <Typography variant="subtitle1" component="h2" className={classes.heading}>
                {truncate(name as string)}
              </Typography>
              <IconButton onClick={handleSettingsOpen}>
                <MoreVertIcon />
              </IconButton>
          </CardActions>
        </Card>
        <ImageCardMenu imagePath={props.imageFilePath} anchor={settingsAnchor} handleClose={handleSettingsClose} handleDelete={deleteImage} />
        </CardActionArea>
      </Fragment>
    );
}

const useStyles = makeStyles((theme) => ({
  card:{
    width: '100%',
    display: "flex",
    flexDirection: "column",
  },
  active:{
    border: 'solid 2px',
    borderColor: theme.palette.primary.main
  },
  heading:{
      overflow: "hidden",
  },
  media: {
    height: '200px',
    objectFit: 'cover'
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '60px',
    overflow: 'hidden',
    padding: "0 1rem",
  },
}));

interface ImageCardProps {
    imageUrl: string;
    imageFilePath: string;
    imageName: string;
    setSelectedImage?: (url: string) =>  void,
    selectedImageUrl?: string
}

function truncate(str:string) {
  return str.length > 19 ? str.substring(0, 19) + "..." : str;
}


export default ImageCard;