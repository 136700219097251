import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container } from "@material-ui/core";
import {
  createOrganisation,
  unsetOrganisationCreateSuccessFlag,
} from "../../store/organisationList/actions";
import { fetchOrganisationListIfNeeded } from "../../store/organisationList/actions";
import * as Routes from "../../routes";
import { RootState } from "../../store/store";
import OrganisationForm from "../../components/forms/organisation";
import Appbar from "../../components/layout/InstanceAppbar";
import FormLayout from "../../components/layout/FormLayout";

function Create() {
  const dispatch = useDispatch();
  let history = useHistory();
  const state = useSelector((state: RootState) => state);

  const [organisation, setOrganisation] = useState({
    name: "",
    slug: "",
    hasCRM: false,
    hasDashboard: false,
  });

  useEffect(() => {
    if (state.organisation_list.createSuccess) {
      dispatch(unsetOrganisationCreateSuccessFlag());
      history.push(Routes.ORGANISATIONS);
    }
  }, [state.organisation_list.createSuccess]);

  useEffect(() => {
    dispatch(fetchOrganisationListIfNeeded());
  }, [state.current_user]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrganisation({
      ...organisation,
      [event.target.name]: event.target.value,
    });
  };
  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrganisation({
      ...organisation,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    let valid = validateForm();

    if (valid) {
      let reqBody: any = {};
      reqBody["organisation"] = organisation;
      dispatch(createOrganisation(reqBody));
    }
  };

  const validateForm = () => {
    return true;
  };

  return (
    <>
      <Appbar />
      <Container className="container">
        <FormLayout
          loading={null}
          formHeading="Create Organisation"
          backLink={Routes.INSTANCE_PORTAL}
          size="md"
        >
          <OrganisationForm
            organisation={organisation}
            handleChange={handleChange}
            handleToggleChange={handleToggleChange}
            handleSubmit={handleSubmit}
            isFetching={false}
            isSubmitting={state.organisation_list.creating}
          />
        </FormLayout>
      </Container>
    </>
  );
}

export default Create;
