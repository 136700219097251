import { gql } from '@apollo/client';

const CREATE_SUBURB = gql`
  mutation city_create($properties: [CustomAttributeInputType]!) {
    model_create(
      model: {
        model_schema_name: "modules/areas/suburb"
        properties: $properties
      }
    ) {
      id
      created_at
      updated_at
      properties
    }
  }
`;

export default CREATE_SUBURB;

