
import { useParams } from 'react-router';
import * as Routes from '../../../routes';
import FormLayout from '../../../components/layout/FormLayout';

const EditForm = () => {

    const params = useParams<SiteParams>()
  

    interface SiteParams {
      site: string
    }

    return(
<FormLayout loading={null} formHeading="Edit Form" backLink={Routes.FORMS_LINK(params.site)} size="md">

</FormLayout>
    );
}



export default EditForm;