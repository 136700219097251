import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import slugify from "slugify";
import CREATE_SINGLE from "../../../graphql/CREATE_SINLGE_TABLE_WITH_RECORD";
import GET_SINGLE_CONTENT_TYPES from "../../../graphql/GET_SINGLE_CONTENT_TYPES";
import GET_PUBLIC_READ_TABLES from "../../../graphql/GET_PUBLIC_READ_TABLES";
import * as Routes from "../../../routes";
import { RootState } from "../../../store/store";
import CollectionsFormView from "../../../components/collections/CollectionsFormView";
import ErrorToast from "../../../components/layout/ErrorToast";

import {
  CollectionMetadata,
  CollectionProperty,
  PropertyMetadata,
} from "../collection/create";
import FormLayout from "../../../components/layout/FormLayout";

const CreateSingle = () => {
  let history = useHistory();
  const params = useParams<SiteParams>();
  const state = useSelector((state: RootState) => state);

  const [collectionFilePath, setCollectionFilePath] = useState("");
  const [collectionTableName, setCollectionTableName] = useState("");
  const [collectionMetadata, setcollectionMetadata] =
    useState<CollectionMetadata>({
      display_name: "",
      description: "",
      public_read: true,
      public_write: false,
      public_delete: false,
      single: true,
    });
  const [collectionProperties, setCollectionProperties] = useState<
    CollectionProperty[]
  >([]);

  const [
    createSingle,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(CREATE_SINGLE, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const newContentType = data?.admin_table_create;
      const existingContentTypes: any = cache.readQuery({
        query: GET_SINGLE_CONTENT_TYPES,
      });
      const existingAllContentTypes: any = cache.readQuery({
        query: GET_PUBLIC_READ_TABLES,
      });

      if (newContentType && existingContentTypes) {
        cache.writeQuery({
          query: GET_SINGLE_CONTENT_TYPES,
          data: {
            admin_tables: {
              results: [
                ...existingContentTypes?.admin_tables.results,
                newContentType,
              ],
            },
          },
        });
      }

      if (newContentType && existingAllContentTypes) {
        cache.writeQuery({
          query: GET_PUBLIC_READ_TABLES,
          data: {
            admin_tables: {
              results: [
                ...existingContentTypes?.admin_tables.results,
                newContentType,
              ],
            },
          },
        });
      }
    },
    onCompleted: (data) => {
      if (data) {
        history.push(Routes.SINGLE_LINK(params.site));
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleSubmit = () => {
    createSingle({
      variables: {
        physical_file_path: collectionFilePath,
        metadata: collectionMetadata,
        properties: collectionProperties,
        table_name: collectionTableName,
      },
    });
  };

  const handleDisplayNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const name: string = event.target.value;
    setcollectionMetadata({
      ...collectionMetadata,
      display_name: name,
    });

    const slugified = slugify(name, {
      replacement: "_",
      lower: true,
      trim: true,
    });
    setCollectionTableName(slugified);
    setCollectionFilePath(`schema/${slugified}.yml`);
  };

  const handleMetadataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    setcollectionMetadata({
      ...collectionMetadata,
      [event.target.name]: value,
    });
  };

  const addCollectionProperty = (
    collectionProperty: CollectionProperty,
    propertyMetadata: PropertyMetadata
  ) => {
    const property: CollectionProperty = {
      ...collectionProperty,
      metadata: propertyMetadata,
    };

    setCollectionProperties([...collectionProperties, property]);
  };

  const removeCollectionProperty = (index: number) => {
    const filteredProperties = collectionProperties.filter((property, i) => {
      if (index !== i) {
        return property;
      }
    });

    setCollectionProperties(filteredProperties);
  };

  const editCollectionProperty = (
    property: CollectionProperty,
    propertyMetadata: PropertyMetadata,
    index: number
  ) => {
    const newProperty: CollectionProperty = {
      ...property,
      metadata: propertyMetadata,
    };

    const editedProperties = collectionProperties.map((prop, i) => {
      if (index === i) {
        return newProperty;
      }
      return prop;
    });

    setCollectionProperties(editedProperties);
  };

  return (
    <FormLayout
      loading={mutationLoading}
      formHeading="Create Single"
      backLink={Routes.SINGLE_LINK(params.site)}
      size="md"
    >
      {mutationError ? (
        <ErrorToast open={true} errorMessage={mutationError.message} />
      ) : null}
      <CollectionsFormView
        collectionMetadata={collectionMetadata}
        collectionProperties={collectionProperties}
        handleDisplayNameChange={handleDisplayNameChange}
        handleMetadataChange={handleMetadataChange}
        setCollectionProperties={setCollectionProperties}
        filePath={collectionFilePath}
        addCollectionProperty={addCollectionProperty}
        removeCollectionProperty={removeCollectionProperty}
        editCollectionProperty={editCollectionProperty}
        handleSubmit={handleSubmit}
      />
    </FormLayout>
  );
};

interface SiteParams {
  site: string;
}

export default CreateSingle;
