import {
    CurrentUserState,
    CurrentUserActions,
    SET_USER,
    LOGOUT,
} from "./types"

const initialState: CurrentUserState = {
    user: null,
    fetching: false,
    success: false,
    errors: [],
    isLoggingOut: false,
    logoutSuccess: false,
    logOutErrors: []
}

function currentUserReducer(state = initialState, action: CurrentUserActions): CurrentUserState {
    switch (action.type) {
        case SET_USER: {
            return {
                ...state,
                user: action.payload
            }
        }
        case LOGOUT:{
            return {
                ...state,
                isLoggingOut: false,
                user: null
            }
        }
        default:
            return state
    }
};

export default currentUserReducer;