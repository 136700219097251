import React, { useMemo, useState } from "react";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  NativeSelect,
  Input,
  Typography,
  Paper
} from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import DefaultColumnFilter from "./defaultColumnFilter";
import SelectColumnFilter from "./selectColumnFilter";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import SwapVertIcon from "@material-ui/icons/SwapVert";

const DataTable = ({ data, columns }) => {
  const [filterInput, setFilterInput] = useState("");
  const classes = useStyles();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    setFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 30 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const filterTypes = useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  return (
    <div className={classes.table__wrapper}>
      <TableContainer component={Paper} className={classes.table}>
        <Table
          {...getTableProps()}
          className={classes.table__head}
          stickyHeader
        >
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <TableCell className={classes.table__headCell}>
                      <div
                        className={classes.table__headCell__inner}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}

                        {column.canSort ? (
                          <span className={classes.table__sortIcon}>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <KeyboardArrowDownIcon color="primary"></KeyboardArrowDownIcon>
                              ) : (
                                <KeyboardArrowUpIcon color="primary"></KeyboardArrowUpIcon>
                              )
                            ) : (
                              <SwapVertIcon color="primary"></SwapVertIcon>
                            )}
                          </span>
                        ) : null}
                      </div>
                      <div>
                        {column.canFilter ? (
                          column.filterType === "select" ? (
                            <SelectColumnFilter column={column} />
                          ) : (
                            <DefaultColumnFilter column={column} />
                          )
                        ) : null}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>

          <TableBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.footer}>
        <Typography className="mobile-hide" component="p" variant="subtitle">
          {rows.length} Items
        </Typography>

        <div className={classes.paginationControls_wrap}>
          <div className={classes.paginationControls}>
            <Typography component="p" variant="subtitle">
              Rows Per Page:{" "}
            </Typography>
            <NativeSelect
              value={pageSize}
              default="15"
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[15, 30, 50, 100, rows.length].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize === rows.length ? "All" : pageSize}
                </option>
              ))}
            </NativeSelect>
          </div>
          <div className={classes.paginationControls}>
            <Typography component="p" variant="subtitle">
              Page {pageIndex + 1} of {pageOptions.length}
            </Typography>
            <Typography
              className="mobile-hide"
              component="p"
              variant="subtitle"
            >
              Go to page:
            </Typography>
            <Input
              className={classes.paginationNumberInput}
  
              type="number"
              inputProps={{ min: 1, max: pageOptions.length }}
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
            />
          </div>

          <div className={classes.paginationControls}>
            <IconButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              <SkipPreviousIcon />
            </IconButton>
            <IconButton
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              <NavigateBeforeIcon />
            </IconButton>
            <IconButton onClick={() => nextPage()} disabled={!canNextPage}>
              <NavigateNextIcon />
            </IconButton>
            <IconButton
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              <SkipNextIcon />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  "@global": {
    "tr:nth-child(even)": {
      backgroundColor: "rgb(0, 0, 0, .01)",
    },
    "tbody>tr>:nth-child(1)": {
      display: "table-cell!important",
    },
    "tbody>tr>:nth-last-child(1)": {
      display: "table-cell!important",
    },
    "thead>tr>:nth-child(1)": {
      display: "table-cell!important",
    },
    "thead>tr>:nth-last-child(1)": {
      display: "table-cell!important",
    },
    ".MuiTableCell-root": {
      padding: "6px 24px!important",
      [theme.breakpoints.down("sm")]: {
        display: "none!important",
      },
    },
  },
  table: {
    boxShadow: "none",
    height: "100%",
    borderColor: theme.palette.grey[600],
  },
  table__head: {
    verticalAlign: "top",
    height: "100%",
  },
  table__wrapper: {
    width: "100%",
  },
  table__headCell: {
    backgroundColor: theme.palette.background.paper,
    border: "0px 0px 1px 0px solid",
    padding: "20px 24px!important",
    borderColor: theme.palette.grey[300],
  },
  table__headCell__inner: {
    display: "flex",
    fontWeight: 600,
    textTransform: "capitalize",
    whiteSpace: "nowrap",
  },
  table__sortIcon: {
    marginLeft: "5px",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: theme.palette.background.paper,
    padding: "20px 24px!important",
    borderColor: theme.palette.grey[300],
    display: "flex",
    alignItems: "center",
  },
  paginationControls: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 20px",
    "& > *": {
      padding: "0 5px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none!important",
    },
  },
  paginationControls_wrap: {
    display: "flex",
    gap: 12,
  },
  paginationNumberInput: {
    maxWidth: 40,
  },
}));

export default DataTable;
