import React, { useState } from 'react';
import { useHistory, useParams} from "react-router-dom";
import ConstantForm from '../../../components/forms/constant';
import { useSelector } from 'react-redux'
import SET_CONSTANT from '../../../graphql/SET_CONSTANT';
import GET_CONSTANTS from '../../../graphql/GET_CONSTANTS';
import { useMutation, gql } from '@apollo/client';
import Loader from '../../../components/layout/Loader';
import ErrorToast from '../../../components/layout/ErrorToast';
import * as Routes from "../../../routes";
import { RootState } from '../../../store/store';
import FormLayout from '../../../components/layout/FormLayout';

const CreateConstant = () => {

    const state = useSelector((state: RootState) => state)
    const params = useParams<SiteParams>()
    const [constantType, setConstantType] = useState("String");
    const [constantName, setConstantName] = useState("");
    const [constantStringValue, setConstantStringValue] = useState("");
    const [constantObjectValue, setConstantObjectValue] = useState([
        {name: "", value: ""}
    ]);

        
    let history = useHistory();
    
    const [setConstant, { data: mutationData, loading: mutationLoading, error: mutationError }] = useMutation(SET_CONSTANT, {
        context: {
            headers: {
                'Authorization': `Bearer ${state.current_user.user?.token}`,
                'instance': state.current_site.site?.slug
            }
        },
        update: (cache, { data } ) => {
            const constantFromResponse = data?.constant_set
            const existingConstants: any = cache.readQuery({query: GET_CONSTANTS })

            if (constantFromResponse && existingConstants) {
                cache.writeQuery({
                    query: GET_CONSTANTS,
                    data: {
                        constants: {
                            results: [
                                ...existingConstants?.constants.results,
                                constantFromResponse,
                            ]
                        }
                    }
                });
            }
        },
        onCompleted: (data) => {
            if(data){
                history.push(Routes.CONSTANTS_LINK(params.site));
            }
        },
        onError: (error) => {
            console.log(error)
        }
    });

    const handleSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();
        let constValue;
        if(constantType === "String"){
            constValue = constantStringValue;
        } 
        else {
            let valueObject: any = {}
            for(let valuePair of constantObjectValue){
                if(valuePair.name && valuePair.value){
                    valueObject[valuePair.name] = valuePair.value;
                }
            }
            constValue = JSON.stringify(valueObject); 
        }
        setConstant({
            variables: {
                name: constantName, 
                value: constValue
            }
        })
    }

    const changeObjectField = (event: React.ChangeEvent<HTMLInputElement>, key: number) => {
        const newFields = constantObjectValue.map((valuePair, index) => {
            if (index !== key) {
              return valuePair
            }
            return {
              ...valuePair,
              [event.target.name]: event.target.value
            }
          })
        setConstantObjectValue(newFields);
    }

    const addField = () => {
        setConstantObjectValue([
            ...constantObjectValue,
            {name: "", value: ""}
        ])
    }
    const removeField = () => {
        const filteredObjectValues = constantObjectValue.filter((property, i, { length }) => {
            if(length - 1  !==  i){
                return property
            }
        })
    
        setConstantObjectValue(filteredObjectValues)
    }
    return(
        <FormLayout loading={mutationLoading} formHeading="Create Site Constant" backLink={Routes.CONSTANTS_LINK(params.site)} size="md">
  { mutationError ? <ErrorToast open={true} errorMessage={mutationError.message} />: null }
            { mutationLoading ? <Loader open={true} /> : null }
            <ConstantForm
                constantType={constantType}
                setConstantType={setConstantType}
                constantName={constantName}
                setConstantName={setConstantName}
                constantStringValue={constantStringValue}
                setConstantStringValue={setConstantStringValue}
                constantObjectValue={constantObjectValue}
                addField={addField}
                changeObjectField={changeObjectField}
                handleSubmit={handleSubmit}
                removeField={removeField}
            />
        </FormLayout>

    );
}

interface SiteParams {
  site: string
}

export default CreateConstant;