import { gql } from '@apollo/client';

const GET_FORM_CONTENT_TYPES = gql`
    query get_form_content_types($name: String){
        admin_tables(
            filter: {
                name: {exact: $name}
                metadata: {
                    attribute: {key: "public_write", value_boolean: true},
                    has_key: "collection"
                }
            }
        ){
            results {
                id
                created_at
                updated_at
                name
                metadata
                parameterized_name
                physical_file_path
                properties {
                    name
                    attribute_type
                    belongs_to
                    metadata
                }
            }
        }
    }
  `

export default GET_FORM_CONTENT_TYPES;