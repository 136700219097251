import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const EmptyContentMessage = (props: EmptyContentProps) => {
  const classes = useStyles();
  return (
    <div className={classes.alert_wrapper}>
      <ErrorOutlineIcon className={classes.alert_icon} />
      <Typography component="p" variant="body2">
        {props.message}
      </Typography>
    </div>
  );
};

interface EmptyContentProps {
  message: string;
}

const useStyles = makeStyles((theme) => ({
  alert_wrapper: {
    padding: theme.spacing(8, 2),
    textAlign: "center",
  },
  alert_icon: {
    fontSize: "48px",
    color: theme.palette.grey[600],
  },
}));

export default EmptyContentMessage;
