import { useSelector } from "react-redux";
import { useParams, NavLink, Link } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  Divider,
} from "@material-ui/core";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import LandscapeIcon from "@material-ui/icons/Landscape";
import HouseIcon from "@material-ui/icons/House";
import DescriptionIcon from "@material-ui/icons/Description";
import * as Routes from "../../../routes";
import { RootState } from "../../../store/store";

const AreasSubMenu = (props: any) => {
  const params = useParams<SiteParams>();
  const state: RootState = useSelector((state: RootState) => state);

  return (
    <List disablePadding={true}>
      <ListItem button onClick={props.handleBackClick}>
        <ListItemIcon>
          <ArrowBackIosIcon />
        </ListItemIcon>
        <ListItemText primary="Back" />
      </ListItem>
      <Divider />
      <ListSubheader>Area Page Manager</ListSubheader>
      <ListItem
        button
        component={NavLink}
        className="navLink"
        to={Routes.CITY_LINK(params.site)}
      >
        <ListItemIcon>
          <LocationCityIcon />
        </ListItemIcon>
        <ListItemText primary="Service Areas" />
      </ListItem>

      <ListItem
        className="navLink"
        button
        component={NavLink}
        to={Routes.REGION_LINK(params.site)}
      >
        <ListItemIcon>
          <LandscapeIcon />
        </ListItemIcon>

        <ListItemText primary="Regions" />
      </ListItem>
      <ListItem
        className="navLink"
        button
        component={NavLink}
        to={Routes.SUBURB_LINK(params.site)}
      >
        <ListItemIcon>
          <HouseIcon />
        </ListItemIcon>

        <ListItemText primary="Suburb/Towns" />
      </ListItem>
      {state.current_site.site?.build ? (
        <ListItem
          className="navLink"
          button
          component={NavLink}
          to={Routes.SUBURB_DEFAULT_CONTENT_LINK(params.site)}
        >
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary="Suburb Default" />
        </ListItem>
      ) : null}
    </List>
  );
};

interface SiteParams {
  site: string;
}

export default AreasSubMenu;
