import React from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormLabel,
  Typography,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

function CityForm(props: CityFormProps) {
  return (
    <div className="form">
      <div className="input_wrapper_flex">
        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <TextField
            variant="outlined"
            type="text"
            name="name"
            onChange={(e) =>
              props.handleChange(e, props.setRegion, props.region)
            }
            value={props.region.name}
            label="Name"
            fullWidth
          />
        )}

        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <TextField
            variant="outlined"
            type="text"
            name="slug"
            onChange={(e) =>
              props.handleChange(e, props.setRegion, props.region)
            }
            onBlur={(e) => props.handleBlur(e, props.setRegion, props.region)}
            value={props.region.slug}
            label="Slug"
            fullWidth
          />
        )}

        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <FormControl variant="outlined" fullWidth>
            <InputLabel>City</InputLabel>
            <Select
              variant="outlined"
              name="city"
              onChange={props.handleSelectChange}
              value={props.region.city_id}
              label="City"
              placeholder="City"
            >
              {props.cities.map((city: any) => {
                return (
                  <MenuItem value={city.id}>{city.properties.name}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
      </div>

      {props.isFetching ? (
        <Skeleton variant="rect" width={"100%"} height={56} />
      ) : (
        <>
          <Typography variant="subtitle2">Select Suburbs</Typography>
          <Select
            className="form_select"
            multiple
            native
            variant="outlined"
            name="layout"
            onChange={props.handleMultiChange}
            value={props.region.suburb_ids}
            label="Layout"
            placeholder="Layout"
            fullWidth
          >
            {props.suburbs.map((suburb: any) => {
              return (
                <option value={suburb.id} key={suburb.id}>
                  {suburb.properties.suburb}
                </option>
              );
            })}
          </Select>
        </>
      )}

      <div className="form_button">
        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={props.handleSubmit}
            disabled={props.isSubmitting}
          >
            {props.isSubmitting ? <CircularProgress /> : "Submit"}
          </Button>
        )}
      </div>
    </div>
  );
}

interface CityFormProps {
  region: any;
  setRegion: React.Dispatch<React.SetStateAction<any>>;
  cities: any[];
  suburbs: any[];
  handleSubmit: any;
  handleChange: any;
  handleBlur: any;
  handleSelectChange: any;
  handleMultiChange: any;
  isFetching: boolean;
  isSubmitting: boolean;
}

export default CityForm;
