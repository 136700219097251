import { Site } from "../currentSite/types";

export interface FetchError {
    type: string
}

export interface SiteListState {
    sites: Site[]
    fetching: boolean
    fetchErrors: FetchError[]
    fetchSuccess: boolean
    creating: boolean
    createSuccess: boolean
    updating: boolean
    updateSuccess: boolean
    deleting: boolean
    deleteSuccess: boolean
    didInvalidate: boolean
    createError: FetchError[]
}

export const FETCHING = "FETCHING_SITE_LIST";
export const FETCHED = "FETCHED_SITE_LIST";
export const CREATING = "CREATING_INSTANCE";
export const CREATED = "CREATED_INSTANCE";
export const UPDATING = "UPDATING_INSTANCE";
export const UPDATED = "UPDATED_INSTANCE";
export const DELETING = "DELETING_INSTANCE";
export const DELETED = "DELETED_INSTANCE";
export const INVALIDATE = "INVALIDATE_SITE_LIST";
export const CREATE_ERROR = "CREATE_ERROR";
export const RESET_CREATE_SUCCESS = "RESET_CREATE_SUCCESS";
export const RESET_UPDATE_SUCCESS = "RESET_UPDATE_SUCCESS";
export const RESET_DELETE_SUCCESS = "RESET_DELETE_SUCCESS";

interface fetchingSiteList {
  type: typeof FETCHING
}

interface fetchedSiteList {
    type: typeof FETCHED,
    payload: Site[]
}

interface invalidateSiteList {
  type: typeof INVALIDATE
}

interface creatingInstance {
  type: typeof CREATING
}

interface createdInstance {
    type: typeof CREATED,
    payload: Site
}

interface updatingInstance {
  type: typeof UPDATING
}

interface updatedInstance {
    type: typeof UPDATED,
    payload: Site
}

interface deletingInstance {
  type: typeof DELETING
}

interface deletedInstance {
    type: typeof DELETED,
    payload: Site
}

interface createError {
  type: typeof CREATE_ERROR,
  payload: FetchError[]
}

interface resetCreateSucces {
  type: typeof RESET_CREATE_SUCCESS
}

interface resetUpdateSucces {
  type: typeof RESET_UPDATE_SUCCESS
}

interface resetDeleteSucces {
  type: typeof RESET_DELETE_SUCCESS
}

export type SiteListActions = fetchingSiteList | fetchedSiteList | invalidateSiteList | createdInstance | creatingInstance | updatingInstance | updatedInstance | deletingInstance | deletedInstance | resetCreateSucces | resetUpdateSucces | resetDeleteSucces | createError