import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import * as Routes from "../../../routes";
import DataTable from "../../../components/dataTable";
import ActionCell from "../../../components/dataTable/pagesActionCell";
import { RootState } from "../../../store/store";
import GET_USERS from "../../../graphql/GET_USERS";
import TableLayout from "../../../components/layout/TableLayout";
import EmptyContentMessage from "../../../components/layout/EmptyContentMessage";

function SiteUsers() {
  const params = useParams<SiteParams>();
  const state = useSelector((state: RootState) => state);

  const { loading, error, data } = useQuery(GET_USERS, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  const users: any = useMemo(() => {
    return data ? data.users.results : [];
  }, [data]);

  const columns = useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
      },
      {
        Header: "email",
        accessor: "email",
      },
      {
        Header: "Created At",
        accessor: "created_at",
        disableFilters: true,
        Cell: (props: any) => {
          let date = new Date(props.value);
          return <>{date.toLocaleString("en-GB")}</>;
        },
      },
      {
        Header: "Updated At",
        accessor: "updated_at",
        disableFilters: true,
        Cell: (props: any) => {
          let date = new Date(props.value);
          return <>{date.toLocaleString("en-GB")}</>;
        },
      },
      {
        Header: "",
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <ActionCell
              site={params.site}
              value={props.value}
              siteURL={"test"}
              slug={props.row.values.slug}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <TableLayout
      loading={loading}
      buttonLink={Routes.PAGE_CREATE_LINK(params.site)}
      tableHeading="Users"
      buttonText="Create Site User"
    >
      {users.length === 0 ? (
        <EmptyContentMessage message="This instance does not have any users." />
      ) : (
        <DataTable data={users} columns={columns} />
      )}
    </TableLayout>
  );
}

interface SiteParams {
  site: string;
}

export default SiteUsers;
