import React from 'react';
import { TextField, Button, CircularProgress, Collapse, Typography, IconButton, FormControl, InputLabel, Select, MenuItem, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AceEditor from '../editor/AceEditor'


function LayoutForm(props: LayoutFormProps){
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
     const toggleExpandClick = () => {
      setExpanded(!expanded);
    };
    
    return (
<>
      <div className="form__columns">
          {props.isFetching ? (
            <Skeleton variant="rect" width={"100%"} height={56} />
          ) : (
            <TextField 
              variant="outlined"
              type="text"
              name="name"
              onChange={props.handleMetadataChange}
              value={props.metadata.name}
              label="Name"
              fullWidth
              className="marginRight"
            />
          )}


            <FormControl variant="outlined" required fullWidth className="marginLeft">
                <InputLabel>Layout Type</InputLabel>
                <Select name="type" onChange={props.handleMetadataChange} value={props.metadata.type} required>
                    <MenuItem value="page">Page</MenuItem>
                    <MenuItem value="email">Email</MenuItem>
                </Select>
            </FormControl>
            </div>

          {props.isFetching ? (
            <Skeleton variant="rect" width={"100%"} height={56} />
          ) : (
            <AceEditor 
              content={props.layout.body}
              handleChange={props.handleBodyChange}
            />
          )}

        <div className='advanced__headingWrap'>
            <Typography className="marginRight" component="h2" variant="h6">
              Advanced Settings
            </Typography>
            <IconButton className={expanded ? 'expandOpen' : 'expand'} onClick={toggleExpandClick}>
              <ExpandMoreIcon />
            </IconButton>
        </div>
         <Collapse in={expanded} timeout="auto" unmountOnExit className="advanced__wrap">

            {props.isFetching ? (
                <Skeleton variant="rect" width={"100%"} height={56} />
            ) : (
                <TextField className="form__input"
                variant="outlined"
                type="text"
                name="path"
                onChange={props.handleChange}
                value={props.layout.path}
                label="path"
                fullWidth
                />
            )}

            {props.isFetching ? (
                <Skeleton variant="rect" width={"100%"} height={56} />
            ) : (
                <TextField className="form__input"
                variant="outlined"
                type="text"
                name="physical_file_path"
                onChange={props.handleChange}
                value={props.layout.physical_file_path}
                label="Physical File Path"
                fullWidth
                />
            )}

        </Collapse>
              <div>
          {props.isFetching ? (
            <Skeleton variant="rect" width={"100%"} height={56} />
          ) : (
            
            <Button className="form_button" variant="contained" color="primary" onClick={props.handleSubmit} disabled={props.isSubmitting}>
              {props.isSubmitting ? <CircularProgress /> : 'Submit' }
            </Button>
          )}
        </div>
      </>
    );
}


interface LayoutFormProps {
  layout: any
  metadata: any
  handleSubmit: any
  handleChange: any
  handleBodyChange: any
  handleMetadataChange: any
  isFetching: boolean
  isSubmitting: boolean
}

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(4),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    margin: '0 auto'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px 0',
  },
  advancedHeader: {
    display: "flex",
    '& h2': {
      flexGrow: 1,
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

export default LayoutForm;