import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Button, Modal, Typography, useTheme } from "@material-ui/core";
import { useEffect, useState } from "react";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-markup";
import "prismjs/themes/prism-coy.css";
import * as prettier from "prettier/standalone";
import * as prettierPluginEstree from "prettier/plugins/estree";
import parserHtml from "prettier/plugins/html";
import { parseDocument } from "htmlparser2";
import he from "he";

function ViewSourceModal(props: ViewSourceModalProps) {
  const classes = useStyles();
  const [content, setContent] = useState(props.content);
  const theme = useTheme();

  const validateSyntax = (content: string) => {
    try {
      console.log("Validating syntax for content: ", content);
      parseDocument(content);
      return true;
    } catch (error: any) {
      console.error("Syntax error in content: ", error.message);
      return false;
    }
  };

  const formatContent = async () => {
    if (typeof props.content === "string") {
      let content = props.content;

      content = he.decode(content);
      content = content.replace(/ dir="ltr"/g, "");
      content = `<div>${content}</div>`;

      setContent(content);

      if (validateSyntax(content)) {
        try {
          let formattedContent = await prettier.format(content, {
            parser: "html",
            plugins: [parserHtml, prettierPluginEstree],
          });

          formattedContent = formattedContent
            .split("\n")
            .map((line, index) =>
              index === 0
                ? line.trim()
                : line.startsWith(" ")
                  ? line.slice(2)
                  : line
            )
            .join("\n");

          formattedContent = formattedContent
            .replace(/^<div>/, "")
            .trim()
            .replace(/<\/div>$/, "")
            .trim();
          setContent(formattedContent);
        } catch (error: any) {
          console.error("Error formatting content:", error.message);
          const errorPosition = error.message.match(/(\d+):(\d+)/);
          if (errorPosition) {
            const line = parseInt(errorPosition[1], 10);
            const column = parseInt(errorPosition[2], 10);
            console.error(`Error at line ${line}, column ${column}`);
          }
        }
      } else {
        console.error("Content has syntax errors and cannot be formatted.");
      }
    } else {
      console.error("Invalid content type. Expected a string.");
    }
  };

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="view-source-modal"
    >
      <div className={classes.paper}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Typography variant="h6" component="h2">
            Source Code
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={() => formatContent()}
          >
            Format
          </Button>
        </div>

        {props.content ? (
          <div className={classes.container}>
            <Editor
              value={content || ""}
              onValueChange={(value) => setContent(value)}
              highlight={(code) => highlight(code, languages.html, "html")}
              padding={10}
              style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 14,
                backgroundColor: theme.palette.background.default,
              }}
            />
          </div>
        ) : null}

        <Button
          variant="contained"
          color="primary"
          onClick={() => props.handleUpdateContent(content)}
          className={classes.button}
        >
          Update
        </Button>
      </div>
    </Modal>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      maxHeight: "70vh",
      top: "50%",
      left: "50%",
      padding: theme.spacing(2),
      transform: "translate(-50%, -50%)",
      position: "absolute",
      width: "65vw",
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[5],
      display: "flex",
      flexDirection: "column",
    },
    container: {
      overflow: "auto",
      maxHeight: "65vh",
      width: "100%",
      overflowX: "hidden",
      border: "1px solid #ccc",
      borderRadius: theme.shape.borderRadius,
    },
    button: {
      marginTop: theme.spacing(2),
    },
  })
);

interface ViewSourceModalProps {
  open: boolean;
  handleClose: any;
  content?: string;
  handleUpdateContent: any;
}

export default ViewSourceModal;
