import { useState, useEffect } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { RootState } from "../../store/store";
import { useSelector, useDispatch } from "react-redux";
import {
  createFavourite,
  fetchFavourites,
  deleteFavourite,
} from "../../store/currentSite/actions";

export default function FavouriteButton(props: FavouriteButtonProps) {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const [isFavourite, setIsFavourite] = useState<any>();
  const url = window.location.pathname.split("/").pop();
  const [color, setColor] = useState<any>();
  useEffect(() => {
    dispatch(fetchFavourites());
  }, [state.current_user]);


  useEffect(() => {
    if (
      state.current_site.site?.favourites &&
      state.current_site.site?.favourites.length
    ) {
      for (let i = 0; i < state.current_site.site?.favourites.length; i++) {
        if (state.current_site.site?.favourites[i].slug === props.slug && state.current_site.site?.favourites[i].type === props.type) {
          setIsFavourite(state.current_site.site?.favourites[i]);
          setColor("secondary")
          break;
        }
        setIsFavourite(undefined);
        setColor("disabled")
      }
    }
  }, [url, state.current_site.site, state.current_site.site?.favourites]);

  function handleAddFavourite() {
    dispatch(createFavourite(props.slug, props.type));
  }

  function handleDeleteFavourite() {
    dispatch(deleteFavourite(isFavourite.id));
  }
  function handleClick() {
    if (isFavourite !== undefined) {
      handleDeleteFavourite();
      setColor("disabled")
    } else {
      handleAddFavourite();
      setColor("secondary")
    }
  }
  return (
    <Tooltip title="Add to Dashboard">
    <IconButton size="small" onClick={handleClick}>
      <FavoriteIcon
        fontSize="small"
        color={color}
      />
    </IconButton>
    </Tooltip>
  );
}

interface FavouriteButtonProps {
  slug: string;
  type: string;
}
