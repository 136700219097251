import "./styles.css";
import { useEffect, useState } from "react";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import ToolbarPlugin from "./ToolbarPlugin";
import { ImageNode } from "./nodes/ImageNode";
import ImagesPlugin from "./ImagePlugin";
import { $getRoot } from "lexical";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { LinkNode } from "@lexical/link";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { ListNode, ListItemNode } from "@lexical/list";

const Editor = ({
  value,
  toggleLegacyEditor,
}: {
  value: string | undefined;
  toggleLegacyEditor: any;
}) => {
  const [val, setVal] = useState("");
  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const editorConfig = {
    namespace: "WYSIWYG",
    nodes: [
      HeadingNode,
      ImageNode,
      LinkNode,
      ListNode,
      ListItemNode,
      QuoteNode,
    ],
    onError(error: Error) {
      throw error;
    },
    theme: {
      code: "editor-code",
      heading: {
        h1: "editor-heading-h1",
        h2: "editor-heading-h2",
        h3: "editor-heading-h3",
        h4: "editor-heading-h4",
        h5: "editor-heading-h5",
      },
      image: "editor-image",
      link: "editor-link",
      list: {
        listitem: "editor-listitem",
        nested: {
          listitem: "editor-nested-listitem",
        },
        ol: "editor-list-ol",
        ul: "editor-list-ul",
      },
      ltr: "ltr",
      paragraph: "editor-paragraph",
      placeholder: "editor-placeholder",
      quote: "editor-quote",
      rtl: "rtl",
      text: {
        bold: "editor-text-bold",
        code: "editor-text-code",
        hashtag: "editor-text-hashtag",
        italic: "editor-text-italic",
        overflowed: "editor-text-overflowed",
        strikethrough: "editor-text-strikethrough",
        underline: "editor-text-underline",
        underlineStrikethrough: "editor-text-underlineStrikethrough",
      },
    },
  };

  const InitialContentPlugin = ({
    initialValue,
  }: {
    initialValue: string | undefined;
  }) => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
      if (val !== initialValue && initialValue) {
        editor.update(() => {
          const parser = new DOMParser();
          const dom = parser.parseFromString(initialValue, "text/html");
          const nodes = $generateNodesFromDOM(editor, dom);
          const root = $getRoot();
          root.clear().append(...nodes);
          setVal(initialValue);
        });
      }
    }, [initialValue]);

    return null;
  };

  return (
    <div>
      <LexicalComposer initialConfig={editorConfig}>
        <ToolbarPlugin toggleLegacyEditor={toggleLegacyEditor} />
        <div
          style={{
            padding: "2px !important",
            margin: "0 !important",
            borderRadius: "0 0 6px 6px",
            border: "1px solid #c4c4c4",
            borderTop: "none",
            outline: "none !important",
          }}
        >
          <RichTextPlugin
            contentEditable={
              <div className="editor-scroller">
                <div className="editor" ref={onRef}>
                  <ContentEditable className="ContentEditable__root" />
                </div>
              </div>
            }
            placeholder={<></>}
            ErrorBoundary={LexicalErrorBoundary}
          />
        </div>

        <HistoryPlugin />
        <LinkPlugin />
        <ListPlugin />
        <ImagesPlugin captionsEnabled={true} />
        <InitialContentPlugin initialValue={value} />
      </LexicalComposer>
    </div>
  );
};

export default Editor;
