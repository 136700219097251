import { gql } from '@apollo/client';

const CREATE_LAYOUT = gql`
    mutation create_page(
        $physical_file_path: String!, 
        $slug: String!, 
        $static_cache_expire: Int, 
        $searchable: Boolean, 
        $request_method: PageRequestMethod, 
        $metadata: HashObject, 
        $layout: String!, 
        $content: String!
    ) {
    admin_page_create(
        page: { 
            physical_file_path: $physical_file_path, 
            slug: $slug, 
            static_cache_expire: $static_cache_expire, 
            searchable: $searchable, 
            request_method: $request_method, 
            content: $content, 
            layout: $layout, 
            metadata: $metadata
        }
    ){
        id
        created_at
        updated_at
        slug
        content
        layout
        metadata
        physical_file_path
        static_cache_expire
        searchable
        request_method
    }
}
`

export default CREATE_LAYOUT;