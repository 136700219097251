import React, { useState, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import UPDATE_COLLECTION from "../../../graphql/UPDATE_COLLECTION";
import GET_COLLECTION_CONTENT_TYPES from "../../../graphql/GET_COLLECTION_CONTENT_TYPES";
import GET_PUBLIC_READ_TABLES from "../../../graphql/GET_PUBLIC_READ_TABLES";
import * as Routes from "../../../routes";
import { RootState } from "../../../store/store";
import CollectionsFormView from "../../../components/collections/CollectionsFormView";
import Loader from "../../../components/layout/Loader";
import ErrorToast from "../../../components/layout/ErrorToast";
import {
  CollectionMetadata,
  CollectionProperty,
  PropertyMetadata,
} from "../collection/create";
import FormLayout from "../../../components/layout/FormLayout";

const UpdateCollection = () => {
  let history = useHistory();
  const params = useParams<SiteParams>();
  const state = useSelector((state: RootState) => state);


  const [collectionFilePath, setCollectionFilePath] = useState("");
  const [collectionMetadata, setcollectionMetadata] =
    useState<CollectionMetadata>({
      display_name: "",
      description: "",
      public_read: false,
      public_write: true,
      public_delete: false,
      collection: true,
      collection_url: "",
    });
  const [collectionProperties, setCollectionProperties] = useState<
    CollectionProperty[]
  >([]);

  const { loading, error, data } = useQuery(GET_COLLECTION_CONTENT_TYPES, {
    variables: {
      name: params.collection,
    },
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  const collectionType: any = useMemo(() => {
    return data ? data.admin_tables.results[0] : null;
  }, [data]);

  useEffect(() => {
    if (collectionType) {
      setCollectionFilePath(collectionType.physical_file_path);
      setcollectionMetadata(collectionType.metadata);
      setCollectionProperties(collectionType.properties);
    }
  }, [collectionType]);

  const [
    updateCollection,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(UPDATE_COLLECTION, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const newContentType = data?.admin_table_update;
      const existingContentTypes: any = cache.readQuery({
        query: GET_COLLECTION_CONTENT_TYPES,
      });
      const existingAllContentTypes: any = cache.readQuery({
        query: GET_PUBLIC_READ_TABLES,
      });

      if (newContentType && existingContentTypes) {
        const updatedContentTypes =
          existingContentTypes.admin_tables.results.map((item: any) => {
            if (item.id === newContentType.id) {
              return newContentType;
            }
            return item;
          });

        cache.writeQuery({
          query: GET_COLLECTION_CONTENT_TYPES,
          data: {
            admin_tables: {
              results: updatedContentTypes,
            },
          },
        });
      }

      if (newContentType && existingAllContentTypes) {
        const updatedContentTypes =
          existingAllContentTypes.admin_tables.results.map((item: any) => {
            if (item.id === newContentType.id) {
              return newContentType;
            }
            return item;
          });

        cache.writeQuery({
          query: GET_PUBLIC_READ_TABLES,
          data: {
            admin_tables: {
              results: updatedContentTypes,
            },
          },
        });
      }
    },
    onCompleted: (data) => {
      if (data) {
        history.push(Routes.COLLECTION_LINK(params.site));
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleDisplayNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const name: string = event.target.value;
    setcollectionMetadata({
      ...collectionMetadata,
      display_name: name,
    });
  };

  const handleMetadataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    setcollectionMetadata({
      ...collectionMetadata,
      [event.target.name]: value,
    });
  };

  const addCollectionProperty = (
    collectionProperty: CollectionProperty,
    propertyMetadata: PropertyMetadata
  ) => {
    const property: CollectionProperty = {
      ...collectionProperty,
      metadata: propertyMetadata,
    };

    setCollectionProperties([...collectionProperties, property]);
  };

  const removeCollectionProperty = (index: number) => {
    const filteredProperties = collectionProperties.filter((property, i) => {
      if (index !== i) {
        return property;
      }
    });

    setCollectionProperties(filteredProperties);
  };

  const editCollectionProperty = (
    property: CollectionProperty,
    propertyMetadata: PropertyMetadata,
    index: number
  ) => {
    const newProperty: CollectionProperty = {
      ...property,
      metadata: propertyMetadata,
    };

    const editedProperties = collectionProperties.map((prop, i) => {
      if (index === i) {
        return newProperty;
      }
      return prop;
    });

    setCollectionProperties(editedProperties);
  };

  const handleSubmit = () => {

    const filteredCollectionProperties = collectionProperties.map(
      ({ __typename, ...rest }) => {
        return rest;
      }
    );

    updateCollection({
      variables: {
        physical_file_path: collectionFilePath,
        metadata: collectionMetadata,
        properties: filteredCollectionProperties,
      },
    });
  };

  return (
    <FormLayout
      formHeading="Edit Collection"
      backLink={Routes.COLLECTION_LINK(params.site)}
      size="md"
      loading={loading}
    >
      <CollectionsFormView
        collectionMetadata={collectionMetadata}
        collectionProperties={collectionProperties}
        handleDisplayNameChange={handleDisplayNameChange}
        handleMetadataChange={handleMetadataChange}
        setCollectionProperties={setCollectionProperties}
        filePath={collectionFilePath}
        addCollectionProperty={addCollectionProperty}
        removeCollectionProperty={removeCollectionProperty}
        editCollectionProperty={editCollectionProperty}
        handleSubmit={handleSubmit}
      />
      {mutationLoading ? <Loader open={true} /> : null}
      {mutationError ? (
        <ErrorToast open={true} errorMessage={mutationError.message} />
      ) : null}
    </FormLayout>
  );
};

interface SiteParams {
  site: string;
  collection: string;
}

export default UpdateCollection;
