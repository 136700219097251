import {
    FETCHED,
    FETCHING,
    CREATED,
    CREATING,
    UPDATING,
    UPDATED,
    DELETED,
    DELETING,
    INVALIDATE,
    RESET_CREATE_SUCCESS,
    RESET_DELETE_SUCCESS,
    RESET_UPDATE_SUCCESS,
    UserListActions,
    UserListState
} from "./types"

const initialState: UserListState = {
    users: [],
    fetching: false,
    fetchSuccess: false,
    creating: false,
    createSuccess: false,
    updating: false,
    updateSuccess: false,
    deleting: false,
    deleteSuccess: false,
    didInvalidate: false,
    fetchErrors: []
}

function userListReducer(state = initialState, action: UserListActions): UserListState {
    switch (action.type) {
        case FETCHING:{
            return {
                ...state,
                fetching: true,
                didInvalidate: false
            }
        }
        case FETCHED:{
            return {
                ...state,
                fetching: false,
                fetchSuccess: true,
                users: action.payload
            }
        }
        case CREATING:{
            return {
                ...state,
                creating: true
            }
        }
        case CREATED:{
            return {
                ...state,
                creating: false,
                createSuccess: true,
                users: state.users.concat(action.payload)
            }
        }
        case RESET_CREATE_SUCCESS: {
            return {
                ...state,
                createSuccess: false
            }
        }
        case UPDATING:{
            return {
                ...state,
                updating: false
            }
        }
        case UPDATED:{
            const updatedUsers = state.users.map(item => (item._id === action.payload._id ? action.payload : item))
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                users: updatedUsers
            }
        }
        case RESET_UPDATE_SUCCESS: {
            return {
                ...state,
                updateSuccess: false
            }
        }
        case DELETING:{
            return {
                ...state,
                deleting: false,
            }
        }
        case DELETED:{
            const filteredUsers = state.users.filter(item => {
                if(item._id !== action.payload._id){
                    return item
                }
            })
            return {
                ...state,
                deleting: false,
                deleteSuccess: true,
                users: filteredUsers
            }
        }
        case RESET_DELETE_SUCCESS: {
            return {
                ...state,
                deleteSuccess: false
            }
        }
        case INVALIDATE:{
            return {
                ...state,
                didInvalidate: true,
                users: []
            }
        }
        default:
            return state
    }
};

export default userListReducer;