export interface FetchError {
    type: string
}

export interface Organisation {
  id: number
  name: string
  hasCRM: boolean
  hasDashboard: boolean
}

export interface OrganisationListState {
    organisations: Organisation[]
    fetching: boolean
    fetchErrors: FetchError[]
    fetchSuccess: boolean
    creating: boolean
    createSuccess: boolean
    updating: boolean
    updateSuccess: boolean
    deleting: boolean
    deleteSuccess: boolean
    didInvalidate: boolean
}

export const FETCHING = "FETCHING_ORGANISATION_LIST";
export const FETCHED = "FETCHED_ORGANISATION_LIST";
export const CREATING = "CREATING_ORGANISATION";
export const CREATED = "CREATED_ORGANISATION";
export const UPDATING = "UPDATING_ORGANISATION";
export const UPDATED = "UPDATED_ORGANISATION";
export const DELETING = "DELETING_ORGANISATION";
export const DELETED = "DELETED_ORGANISATION";
export const INVALIDATE = "INVALIDATE_ORGANISATION_LIST";
export const RESET_CREATE_SUCCESS = "RESET_CREATE_SUCCESS";
export const RESET_UPDATE_SUCCESS = "RESET_UPDATE_SUCCESS";
export const RESET_DELETE_SUCCESS = "RESET_DELETE_SUCCESS";

interface fetchingOrganisationList {
  type: typeof FETCHING
}

interface fetchedOrganisationList {
    type: typeof FETCHED,
    payload: Organisation[]
}

interface invalidateOrganisationList {
  type: typeof INVALIDATE
}

interface creatingOrganisation {
  type: typeof CREATING
}

interface createdOrganisation {
    type: typeof CREATED,
    payload: Organisation
}

interface updatingOrganisation {
  type: typeof UPDATING
}

interface updatedOrganisation {
    type: typeof UPDATED,
    payload: Organisation
}

interface deletingOrganisation {
  type: typeof DELETING
}

interface deletedOrganisation {
    type: typeof DELETED,
    payload: Organisation
}

interface resetCreateSucces {
  type: typeof RESET_CREATE_SUCCESS
}

interface resetUpdateSucces {
  type: typeof RESET_UPDATE_SUCCESS
}

interface resetDeleteSucces {
  type: typeof RESET_DELETE_SUCCESS
}

export type OrganisationListActions = fetchingOrganisationList | fetchedOrganisationList | invalidateOrganisationList | createdOrganisation | creatingOrganisation | updatingOrganisation | updatedOrganisation | deletingOrganisation | deletedOrganisation | resetCreateSucces | resetUpdateSucces | resetDeleteSucces