import { TextField, Button, CircularProgress } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

function CityForm(props: CityFormProps) {
  return (
    <div className="form">
      {props.isFetching ? (
        <Skeleton variant="rect" width={"100%"} height={56} />
      ) : (
        <TextField
          variant="outlined"
          type="text"
          name="name"
          onChange={(e) => props.handleChange(e, props.setCity, props.city)}
          value={props.city.name}
          label="Name"
          fullWidth
        />
      )}

      {props.isFetching ? (
        <Skeleton variant="rect" width={"100%"} height={56} />
      ) : (
        <TextField
          variant="outlined"
          type="text"
          name="slug"
          onChange={(e) => props.handleChange(e, props.setCity, props.city)}
          onBlur={(e) => props.handleBlur(e, props.setCity, props.city)}
          value={props.city.slug}
          label="Slug"
          fullWidth
        />
      )}

      {props.isFetching ? (
        <Skeleton variant="rect" width={"100%"} height={56} />
      ) : (
        <TextField
          variant="outlined"
          type="text"
          name="state"
          onChange={(e) => props.handleChange(e, props.setCity, props.city)}
          value={props.city.state}
          label="State"
          fullWidth
        />
      )}

      <div className="form_button">
        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={props.handleSubmit}
            disabled={props.isSubmitting}
          >
            {props.isSubmitting ? <CircularProgress /> : "Submit"}
          </Button>
        )}
      </div>
    </div>
  );
}

interface CityFormProps {
  city: any;
  setCity: React.Dispatch<React.SetStateAction<any>>;
  handleSubmit: any;
  handleChange: any;
  handleBlur: any;
  isFetching: boolean;
  isSubmitting: boolean;
}

export default CityForm;
