import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete'

import * as Routes from "../../../routes";

const FormActionCell = (props) => {
  const [openDeleteWarning, setOpenDeleteWarning] = useState(false);

  const handleOpenDelete = () => {
    setOpenDeleteWarning(true);
  };

  const handleCloseDelete = () => {
    setOpenDeleteWarning(false);
  };

  const handleDeleteAndClose = () => {
    props.handleDelete(props.value);
    handleCloseDelete();
  };  
  
  
  return (
    <>
      <IconButton
        component={RouterLink}
        to={Routes.CITY_CONTENT_EDIT_LINK(props.site, props.city, props.value)}
      >
        <EditIcon />
      </IconButton>
      <IconButton onClick={handleOpenDelete}>
        <DeleteIcon />
      </IconButton>
      <Dialog open={openDeleteWarning} onClose={handleCloseDelete}>
        <DialogTitle>{"Delete Page"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you wish to delete this Item? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteAndClose}
            color="secondary"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default FormActionCell;