import { gql } from '@apollo/client';

const GET_WEBFORMS = gql`
    query get_webforms($id: ID $per_page: Int = 2000 $page: Int = 1) {
        models(
            filter: { 
                id: { value: $id },
                properties: { name: "webform" exists: true }
                deleted_at: { exists: false }
            }, 
            per_page: $per_page, 
            page: $page
        ) {
            total_entries
            total_pages
            results {
                id
                model_schema_name
                created_at
                updated_at
                properties
            }
        }
    }
`

export default GET_WEBFORMS;