import React, { useState, useMemo, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Button, Grid, MenuItem, Select } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useQuery, useMutation } from "@apollo/client";
import GET_COLLECTION_WITH_ENTITIES from "../../../graphql/GET_SINGLE_CONTENT_TYPE_WITH_ENTITIES";
import CREATE_RECORD from "../../../graphql/CREATE_RECORD";
import UPDATE_RECORD from "../../../graphql/UPDATE_RECORD";
import * as Routes from "../../../routes";
import { RootState } from "../../../store/store";
import Loader from "../../../components/layout/Loader";
import TextAreaInput from "../../../components/entities/TextAreaInput";
import TextInput from "../../../components/entities/TextInput";
import RichTextAreaInput from "../../../components/entities/RichTextAreaInput";
import NumberInput from "../../../components/entities/NumberInput";
import BooleanInput from "../../../components/entities/BooleanInput";
import ImageInput from "../../../components/entities/ImageInput";
import ImageGroupInput from "../../../components/entities/ImageGroupInput";
import DateTimeInput from "../../../components/entities/DateTimeInput";
import ArrayInput from "../../../components/entities/ArrayInput";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import ColourInput from "../../../components/entities/ColourInput";
import DateInput from "../../../components/entities/DateInput";
import TimeInput from "../../../components/entities/TimeInput";
import SingleRelationInput from "../../../components/entities/SingleRelationInput";
import ManyRelationInput from "../../../components/entities/ManyRelationsInput";
import ErrorToast from "../../../components/layout/ErrorToast";
import FormLayout from "../../../components/layout/FormLayout";

const CreateEntity = () => {
  const [properties, setProperties] = useState<PropertyValue[]>([]);
  const [isValidJSON, setIsValidJSON] = useState<boolean[]>([]);
  const params = useParams<CollectionParams>();
  const state = useSelector((state: RootState) => state);
  let history = useHistory();

  const [
    createRecord,
    {
      data: createMutationData,
      loading: createMutationLoading,
      error: createMutationError,
    },
  ] = useMutation(CREATE_RECORD, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const newRecord = data?.record_create;
      const existingRecords: any = cache.readQuery({
        query: GET_COLLECTION_WITH_ENTITIES,
        variables: {
          name: params.collection,
        },
      });
      if (newRecord && existingRecords) {
        cache.writeQuery({
          query: GET_COLLECTION_WITH_ENTITIES,
          variables: {
            name: params.collection,
          },
          data: {
            records: {
              results: [...existingRecords?.records.results, newRecord],
            },
          },
        });
      }
    },
    onCompleted: (data) => {
      if (data) {
        history.push(
          Routes.COLLECTION_VIEW_LINK(params.site, params.collection)
        );
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [
    updateRecord,
    {
      data: editMutationData,
      loading: editMutationLoading,
      error: editMutationError,
    },
  ] = useMutation(UPDATE_RECORD, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const updatedRecord = data?.record_update;
      const existingRecords: any = cache.readQuery({
        query: GET_COLLECTION_WITH_ENTITIES,
        variables: {
          name: params.collection,
        },
      });

      // map new result
      const updatedRecords = existingRecords.records.results.map(
        (item: any) => {
          if (item.id === updatedRecord.id) {
            return updatedRecord;
          }
          return item;
        }
      );

      if (updatedRecord && existingRecords) {
        cache.writeQuery({
          query: GET_COLLECTION_WITH_ENTITIES,
          variables: {
            name: params.collection,
          },
          data: {
            records: {
              results: [...updatedRecords],
            },
          },
        });
      }
    },
    onCompleted: (data) => {
      if (data) {
        history.push(
          Routes.COLLECTION_VIEW_LINK(params.site, params.collection)
        );
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { loading, error, data } = useQuery(GET_COLLECTION_WITH_ENTITIES, {
    variables: {
      name: params.collection,
      id: params.id ? params.id : undefined,
    },
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  const collectionType: any = useMemo(() => {
    return data ? data.admin_tables.results[0] : null;
  }, [data]);

  const collectionItem: any = useMemo(() => {
    return data ? data.records.results[0] : null;
  }, [data]);

  useEffect(() => {
    if (collectionType) {
      const _props = [...collectionType.properties];

      let sorted = _props.sort(
        (a: CollectionProperty, b: CollectionProperty) => {
          return b.metadata.display_priority - a.metadata.display_priority;
        }
      );

      const _properties = sorted.map(
        (_prop: CollectionProperty): PropertyValue => {
          let value: PropertyValue = {
            name: _prop.name,
            input_type: _prop.metadata.type,
            display_name: _prop.metadata.display_name,
          };

          if (_prop.belongs_to) {
            value = {
              ...value,
              belongs_to: _prop.belongs_to,
            };
          }

          if (params.id) {
            if (collectionItem) {
              value = mapPropertyWithCurrentValue(
                value,
                _prop.metadata.type,
                collectionItem
              );
            } else {
              value = mapPropertyValueType(value, _prop.metadata.type);
            }
          } else {
            value = mapPropertyValueType(value, _prop.metadata.type);
          }

          return value;
        }
      );

      setProperties(_properties);
    }
  }, [collectionType, collectionItem]);

  // renders the input component based on the type of input from the schema
  const mapInputComponent = (type: string, index: number) => {
    switch (type) {
      case "short_text":
        return (
          <TextInput
            index={index}
            key={index}
            handleChange={handleTextInputChange}
            validateSlug={validateSlugOnBlur}
            propertyValue={properties[index]}
          />
        );
      case "slug":
        return (
          <TextInput
            index={index}
            key={index}
            // only one onChange event, so the slug validation happens in same event as other inputs
            handleChange={handleTextInputChange}
            validateSlug={validateSlugOnBlur}
            propertyValue={properties[index]}
          />
        );
      case "long_text":
        return (
          <TextAreaInput
            index={index}
            key={index}
            handleChange={handleTextInputChange}
            propertyValue={properties[index]}
          />
        );
      case "rich_text":
        return (
          <RichTextAreaInput
            index={index}
            key={index}
            handleChange={handleTextInputChange}
            propertyValue={properties[index]}
          />
        );
      case "int":
        return (
          <NumberInput
            index={index}
            key={index}
            handleChange={handleIntInputChange}
            propertyValue={properties[index]}
          />
        );
      case "float":
        return (
          <NumberInput
            index={index}
            key={index}
            handleChange={handleFloatInputChange}
            propertyValue={properties[index]}
          />
        );
      case "boolean":
        return (
          <BooleanInput
            index={index}
            key={index}
            handleChange={handleBooleanInputChange}
            propertyValue={properties[index]}
          />
        );
      case "datetime":
        return (
          <DateTimeInput
            index={index}
            key={index}
            handleChange={handleTextInputChange}
            propertyValue={properties[index]}
          />
        );
      case "date":
        return (
          <DateInput
            index={index}
            key={index}
            handleChange={handleTextInputChange}
            propertyValue={properties[index]}
          />
        );
      case "time":
        return (
          <TimeInput
            index={index}
            key={index}
            handleChange={handleTextInputChange}
            propertyValue={properties[index]}
          />
        );
      case "array":
        return (
          <ArrayInput
            index={index}
            key={index}
            handleEditArray={handleArraySetInputChange}
            handleChange={handleArrayAddInputChange}
            handleRemove={handleArrayRemoveInputChange}
            propertyValue={properties[index]}
          />
        );
      case "image":
        return (
          <ImageInput
            index={index}
            key={index}
            handleChange={handleTextInputChange}
            propertyValue={properties[index]}
            collectionType={collectionType.name}
          />
        );
      case "image_group":
        return (
          <ImageGroupInput
            index={index}
            key={index}
            handleChange={handleArrayAddInputChange}
            handleRemove={handleArrayRemoveInputChange}
            propertyValue={properties[index]}
            collectionType={collectionType.name}
          />
        );
      case "dropdown":
        let collectionTypeIndex = collectionType.properties.findIndex(
          (prop: any) => prop.name === properties[index].name
        );
        return (
          <Grid item xs={12} lg={6}>
            <p className="entity__inputHeading">
              {properties[index].display_name}
            </p>
            <Select
              fullWidth
              value={properties[index].value}
              onChange={(e) => {
                handleTextInputChange(e.target.value as string, index);
              }}
              variant="outlined"
            >
              {collectionType.properties[
                collectionTypeIndex
              ]?.metadata?.drop_down_options!.map((item: any) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </Grid>
        );
      case "colour":
        return (
          <ColourInput
            index={index}
            key={index}
            handleChange={handleTextInputChange}
            propertyValue={properties[index]}
          />
        );
      case "one_to_many":
        return (
          <ManyRelationInput
            index={index}
            key={index}
            handleChange={handleArraySetInputChange}
            propertyValue={properties[index]}
            collectionType={collectionType.name}
          />
        );
      case "one_to_one":
        return (
          <SingleRelationInput
            index={index}
            key={index}
            handleChange={handleIntInputChange}
            propertyValue={properties[index]}
            collectionType={collectionType.name}
          />
        );
      case "many_to_many":
        return (
          <TextAreaInput
            index={index}
            key={index}
            handleChange={handleTextInputChange}
            propertyValue={properties[index]}
          />
        );
      case "many_to_one":
        return (
          <SingleRelationInput
            index={index}
            key={index}
            handleChange={handleIntInputChange}
            propertyValue={properties[index]}
            collectionType={collectionType.name}
          />
        );
      default:
        return null;
    }
  };

  const mapPropertyWithCurrentValue = (
    value: PropertyValue,
    type: string,
    collectionItem: any
  ): PropertyValue => {
    const currentValue = collectionItem.properties[value.name];
    return mapPropertyValueType(value, type, currentValue);
  };

  const mapPropertyValueType = (
    value: PropertyValue,
    type: string,
    currentValue?: any
  ): PropertyValue => {
    switch (type) {
      case "short_text":
        return {
          ...value,
          value: currentValue ? currentValue : "",
        };
      case "slug":
        return {
          ...value,
          value: currentValue ? currentValue : "",
        };
      case "long_text":
        return {
          ...value,
          value: currentValue ? currentValue : "",
        };
      case "rich_text":
        return {
          ...value,
          value: currentValue ? currentValue : "",
        };
      case "int":
        return {
          ...value,
          value_int: currentValue ? currentValue : 0,
        };
      case "float":
        return {
          ...value,
          value_float: currentValue ? currentValue : 0,
        };
      case "boolean":
        return {
          ...value,
          value_boolean: currentValue ? currentValue : false,
        };
      case "datetime":
        return {
          ...value,
          value: currentValue ? currentValue : "",
        };
      case "date":
        return {
          ...value,
          value: currentValue ? currentValue : "",
        };
      case "time":
        return {
          ...value,
          value: currentValue ? currentValue : "",
        };
      case "array":
        return {
          ...value,
          value_array: currentValue ? currentValue : [],
        };
      case "image":
        return {
          ...value,
          value: currentValue ? currentValue : "",
        };
      case "image_group":
        return {
          ...value,
          value_array: currentValue ? currentValue : [],
        };
      case "dropdown":
        return {
          ...value,
          value: currentValue ? currentValue : "",
        };
      case "colour":
        return {
          ...value,
          value: currentValue ? currentValue : "",
        };
      case "one_to_many":
        return {
          ...value,
          value_array: currentValue ? currentValue : [],
        };
      case "one_to_one":
        return {
          ...value,
          value_int: currentValue ? currentValue : 0,
        };
      case "many_to_many":
        return {
          ...value,
          value_array: currentValue ? currentValue : [],
        };
      case "many_to_one":
        return {
          ...value,
          value_int: currentValue ? currentValue : 0,
        };
      default:
        return {
          ...value,
        };
    }
  };

  // this is an array of possible names for the slug property. This is used to check if an old instance is using the slug property so the code can validate the slug. We consider different variations of the word slug in case the developer has used a different wording. For new instances, the check is done based on the metadata type property slug instead of the property name.
  const possiblePropertyNameValues = [
    "slug",
    "slugs",
    "Slug",
    "Slugs",
    "link",
    "Link",
    "Links",
  ];

  const handleTextInputChange = (value: string, index: number) => {
    let newArr = [...properties];
    // checks if it is a slug before validating. This event function is used for other inputs as well
    if (
      newArr[index].input_type === "slug" ||
      possiblePropertyNameValues.some((val) => newArr[index].name.includes(val))
    ) {
      value = validateSlugOnChange(value);
    }
    newArr[index] = {
      ...newArr[index],
      value: value,
    };
    setProperties(newArr);
  };

  const validateSlugOnBlur = (value: string, index: number) => {
    let newArr = [...properties];
    // checks if it is a slug before validating. This event function is used for other inputs as well
    if (
      newArr[index].input_type === "slug" ||
      possiblePropertyNameValues.some((val) => newArr[index].name.includes(val))
    ) {
      value = value.replace(/[-\/]$/, "");
    }
    // Remove trailing hyphen or slash
    newArr[index] = {
      ...newArr[index],
      value: value,
    };
    setProperties(newArr);
  };

  const validateSlugOnChange = (value: string) => {
    let slug = value;
    // Convert to lowercase
    slug = slug.toLowerCase();
    // Replace spaces with hyphens
    slug = slug.replace(/\s+/g, "-");
    // Remove invalid characters (only keep numbers, letters, '/', and '-')
    slug = slug.replace(/[^a-z0-9/-]/g, "");
    // Ensure no consecutive slashes
    slug = slug.replace(/[/]+/g, "/");
    // Ensure no consecutive hyphens
    slug = slug.replace(/-+/g, "-");
    // Remove invalid sequences `/-` or `-/`
    slug = slug.replace(/\/-|-\/+/g, "/");
    // Remove leading hyphens or slashes
    slug = slug.replace(/^[-\/]+/, "");
    return slug;
  };

  const handleIntInputChange = (value: number, index: number) => {
    let newArr = [...properties];

    newArr[index] = {
      ...newArr[index],
      value_int: value,
    };

    setProperties(newArr);
  };

  const handleFloatInputChange = (value: number, index: number) => {
    let newArr = [...properties];

    newArr[index] = {
      ...newArr[index],
      value_float: value,
    };

    setProperties(newArr);
  };

  const handleBooleanInputChange = (value: boolean, index: number) => {
    let newArr = [...properties];

    newArr[index] = {
      ...newArr[index],
      value_boolean: value,
    };

    setProperties(newArr);
  };

  const handleSubmit = () => {
    const mappedProperties = properties.map(
      (_prop: PropertyAttribute): PropertyAttribute => {
        delete _prop["display_name"];
        delete _prop["input_type"];
        delete _prop["belongs_to"];

        return _prop;
      }
    );

    if (params.id) {
      updateRecord({
        variables: {
          id: params.id,
          properties: mappedProperties,
        },
      });
    } else {
      createRecord({
        variables: {
          table: params.collection,
          properties: mappedProperties,
        },
      });
    }
  };

  const handleArraySetInputChange = (value: string[], index: number) => {
    let newArr = [...properties];
    console.log(value);

    newArr[index] = {
      ...newArr[index],
      value_array: [...value],
    };

    setProperties(newArr);
  };

  const handleArrayAddInputChange = (value: string, index: number) => {
    let newArr = [...properties];

    newArr[index] = {
      ...newArr[index],
      value_array: [...newArr[index].value_array!, value],
    };

    setProperties(newArr);
  };

  const handleArrayRemoveInputChange = (index: number, removeIndex: number) => {
    let newArr = [...properties];

    const newValueArray = newArr[index].value_array!.filter(
      (arrItem: string, index) => {
        if (index !== removeIndex) {
          return arrItem;
        }
      }
    );

    newArr[index] = {
      ...newArr[index],
      value_array: newValueArray as string[],
    };

    setProperties(newArr);
  };

  const validateJSON = (str: string) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    properties.forEach((property: PropertyAttribute) => {
      if (property.input_type === "array") {
        property.value_array!.forEach((itemString: string, index) => {
          setIsValidJSON((prevValidity) => {
            const newValidity = [...prevValidity];
            newValidity[index] = validateJSON(itemString);
            return newValidity;
          });
        });
      }
    });
  }, [properties]);

  return (
    <FormLayout
      loading={loading}
      size={"lg"}
      formHeading={`${params.id ? "Edit" : "Create"} ${
        collectionType?.metadata.display_name
          ? collectionType?.metadata.display_name
          : " "
      } Item`}
      backLink={Routes.COLLECTION_VIEW_LINK(params.site, params.collection)}
    >
      {loading ? (
        <Loader open={true} />
      ) : collectionType ? (
        <>
          <Grid item xs={12} container spacing={2}>
            {properties.map((_property, i: number) =>
              mapInputComponent(_property.input_type, i)
            )}
          </Grid>
          <Button
            className="form_button"
            size="large"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isValidJSON.some((validity) => !validity)}
          >
            Submit
          </Button>
          {createMutationLoading || editMutationLoading ? (
            <Loader open={true} />
          ) : null}
          {createMutationError ? (
            <ErrorToast
              open={true}
              errorMessage={createMutationError.message}
            />
          ) : null}
        </>
      ) : null}
    </FormLayout>
  );
};

interface CollectionParams {
  site: string;
  collection: string;
  id?: string;
}

export interface CollectionProperty {
  name: string;
  belongs_to?: string;
  attribute_type: string | null;
  metadata: PropertyMetadata;
}

export interface PropertyMetadata {
  display_name: string;
  type: string;
  private: boolean;
  display_priority: number;
  required: boolean;
}

export interface PropertyValue {
  name: string;
  value?: string;
  value_int?: number;
  value_float?: number;
  value_boolean?: boolean;
  value_array?: string[];
  input_type: string;
  display_name: string;
  belongs_to?: string;
}

export interface PropertyAttribute {
  name: string;
  value?: string;
  value_int?: number;
  value_float?: number;
  value_boolean?: boolean;
  value_array?: string[];
  input_type?: string;
  display_name?: string;
  belongs_to?: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({}));

export default CreateEntity;
