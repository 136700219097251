import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery, useMutation } from "@apollo/client";
import GET_LAYOUTS from "../../../graphql/GET_LAYOUTS";
import DELETE_LAYOUT from "../../../graphql/DELETE_LAYOUT";
import * as Routes from "../../../routes";
import DataTable from "../../../components/dataTable";
import ActionCell from "../../../components/dataTable/layoutsActionCell";
import { RootState } from "../../../store/store";
import TableLayout from "../../../components/layout/TableLayout";
import EmptyContentMessage from "../../../components/layout/EmptyContentMessage";

function Layouts() {
  const params = useParams<SiteParams>();
  const state = useSelector((state: RootState) => state);

  const { loading, error, data } = useQuery(GET_LAYOUTS, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  const layouts: any = useMemo(() => {
    return data ? data.admin_liquid_layouts.results : [];
  }, [data]);

  const [
    deleteLayout,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(DELETE_LAYOUT, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const layoutFromResponse = data?.admin_liquid_layout_delete;
      const existingLayouts: any = cache.readQuery({ query: GET_LAYOUTS });

      if (layoutFromResponse && existingLayouts) {
        const updatedLayouts =
          existingLayouts.admin_liquid_layouts.results.filter((layout: any) => {
            if (layout.id !== layoutFromResponse.id) {
              return layout;
            }
          });

        cache.writeQuery({
          query: GET_LAYOUTS,
          data: {
            admin_liquid_layouts: {
              results: [...updatedLayouts],
            },
          },
        });
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleDelete = (id: string) => {
    deleteLayout({
      variables: {
        id: id,
      },
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "metadata.name",
      },
      {
        Header: "Type",
        accessor: "metadata.type",
      },
      {
        Header: "path",
        accessor: "path",
      },
      {
        Header: "Created At",
        accessor: "created_at",
        disableFilters: true,
        Cell: (props: any) => {
          let date = new Date(props.value);
          return <>{date.toLocaleString("en-GB")}</>;
        },
      },
      {
        Header: "Updated At",
        accessor: "updated_at",
        disableFilters: true,
        Cell: (props: any) => {
          let date = new Date(props.value);
          return <>{date.toLocaleString("en-GB")}</>;
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <ActionCell
              site={params.site}
              id={props.value}
              handleDelete={handleDelete}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <TableLayout
      loading={loading}
      buttonLink={Routes.LAYOUTS_CREATE_LINK(params.site)}
      tableHeading="Layouts"
      buttonText="Create Layout"
    >
      {layouts.length === 0 ? (
        <EmptyContentMessage
          message={"This instance does not have any layouts"}
        />
      ) : (
        <DataTable data={layouts} columns={columns} />
      )}
    </TableLayout>
  );
}

interface SiteParams {
  site: string;
}

export default Layouts;
