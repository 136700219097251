import React from "react";
import {
  Container,
  Typography,
  Button,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import InfoIcon from "@material-ui/icons/Info";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import FavouriteButton from "./FavouriteButton";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "../../components/layout/Loader";
export default function TableLayout(props: TableLayoutProps) {
  const classes = useStyles();
  return (
    <>
      {props.loading ? (
        <Loader open={true} />
      ) : (
        <Container maxWidth="lg" className={classes.tableWrapper}>
          <div className="action_bar">
            <div className="action_bar_left">
              <Typography component="h1" variant="h3">
                {props.tableHeading}
              </Typography>

              <div className="action_bar_left_buttons">
                {props.secondaryButton ? (
                  <Tooltip
                    title={props.secondaryButton.buttonText}
                  >
                    <IconButton
                      component={RouterLink}
                      to={props.secondaryButton.buttonLink}
                      size="small"
                      color="primary"
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                ) : (
                  " "
                )}
                {props.favouriteSlug && props.favouriteType ? (
           
                    <FavouriteButton
                      type={props.favouriteType}
                      slug={props.favouriteSlug}
                    />
  
                ) : (
                  ""
                )}
                {props.description ? (
                  <Tooltip title={props.description}>
                    <IconButton size="small">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="action_bar_right">
              {props.buttonLink ? (
                <Button
                  className="mobile-hide"
                  component={RouterLink}
                  to={props.buttonLink}
                  variant="contained"
                  color="primary"
                  startIcon={<AddCircleIcon />}
                >
                  {props.buttonText}
                </Button>
              ) : null}
              <IconButton
                component={RouterLink}
                to={props.buttonLink}
                className="desktop-hide"
                color="primary"
                aria-label={props.buttonText}
              >
                <AddCircleIcon />
              </IconButton>
            </div>
          </div>
          {props.children}
        </Container>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  appbarButtons: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    cursor: "pointer",
    color: "white",
  },
  tableWrapper: {
    borderColor: theme.palette.grey[300],
    border: "1px solid",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "12px",
    padding: "0!important",
    overflow: "hidden",
  },
  iconPaddingLeft: {
    paddingLeft: "12px",
  },

  settingsIcon: {
    "&.hover": {
      color: theme.palette.primary,
    },
  },
}));
interface TableLayoutProps {
  tableHeading: string;
  buttonText: string;
  buttonLink: any;
  secondaryButton?: SecondaryButton;
  children: React.ReactNode;
  favouriteSlug?: string;
  favouriteType?: string;
  description?: string;
  loading: any;
}

interface SecondaryButton {
  buttonText: string;
  buttonLink: string;
}
