import { gql } from '@apollo/client';

const CREATE_PARTIAL = gql`
    mutation create_partial(
        $path: String!, 
        $body: String!, 
        $physical_file_path: String!, 
        $metadata: HashObject!
    ){
    admin_liquid_partial_create(liquid_partial: {path: $path, physical_file_path: $physical_file_path, body: $body, metadata: $metadata}) {
        id
        created_at
        updated_at
        path
        metadata
        body
        physical_file_path
  }
}

`

export default CREATE_PARTIAL;