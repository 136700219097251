import React, { useState } from 'react';
import ImageCard from './ImageCard';
import { Grid, Input, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { useMemo } from 'react';

const FlatView = (props: FlatViewProps) => {
    const classes = useStyles();
    const [ searchValue, setSearchValue ] = useState("")

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value as string)
    }

    const filteredImages = useMemo(() => {
        if(searchValue && searchValue.length > 1){
            
            const filteredAssets = props.assets.filter((asset: any) => {
                if(asset.name.includes(searchValue)){
                    return asset
                }
            })
            
            return filteredAssets;
        }
        return props.assets
    }, [props.assets, searchValue])

    return(
        <>
        <Input
            style={{marginBottom: 30}}
            placeholder="Search"
            onChange={handleChange}
            value={searchValue}
            startAdornment={
                <InputAdornment position="start">
                    <SearchIcon />
                </InputAdornment>
            }
        />
        <Grid container spacing={2} justify="center">
            {filteredImages.map((asset: any) => {
                return(
                <Grid item xs={12} sm={6} md={4} lg={3} key={asset.id} className={classes.card}>
                    <ImageCard imageFilePath={asset.physical_file_path} imageName={asset.name} imageUrl={asset.url} selectedImageUrl={props.selectedImageUrl} setSelectedImage={props.setSelectedImage}  />
                </Grid>
                )
            })}
        </Grid>
        </>
    );
}

interface FlatViewProps {
    assets: any[],
    setSelectedImage?: (url: string) =>  void,
    selectedImageUrl?: string
}

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: '',
  },
}));

export default FlatView;