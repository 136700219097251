import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Modal,
  Paper,
  Tabs,
  Tab,
  Box,
  Select,
  MenuItem,
  Button,
  TextField,
} from "@material-ui/core";
import ImageUploader from "./ImageUploader";
import { RootState } from "../../store/store";
import GET_ASSETS from "../../graphql/GET_ASSETS";
import Loader from "../layout/Loader";
import DirectoryView from "./DirectoryView";
import FlatView from "./FlatView";
import { PropertyValue } from "../../pages/site/entities/create";
import EmptyContentMessage from "../layout/EmptyContentMessage";

function ImageSelectModal(props: UploadModalProps) {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [selectedImage, setSelectedImage] = useState("");
  const state = useSelector((state: RootState) => state);
  const [imageView, setImageView] = useState("DIRECTORY");

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const { loading, error, data } = useQuery(GET_ASSETS, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  const assets: any = useMemo(() => {
    return data ? data.admin_assets.results : [];
  }, [data]);

  const switchViews = (event: React.ChangeEvent<{ value: unknown }>) => {
    setImageView(event.target.value as string);
  };

  const selectImage = (imagePath: string) => {
    setSelectedImage(imagePath);
  };

  const addSelectedImage = () => {
    props.setImagePath(selectedImage);
    props.handleClose();
  };

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="image-upload-modal"
    >
      <div className={classes.paper}>
        <Paper square>
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
          >
            <Tab label="Select" />
            <Tab label="Upload" />
          </Tabs>
        </Paper>
        <TabPanel value={tabValue} index={0}>
          <Select
            style={{ marginRight: 24 }}
            value={imageView}
            onChange={switchViews}
          >
            <MenuItem value={"DIRECTORY"}>Directory</MenuItem>
            <MenuItem value={"FLAT"}>All</MenuItem>
          </Select>

          <div className={classes.container}>
            {assets.length === 0 ? (
              loading ? (
                <Loader open={true} />
              ) : (
                <EmptyContentMessage
                  message={"This instance does not have any assets"}
                />
              )
            ) : imageView === "DIRECTORY" ? (
              <DirectoryView
                assets={assets}
                setSelectedImage={selectImage}
                selectedImage={selectedImage}
              />
            ) : (
              <FlatView
                assets={assets}
                setSelectedImage={selectImage}
                selectedImageUrl={selectedImage}
              />
            )}
          </div>
        </TabPanel>
        <div style={{ display: "flex", alignItems: "center", margin: 16 }}>
          {props.setAltText ? (
            <TextField
              style={{ marginRight: 16 }}
              size="small"
              fullWidth
              label="Image Alt Text"
              variant="outlined"
              onChange={(event) =>
                props.setAltText && props.setAltText(event.target.value)
              }
            />
          ) : null}
          <Button
            variant="contained"
            color="primary"
            onClick={addSelectedImage}
            disabled={
              !selectedImage || props.setAltText ? !props.altText : false
            }
          >
            Select
          </Button>
        </div>
        <TabPanel value={tabValue} index={1}>
          <ImageUploader
            handleClose={props.handleClose}
            setImagePath={props.setImagePath}
            assetPath={props.assetPath}
          />
        </TabPanel>
      </div>
    </Modal>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      position: "absolute",
      width: "80vw",
      // height: "80vh",
      backgroundColor: theme.palette.background.paper,
      borderRadius: 4,
      boxShadow: theme.shadows[5],
      overflow: "hidden",
    },
    container: {
      overflow: "auto",
      height: "65vh",
      maxWidth: "100%",
      overflowX: "hidden",
    },
  })
);

interface UploadModalProps {
  open: boolean;
  handleClose: any;
  altText?: string;
  setImagePath: (imagePath: string) => void;
  setAltText?: (altText: string) => void;
  assetPath?: string;
  propertyValue?: PropertyValue;
}

export default ImageSelectModal;
