import { User } from "../currentUser/types"

export interface FetchError {
    type: string
}

export interface UserListState {
    users: User[]
    fetching: boolean
    fetchErrors: FetchError[]
    fetchSuccess: boolean
    creating: boolean
    createSuccess: boolean
    updating: boolean
    updateSuccess: boolean
    deleting: boolean
    deleteSuccess: boolean
    didInvalidate: boolean
}

export const FETCHING = "FETCHING_USER_LIST";
export const FETCHED = "FETCHED_USER_LIST";
export const CREATING = "CREATING_USER";
export const CREATED = "CREATED_USER";
export const UPDATING = "UPDATING_USER";
export const UPDATED = "UPDATED_USER";
export const DELETING = "DELETING_USER";
export const DELETED = "DELETED_USER";
export const INVALIDATE = "INVALIDATE_USER_LIST";
export const RESET_CREATE_SUCCESS = "RESET_CREATE_SUCCESS";
export const RESET_UPDATE_SUCCESS = "RESET_UPDATE_SUCCESS";
export const RESET_DELETE_SUCCESS = "RESET_DELETE_SUCCESS";

interface fetchingUserList {
  type: typeof FETCHING
}

interface fetchedUserList {
    type: typeof FETCHED,
    payload: User[]
}

interface invalidateUserList {
  type: typeof INVALIDATE
}

interface creatingUser {
  type: typeof CREATING
}

interface createdUser {
    type: typeof CREATED,
    payload: User
}

interface updatingUser {
  type: typeof UPDATING
}

interface updatedUser {
    type: typeof UPDATED,
    payload: User
}

interface deletingUser {
  type: typeof DELETING
}

interface deletedUser {
    type: typeof DELETED,
    payload: User
}

interface resetCreateSucces {
  type: typeof RESET_CREATE_SUCCESS
}

interface resetUpdateSucces {
  type: typeof RESET_UPDATE_SUCCESS
}

interface resetDeleteSucces {
  type: typeof RESET_DELETE_SUCCESS
}

export type UserListActions = fetchingUserList | fetchedUserList | invalidateUserList | creatingUser | createdUser | updatingUser | updatedUser | deletingUser | deletedUser | resetCreateSucces | resetUpdateSucces | resetDeleteSucces