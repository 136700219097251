import React from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Collapse,
  Typography,
  IconButton,
  Paper,
  Select,
  MenuItem
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from "@material-ui/lab/Skeleton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AceEditor from "../editor/AceEditor";

function PageForm(props: PageFormProps) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const toggleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="form">

        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <TextField
            variant="outlined"
            type="text"
            name="name"
            onChange={props.handleMetadataChange}
            value={props.metadata.name}
            label="Name"
            fullWidth
          />
        )}

        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <TextField
            variant="outlined"
            type="text"
            name="slug"
            onChange={props.handleChange}
            value={props.page.slug}
            label="Slug"
            fullWidth
          />
        )}

        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          
          <Select
            variant="outlined"
            name="layout"
            onChange={props.handleLayoutChange}
            value={props.page.layout}
            label="Layout"
            placeholder="Layout"
            fullWidth
          >
            {props.layouts.map((layout: any) => {
              return (
                <MenuItem
                
                  value={
                    layout.path.startsWith("layouts/")
                      ? layout.path.slice(8)
                      : layout.path
                  }
                >
                  {layout.metadata.name}
                </MenuItem>
              );
            })}
          </Select>
        )}

        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <Autocomplete
            fullWidth
            value={props.metadata.type}
            onChange={props.handleTypeChange}
            onInputChange={props.handleTypeChange}
            options={typeOptions}
            getOptionLabel={(option: string) => option}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Type"
                name="type"
                variant="outlined"
              />
            )}
          />
        )}
     

      {props.isFetching ? (
        <Skeleton variant="rect" width={"100%"} height={56} />
      ) : (
        <TextField

          variant="outlined"
          type="text"
          name="meta_title"
          onChange={props.handleMetadataChange}
          value={props.metadata.meta_title}
          label="Meta Title"
          fullWidth
        />
      )}

      {props.isFetching ? (
        <Skeleton variant="rect" width={"100%"} height={56} />
      ) : (
        <TextField

          variant="outlined"
          type="text"
          name="meta_desc"
          onChange={props.handleMetadataChange}
          value={props.metadata.meta_desc}
          label="Meta Description"
          rows={5}
          fullWidth
        />
      )}

      {props.isFetching ? (
        <Skeleton variant="rect" width={"100%"} height={56} />
      ) : (
        <AceEditor
          content={props.page.content}
          handleChange={props.handleBodyChange}
        />
      )}

      <div className="advanced__headingWrap">
        <Typography component="h2" variant="h6" className="marginRight">
          Advanced Settings
        </Typography>
        <IconButton
          className={expanded ? "expandOpen" : "expand"}
          onClick={toggleExpandClick}
        >
          <ExpandMoreIcon />
        </IconButton>
      </div>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        className="advanced__wrap"
      >
        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            name="physical_file_path"
            onChange={props.handleChange}
            value={props.page.physical_file_path}
            label="Physical File Path"
          />
        )}
      </Collapse>
      <div className="form_button">
        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={props.handleSubmit}
            disabled={props.isSubmitting}
          >
            {props.isSubmitting ? <CircularProgress /> : "Submit"}
          </Button>
        )}
      </div>
   </div>
  );
}

const typeOptions = ["page", "admin", "area"];

interface PageFormProps {
  page: any;
  metadata: any;
  handleSubmit: any;
  handleChange: any;
  handleBodyChange: any;
  handleMetadataChange: any;
  handleTypeChange: any;
  handleLayoutChange: any;
  layouts: any[];
  isFetching: boolean;
  isSubmitting: boolean;
}

const useStyles = makeStyles((theme) => ({}));

export default PageForm;
