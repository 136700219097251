import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  updateOrganisation,
  unsetOrganisationUpdateSuccessFlag,
} from "../../store/organisationList/actions";
import * as Routes from "../../routes";
import { RootState } from "../../store/store";
import OrganisationForm from "../../components/forms/organisation";
import FormLayout from "../../components/layout/FormLayout";
function Create() {
  const dispatch = useDispatch();
  let history = useHistory();
  const params = useParams<OrganisationParams>();
  const state = useSelector((state: RootState) => state);
  const [isFetching, setIsFetching] = useState(false);
  const [organisation, setOrganisation] = useState({
    name: "",
    slug: "",
    hasCRM: false,
    hasDashboard: false,
  });

  useEffect(() => {
    if (state.organisation_list.updateSuccess) {
      dispatch(unsetOrganisationUpdateSuccessFlag());
      history.push(Routes.ORGANISATIONS);
    }
  }, [state.organisation_list.updateSuccess]);

  useEffect(() => {
    setIsFetching(true);
    fetch(`${process.env.REACT_APP_BASE_URL}/v1/organisation/${params.id}`, {
      method: "get",
      headers: {
        Authorization: "Bearer " + state.current_user.user?.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        delete data.id;
        setOrganisation({
          ...organisation,
          name: data.name,
          slug: data.slug,
          hasCRM: data.hasCRM,
          hasDashboard: data.hasDashboard,
        });
        setIsFetching(false);
      });
  }, [state.current_user]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrganisation({
      ...organisation,
      [event.target.name]: event.target.value,
    });
  };
  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrganisation({
      ...organisation,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    let valid = validateForm();

    if (valid) {
      let reqBody: any = {};
      reqBody["organisation"] = organisation;
      dispatch(updateOrganisation(Number(params.id), reqBody));
    }
  };

  const validateForm = () => {
    return true;
  };

  return (
    <>
      <div className="container">
        <FormLayout
          loading={null}
          formHeading="Edit Organisation"
          backLink={Routes.INSTANCE_PORTAL}
          size="md"
        >
          <OrganisationForm
            organisation={organisation}
            handleChange={handleChange}
            handleToggleChange={handleToggleChange}
            handleSubmit={handleSubmit}
            isFetching={false}
            isSubmitting={state.organisation_list.creating}
          />
        </FormLayout>
      </div>
    </>
  );
}
interface OrganisationParams {
  id: string;
}

export default Create;
