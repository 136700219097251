import React, { useRef, useState } from "react";
import { Grid, IconButton } from "@material-ui/core";
import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import { PropertyValue } from "../../pages/site/entities/create";
import { Editor as LegacyEditor } from "@tinymce/tinymce-react";
import ImageIcon from "@material-ui/icons/Image";
import Alert from "@material-ui/lab/Alert";
import ImageSelectModal from "../assets/ImageSelectModal";
import Editor from "../../components/editor/lexical/Editor";

const RichTextAreaInput = (props: RichTextAreaInputProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [isLegacyEditor, setIsLegacyEditor] = useState(true);
  const editorRef = useRef(null);

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const setImage = (imagePath: string) => {
    navigator.clipboard.writeText(imagePath);
    setOpenToast(true);
  };

  const handleToastClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenToast(false);
  };

  const handleEditorChange = () => {
    if (editorRef.current) {
      // @ts-expect-error
      props.handleChange(editorRef.current.getContent(), props.index);
    }
  };

  const handleEditorV2Change = (value: string) => {
    props.handleChange(value, props.index);
  };

  const position: SnackbarOrigin = { vertical: "top", horizontal: "center" };

  return (
    <Grid item xs={12}>
      <p className="entity__inputHeading">{props.propertyValue.display_name}</p>
      {!isLegacyEditor ? (
        <Editor
          value={props.propertyValue.value}
          // handleChange={handleEditorV2Change}
          toggleLegacyEditor={setIsLegacyEditor}
        />
      ) : (
        <>
          <IconButton onClick={() => setOpenModal(true)}>
            <ImageIcon />
          </IconButton>

          {openModal ? (
            <ImageSelectModal
              open={openModal}
              handleClose={handleModalClose}
              setImagePath={setImage}
            />
          ) : null}
          {openToast ? (
            <Snackbar
              anchorOrigin={position}
              open={openToast}
              onClose={handleToastClose}
              key={position.vertical + position.horizontal}
            >
              <Alert severity="info" onClose={handleToastClose}>
                Copied Image Url to clipboard
              </Alert>
            </Snackbar>
          ) : null}
          <LegacyEditor
            // @ts-expect-error
            onInit={(evt, editor) => (editorRef.current = editor)}
            apiKey="2dqjlzhfais6i4d8ltfd45j4viaj62fbalr7re4gd73lzci0"
            value={props.propertyValue.value}
            init={{
              height: 700,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | " +
                "bold italic |  bullist numlist | blockquote code | link image | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
            onEditorChange={handleEditorChange}
          />
        </>
      )}
    </Grid>
  );
};

interface RichTextAreaInputProps {
  index: number;
  propertyValue: PropertyValue;
  handleChange: (value: string, index: number) => void;
}

export default RichTextAreaInput;
