import { gql } from '@apollo/client';

const DELETE_RECORD = gql`
    mutation delete_record($id: ID!) {
        record_delete(id: $id) {
            id
        }
    }
`

export default DELETE_RECORD;