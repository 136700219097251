
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const Loader = (props: loaderProps) => {
    const classes = useStyles();

    return(
    <Backdrop className={classes.backdrop} open={props.open}>
        <CircularProgress color="inherit" />
    </Backdrop>
    );
}

interface loaderProps {
    open: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);


export default Loader;