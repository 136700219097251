import { gql } from '@apollo/client';

const GET_INFO_PAGES = gql`
query get_info_collection($per_page: Int = 2000 $page: Int = 1) {
  models(
    filter: { 
      name: { exact: "modules/infopages/info_collection_page" }, 
      deleted_at: { exists: false } 
    }, 
    per_page: $per_page, 
    page: $page,
  ) {
    results {
      id
      created_at
      updated_at
      properties
    }
  }
}
  `

export default GET_INFO_PAGES;