import { gql } from '@apollo/client';

const SET_CONSTANT = gql`
    mutation set_constant($name: String!, $value: String!){
        constant_set(name: $name, value: $value) {
            id: name
            name
            value
            updated_at
            created_at
        }
    }
`

export default SET_CONSTANT;