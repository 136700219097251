

  import { gql } from '@apollo/client';

const CREATE_INFO = gql`
mutation MyMutation($properties: [CustomAttributeInputType]!) {
  model_create(
    model: {model_schema_name: "modules/infopages/info_article", properties: $properties}
  ) {
    id
    created_at
    updated_at
    properties
  }
}
`

export default CREATE_INFO;