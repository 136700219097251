import { gql } from '@apollo/client';

const GET_REDIRECTS = gql`
    query get_admin_pages($id: ID){
        admin_pages(
                per_page: 1000, 
                page: 1, 
                filter: {
                    metadata: {attribute: {key: "type", value: "redirect"}},  
                    id: {value: $id}
                }
            ){
            results {
                id
                slug
                created_at
                updated_at
                redirect_to
                redirect_code
                metadata
                physical_file_path
            }
        }
    }
`

export default GET_REDIRECTS;