import { gql } from '@apollo/client';

const GET_ASSETS = gql`
    query get_assets {
        admin_assets(
            page: 1, 
            per_page: 2000, 
            filter: {
                physical_file_path: {starts_with: "assets/images"}
            }
        ) {
            results {
                id
                created_at
                updated_at
                name
                metadata
                url
                physical_file_path
            }
        }
    }
`

export default GET_ASSETS;