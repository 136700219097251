import { TextField, Grid } from '@material-ui/core';
import React from 'react';
import { PropertyValue } from '../../pages/site/entities/create';

const TimeInput = (props: DateTimeInputProps) => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.handleChange(event.target.value, props.index);
    }
    
    return(
        <Grid item xs={12} lg={6}>
            <p className="entity__inputHeading">{props.propertyValue.display_name}</p>
            <TextField
                
                type="time"
                onChange={handleChange}
                InputLabelProps={{
                    shrink: true,
                }}
                variant="outlined"
            />
        </Grid>
    );
}

interface DateTimeInputProps {
    index: number
    propertyValue: PropertyValue
    handleChange: (value: string, index: number) => void
}


export default TimeInput;