import React from 'react';
import { TextField, Button, Switch, FormControlLabel, FormControl, Input, InputLabel, Chip, Select, MenuItem, CircularProgress, Tooltip } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';


function UserForm(props: UserFormProps){
    return (
        <form className='form'>

                {props.isFetching ? (
                    <Skeleton variant="rect" width={"100%"} height={56} />
                ) : ( 
                        <TextField
                        className="form__input"
                            variant="outlined"
                            type="text"
                            name="name"
                            onChange={props.handleChange}
                            value={props.user.name}
                            label="Name"
                            fullWidth
                        />
                    )}


                {props.isFetching ? (
                    <Skeleton variant="rect" width={"100%"} height={56} />
                ) : (
                        <TextField
                            className="form__input"
                            variant="outlined"
                            type="email"
                            name="email"
                            onChange={props.handleChange}
                            value={props.user.email}
                            label="Email"
                            required
                            fullWidth
                        />
                    )}

                {props.isFetching ? (
                    <Skeleton variant="rect" width={"100%"} height={56} />
                ) : (
                        <TextField
                        className="form__input"
                            variant="outlined"
                            type="password"
                            name="password"
                            onChange={props.handleChange}
                            value={props.user.password}
                            label="Password"
                            required
                            fullWidth
                        />
                    )}

                {props.currentUserRole === "ADMIN" ? (
                    <>
                    { props.isFetching ? <Skeleton variant="rect" width={"100%"} height={56} /> 
                    : (
                        <Tooltip
                            title="Select the permissions for the user."
                            placement="right"
                        >
                            <FormControl className="form__input">
                                <InputLabel>User Type</InputLabel>
                                <Select
                                    value={props.user.role}
                                    onChange={props.handleChange}
                                    name="role"
                                    fullWidth
                                    
                                >
                                    <MenuItem value={"ADMIN"}>Admin</MenuItem>
                                    <MenuItem value={"USER"}>Client</MenuItem>
                                </Select>
                            </FormControl>
                            </Tooltip>
                    ) } 
                    
                    { props.organisationList && props.user.role === "USER" ?  (
                        <Tooltip title="Select the Organisation you want the user to belong to."
                            placement="right"
                        >
                        <FormControl className="form__input">
                            <InputLabel>Organisation</InputLabel>
                            <Select
                                value={props.user.organisationID}
                                onChange={props.handleChange}
                                name="organisationID"
                                fullWidth
                                input={<Input />}
                            >
                                {props.organisationList.map((organisation: any) => (
                                    
                                    <MenuItem key={organisation.id} value={organisation.id}>
                                        {organisation.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        </Tooltip>
                    ) : (
                        null
                    ) }
                    </>
                    )
                    : null
                }

                {props.isFetching ? (
                    <Skeleton variant="rect" width={"100%"} height={56} />
                ) : (
                    <Button className="form_button" variant="contained" size="large" color="primary" onClick={props.handleSubmit} disabled={props.isSubmitting}>
                        {props.isSubmitting ? <CircularProgress /> : 'Submit' }
                    </Button>
                )}
            
        </form>
    );
}

interface UserFormProps {
    isFetching: boolean
    isSubmitting: boolean
    handleChange: any
    handleSubmit: any
    user: any
    organisationList: any
    currentUserRole: any
}



export default UserForm;