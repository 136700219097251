import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  CircularProgress,
  Typography,
  Button,
  Container,
  InputAdornment,
  Grid,
  IconButton,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  MenuItem,
  TextField,
  useTheme,
} from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SearchIcon from "@material-ui/icons/Search";
import InstanceCard from "../components/instance/InstanceCard";
import {
  fetchSiteListIfNeeded,
  deleteInstance,
} from "../store/siteList/actions";
import * as Routes from "../routes";
import { RootState } from "../store/store";
import Appbar from "../components/layout/InstanceAppbar";
import EmptyContentMessage from "../components/layout/EmptyContentMessage";
import WaveBi from "../assets/logo-wavebi.jpg";

function InstancePortal() {
  const classes = useStyles();
  const theme = useTheme();
  const state = useSelector((state: RootState) => state);
  const currentUser = state.current_user.user;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSiteListIfNeeded());
  }, [dispatch, currentUser]);

  const handleDelete = (id: number) => {
    dispatch(deleteInstance(id));
  };

  const [searchValue, setSearchValue] = useState("");
  const [environment, setEnvironment] = useState("PRODUCTION");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value as string);
  };

  const handleEnvironmentChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEnvironment(event.target.value as string);
  };

  const filteredInstances = useMemo(() => {
    let filteredInstances = state.site_list.sites;

    if (searchValue && searchValue.length > 1) {
      filteredInstances = filteredInstances.filter((instance: any) =>
        instance.name.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    if (environment !== "ALL") {
      filteredInstances = filteredInstances.filter(
        (instance: any) =>
          (environment === "STAGING" && instance.isStaging) ||
          (environment === "PRODUCTION" && !instance.isStaging)
      );
    }

    return filteredInstances;
  }, [state.site_list.sites, searchValue, environment]);

  return (
    <>
      <Container className="container">
        <div
          className={classes.action_bar}
          style={{ marginBottom: theme.spacing(3) }}
        >
          <TextField
            variant="outlined"
            autoFocus
            size="small"
            label="Search"
            onChange={handleChange}
            value={searchValue}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <div className={classes.action_bar}>
            <TextField
              select
              style={{
                width: "10rem",
              }}
              size="small"
              variant="outlined"
              label="Environment"
              value={environment}
              onChange={handleEnvironmentChange}
            >
              <MenuItem value={"STAGING"}>Staging</MenuItem>
              <MenuItem value={"PRODUCTION"}>Production</MenuItem>
              <MenuItem value={"ALL"}>All</MenuItem>
            </TextField>
            {currentUser?.role === "ADMIN" ? (
              <>
                <Button
                  className="mobile-hide"
                  component={RouterLink}
                  startIcon={<AddCircleIcon />}
                  to={Routes.INSTANCE_CREATE}
                  variant="contained"
                  color="primary"
                  size="medium"
                >
                  Add Website
                </Button>
                <IconButton
                  component={RouterLink}
                  to={Routes.INSTANCE_CREATE}
                  className="desktop-hide"
                  color="primary"
                  aria-label="add to shopping cart"
                >
                  <AddCircleIcon />
                </IconButton>
              </>
            ) : null}
          </div>
        </div>
        {state.site_list.sites.length === 0 ? (
          state.site_list.fetching ? (
            <div className={classes.loader}>
              <CircularProgress />
            </div>
          ) : (
            <EmptyContentMessage message={"No sites found"} />
          )
        ) : (
          <>
            <Grid container spacing={4}>
              {filteredInstances.map((instance: any) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={instance.id}>
                    <InstanceCard
                      instanceID={instance.id}
                      instanceName={instance.name}
                      instanceSlug={instance.slug}
                      instanceUrl={instance.url}
                      instanceImage={instance.image}
                      instanceEnv={
                        instance.isStaging ? "STAGING" : "PRODUCTION"
                      }
                      handleDelete={handleDelete}
                      currentUser={currentUser}
                    />
                  </Grid>
                );
              })}
              {currentUser?.role === "USER" &&
              currentUser.organisation &&
              currentUser.organisation.hasCRM ? (
                <Grid item xs={12} sm={6} md={4}>
                  <a href={process.env.REACT_APP_BI_URL}>
                    <Card variant="outlined" className={classes.root}>
                      <CardActionArea style={{ height: "100%" }}>
                        <CardMedia
                          style={{
                            height: "76%",
                            objectFit: "contain",
                            objectPosition: "center",
                            padding: 20,
                          }}
                          className="media"
                          component="img"
                          image={WaveBi}
                          title="WaveBI"
                        />
                        <CardContent className="actions">
                          <Typography variant="h6" component="h2">
                            {currentUser.organisation.name} Analytics
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </a>
                </Grid>
              ) : null}
            </Grid>
            <Appbar />
          </>
        )}
      </Container>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  loader: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  action_bar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(2),
  },
}));

export default InstancePortal;
