import { createMuiTheme, createStyles } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';


export const baseTheme = createMuiTheme({
  
  props: {
    MuiMenu: {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    getContentAnchorEl: null
  },
},

overrides: {

MuiContainer: {
  maxWidthLg: {
    maxWidth: '1500px!important',
    
  }, 
},
  MuiButton: {
    text: {
      fontWeight: 600
    },
  },
  MuiTooltip : {
    tooltip: {
      fontSize: '13px',
    },
  },
  MuiListItemText : {
    secondary : {
      fontSize: '14px',
      fontWeight: 400,
    },
  },
  MuiTableCell: {
    root: {
      padding: "24px"
    },
  },
  MuiTypography: {
    h1: {
      fontWeight: 700,
      fontSize: '48px',
    },
    h2: {
      fontWeight: 700,
      fontSize: '42px',
    },
    h3: {
      fontWeight: 700,
      fontSize: '28px',
    },
    h4: {
      fontWeight: 500,
      fontSize: '24px',
    },
    h5: {
      fontWeight: 500,
      fontSize: '20px',
    },
    h6: {
      fontSize: "16px",
      fontWeight: 500,
    },
  },
},
})

export const lightTheme = createMuiTheme({
...baseTheme,
palette: {
  type: 'light',
  primary: {
    main: blue[800],
    light: blue[500],
  },
  secondary: {
    main: red[500],
  },
  info: {
    main: grey[50],
    light: "white"
  },
},
  
});

export const darkTheme = createMuiTheme({
  ...baseTheme,
  palette: {
    type: 'dark',
    primary: {
      main: blue[600],
    },
    secondary: {
      main: red[500],
    },
    success: {
      main: green[500],
    },
    info: {
      main: grey[700],
      light: grey[800]
    },
  },
});
