import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  FETCHING,
  FETCHED,
  INVALIDATE,
  CREATED,
  CREATING,
  UPDATED,
  UPDATING,
  DELETING,
  DELETED,
  RESET_CREATE_SUCCESS,
  RESET_DELETE_SUCCESS,
  RESET_UPDATE_SUCCESS,
  SiteListState,
  CREATE_ERROR,
} from "./types";
import { RootState } from "../store";

export function invalidateSiteList() {
  return { type: INVALIDATE };
}

export function unsetSiteCreateSuccessFlag() {
  return { type: RESET_CREATE_SUCCESS };
}
export function unsetSiteUpdateSuccessFlag() {
  return { type: RESET_UPDATE_SUCCESS };
}
export function unsetSiteDeleteSuccessFlag() {
  return { type: RESET_DELETE_SUCCESS };
}

export function fetchSiteListIfNeeded() {
  return function (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) {
    const state: RootState = getState();
    if (shouldFetchSiteList(state.site_list)) {
      return dispatch(fetchSiteList());
    }
  };
}

function shouldFetchSiteList(state: SiteListState) {
  if (!Array.isArray(state.sites) || state.sites.length === 0) {
    return true;
  }
  if (state.fetching) {
    return false;
  }
  return state.didInvalidate;
}

function fetchSiteList() {
  return function (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) {
    const state: RootState = getState();

    dispatch({ type: FETCHING });
    return fetch(`${process.env.REACT_APP_BASE_URL}/v1/instance`, {
      method: "get",
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        dispatch({ type: FETCHED, payload: json });
      });
  };
}

export function createInstance(instance: any) {
  return function (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) {
    const state: RootState = getState();

    dispatch({ type: CREATING });
    return fetch(`${process.env.REACT_APP_BASE_URL}/v1/instance`, {
      method: "post",
      body: JSON.stringify(instance),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.current_user.user?.token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          dispatch({ type: CREATE_ERROR, payload: response.json() });
        }
      })
      .then((json) => {
        if (!json.errors) {
          dispatch({ type: CREATED, payload: json });
        } else {
          for (let error of json.errors) {
            throw new Error(error.message);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function updateInstance(id: number, instance: any) {
  return function (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) {
    const state: RootState = getState();

    console.log(instance);

    dispatch({ type: UPDATING });
    return fetch(`${process.env.REACT_APP_BASE_URL}/v1/instance/${id}`, {
      method: "put",
      body: JSON.stringify(instance),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.current_user.user?.token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Network Error");
        }
      })
      .then((json) => {
        if (!json.errors) {
          dispatch({ type: UPDATED, payload: json });
        } else {
          for (let error of json.errors) {
            throw new Error(error.message);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function deleteInstance(id: number) {
  return function (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) {
    const state: RootState = getState();

    dispatch({ type: DELETING });
    return fetch(`${process.env.REACT_APP_BASE_URL}/v1/instance/${id}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.current_user.user?.token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Network Error");
        }
      })
      .then((json) => {
        if (!json.errors) {
          dispatch({ type: DELETED, payload: json });
        } else {
          for (let error of json.errors) {
            throw new Error(error.message);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
