import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSelector } from "react-redux";

import * as Routes from "../../../routes";

const FormActionCell = (props) => {
  const state = useSelector((state) => state);

  const [openDeleteWarning, setOpenDeleteWarning] = useState(false);

  const handleOpenDelete = () => {
    setOpenDeleteWarning(true);
  };

  const handleCloseDelete = () => {
    setOpenDeleteWarning(false);
  };

  const handleDeleteAndClose = () => {
    props.handleDelete(props.value);
    handleCloseDelete();
  };

  return (
    <>
    <div className="action_cell">
      <IconButton
        component={RouterLink}
        to={Routes.SUBURB_EDIT_LINK(props.site, props.value)}
      >
        <EditIcon />
      </IconButton>
      <IconButton onClick={handleOpenDelete}>
        <DeleteIcon />
      </IconButton>
      {state.current_site.site?.build ? (
        <IconButton
          component={RouterLink}
          to={Routes.SUBURB_CONTENT_LINK(props.site, props.value)}
        >
          <ChevronRightIcon />
        </IconButton>
    
      ) : null}
           </div>
      <Dialog open={openDeleteWarning} onClose={handleCloseDelete}>
        <DialogTitle>{"Delete Page"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you wish to delete this Item? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteAndClose}
            color="secondary"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FormActionCell;
