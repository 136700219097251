import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import CityForm from "../../../../../../components/forms/cityContent";
import { useSelector } from "react-redux";
import CREATE_CITY_CONTENT from "../../../../../../graphql/areas/CREATE_CITY_CONTENT";
import UPDATE_CITY_CONTENT from "../../../../../../graphql/areas/UPDATE_CITY_CONTENT";
import GET_CITY_CONTENT from "../../../../../../graphql/areas/GET_CITY_CONTENT";
import GET_CITIES from "../../../../../../graphql/areas/GET_CITIES";
import GET_SERVICES from "../../../../../../graphql/areas/GET_SERVICES";
import GET_SIDEMENUS from "../../../../../../graphql/areas/GET_SIDEMENUS";
import GET_MAIN_SERVICES from "../../../../../../graphql/areas/GET_MAIN_SERVICES";
import GET_MENUS from "../../../../../../graphql/areas/GET_MENUS";
import { useMutation, useQuery } from "@apollo/client";
import Loader from "../../../../../../components/layout/Loader";
import ErrorToast from "../../../../../../components/layout/ErrorToast";
import * as Routes from "../../../../../../routes";
import { RootState } from "../../../../../../store/store";
import FormLayout from "../../../../../../components/layout/FormLayout";

const CreateCityPage = () => {
  let history = useHistory();
  const params = useParams<SiteParams>();
  const state = useSelector((state: RootState) => state);
  const [content, setContent] = useState({
    name: "",
    slug: "",
    city_id: 0,
    content: "",
    subtitle: "",
    form_heading: "",
    description: "",
    meta_title: "",
    meta_desc: "",
    breadcrumbs: [] as any,
    side_menu: "",
  });
  const [city, setCity] = useState<any>(null);
  const [services, setServices] = useState<any[]>([]);
  const [mainServices, setMainServices] = useState<any[]>([]);
  const [sideMenus, setSideMenus] = useState<any[]>([]);
  const [menus, setMenus] = useState<any[]>([]);
  const [selectedService, setSelectedService] = useState<any>(null);
  const [newBreadcrumb, setNewBreadcrumb] = useState({
    name: "",
    title: "",
    link: "",
  });

  //get city data for edit page
  const {
    loading: contentLoading,
    error: contentError,
    data: contentData,
  } = useQuery(GET_CITY_CONTENT, {
    variables: {
      id: params.id,
    },
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  useEffect(() => {
    if (contentData && params.id) {
      const result = contentData.models.results[0];
      setContent({
        ...content,
        name: result.properties.name,
        slug: result.properties.slug,
        subtitle: result.properties.subtitle,
        meta_title: result.properties.meta_title,
        meta_desc: result.properties.meta_desc,
        description: result.properties.description,
        form_heading: result.properties.form_heading,
        content: result.properties.content,
        side_menu: result.properties.side_menu,
        breadcrumbs: result.properties.breadcrumbs.map((crumb: string) =>
          JSON.parse(crumb)
        ),
        city_id: Number(result.properties.city_id),
      });
    }
  }, [contentData]);

  // get cities for city id select
  const {
    loading: cityLoading,
    error: cityError,
    data: cityData,
  } = useQuery(GET_CITIES, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    variables: {
      id: params.cityID,
    },
  });

  // get services for service select
  const {
    loading: serviceLoading,
    error: serviceError,
    data: serviceData,
  } = useQuery(GET_SERVICES, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  // get Main services for Main service select
  const {
    loading: mainServiceLoading,
    error: mainServiceError,
    data: mainServiceData,
  } = useQuery(GET_MAIN_SERVICES, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  // get sideMenus for sidemenu select
  const {
    loading: sideMenuLoading,
    error: sideMenuError,
    data: sideMenuData,
  } = useQuery(GET_SIDEMENUS, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  // get Menus for sidemenu select
  const {
    loading: menuLoading,
    error: menuError,
    data: menuData,
  } = useQuery(GET_MENUS, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  useEffect(() => {
    const mainSerivceResult = mainServiceData?.records?.results;
    const sideMenuResult = sideMenuData?.models.results;
    const serviceResult = serviceData?.models.results;
    const menuResult = menuData?.models.results;

    const cityResult = cityData?.models.results;
    if (cityResult) {
      setCity(cityResult[0]);
    }

    setMainServices(mainSerivceResult);
    setServices(serviceResult);
    setSideMenus(sideMenuResult);
    setMenus(menuResult);
  }, [cityData, mainServiceData, serviceData, sideMenuData, menuData]);

  const [
    createContent,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(CREATE_CITY_CONTENT, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const newItem = data?.model_create;
      const existingItems: any = cache.readQuery({
        query: GET_CITY_CONTENT,
        variables: { city_id: Number(params.cityID) },
      });

      if (newItem && existingItems) {
        cache.writeQuery({
          query: GET_CITY_CONTENT,
          data: {
            models: {
              results: [...existingItems?.models.results, newItem],
            },
          },
        });
      }
    },
    onCompleted: (data) => {
      if (data) {
        history.push(Routes.CITY_CONTENT_LINK(params.site, params.cityID));
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [
    updateContent,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useMutation(UPDATE_CITY_CONTENT, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const newItem = data?.model_update;
      const existingItems: any = cache.readQuery({
        query: GET_CITY_CONTENT,
        variables: { city_id: Number(params.cityID) },
      });

      const updatedItems = existingItems.models.results.map((item: any) => {
        if (item.id === newItem.id) {
          return newItem;
        }
        return item;
      });

      if (newItem && existingItems) {
        cache.writeQuery({
          query: GET_CITY_CONTENT,
          data: {
            models: {
              results: updatedItems,
            },
          },
        });
      }
    },
    onCompleted: (data) => {
      if (data) {
        history.push(Routes.CITY_CONTENT_LINK(params.site, params.cityID));
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContent({
      ...content,
      [event.target.name]: event.target.value as string,
    });
  };

  const handleBodyChange = (body: string) => {
    setContent({
      ...content,
      content: body,
    });
  };

  const handleDescriptionChange = (body: string) => {
    setContent({
      ...content,
      description: body,
    });
  };

  const handleSideMenuChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setContent({
      ...content,
      side_menu: event.target.value,
    });
  };

  const handleServiceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setContent((prevContent) => ({
      ...prevContent,
      slug: `${event.target.value}/${city.properties.slug}`,
    }));
  };

  const handleNewBreadcrumb = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewBreadcrumb({
      ...newBreadcrumb,
      [event.target.name]: event.target.value,
    });
  };

  const editBreadcrumb = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const editedBreadcrumbs = content.breadcrumbs.map(
      (link: any, i: number) => {
        if (i === index) {
          return {
            ...link,
            [event.target.name]: event.target.value,
          };
        }
        return link;
      }
    );
    setContent({
      ...content,
      breadcrumbs: editedBreadcrumbs,
    });
  };

  const handleBreadcrumbDelete = (index: number) => {
    const filteredBreadcrumbs = content.breadcrumbs.filter(
      (link: any, i: number) => {
        if (i !== index) {
          return link;
        }
      }
    );
    setContent({
      ...content,
      breadcrumbs: filteredBreadcrumbs,
    });
  };

  const handleAddNewBReadcrumb = () => {
    setContent({
      ...content,
      breadcrumbs: content.breadcrumbs.concat(newBreadcrumb),
    });
    setNewBreadcrumb({
      name: "",
      title: "",
      link: "",
    });
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (params.id) {
      updateContent({
        variables: {
          id: params.id,
          properties: [
            { name: "slug", value: content.slug },
            { name: "content", value: content.content },
            { name: "name", value: content.name },
            { name: "subtitle", value: content.subtitle },
            { name: "description", value: content.description },
            { name: "meta_title", value: content.meta_title },
            { name: "meta_desc", value: content.meta_desc },
            { name: "form_heading", value: content.form_heading },
            {
              name: "breadcrumbs",
              value_array: content.breadcrumbs.map((crumb: any) =>
                JSON.stringify(crumb)
              ),
            },
            { name: "side_menu", value: String(content.side_menu) },
            { name: "city_id", value_int: Number(content.city_id) },
          ],
        },
      });
    } else {
      createContent({
        variables: {
          properties: [
            { name: "slug", value: content.slug },
            { name: "content", value: content.content },
            { name: "name", value: content.name },
            { name: "subtitle", value: content.subtitle },
            { name: "description", value: content.description },
            { name: "meta_title", value: content.meta_title },
            { name: "meta_desc", value: content.meta_desc },
            { name: "form_heading", value: String(content.form_heading) },
            {
              name: "breadcrumbs",
              value_array: content.breadcrumbs.map((crumb: any) =>
                JSON.stringify(crumb)
              ),
            },
            { name: "side_menu", value: String(content.side_menu) },
            { name: "city_id", value_int: Number(params.cityID) },
          ],
        },
      });
    }
  };

  return (
    <FormLayout
      loading={mutationLoading}
      formHeading={
        params.id
          ? "Update Service Area Content"
          : "Create Service Area Content"
      }
      backLink={Routes.CITY_CONTENT_LINK(params.site, params.cityID)}
      size="lg"
    >
      {mutationError ? (
        <ErrorToast open={true} errorMessage={mutationError.message} />
      ) : null}
      {mutationLoading ? <Loader open={true} /> : null}
      <CityForm
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handleBodyChange={handleBodyChange}
        handleDescriptionChange={handleDescriptionChange}
        handleSideMenuChange={handleSideMenuChange}
        newBreadcrumb={newBreadcrumb}
        handleAddNewBreadcrumb={handleAddNewBReadcrumb}
        handleNewBreadcrumb={handleNewBreadcrumb}
        editBreadcrumb={editBreadcrumb}
        deleteBreadcrumb={handleBreadcrumbDelete}
        sideMenus={sideMenus}
        menus={menus}
        isFetching={false}
        selectedService={selectedService}
        services={services}
        mainServices={mainServices}
        handleServiceChange={handleServiceChange}
        content={content}
        isSubmitting={mutationLoading}
      />
    </FormLayout>
  );
};

interface SiteParams {
  site: string;
  cityID: string;
  id?: string;
}

export default CreateCityPage;
