import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import jwt_decode from "jwt-decode";
import { lightTheme, darkTheme } from "./themes/Theme";
import * as Routes from "./routes";
import InstancePortal from "./pages/InstancePortal";
import Site from "./pages/site";
import Users from "./pages/users";
import UserCreator from "./pages/users/create";
import UserUpdater from "./pages/users/update";
import InstanceCreator from "./pages/instance/create";
import InstanceUpdater from "./pages/instance/update";
import OrganisationCreate from "./pages/organisations/create";
import OrganisationUpdate from "./pages/organisations/update";
import Organisations from "./pages/organisations";
import Account from "./pages/account";
import Styles from "./themes/Styles";
import { setAuthenticatedUser } from "./store/currentUser/actions";
import { RootState } from "./store/store";

function App() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);

  useEffect(() => {
    try {
      const user = extractAuthCookieInfo();
      dispatch(setAuthenticatedUser(user));
    } catch (error: any) {
      console.log(error.message);
      window.location.href = process.env.REACT_APP_LOGIN_URL as string;
    }
  }, [dispatch]);

  return (
    <ThemeProvider
      theme={state.site_mode.isDarkMode ? { ...darkTheme } : { ...lightTheme }}
    >
      <Router>
        <CssBaseline />
        <main className={classes.main}>
          <Switch>
            <Route path={Routes.SITE} component={Site} />
            <Route
              exact
              path={Routes.INSTANCE_PORTAL}
              component={InstancePortal}
            />
            <Route
              exact
              path={Routes.INSTANCE_CREATE}
              component={InstanceCreator}
            />
            <Route
              exact
              path={Routes.INSTANCE_EDIT}
              component={InstanceUpdater}
            />
            <Route exact path={Routes.ACCOUNT} component={Account} />
            <Route exact path={Routes.USERS} component={Users} />
            <Route exact path={Routes.USERS_CREATE} component={UserCreator} />
            <Route exact path={Routes.USERS_EDIT} component={UserUpdater} />
            <Route
              exact
              path={Routes.ORGANISATIONS}
              component={Organisations}
            />
            <Route
              exact
              path={Routes.ORGANISATION_CREATE}
              component={OrganisationCreate}
            />
            <Route
              exact
              path={Routes.ORGANISATION_EDIT}
              component={OrganisationUpdate}
            />
          </Switch>
        </main>
      </Router>
    </ThemeProvider>
  );
}

const useStyles = makeStyles((theme) => Styles(theme));
const extractAuthCookieInfo = () => {
  const cookie = document.cookie
    .split(";")
    .find((row) =>
      row.trim().startsWith(process.env.REACT_APP_COOKIE_NAME as string)
    );

  if (cookie) {
    const [cookieKey, cookieValue] = cookie.split("=");
    const token: any = jwt_decode(cookieValue);
    let dateNow = new Date();

    if (token.exp * 1000 > dateNow.getTime()) {
      return {
        ...token.user,
        token: cookieValue,
      };
    } else {
      throw new Error(`${cookieKey} token is expired`);
    }
  } else {
    throw new Error("no auth cookie");
  }
};

export default App;
