import { gql } from "@apollo/client";

const UPDATE_REGION = gql`
  mutation region_update($id: ID!, $properties: [CustomAttributeInputType]!) {
    model_update(
      id: $id
      model: {
        model_schema_name: "modules/areas/region"
        properties: $properties
      }
    ) {
      id
      created_at
      updated_at
      properties
    }
  }
`;

export default UPDATE_REGION;
