import { gql } from "@apollo/client";

const GET_COMPONENT_CONTENT_TYPES = gql`
  query get_component_content_types($name: String) {
    admin_tables(
      filter: {
        name: { exact: $name }
        metadata: {
          attribute: { key: "public_read", value_boolean: true }
          has_key: "component"
        }
      }
    ) {
      results {
        id
        created_at
        updated_at
        name
        metadata
        parameterized_name
        physical_file_path
        properties {
          name
          attribute_type
          belongs_to
          metadata
        }
      }
    }
  }
`;

export default GET_COMPONENT_CONTENT_TYPES;
