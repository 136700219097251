import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import SuburbForm from "../../../../../components/forms/suburb";
import { useSelector } from "react-redux";
import CREATE_SUBURB from "../../../../../graphql/areas/CREATE_SUBURB";
import UPDATE_SUBURB from "../../../../../graphql/areas/UPDATE_SUBURB";
import GET_CITIES from "../../../../../graphql/areas/GET_CITIES";
import { useMutation, useQuery } from "@apollo/client";
import ErrorToast from "../../../../../components/layout/ErrorToast";
import * as Routes from "../../../../../routes";
import { RootState } from "../../../../../store/store";
import slugify from "slugify";
import FormLayout from "../../../../../components/layout/FormLayout";
import GET_SUBURBS from "../../../../../graphql/areas/GET_SUBURBS";
import GET_SERVICES from "../../../../../graphql/areas/GET_SERVICES";
import GET_MAIN_SERVICES from "../../../../../graphql/areas/GET_MAIN_SERVICES";
import { handleChange, handleBlur } from "../../../../../utils/slugsCheck";

const CreateSuburbPage = () => {
  let history = useHistory();
  const params = useParams<SiteParams>();
  const state = useSelector((state: RootState) => state);
  const [suburb, setSuburb] = useState({
    suburb: "",
    slug: "",
    postcode: "",
    service_ids: [] as any,
    city_id: "",
  });
  const [cities, setCities] = useState<any[]>([]);
  const [services, setServices] = useState<any[]>([]);
  const [mainServices, setMainServices] = useState<any[]>([]);

  // get cities for city id select
  const {
    loading: cityLoading,
    error: cityError,
    data: cityData,
  } = useQuery(GET_CITIES, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  useEffect(() => {
    if (cityData) {
      const result = cityData.models.results;
      setCities(result);
    }
  }, [cityData]);

  // get services for service multiselect
  const {
    loading: serviceLoading,
    error: serviceError,
    data: serviceData,
  } = useQuery(GET_SERVICES, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  useEffect(() => {
    if (serviceData) {
      const result = serviceData.models.results;
      setServices(result);
    }
  }, [serviceData]);

  // get Main services for Main service multiselect
  const {
    loading: mainServiceLoading,
    error: mainServiceError,
    data: mainServiceData,
  } = useQuery(GET_MAIN_SERVICES, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  useEffect(() => {
    if (mainServiceData) {
      const result = mainServiceData.records.results;

      setMainServices(result);
    }
  }, [mainServiceData]);

  //get suburbs for edit page
  const {
    loading: suburbLoading,
    error: suburbError,
    data: suburbData,
  } = useQuery(GET_SUBURBS, {
    variables: {
      id: params.id,
    },
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  useEffect(() => {
    if (suburbData && params.id) {
      const result = suburbData.models.results[0];
      setSuburb({
        ...suburb,
        suburb: result.properties.suburb,
        slug: result.properties.slug,
        postcode: result.properties.postcode,
        service_ids: result.properties.service_ids,
        city_id: result.properties.city_id,
      });
    }
  }, [suburbData]);

  const [
    createSuburb,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(CREATE_SUBURB, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const newItem = data?.model_create;
      const existingItems: any = cache.readQuery({ query: GET_SUBURBS });

      if (newItem && existingItems) {
        cache.writeQuery({
          query: GET_SUBURBS,
          data: {
            models: {
              results: [...existingItems?.models.results, newItem],
            },
          },
        });
      }
    },
    onCompleted: (data) => {
      if (data) {
        history.push(Routes.SUBURB_LINK(params.site));
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [
    updateSuburb,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useMutation(UPDATE_SUBURB, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const newInfo = data?.model_update;
      const existingInfos: any = cache.readQuery({ query: GET_SUBURBS });

      const updatedCities = existingInfos.models.results.map((item: any) => {
        if (item.id === newInfo.id) {
          return newInfo;
        }
        return item;
      });

      if (newInfo && existingInfos) {
        cache.writeQuery({
          query: GET_SUBURBS,
          data: {
            models: {
              results: updatedCities,
            },
          },
        });
      }
    },
    onCompleted: (data) => {
      if (data) {
        history.push(Routes.SUBURB_LINK(params.site));
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSuburb({
      ...suburb,
      city_id: event.target.value as string,
    });
  };

  const handleMultiChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { options } = event.target;
    console.log(options);
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }

    setSuburb({
      ...suburb,
      service_ids: value,
    });
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (params.id) {
      updateSuburb({
        variables: {
          id: params.id,
          properties: [
            { name: "slug", value: suburb.slug },
            { name: "suburb", value: suburb.suburb },
            { name: "postcode", value: suburb.postcode },
            { name: "city_id", value_int: Number(suburb.city_id) },
            { name: "service_ids", value_array: suburb.service_ids },
          ],
        },
      });
    } else {
      createSuburb({
        variables: {
          properties: [
            { name: "slug", value: suburb.slug },
            { name: "suburb", value: suburb.suburb },
            { name: "postcode", value: suburb.postcode },
            { name: "city_id", value_int: Number(suburb.city_id) },
            { name: "service_ids", value_array: suburb.service_ids },
          ],
        },
      });
    }
  };

  return (
    <FormLayout
      loading={mutationLoading}
      formHeading={params.id ? "Update Suburb/Town" : "Create Suburb/Town"}
      backLink={Routes.SUBURB_LINK(params.site)}
      size="lg"
    >
      {mutationError ? (
        <ErrorToast open={true} errorMessage={mutationError.message} />
      ) : null}
      <SuburbForm
        handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChange(e, setSuburb, suburb)
        }
        handleBlur={(e: React.FocusEvent<HTMLInputElement>) =>
          handleBlur(e, setSuburb, suburb)
        }
        handleSubmit={handleSubmit}
        handleMultiChange={handleMultiChange}
        handleSelectChange={handleSelectChange}
        isFetching={false}
        suburb={suburb}
        setSuburb={setSuburb}
        cities={cities}
        services={services}
        mainServices={mainServices}
        isSubmitting={mutationLoading}
      />
    </FormLayout>
  );
};

interface SiteParams {
  site: string;
  id?: string;
}

export default CreateSuburbPage;
