
const generateGraphQueryBody = (name: string, fields: any[]): string => {
    
    let hasSlug = false
    const relationships: Relationship[] = []
    for(const field of fields){
        if(field.name === "slug"){
            hasSlug = true
        }
        if(field.belongs_to){
            relationships.push({
                table: field.belongs_to,
                type: field.metadata.type,
                propertyName: field.name
            })
        }
    }

    const body = `query ${name}_get($per_page: Int = 2000, $page: Int = 1 ${ hasSlug ? "$slug: String" :  ""}) {
    records(per_page: $per_page, page: $page, filter: { table: { exact: "${name}" }, ${hasSlug ?  'properties: { name: "slug", value: $slug }' : ''} }) {
        results {
            id
            created_at
            updated_at
            table
            properties
            ${relationships.map(relationship => {
                let join = ""
                if(relationship.type === "one_to_one"){
                    join = `${relationship.propertyName}: related_record(join_on_property: "${relationship.propertyName}", table: "${relationship.table}"){ id properties }`
                }
                if(relationship.type === "many_to_one"){
                    join = `${relationship.propertyName}: related_records(foreign_property: "id", join_on_property: "${relationship.propertyName}", table: "${relationship.table}") { id properties }`
                }
                return join
            })}
        }
    }
}`

    return body;
}

interface Relationship{
    table: string
    type: string
    propertyName: string
} 


export default generateGraphQueryBody;