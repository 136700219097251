import React from "react";
import { PropertyValue } from "../../pages/site/entities/create";
import { Grid, Switch } from "@material-ui/core";

const BooleanInput = (props: BooleanInputProps) => {
  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleChange(event.target.checked, props.index);
  };

  return (
    <Grid item xs={12} lg={6}>
      <p className="entity__inputHeading">{props.propertyValue.display_name}</p>
      <Switch
        checked={props.propertyValue.value_boolean}
        onChange={handleSwitch}
        name="checkedA"
        color="primary"
      />
    </Grid>
  );
};

interface BooleanInputProps {
  index: number;
  propertyValue: PropertyValue;
  handleChange: (value: boolean, index: number) => void;
}

export default BooleanInput;
