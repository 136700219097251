import React, { useState } from 'react';
import { PropertyValue } from '../../pages/site/entities/create';
import { Button, IconButton, Grid} from '@material-ui/core';
import ImageSelectModal from '../assets/ImageSelectModal';
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { classicNameResolver } from 'typescript';

const ImageGroupInput = (props: ImageGroupInputProps) => {
    const [modalOpen, setModalOpen] = useState(false)
    
    const setImage = (imagePath: string) => {
        props.handleChange(imagePath, props.index)
    }

    const handleClose = () => {
        setModalOpen(false)
    }

    const handleOpen = () => {
        setModalOpen(true)
    }

    return(
        <Grid item xs={12} lg={6}>
            
            <p className="entity__inputHeading">{props.propertyValue.display_name}</p>
            <Button className="marginBottom" startIcon={<AddCircleOutlineIcon />} color="primary" onClick={handleOpen}>Add</Button>

            <Grid container spacing={2}>
            {
                props.propertyValue.value_array!.map((path: string, i) => (
                    
                         <Grid item xs={12} sm={4} className="entity__imageWrap">
                        <img className="entity__image" src={path} />
                        <IconButton className="entity__removeButton">
                        <CancelIcon onClick={() => props.handleRemove(props.index, i)} />
                        </IconButton>
                        </Grid>
                    
                ))
            }
            </Grid>
            <ImageSelectModal open={modalOpen} handleClose={handleClose} setImagePath={setImage} assetPath={props.collectionType} propertyValue={props.propertyValue} />
        </Grid>
    );
}

interface ImageGroupInputProps {
    index: number
    propertyValue: PropertyValue
    handleChange: (value: string, index: number) => void,
    handleRemove: (index: number, removeIndex: number) => void
    collectionType: string
}

export default ImageGroupInput;