import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import LayoutForm from "../../../components/forms/layout";
import { useSelector } from "react-redux";
import CREATE_LAYOUT from "../../../graphql/CREATE_LAYOUT";
import GET_LAYOUTS from "../../../graphql/GET_LAYOUTS";
import { useMutation } from "@apollo/client";
import ErrorToast from "../../../components/layout/ErrorToast";
import * as Routes from "../../../routes";
import { RootState } from "../../../store/store";
import slugify from "slugify";
import FormLayout from "../../../components/layout/FormLayout";

const CreateLayout = () => {
  let history = useHistory();
  const params = useParams<SiteParams>();
  const state = useSelector((state: RootState) => state);
  const [layout, setLayout] = useState({
    path: "",
    body: "",
    physical_file_path: "",
  });
  const [metadata, setMetadata] = useState({
    name: "",
    type: "",
  });

  interface SiteParams {
    site: string;
  }

  const [
    createLayout,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(CREATE_LAYOUT, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const newLayout = data?.admin_liquid_layout_create;
      const existingLayouts: any = cache.readQuery({ query: GET_LAYOUTS });

      if (newLayout && existingLayouts) {
        cache.writeQuery({
          query: GET_LAYOUTS,
          data: {
            admin_liquid_layouts: {
              results: [
                ...existingLayouts?.admin_liquid_layouts.results,
                newLayout,
              ],
            },
          },
        });
      }
    },
    onCompleted: (data) => {
      if (data) {
        history.push(Routes.LAYOUTS_LINK(params.site));
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLayout({
      ...layout,
      [event.target.name]: event.target.value as string,
    });
  };

  const handleBodyChange = (content: string) => {
    setLayout({
      ...layout,
      body: content,
    });
  };

  const handleMetadataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const slugifiedName = slugify(event.target.value).toLowerCase();
    if (event.target.name === "name") {
      setLayout({
        ...layout,
        path: `${slugifiedName}`,
        physical_file_path: `views/layouts/${slugifiedName}.liquid`,
      });
      setMetadata({
        ...metadata,
        name: event.target.value as string,
      });
    } else if (event.target.name === "type") {
      setMetadata({
        ...metadata,
        type: slugifiedName as string,
      });
    }
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    createLayout({
      variables: {
        physical_file_path: layout.physical_file_path,
        path: layout.path,
        body: layout.body,
        metadata: metadata,
      },
    });
  };

  return (
    <FormLayout
      loading={mutationLoading}
      formHeading="Create Site Layout"
      backLink={Routes.LAYOUTS_LINK(params.site)}
      size="lg"
    >
      {mutationError ? (
        <ErrorToast open={true} errorMessage={mutationError.message} />
      ) : null}
      <LayoutForm
        handleChange={handleChange}
        handleBodyChange={handleBodyChange}
        handleMetadataChange={handleMetadataChange}
        handleSubmit={handleSubmit}
        isFetching={false}
        layout={layout}
        metadata={metadata}
        isSubmitting={mutationLoading}
      />
    </FormLayout>
  );
};

export default CreateLayout;
