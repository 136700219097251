import React from 'react';
import { TextField, Grid } from '@material-ui/core';
import { PropertyValue } from '../../pages/site/entities/create';

const TextAreaInput = (props: TextAreaInputProps) => {

    return(
        <Grid item xs={12}>
            <p className="entity__inputHeading">{props.propertyValue.display_name}</p>
            <TextField 
                value={props.propertyValue.value}
                variant="outlined"
                onChange={(event) => props.handleChange((event.target.value as string), props.index)}
                multiline
                rows={6}
                placeholder="Text Area Input"
                fullWidth
            />
        </Grid>
    );
}

interface TextAreaInputProps {
    index: number
    propertyValue: PropertyValue
    handleChange: (value: string, index: number) => void
}

export default TextAreaInput;