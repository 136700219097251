import { gql } from "@apollo/client";

const DELETE_SINGLE = gql`
  mutation item_delete($id: ID!, $table: String!) {
    record_delete(table: $table, id: $id) {
      id
    }
  }
`;

export default DELETE_SINGLE;
