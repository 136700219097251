export interface SiteMode {
    isDarkMode: boolean
}

export const TOGGLE_DARK_MODE = "TOGGLE_DARK_MODE";

interface toggleDarkMode {
  type: typeof TOGGLE_DARK_MODE
}

export type SiteModeActions = toggleDarkMode