import { gql } from '@apollo/client';

const GET_INFO_PAGES = gql`
query get_info_category($info_collection_id: Int $per_page: Int = 2000 $page: Int = 1) {
  models(
    filter: { 
      name: { exact: "modules/infopages/info_category" }, 
      properties: [

        { name: "info_collection_page_id" value_int: $info_collection_id },
      ],
      deleted_at: { exists: false } 
    }, 
    per_page: $per_page, 
    page: $page,
  ) {
    results {
      id
      created_at
      updated_at
      properties
    }
  }
}
  `

export default GET_INFO_PAGES;