import { Link as RouterLink } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import * as Routes from "../../../routes";
import { useSelector } from "react-redux";

const FormActionCell = (props) => {
  const state = useSelector((state) => state);
  return (
    <>
      <IconButton
        component={RouterLink}
        to={Routes.CITY_EDIT_LINK(props.site, props.value)}
      >
        <EditIcon />
      </IconButton>
      {state.current_site.site?.build ? (
        <IconButton
          component={RouterLink}
          to={Routes.CITY_CONTENT_LINK(props.site, props.value)}
        >
          <ChevronRightIcon />
        </IconButton>
      ) : null}
    </>
  );
};

export default FormActionCell;
