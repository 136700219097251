import Settings from "./Settings";
import logo from "../../assets/WaveDXP_Logo_Text.svg";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import * as Routes from "../../routes";
import Avatar from "./User";
import { AppBar, Toolbar, Button } from "@material-ui/core";

function InstanceAppbar() {
  const classes = useStyles();
  return (
    <AppBar className={classes.appbar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.appbar_left}>
          {/* <Button component={RouterLink} to={Routes.INSTANCE_PORTAL}>
            <img className={classes.logo} src={logo} alt="SmartyrDXP Logo" />
          </Button> */}
        </div>
        <div className={classes.appbar_right}>
          <Settings />
          <Avatar />
        </div>
      </Toolbar>
    </AppBar>
  );
}

const useStyles = makeStyles((theme) => ({
  appbar_left: {
    display: "flex",
    alignItems: "center",
  },
  appbar_right: {
    display: "flex",
    alignItems: "center",
  },
  toolbar: {
    justifyContent: "space-between",
    display: "flex",
  },
  logo: {
    height: "40px",
    marginRight: theme.spacing(1),
  },
  appbar: {
    boxShadow: "none",
    backgroundColor: "transparent",
    top: 'auto',
    bottom: 0,
  }
}));

export default InstanceAppbar;
