import React from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
function CityForm(props: CityFormProps) {
  return (
    <div className="form">
      {props.isFetching ? (
        <Skeleton variant="rect" width={"100%"} height={56} />
      ) : (
        <TextField
          variant="outlined"
          type="text"
          name="suburb"
          onChange={(e) => props.handleChange(e, props.suburb, props.setSuburb)}
          value={props.suburb.suburb}
          label="Name"
          fullWidth
        />
      )}

      {props.isFetching ? (
        <Skeleton variant="rect" width={"100%"} height={56} />
      ) : (
        <TextField
          variant="outlined"
          type="text"
          name="slug"
          onChange={(e) => props.handleChange(e, props.setSuburb, props.suburb)}
          onBlur={(e) => props.handleBlur(e, props.setSuburb, props.suburb)}
          value={props.suburb.slug}
          label="Slug"
          fullWidth
        />
      )}

      {props.isFetching ? (
        <Skeleton variant="rect" width={"100%"} height={56} />
      ) : (
        <TextField
          variant="outlined"
          type="text"
          name="postcode"
          onChange={(e) => props.handleChange(e, props.setSuburb, props.suburb)}
          value={props.suburb.postcode}
          label="Postcode"
          fullWidth
        />
      )}

      {props.isFetching ? (
        <Skeleton variant="rect" width={"100%"} height={56} />
      ) : (
        <Select
          variant="outlined"
          name="city"
          onChange={props.handleSelectChange}
          value={props.suburb.city_id}
          label="City"
          placeholder="City"
          fullWidth
        >
          {props.cities.map((city: any) => {
            return <MenuItem value={city.id}>{city.properties.name}</MenuItem>;
          })}
        </Select>
      )}

      {props.isFetching ? (
        <Skeleton variant="rect" width={"100%"} height={56} />
      ) : (
        <FormControl variant="outlined">
          <InputLabel>Services</InputLabel>
          <Select
            multiple
            native
            name="services"
            onChange={props.handleMultiChange}
            value={props.suburb.service_ids}
            label="Services"
            placeholder="Services"
            fullWidth
          >
            {props.services.map((service: any) => {
              return (
                <option value={service.id} key={service.id}>
                  {service.properties.name}
                </option>
              );
            })}
          </Select>
          <FormHelperText>
            <span>
              Main services is the direct correlation to the Main Services in
              the Collection. Services is related to the data in the DB.
            </span>
          </FormHelperText>
        </FormControl>
      )}

      {props.isFetching ? (
        <Skeleton variant="rect" width={"100%"} height={56} />
      ) : (
        <FormControl variant="outlined">
          <InputLabel>Main Services</InputLabel>
          <Select
            multiple
            native
            name="mainServices"
            onChange={props.handleMultiChange}
            value={props.suburb.service_ids}
            label="Main Services   a "
            placeholder="Services"
            fullWidth
          >
            {props.mainServices.map((service: any) => {
              return (
                <option value={service.id} key={service.id}>
                  {service.title}
                </option>
              );
            })}
          </Select>
        </FormControl>
      )}

      <div className="form_button">
        {props.isFetching ? (
          <Skeleton variant="rect" width={"100%"} height={56} />
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={props.handleSubmit}
            disabled={props.isSubmitting}
          >
            {props.isSubmitting ? <CircularProgress /> : "Submit"}
          </Button>
        )}
      </div>
    </div>
  );
}

interface CityFormProps {
  suburb: any;
  setSuburb: React.Dispatch<React.SetStateAction<any>>;
  cities: any[];
  services: any[];
  mainServices: any[];
  handleSubmit: any;
  handleChange: any;
  handleBlur: any;
  handleSelectChange: any;
  handleMultiChange: any;
  isFetching: boolean;
  isSubmitting: boolean;
}

export default CityForm;
